body.contentmanager-contenttype-centre_op {
    .banner-heading {
        margin-bottom: 3.5rem;
    }

    //TODO factorise style with .product-authors ?
    .responsables {
        margin-bottom: 3rem;
        padding-left: 1.75rem;
        border-left: 0.5rem solid $primary;

        .responsables__label {
            display: inline-block;
            margin-bottom: 0.5rem;
            font-weight: 700;
        }

        .author {
            display: inline-block;

            &:not(:first-child) {
                &::before {
                    content: "-";
                    font-weight: 700;
                }
            }
        }
    }

    .box-section {
        margin-bottom: 3rem;
    }
}
