#confirmBox {
  border-radius: 0;

  .added-item {
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
    color: $black;
    font-size: 1.25rem;

    &:hover {
      color: $dark-green;
    }
  }
}

.amcart-confirm-buttons {
  .button {
    &.am-btn-right {
      @include btn;
      width: unset;
      font-size: 1.125rem;
      font-weight: 700;
    }

    &.am-btn-left {
      display: none;
    }
  }

  .checkout {
    display: none;
  }
}
