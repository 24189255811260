.mgt-xxs { display: block; font-weight: 900; line-height: 45px; }
.mgt-xs  { display: none;  font-weight: 900; line-height: 45px; }
.mgt-sm  { display: none;  font-weight: 900; line-height: 45px; }
.mgt-md  { display: none;  font-weight: 900; line-height: 45px; }
.mgt-lg  { display: none;  font-weight: 900; line-height: 45px; }
.mgt-hd  { display: none;  font-weight: 900; line-height: 45px; }

.mgt-developer-toolbar-logo {
    margin-right: 5px;
}
//.mgt-developer-toolbar-blocks {
//    padding: 0;
//    .mgt-developer-toolbar-block {
//        padding: 0;
//        margin: 0;
//        &-magento-version,
//        &-php-parsetime,
//        &-memory-consumption,
//        &-database-queries {
//            svg {
//                width: 12px;
//                height: 12px;
//            }
//            a span {
//                display: none;
//            }
//        }
//        &-info {
//            padding: 0 10px;
//            line-height: 35px;
//            margin: 2px;
//            a {
//                padding: 0;
//                font-size: 90%;
//                span {
//                    padding: 3px 5px;
//                }
//            }
//        }
//    }
//}
