.related-contents {
    .card-item__footer {
        .price-label {
            display: none;
        }

        .price-box {
            color: $lighter-grey;
        }

        .price-container.price-final_price {
            color: $primary;

            .price {
                font-size: 1.75rem;
                font-weight: 700;
            }
        }

        .old-price {
            .price-container.price-final_price {
                color: $lighter-grey;

                .price {
                    font-size: 0.875rem;
                    font-weight: 400;
                }
            }
        }
    }
}
