.product-formation {
    .product-formation__description{
        margin-bottom: 3rem;
    }
    .formation-type-container {
        padding: 1.75rem 0;
        background-color: $orange;
        color: $white;

        .formation-type {
            letter-spacing: 0.25em;
            text-transform: uppercase;

            &::before {
                margin-right: 1rem;
                vertical-align: middle;
                color: $white;
                font-size: 2rem;
            }
        }
    }

    .formation-textlist {
        .formation-textlist__title {
            display: inline-block;
            margin-bottom: 1.25em;
            font-weight: 700;
        }

        .formation-textlist__textlist {
            color: $primary;

            p {
                margin-bottom: 0.5em;
            }
        }
    }

    .formation-sessions {
        .formation-sessions__title {
            display: inline-block;
            margin-bottom: 1.25em;
            font-weight: 700;
        }

        .formation-sessions__label {
            color: $primary;
            margin-bottom: 1rem;
        }

        .formation-sessions__list {
            margin-bottom: 0.5rem;
            color: $primary;
        }

        .formation-sessions__item {
            margin-bottom: 0.5rem;
        }

        .formation-sessions__date {
            text-transform: capitalize;
        }
    }

    .sipl__side-container {
        .product-formation__product-image-container {
            position: relative;
        }

        .product-formation__places {
            position: absolute;
            top: 0;
            right: 0;
            display: inline-block;
            padding: 0.5rem 1.25rem;
            background-color: #ffffffee;
            text-transform: lowercase;
            font-weight: 700;
        }

        .formation-request-quote-link {
            @include link-underline($black, 1ch, 1.5rem, 1px);
            font-size: 0.75rem;
            font-weight: 700;
        }
    }

    .formation-textlist {
        margin-bottom: 3rem;
    }

    .product-authors {
        padding-left: 1.75rem;
        border-left: 0.5rem solid $primary;
    }
}
