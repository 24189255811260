html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: $roboto;
    vertical-align: baseline;
}
table:not([border]) td {
    margin: 0;
    padding: 0;
    font-size: 100%;
    font-family: $roboto;
    vertical-align: baseline;
}
strong {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-weight: 700;
    vertical-align: baseline;
}

html {
    scroll-behavior: smooth;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
* {
    box-sizing: border-box;
}

h1 {

}

h2 {

}

h3 {

}

h4 {

}

a {
    text-decoration: none;
    line-height: 1rem;
    &:link,
    &:visited {

    }

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }

    &:active,
    &:focus {
        color: $grey;
    }
}

p {

}

.primary {
    color: $primary;
}

.page.messages {
    margin-top: 1rem;
}

.edition-first-page{
    .breadcrumbs {
        margin: 15px 0 60px !important;
    }
}

.breadcrumbs {
    margin: 53px 0 20px;
}
.breadcrumbs_custom,
.breadcrumbs {
    min-height: calc(0.87rem + 7px);
    .items {
        font-size: 0.87rem;
        color: $primary;
        margin: 0;
        padding: 0;
        list-style: none none;
        height: 100%;
        .item:last-child {
            background-image: linear-gradient($primary, $primary);
            height: 100%;
            background-position: 0 100%;
            background-size: auto 1px;
            background-repeat: no-repeat;
            max-width: 35%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .item:not(:last-child) {
            display: inline-block;
            color: $light-grey;
            text-decoration: none;
            &::after {
                line-height: 18px;
                content: url("data:image/svg+xml;utf8,<svg width=\'8\' height=\'14\' viewBox=\'0 0 8 14\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M1 1L7 7L1 13\' stroke=\'%238F8F8F\'/></svg>");
                margin: 0 1rem;
                padding-top: 2px;
                vertical-align: top;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                text-align: center;
            }
        }
        > li {
            display: inline-block;
            vertical-align: top;
        }
        a {
            @include link-underline($light-grey, 0, 7px, 1px, $light-grey);
            text-decoration: none;
            font-size: 1em;
            &:visited {
                color: $light-grey;
            }
        }
    }
}

.breadcrumbs strong {
    font-weight: 400;
}


.actu-card-sticker {
    z-index: 1000;

    @include card-sticker-colors;
}

.card-link {
    position: relative;
    @include link-underline($black, 1ch, 1.3rem, 1px);
    font-size: 1rem;
    display: inline-block;

    &:visited {
        color: $black;
    }

    &:hover,
    &:focus {
        color: $primary;
        background-image: linear-gradient($primary, $primary);
    }

    &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}

.article-banner {
    margin-top: 50px !important;
    padding-bottom: 0 !important;
    &-title {
        margin: 0 0 1rem 0 !important;
        color: $black !important;
        padding: 0 !important;
        h1, h2, h3 {
            &::before {
                content: unset;
            }
        }
        a.tag {
            color: $light-grey;
            text-transform: lowercase;
            z-index: 100;
            &:hover {
                color: $primary !important;
                text-decoration: none !important;
            }
            &::before {
                content: '#';
            }
        }
    }
}
a.tag {
    font-size: 0.9rem;
    margin-right: 1ch;
    color: inherit!important;
    text-transform: lowercase;
    z-index: 100;
    line-height: 1.2rem;
    &::before {
        content: '#';
    }
}

.block-content {
    .actions-toolbar {
        .secondary {
            .secondary {
                padding: 0.765rem 1.5rem;
                margin-top: 0;
                background-color: $lightest-grey-nearly;
                border: 0 solid transparent;
                border-radius: 2px;
                line-height: 1rem;
                color: $black;
                text-align: center;
                transition: all 0.3s ease;
                width: 100%;
                display: inherit;
                &:hover {
                    background-color: $primary;
                    color: $white;
                }
            }
        }
    }
}

.xxs-only {
    display: block;
}
@media screen and (min-width: $screen-xs) {
    .xxs-only {
        display: none;
    }
}

.xs-only {
    display: none;
}
@media screen and (min-width: $screen-xs) and (max-width: $screen-sm) {
    .xs-only {
        display: block;
    }
}

.sm-only {
    display: none;
}
@media screen and (min-width: $screen-sm) and (max-width: $screen-md) {
    .sm-only {
        display: block;
    }
}

.xs-sm-only {
    display: none;
}
@media screen and (min-width: $screen-xs) and (max-width: $screen-md) {
    .xs-sm-only {
        display: block;
    }
}

.font-size-button-decrease,
.font-size-button-increase {
    margin-left: 20px;
    position: relative;
    width: 1em;
    cursor: pointer;
    color: $black;
    user-select: none;
    &:hover {
        color: $grey;
    }
    &::before {
        position: absolute;
        top: -0.4em;
        font-size: 80%;
    }
    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px red solid;
    }
}

.font-size-button-decrease::before {
    content: '-';
    left: -0.3em;
}
.font-size-button-increase::before {
    content: '+';
    left: -0.5em;
}

.lazy {
    transition: opacity 0.25ms ease;
}
.lazyload {
    opacity: 0;
}

.lazyloaded {
    opacity: 1;
}
.field-recaptcha {
    display: none;
}

.link-no-underline {
    background: none !important;
    transition: all 0.25s ease;
    &:hover,
    &:active {
        opacity: 0.65;
    }
}
.superscript {
    vertical-align: super;
    font-size: 0.75em;
}
.subscript {
    vertical-align: sub;
    font-size: 0.75em;
}
