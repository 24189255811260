.request-form {
    padding-left: $padding-horizontal-xxs;
    padding-right: $padding-horizontal-xxs;

    #quote-request-form-wrapper {
        padding: 2rem;
        border: 1px solid $lighter-grey;
    }

    .webform_form-title {
        display: block;
        margin-bottom: 1.25rem;
        padding-bottom: 0.45rem;
        border-bottom: 1px solid $lighter-grey;
        font-size: 1.5rem;
        font-weight: 700;
    }

    .webforms-description-text {
        margin-bottom: 1.25rem;
    }

    .webforms {
        .fieldset, .field {
            padding-left: 0;
            padding-right: 0;
        }

        label {
            font-weight: 400;
        }

        .field {
            > label {
                margin-bottom: 0.75rem;
            }
        }

        .type-select_radio {
            .field {
                display: inline-block;

                &:not(:first-child) {
                    margin-left: 2rem;
                }
            }
        }

        .actions-toolbar {
            margin-left: 0;
            padding-left: 0;
            padding-right: 0;
        }
    }
}
