//Inspired by : https://nemzes.net/posts/animating-height-auto/

.ctiflAccordion {
    .ctiflAccordion__toggler {
        position: relative;
        display: inline-block;
        padding-right: 3rem;
        width: 100%;
        cursor: pointer;

        &::after {
            content: '\e622';
            position: absolute;
            right: 0;
            display: inline-block;
            vertical-align: middle;
            font-family: 'icons-blank-theme';
            line-height: 1;
        }
    }

    .ctiflAccordion__content {
        display: grid;
        grid-template-rows: 0fr;
        overflow-y: hidden;
        transition: grid-template-rows 0.3s;
    }

    .ctiflAccordion__inside {
        align-self: end;
        min-height: 0;
        transition: visibility 0.3s;
        visibility: hidden;
    }

    &.ctiflAccordion--active {
        .ctiflAccordion__toggler {
            &::after {
                content: '\e621';
            }
        }

        .ctiflAccordion__content {
            grid-template-rows: 1fr;
        }

        .ctiflAccordion__inside {
            visibility: visible;
        }
    }
}
