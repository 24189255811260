.cms-no-route {
    &#html-body {
        .pagebuilder-column-group {
            flex-direction: column;
        }

        .cms-no-route__splash-img,
        .cms-no-route__desc {
            width: 100%;
        }
    }

    .page-header {
        margin-bottom: 0;
    }

    .page-main {
        margin: 0;
        padding: 0;
    }

    .page.messages {
        margin-top: 0;
    }

    .cms-no-route__splash-txt {
        margin: 0 10%;
    }

    .cms-no-route__desc {
        padding: 2rem;

        a {
            @include link-underline($primary, 2ch, 1rem, 1px, $primary);
            display: inline-block;
            height: calc(1rem + 7px);
            color: $primary;
            text-decoration: none !important; //!important to override underline
        }
    }

    .cms-no-route__title {
        font-size: 3rem;
        font-weight: 700;
    }
}
