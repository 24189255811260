////////////// Variables //////////////
// Texts sizes
$text_size_sub_title: 1.2rem;
$text_size_secondary_sub_title: 1rem;
$text_size_corp: 1rem;
$text_size_small: 0.9rem;

@mixin customer-section-title {
    font-size: 1.5rem;
    font-weight: 400;
}

.account {
    .columns {
        flex-direction: column-reverse;
    }
    .page-main {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
    }
    .column.main {
        padding: 0 15px;

        .order-links {
            display: none;
        }

        .order-title strong {
            margin-top: 0;
        }

        .block-dashboard-info {
            margin-bottom: 20px;
        }

        .block:not(.widget) {
            .block-title {
                margin: 1rem 0;
            }

            .block-content {
                p {
                    letter-spacing: 0.5px;
                    font-size: $text_size_corp;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid $primary;
                }

                .box {
                    margin-bottom: 1.25rem;

                    &.subscription-veille,
                    &.subscription-newsletter {
                        margin-bottom: 0;
                        text-align: center;

                        .box-title {
                            width: 100%;
                        }

                        .box-content {
                            .btn {
                                margin-top: 1rem;
                                margin-bottom: 1rem;
                            }
                        }
                    }
                }
            }

            &.block-dashboard-info {
                .block-content {
                    &::before,
                    &::after {
                        display: none;
                    }
                }
            }

            &.block-dashboard-info,
            &.block-dashboard-addresses,
            &.block-addresses-default,
            &.block-veille-reglementaire,
            &.block-newsletter {
                .block-content {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                }

                .box {
                    padding: 1.5rem;
                    width: 100%;
                    border: 1px solid $primary;

                    a,
                    address a,
                    .box-actions a {
                        &:not(.btn) {
                            text-decoration: underline;
                        }
                    }
                }

                &:not(.widget) {
                    .block-content {
                        &::before,
                        &::after {
                            display: none;
                        }
                    }
                }

                &:not(:last-child) {
                    .block-content {
                        padding-bottom: 1rem;
                        border-bottom: 1px solid $primary;
                    }
                }
            }

            &.block-veille-reglementaire {
                .block-content {
                    padding-bottom: 2.5rem !important;
                }
            }
        }

        .block-title strong {
            font-weight: 200;
            line-height: 1.1;
            font-size: $text_size_sub_title;
            color: $grey;
        }
    }

    .account-nav {
        margin-bottom: 20px;
    }

    .account-nav-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        margin: 0;
        border-bottom: 1px solid $lighter-grey;
        border-top: 1px solid $lighter-grey;

        strong {
            @include customer-section-title;
            margin: 0;
        }

        &::after {
            font-family: icons-blank-theme;
            content: '\e622';
            font-size: 2.5rem;
            color: $primary;
        }

        &.active {
            &::after {
                content: '\e621';
            }
        }
    }

    .account-nav-content {
        max-height: 0;
        overflow: hidden;
        transition: max-height 1.5s cubic-bezier(0, 1, 0, 1);
        padding-bottom: 0;

        &.active {
            max-height: 30rem;
            transition: max-height 1s ease-in-out;
            border-bottom: 1px solid $lighter-grey;
            padding-bottom: 10px;
        }

        li {
            margin-top: 0;
        }
    }

    .block {
        .block-title a {
            letter-spacing: 0.5px;
            font-size: $text_size_corp;
            color: $primary;
        }

        .box {
            .box-actions .change-password {
                margin-left: 50px;
            }
        }
    }

    .form-address-edit .fieldset,
    .form-newsletter-manage .fieldset,
    .form-edit-account .info {
        margin-bottom: 0;
    }

    .form-edit-account {
        .fieldset.info {
            margin: 2rem 0;
        }

        .additional_info,
        .fieldset.password {
            margin: 0;
        }
    }

    .form-address-edit .field.telephone {
        margin-bottom: 2rem;
    }

    div.block-content.amgdpr-block-content > form > p.field.control.checkbox {
        position: relative;

        input[type='checkbox'] {
            margin: 0;
        }
    }

    form .fieldset .legend {
        margin-bottom: 2rem;
        font-weight: 200;
        line-height: 1.1;
        font-size: $text_size_sub_title;
        color: $grey;
    }

    .form-newsletter-manage .fieldset .legend {
        display: none;
    }

    .loyaltycard p,
    form,
    .message.info {
        font-size: $text-size_corp;
    }

    .actions-toolbar {
        display: flex;
        flex-direction: column;
        margin-left: 0 !important;

        .primary {
            display: flex;
            justify-content: center;

            button {
                margin-top: 1.5rem;
            }
        }

        .secondary {
            margin-top: 0.5rem;
            display: flex;
            justify-content: flex-start;

            a {
                letter-spacing: 0.5px;
                font-size: $text_size_corp;
                color: $primary;
            }
        }
    }

    table {
        th,
        td {
            padding: 0.25rem 0.5rem !important; //override overly specific selector from _blank
            width: 1%;
        }
    }

    //////////////// Tableaux ////////////////

    .orders-recent {
        width: 100%;
    }

    .table-downloadable-products,
    .table-additional-addresses-items,
    .table-order-items {
        tbody {
            tr {
                padding: 0.5rem;

                &:nth-child(odd) {
                    background-color: $primary-lighter;
                }
            }
        }

        thead th {
            text-align: center;
        }

        td.col.total {
            color: $primary;
            font-weight: 700;
        }

        .col {
            &.actions {
                &.order,
                &.view svg {
                    display: none !important; //override overly specific selector from _blank
                }

                &.view::before {
                    display: none !important; //override overly specific selector from _blank
                }
            }

            &.id {
                text-align: center;
                max-width: calc(7ch + 1.5rem);
            }

            &.date {
                max-width: calc(8ch + 1.5rem);
            }

            &.view {
                max-width: calc(3ch + 1rem);
            }

            &.order {
                max-width: calc(7ch + 1rem);
            }
        }

        tbody {
            .col.id {
                padding-top: 1rem !important; //override overly specific selector from _blank
            }

            .col.actions.view {
                padding-bottom: 1rem !important; //override overly specific selector from _blank
            }
        }

        .col {
            width: 100%;
            line-height: 1.3rem;
            letter-spacing: 0.5px;
            font-size: $text_size_corp;
            vertical-align: middle;
        }

        td.col.actions {
            color: $primary;
        }

        .price-excluding-tax {
            font-size: $text-size_corp;
            line-height: normal;
            vertical-align: auto;
        }

        .item-options dd {
            margin-left: 0.5rem;
        }
    }

    .order-products-toolbar .pager {
        display: flex;
        flex-direction: column;

        p {
            margin-bottom: 0.5rem;
        }

        select {
            color: $grey;
            font-size: $text_size_small;
            cursor: pointer;
        }
    }

    .password-row {
        display: flex;

        .change-password {
            margin-left: auto;
        }
    }
}

#activity-type {
    flex-direction: row-reverse;
    justify-content: start;
}

.ticket-listing-surtitle {
    margin-bottom: 0.5rem;
}

.ticket-listing {
    --nb-colmuns: 1;

    display: grid;
    grid-template-columns: repeat(var(--nb-colmuns), 1fr);
    gap: 2.5rem;
    margin-bottom: 2rem;
}

.ticket-item {
    padding: 0.75rem;
    text-align: center;

    @include box-shadow;
}

.ticket-item__img-container {
    margin-top: 0.5rem;
    width: 100%;
    height: 6rem;
}

.ticket-item__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.ticket-item__text {
    margin-bottom: 1rem;
}

.ticket-item__link {
    margin: 0 auto;
}

.account-nav {
    .content {
        padding: 15px 0;
    }
    .item {
        margin: 10px 0 0;

        &:first-child {
            margin-top: 0;
        }

        a,
        > strong {
            display: block;
            padding: 1rem 2rem;
        }

        a {
            font-family: "Roboto", sans-serif;
            font-size: $text_size_corp;
            color: $black;
        }

        &.current {
            a,
            strong {
                background-color: $primary;
                color: $white;
                font-size: $text_size_corp;
                font-weight: 700;
            }
        }

        .delimiter {
            border-top: 1px solid $primary;
            display: block;
            margin: 10px 0;
            opacity: 0.25;
        }
    }
}

.customer_account_dashboard_title {
    @include customer-section-title;
    margin-bottom: 0.5rem;
    margin-top: 0;
}

.box {
    .box-header {
        display: flex;
        margin-bottom: 0.25rem;

        .box-title {
            margin: 0;
        }

        .action {
            margin-left: auto;
        }
    }

    .box-title {
        font-weight: 700;
        font-size: $text_size_secondary_sub_title;
        color: $primary;
    }

    .box-content {
        letter-spacing: 0.5px;
        font-size: $text_size_corp;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        hyphens: auto;
        line-height: 26px;

        address {
            line-height: 1.2rem;

            a {
                color: $primary;
            }
        }
    }

    .box-actions a {
        letter-spacing: 0.5px;
        font-size: $text_size_corp;
        color: $primary;
    }

    &.box-information,
    &.box-billing-address,
    &.box-address-billing {
        background-color: $primary;
        color: $white;

        .box-title {
            color: $white;
        }

        a,
        address a,
        .box-actions a {
            color: $white;
        }
    }
}

.subscription-product-blocks {
    margin-top: 3em;

    .fotorama__img {
        height: 36rem;
    }

    .subscription-product-infos {
        margin-top: 10em;
        padding: 2em;

        .product-price {
            display: flex;
            border-bottom: 1px solid $lighter-grey;
            margin-bottom: 1em;
            padding-bottom: 0.5em;

            .price,
            .stock span {
                font-size: 1rem;
                padding-right: 0.5em;
            }

            .product-info-attributes-container {
                margin-left: auto;
            }
        }
    }
}
