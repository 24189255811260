

.title-block {
    margin: 3.2rem 0;

    h1, .title-block__title--main {
        font-size: 2.5rem;
    }

    h2, .title-block__title {
        font-size: 1.7rem;
    }

    h1, h2, .title-block__title {
        position: relative;
        font-weight: 700;
        margin-bottom: 1rem;
        &::first-line {
            margin-left: -5vw;
        }

        &::before {
            content: '\00a0';
            position: absolute;
            top: 0;
            left: -1rem;
            display: block;
            width: 100vw;
            height: 1em;
            transform: translateX(-100%);
            background-image: linear-gradient($primary, $primary);
            background-position: 0 50%;
            background-repeat: no-repeat;
            background-size: 100% 4px;
        }
    }

    h3,
    .SURTITRE_par,
    .title-block__subtitle {
        margin-top: 0.625rem;
        font-size: 1.2rem;
        font-weight: 400;
    }
    &.white-banner-title {
        color: $white;

        h1, h2 {
            &::before {
                background-image: linear-gradient($white, $white);
            }
        }
    }
}
