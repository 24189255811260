$vertcial-thread-left-margin: 18px;

.cms-page-view .column.main,
[data-content-type="row"],
.pagebuilder-column-group,
.product.attribute.overview {
    @include wysiwyg-content-styles;

    [data-content-type="text"] {
        a {
            color: $primary;
            text-decoration: underline;
        }
    }

    .pagebuilder-button-primary, .pagebuilder-button-secondary {
        @include btn;

        &:hover {
            border: 0 solid transparent;
            color: $white;
            cursor: pointer;
        }
    }

    .pagebuilder-button-link {
        @include button-to-link;
    }
}

body.cms-page-view {
    .column.main {
        padding: 0;
    }
    .pagebuilder-column:first-child {
        &.navigator-column {
            min-width: 0 !important;
            width: 0;
            overflow: hidden;
        }
        div {
            height: 100%;
            .who-are-we-navigator {
                display: none;
                height: auto;
                position: sticky;
                top: 165px;
                width: 280px;
                background-color: $lightest-grey;
                &-body {
                    padding: 10px 20px;
                    &-summary {
                        list-style: none;
                        padding: 0;
                        position: relative;
                        li {
                            margin-left: $vertcial-thread-left-margin;
                            margin-bottom: 0;
                            padding-top: 0.5rem;
                            padding-bottom: 0.5rem;
                            position: relative;
                            &::before {
                                content: '';
                                width:  0.5rem;
                                height: 0.5rem;
                                border-radius: 50%;
                                background-color: $lighter-grey;
                                position: absolute;
                                left: -$vertcial-thread-left-margin;
                                transform: translate(-50%, 0.25rem);
                                line-height: 1rem;
                                transition: all 0.25s ease-in;
                                transition-delay: 250ms;
                                z-index: 3;
                            }
                            &::after {
                                content: '';
                                width: 1px;
                                height: 100%;
                                position: absolute;
                                transform: translateX(-100%);
                                top: 1rem;
                                background-color: $lighter-grey;
                                left: -$vertcial-thread-left-margin;
                                transition: all 0.25s ease;
                                z-index: 1;
                            }

                            &:last-child::after,
                            &.passed:last-child::after,
                            &.active:last-child::after {
                                content: unset;
                            }
                            &.passed::after,
                            &.passed::before,
                            &.active::before {
                                background-color: $primary;
                            }
                            &.active::before {
                                width:  0.75rem;
                                height: 0.75rem;
                                z-index: 100;
                            }
                            a {
                                color: $black;
                                &:hover {
                                    color: $primary;
                                }
                            }
                        }
                        .summary-H2 {
                            padding: .75rem 0 0;
                            font-weight: 700;
                            font-size: 1rem;
                        }
                        .summary-H3 {
                            padding: 0.3rem 0 0.3rem 20px;
                            font-size: 0.8em;
                        }
                    }
                }
                &-footer {
                    background-color: $lightest-grey-nearly;
                    font-family: $roboto;
                    font-size: 0.8rem;
                    color: $black;
                    display: flex;
                    justify-content: space-around;
                    flex-direction: row;
                    align-items: center;
                    padding: 0;
                    li {
                        padding: 10px;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        margin-bottom: 0;
                        position: relative;

                        a {
                            font-family: $roboto !important;
                            color: $black;
                            &:hover,
                            &:active {
                                font-weight: 700;
                                color: $black;
                            }
                            &::before {
                                color: $black;
                                margin-right: 1rem;
                                font-size: 120%;
                                transform: translateY(-30%);
                            }
                            &::after {
                                position: absolute;
                                content: '';
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    li:not(:first-child)::before {
                        content:'';
                        position: absolute;
                        width: 1px;
                        height: 50%;
                        left: 0;
                        background-color: $black;
                    }

                }
            }
        }
    }
    .pagebuilder-column:last-child {
        width: 90%;
        margin: 0 auto;
    }
}

.pagebuilder-slider .pagebuilder-slide-wrapper {
    max-width: 75%;
    height: calc((75vw - 1.5rem) / 4 * 3);
    margin: 0 auto;
}
