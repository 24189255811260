:root {
    --container-width: #{$container-xxs};
}

html, body {
    font-size: 12px;
}

.title-block {
    padding-left: calc(var(--container-width) / 12);;
}

.contentmanager-contenttype-centre_op__content {
    padding-left: calc(var(--container-width) / 12);
    padding-right: calc(var(--container-width) / 12);
}

@media only screen and (max-width: 768px) {
    .breadcrumbs,
    .page-main,
    .page-wrapper > .widget,
    .page-wrapper > .page-bottom,
    .block.category.event,
    .top-container,
    .newsletter-inner,
    .block-content-footer,
    .footer-copyright {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        width: 100%;
    }
}

.webview_logo {
    display: none !important;
}

// Container size
//@include container-sizing($container-xxs);
