/****** GENERAL HEADER VARS  ******/

$header-icon-font-size: 1.25rem;
$header-link-element-height: 2rem;

$header-mobile-link-padding: 0.75rem;

$mobile-nav-icon: 2rem;

$panel-header__line-height: 25px;

$switcher-language__padding: 0.5rem;

$HEADER_HEIGHT: 4 * $header-mobile-link-padding + $header-link-element-height;
$HEADER_OUTER_PADDING: calc(2 * #{$header-mobile-link-padding});

/****** ALGOLIA SEARCHBAR SPECIFIC VARS  ******/

$algolia-search-input-v-margin: 1rem;
$algolia-search-input-height: 2.5rem;

$ALGOLIA_SEARCH_HEIGHT: calc(#{$algolia-search-input-height} + 2 * #{$algolia-search-input-v-margin});

.page-header {
    position: sticky;
    top: 0;
    z-index: 899;
    padding: $header-mobile-link-padding;
    background-color: $white;
    @include light-box-shadow;

    .has-welcome-banner {
        top: 32px !important;
    }

    .header-banner-wrapper {
        margin: 0 -0.75rem;
        margin-top: -0.75rem;
    }

    .header-banner {
        background-color: $dark-green;
        color: $white;
        line-height: 32px;
        padding: 0 0.75rem;
        display:block;

        .welcome-wrapper {
            height: $welcome-banner-height;
        }

        a {
            color: $white;
            float: right;
            line-height: 16px;
            text-decoration: underline;
            padding-top: 9px;

            &:active,
            &:hover {
                color: $white;
            }
        }
    }

    .logo {
        float: none;

        img {
            padding: $header-mobile-link-padding;
            height: 3.5rem;
        }
    }

    .panel {
        &.wrapper {
            float: right;
            padding-right: calc(2 * #{$header-mobile-link-padding} + #{$header-link-element-height}); //padding-right equal to the absolute positionned nav-toggle
            width: 60%;
            border-bottom-color: $lighter-grey;

            .header-socials {
                display: none;
                float: left;

                .header-socials__contact,
                .header-socials__socials {
                    display: inline-block;
                    vertical-align: middle;
                }

                .header-socials__contact {
                    margin: 0 3rem;

                    &::before {
                        margin-right: 1rem;
                    }

                    &:hover {
                        color: $black;

                        &::before {
                            color: $black;
                        }
                    }
                }

                .header-socials__socials {
                    position: relative;
                    padding-left: 2rem;

                    @include header-separator;

                    .socials__link {
                        line-height: $panel-header__line-height;
                    }

                    &::after {
                        left: 0;
                        border-color: $grey;
                    }
                }
            }
        }

        &.header {
            color: $grey;

            a {
                color: $grey;
            }

            .header.links li {
                margin-left: 0;
            }

            .toplink-user-account {
                font-size: $header-icon-font-size;
                height: $header-link-element-height;

                .icon-account {
                    line-height: $header-link-element-height;

                    &::before {
                        line-height: $header-link-element-height;
                    }

                    &:hover {
                        &::before {
                            color: $black;
                        }
                    }
                }
            }

            .minicart-wrapper {
                float: right;
                font-size: $header-icon-font-size;

                .action.showcart {
                    padding: $header-mobile-link-padding;
                    vertical-align: top;

                    &::before {
                        line-height: $header-link-element-height;
                        width: $header-link-element-height;
                    }
                }
            }

            .switcher-language {
                @include header-separator;
                position: relative;
                float: left;
                margin-right: 0;
                padding-right: 1.5rem;
                line-height: $panel-header__line-height;

                &::after {
                    border-color: $grey;
                }

                .switcher-options {
                    ul.switcher-dropdown {
                        margin-left: -0.5rem;
                        border: 0 solid transparent;

                        &::before, &::after {
                            content: '';
                            display: none;
                        }

                        a {
                            padding: 0.5rem;
                        }
                    }
                }
            }
        }
    }

    .header.content {
        width: 40%;
        min-width: 100px;
    }

    .nav-sections {
        height: calc(100% - #{$HEADER_HEIGHT});
        z-index: 99;

        [id="header.mobile.header.socials"] {
            display: block !important;

            .header-socials__contact {
                display: inline-block;
                padding: 0.75rem calc(2 * 0.75rem);
                width: 100%;
                border-bottom: 1px solid $lighter-grey;
                color: $black;
                font-size: 1.25rem;
                line-height: 1.2;

                &::before {
                    @include icomoon-base();
                    @include icon-envelope();
                    display: inline-block;
                    margin-right: $header-mobile-link-padding;
                    width: $mobile-nav-icon;
                    text-align: center;
                    font-size: 1.5rem;
                    line-height: inherit;
                    vertical-align: middle;
                }
            }

            .header-socials__socials {
                display: none;
            }
        }
    }

    .mobile-nav-footer {
        padding: 0 $HEADER_OUTER_PADDING;
        width: 100%;
    }

    .navigation {
        background-color: transparent;

        .menu-group-title {
            > span, > a {
                color: $primary;
                font-size: 1.25rem;
                font-weight: 600;
            }
        }

        .level0 {
            > .level-top {
                color: $black;
                padding: 0;

                .ui-menu-icon {
                    display: none;
                }
            }

            > span, > a {
                display: inline-block;
                padding: $header-mobile-link-padding $HEADER_OUTER_PADDING;
                width: 100%;
            }
        }

        li.level0 {
            //&.first > ul {
            //    display: block !important; //used for debug purpose
            //}

            &.mobile-nav-icon {
                > a.level-top span, > span {
                    &::before {
                        @include icomoon-base();
                        display: inline-block;
                        margin-right: $header-mobile-link-padding;
                        width: $mobile-nav-icon;
                        text-align: center;
                        font-size: 1.5rem;
                    }
                }

                &.mobile-nav-icon--evenements {
                    > a.level-top span, > span {
                        &::before {
                            @include icon-evenements();
                        }
                    }
                }

                &.mobile-nav-icon--actualites {
                    > a.level-top span, > span {
                        &::before {
                            @include icon-actualites();
                        }
                    }
                }

                &.mobile-nav-icon--publications {
                    > a.level-top span, > span {
                        &::before {
                            @include icon-publications();
                        }
                    }
                }

                &.mobile-nav-icon--produits-services {
                    > a.level-top span, > span {
                        &::before {
                            @include icon-produits_services();
                        }
                    }
                }

                &.mobile-nav-icon--especes {
                    > a.level-top span, > span {
                        &::before {
                            @include icon-especes();
                        }
                    }
                }

                &.mobile-nav-icon--formations {
                    > a.level-top span, > span {
                        &::before {
                            @include icon-formations();
                        }
                    }
                }

                &.mobile-nav-icon--proj-resultats {
                    > a.level-top span, > span {
                        &::before {
                            @include icon-proj_resultats();
                        }
                    }
                }

                &.mobile-nav-icon--ctifl {
                    > a.level-top span, > span {
                        &::before {
                            @include icon-ctifl();
                        }
                    }
                }
            }

            ul.level0.submenu {
                background-color: $lightest-grey;
            }

            &.big {
                li.level1.container {
                    padding: 0;
                    max-width: 100%;

                    > span {
                        display: none;
                    }
                }

                li.level2.col {
                    padding-right: 0;
                    padding-left: 0;

                    > span {
                        display: none;
                    }

                    > ul {
                        max-width: 100%;
                    }
                }

                li.level3 {
                    > span, a {
                        display: inline-block;
                        padding: $header-mobile-link-padding $HEADER_OUTER_PADDING $header-mobile-link-padding calc(2 * #{$HEADER_OUTER_PADDING});
                        width: 100%;
                    }

                    a {
                        color: $black;

                        &:hover {
                            color: $primary;
                        }
                    }

                    //hide Magento blocks (img links) on mobile
                    &.menu-img-link {
                        display: none;
                    }
                }

                li.level4 {
                    a {
                        padding: $header-mobile-link-padding $HEADER_OUTER_PADDING $header-mobile-link-padding calc(3 * #{$HEADER_OUTER_PADDING});
                        width: 100%;
                    }
                }

                .pagebuilder-column-group {
                    display: none !important; //Hide cms block content on mobile
                }
            }

            &:not(.big) {
                a.level1 {
                    padding: $header-mobile-link-padding $HEADER_OUTER_PADDING $header-mobile-link-padding calc(2 * #{$HEADER_OUTER_PADDING});
                    width: 100%;
                    color: $black;
                    font-weight: 400;

                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }

    .nav-toggle {
        position: absolute;
        top: $header-mobile-link-padding;
        right: $header-mobile-link-padding;
        z-index: 14;
        padding: $header-mobile-link-padding;
        font-size: $header-icon-font-size;

        &::before {
            line-height: $header-link-element-height;
            width: $header-link-element-height;
        }
    }

    .algolia-search-block {
        @include mobile-navigation-closed;
        top: $HEADER_HEIGHT;
        z-index: 149;
        padding: 0 $HEADER_OUTER_PADDING;

        .algolia-search-input {
            margin: $algolia-search-input-v-margin 0;
            padding: 0 2.5rem 0 1.25rem;
            height: $algolia-search-input-height;
            line-height: $algolia-search-input-height;
            border: 1px solid transparent;
            border-radius: 2px;
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
            color: $grey;

            &::placeholder {
                color: $grey;
            }
        }

        .magnifying-glass {
            top: 50%;
            bottom: auto;
            transform: translateY(-50%);
        }
    }

    .nav-sections-items {
        margin-top: $ALGOLIA_SEARCH_HEIGHT;
    }

    [id="header.mobile.header.settings"] {
        display: none;
    }
}

.socials {
    &__link {
        line-height: 20px;

        &:hover {
            .socials__icon {
                fill: $black;
            }
        }
    }

    &__item {
        float: left;
        margin: 0 0.75rem;
    }

    &__icon {
        display: block;
        fill: $grey;
        transition: fill 0.15s ease-in-out;
    }
}

body.edition-page {
    .page-header {
        //padding: $header-mobile-link-padding $header-mobile-link-padding 0;
        margin-bottom: 0;
        //        border-bottom: none;
        //        .panel.wrapper {
        //            margin-right: $header-mobile-link-padding;
        //        }
        //        .header.content {
        //            margin-left: $header-mobile-link-padding;
        //        }
        //
    }
}

@media only screen and (max-width: 768px) {
    .nav-open {
        .algolia-search-block {
            @include light-top-inset-box-shadow;
            left: 0;
            background-color: $white;
            border-bottom: 1px solid $lighter-grey;
        }
    }

    .page-header {
        .navigation {
            li.level0 {
                &.big {
                    > span, a.level0 > span {
                        &::after {
                            content: '\e622';
                            position: absolute;
                            top: 0.25rem;
                            right: 0.5rem;
                            display: inline-block;
                            vertical-align: middle;
                            text-align: center;
                            font-size: 2rem;
                            font-family: 'icons-blank-theme';
                        }
                    }

                    &.level0-active-mobile {
                        > span, a.level0 > span {
                            &::after {
                                content: '\e621';
                            }
                        }
                    }
                }
            }
        }

        [id="header.mobile.header.settings"] {
            display: block !important;

            .switcher-language {
                border-top: none;
                font-size: 1rem;

                .switcher-label {
                    display: none;
                }

                .switcher-trigger {
                    strong {
                        &::after {
                            position: unset;
                            top: unset;
                            right: unset;
                            line-height: 1rem;
                            font-size: 2rem;
                        }
                    }
                }
            }
        }
    }
}
