$cta__size: 3rem;

#moveToTop {
    position: fixed;
    bottom: 8rem;
    right: 2rem;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    width: $cta__size;
    height: $cta__size;
    border-radius: $cta__size;
    background-color: $primary;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0s 0.3s;

    .moveToTop__icon {
        fill: $white;
    }

    &.active {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s ease, visibility 0s 0s;
    }
}
