$aw-et__workshop-label-width: 11rem;
$aw-et__ticket-type-padding: 1.5rem;

$event-infos__m-bottom: 1.5rem;

@mixin aw-et-hour-section-first-cell {
    position: absolute;
    left: 0.5rem;
    display: inline-block;
    width: $aw-et__workshop-label-width;
}

@mixin aw-et-hour-section-label-padding {
    padding: 0.5rem 1.5rem 0.5rem 0.5rem;
}

.aw-et-product-page {
    .title-block {
        h3 {
            display: none;
        }
    }

    .product-info-main {
        .page-title-wrapper {
            margin-bottom: 1rem;
            border-bottom: 1px solid $lighter-grey;

            .page-title {
                margin-bottom: 0;
            }
        }

        .aw-et__goto-register-btn {
            margin-bottom: 1.5rem;
        }
    }

    .aw-et__form-container-wrapper {
        clear: both;
    }

    .aw-et__stock-status {
        margin-bottom: 1rem;
    }

    .aw-et-hour-grid-section {
        position: relative;
        padding: 0.5rem;
        border: 1px solid $lighter-grey;
        border-radius: 2px;

        .aw-et-hour-grid-section__table-container {
            margin-left: $aw-et__workshop-label-width;
            overflow-x: auto;
        }

        .aw-et-hour-grid-section__table {
            width: auto;
        }

        .aw-et-hour-grid-section__day-separator {
            &:not(:last-child) {
                border-right: 1px solid $lightest-grey;
            }
        }

        .aw-et-hour-grid-section__headings {
            th {
                &:first-child {
                    @include aw-et-hour-section-first-cell;
                    padding: 0;
                }
            }
            span {
                opacity: 0.5;
                color: $primary;
                font-weight: 400;
            }
        }

        .aw-et-hour-grid-section__workshop {
            .aw-et-hour-grid-section__workshop-label {
                @include aw-et-hour-section-first-cell;
                @include aw-et-hour-section-label-padding;
                height: auto;
                border-right: 1px solid $light-grey;
            }

            .aw-et-hour-grid-section__line-sizer-text {
                display: inline-block;
                margin-bottom: 0;
                width: 0;
                overflow: hidden;
            }

            .aw-et-hour-grid-section__line-sizer-text-inner {
                @include aw-et-hour-section-label-padding;
                display: inline-block;
                width: $aw-et__workshop-label-width;
            }

            &.active {
                .aw-et-hour-grid-section__workshop-label {
                    font-weight: 700;
                }
            }
        }

        td {
            padding: 0 0.5rem;
            height: 100%;
            vertical-align: middle;
        }

        .aw-et-hour-grid-section__line-sizer {
            padding: 0;
        }

        .aw-et-hour-grid-section__empty-timeSlot {
            display: block;
            vertical-align: middle;
            margin: 1rem auto 0 auto;
            width: 16px;
            height: 1px;
            background-color: $lighter-grey;
        }

        .aw-et-hour-grid-section__timeSlot {
            min-width: calc(5ch + 1rem);
            margin-bottom: 1rem;
            padding: 0.5rem;
            background-color: $lightest-grey;
            text-align: center;
            color: $black;

            &.active {
                background-color: $primary;
                color: $white;
                font-weight: 700;
            }
        }
    }

    .aw-et-accordion {
        clear: both;
        margin-bottom: $event-infos__m-bottom;

        .aw-et-accordion__toggler {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;

            &::after {
                top: 0.25rem;
                text-align: center;
                color: $black;
                font-size: 2rem;
            }

            &::before {
                content:"";
                position: absolute;
                top: 50%;
                left: 0;
                right: 3rem;
                height: 0.5em;
                border-top: 1px solid $lighter-grey;
                z-index: -1;
            }
        }

        .aw-et-accordion__title {
            padding-right: 1rem;
            background-color: $white;
            font-size: 1.25rem;
            font-weight: 700;
        }

        .aw-et-accordion__content {
            margin-top: 1rem;
        }
    }

    .aw-et-view-options-wrapper {
        .options-container {
            margin-bottom: $event-infos__m-bottom;
            background-color: $lightest-grey;

            .sectors {
                .tickets-type {
                    .qty-inp-block {
                        width: auto;
                        border: none;

                        .button-qty {
                            width: auto;
                            background-color: transparent;
                            color: black;
                            font-size: 0.875rem;

                            &::after {
                                content: "";
                                display: none;
                            }

                            &.increase {
                                font-weight: 700;
                            }

                            &.decrease {
                                display: none;
                            }
                        }

                        .qty {
                            display: none;
                        }
                    }

                    .tickets-type-item {
                        position: relative;
                        padding: $aw-et__ticket-type-padding;

                        .tickets-type-item-content {
                            .item-content-ticket {
                                .ticket-type-storefront-description {
                                    margin-bottom: 0;

                                    .tickets-type-item-content {
                                        .item-content-ticket {
                                            margin-bottom: 0;
                                        }
                                    }

                                    .name {
                                        text-transform: unset;
                                        font-size: 1.25rem;
                                        font-weight: 700;

                                        &:first-letter {
                                            text-transform: uppercase;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .empty-attendee-element {
                        display: none;

                        ~ .qty-inp-block {
                            height: auto;

                            .button-qty.decrease {
                                display: block;
                            }
                        }
                    }
                }
            }

            .attendee-element {
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
                padding: 1.5rem;
                background-color: $white;

                .attendee-element__header {
                    display: inline-flex;
                    flex-direction: column;
                    margin-bottom: 0;
                    margin-left: 0;
                    padding-right: 2rem;
                    width: 100%;

                    &::after {
                        font-size: 1.5rem;
                    }
                }

                .attendee-element__label {
                    white-space: nowrap;
                    font-size: 1.25rem;
                    font-weight: 700;
                }

                .attendee-element__remove {
                    display: inline-block;
                    color: $light-grey;
                    text-decoration: underline;
                }

                .attendee-element__attendee-label {
                    margin-left: auto;
                    padding-left: 1rem;
                    text-align: right;
                }

                .attendee-element__label-part {
                    display: inline-block;
                    white-space: nowrap;

                    + .attendee-element__label-part {
                        margin-left: 1ch;
                    }
                }

                .label {
                    display: inline-block;
                    margin-bottom: 0.25rem;
                    color: $light-grey;
                    font-weight: 400;
                }

                .fieldset {
                    margin-bottom: 0;

                    .ctiflAccordion__inside {
                        overflow-x: hidden;
                    }

                    .field {
                        margin-bottom: 1rem;

                        &.aw-et-hour-grid-field {
                            margin-bottom: 0;
                        }

                        &._required {
                            .label {
                                &::after {
                                    content: '*';
                                    display: inline-block;
                                    color: $red;
                                }
                            }
                        }
                    }

                    .aw-et-dropdown-field {
                        display: none;
                    }
                }

                .field-error {
                    font-size: 0.75rem;
                }

                &.ctiflAccordion--active {
                    .attendee-element__header {
                        margin-bottom: 1rem;
                    }
                }
            }
        }

        .summary-container {
            .summary-detail-block {
                .sector-element {
                    padding-left: 0;

                    &::after {
                        content: '';
                        display: block;
                        clear: both;
                    }
                }

                .ticket-name-wrapper {
                    float: left;
                    width: calc(100% - 15ch);
                    font-size: 1rem;
                    font-weight: 700;
                }

                .summary-ticket-wrapper {
                    margin-bottom: 0.75rem;

                    &::after {
                        content: '';
                        display: block;
                        clear: both;
                    }
                }

                .summ-tickets {
                    float: right;
                    width: 15ch;
                    text-align: right;

                    .subtotal, .subtotal .price-wrapper {
                        color: $light-grey;
                        font-size: 1rem;
                        font-weight: 400;
                    }

                    .subtotal {
                        display: inline-block;
                        margin-bottom: 0.5rem;
                    }

                    .total {
                        display: block;
                        font-size: 1rem;
                        font-weight: 700;
                    }
                }

                .price-wrapper {
                    color: $black;
                    text-align: right;
                    font-size: 2rem;
                }

                .box-tocart {
                    text-align: right;
                }
            }
        }
    }
    .aw-et-sponsor-image {
        margin-top: 2rem;
    }
    .fotorama__stage__frame .fotorama__img {
        height: auto;
        object-fit: unset;
    }
}
