@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans/light/opensans-300.woff2') format('woff2'),
        url('../fonts/opensans/light/opensans-300.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans/regular/opensans-400.woff2') format('woff2'),
        url('../fonts/opensans/regular/opensans-400.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans/semibold/opensans-600.woff2') format('woff2'),
        url('../fonts/opensans/semibold/opensans-600.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans/bold/opensans-700.woff2') format('woff2'),
        url('../fonts/opensans/bold/opensans-700.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src:  url('../fonts/RobotoRegular.woff2') format("woff2"),
        url('../fonts/RobotoRegular.eot') format('embedded-opentype'),
        url('../fonts/RobotoRegular.ttf') format('truetype'),
        url('../fonts/RobotoRegular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: block;
    font-variant: normal;
}
@font-face {
    font-family: 'Roboto';
    src:  url('../fonts/RobotoBold.woff2') format("woff2"),
    url('../fonts/RobotoBold.eot') format('embedded-opentype'),
    url('../fonts/RobotoBold.ttf') format('truetype'),
    url('../fonts/RobotoBold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: block;
    font-variant: normal;
}

@font-face {
    font-family: 'NotoSansBold';
    src:  url('../fonts/NotoSansBold.woff2') format("woff2"),
    url('../fonts/NotoSansBold.eot') format('embedded-opentype'),
    url('../fonts/NotoSansBold.ttf') format('truetype'),
    url('../fonts/NotoSansBold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: block;
    font-variant: normal;
}

@font-face {
    font-family: 'icons-blank-theme';
    src: url('../fonts/Blank-Theme-Icons/Blank-Theme-Icons.woff2') format('woff2'), url('../fonts/Blank-Theme-Icons/Blank-Theme-Icons.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'icons-blank-theme';
    src: url('../fonts/Blank-Theme-Icons/Blank-Theme-Icons.woff2') format('woff2'), url('../fonts/Blank-Theme-Icons/Blank-Theme-Icons.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
