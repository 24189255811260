$opc-sidebar__width: 480px;

body.checkout-index-index {
    .page-header {
        .header.content {
            justify-content: flex-start;
            padding-left: 40px;
            margin: 0 auto;
        }
    }
}
// Hide progress bar for virtual quote (because only 1 step)
body.checkout-index-index.checkout-virtual-quote {
    .opc-progress-bar {
        display: none;
    }
}
.checkout-container {
    position: relative;
    margin: 10px 0;
}
.opc-wrapper {
    margin: 0 0 20px;
    .step-content {
        margin: 0 0 40px;
    }
}
.checkout-index-index {
    .nav-sections,
    .nav-toggle {
        display: none;
    }
    .logo {
        margin-left: 0;
    }
}
.checkout-onepage-success .print {
    display: none;
}

.opc-estimated-wrapper {
    border-bottom: 1px solid #cccccc;
    margin: 0 0 15px;
    padding: 18px 15px;
    .estimated-block {
        font-size: 18px;
        font-weight: 700;
        float: left;
        .estimated-label {
            display: block;
            margin: 0 0 5px;
        }
    }
    .minicart-wrapper {
        .action.showcart::before {
            color: $black;
        }
        .action.showcart:hover:before {
            color: #555555;
        }
    }
}
.opc-progress-bar {
    margin: 0 0 20px;
    counter-reset: i;
    font-size: 0;
    width: 100%;
    max-width: 740px;
    padding-right: 30px;
    .opc-progress-bar-item {
        margin: 0 0 10px;
        display: inline-block;
        position: relative;
        text-align: center;
        vertical-align: top;
        width: 33.33333333333333%;
        &::before {
            background: $lighter-grey;
            top: 19px;
            content: '';
            height: 3px;
            left: 0;
            position: absolute;
            width: 100%;
        }
        &:first-child:before {
            border-radius: 6px 0 0 6px;
        }
        &:last-child:before {
            border-radius: 0 6px 6px 0;
        }
        > span {
            display: inline-block;
            padding-top: 65px;
            width: 100%;
            word-wrap: break-word;
            color: $black;
            font-weight: 300;
            font-size: 0.75rem;
            line-height: 1.2rem;
            &::before,
            ::after {
                position: absolute;
                left: 50%;
                top: 0;
                height: 44px;
                width: 44px;
                background: $lighter-grey;
                border-radius: 50%;
                content: '';
                transform: translateX(-50%);
            }
            &::after {
                position: absolute;
                background: none;
                left: 50%;
                top: 13px;
                content: counter(i);
                counter-increment: i;
                color: $white;
                font-weight: 900;
                font-size: 1.5rem;
                transform: translateX(-50%);
            }
        }
        &._complete,
        &._active {
            &::before {
                background: $primary;
            }
            > span {
                position: relative;
                &::before {
                    background: $primary;

                }
                &::after {
                    color: $white;
                }

            }
        }
        &._complete {
            cursor: pointer;
            > span {
                color: $black;
            }
        }
    }
}
.opc-wrapper {
    max-width: 740px;
    .fieldset {
        .field {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            &.choice {
                margin-bottom: 0;
                position:relative;
            }
            &:last-child {
                margin-bottom: 25px;
            }
            .control {
                width: 100%;
                .input-text,
                .select {
                    height: 40px;
                    border: 1px $lighter-grey solid;
                    width: 100%;
                }
            }
        }

    }
}
.step-desc {
    display: none;
}
.opc-payment,
.methods-shipping {
    width: calc(100% - 110px);
}
.methods-shipping {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .actions-toolbar {
        margin: 0 !important;
        display: flex;
        justify-content: flex-end;
        .primary {
            .button.action.continue.primary {
                height: 40px;
                padding: 0 24px;
            }
        }
    }
    .table-checkout-shipping-method {
        width: 100%;
        .col-button {
            overflow: hidden;
            .radio {
                position: unset;
            }
            label {
                white-space: nowrap;
                margin: 0;
                padding: 0;
                &::before,
                &::after {
                    left: 9995px;
                }
            }
        }
        tbody {
            .row {
                margin: 0;
                height: 80px;
                max-height: 80px;
                display: table-row;
                cursor: pointer;
                td {
                    vertical-align: middle;
                }
                .col-method {
                    vertical-align: middle;
                }

                .col-price {
                    width: 110px;
                    max-width: 110px;
                    margin: auto 0;
                    padding: 0 0 0 10px;

                    .price .price {
                        font-weight: 700;
                    }
                }

                .col-method-title {
                    width: 300px;
                    max-width: 300px;
                }

                .col-carrier {
                    max-width: 180px;
                    width: 180px;
                }
            }
        }
    }
}

.opc-sidebar {
    margin: 44px 0 0;
    padding: 0 0 120px 0;
    position: absolute;
    right: 0;
    width: $opc-sidebar__width;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;

    .opc-block-summary {
        display: flex;
        flex-direction: column;
        width: $opc-sidebar__width;
        padding: 2rem 2.75rem;

        > .title {
            border: 0;
        }
        .table-totals {
            order: 2;
            .grand.totals {
                border-bottom: 1px solid $lighter-grey;
            }
            .totals.sub,
            .grand.totals {
                border-top: 1px solid $lighter-grey;
                margin: auto 0;
                .mark,
                .amount {
                    font-weight: 700;
                    padding: 20px 0;
                    .price {
                        font-weight: 700;
                    }
                }
            }
            .totals.sub {
                .mark,
                .amount {
                    padding-bottom: 0;
                }
            }
            .totals.shipping.excl,
            .totals-tax {
                .mark,
                .amount .price,
                .amount .not-calculated {
                    font-size: 0.9rem;
                }
            }
        }
        .items-in-cart {
            margin-bottom: 0;
            .title {
                strong {
                    .strong span {
                        font-weight: 900;
                    }
                }
                .content.minicart-items {
                    .minicart-items-wrapper {
                        padding-top: 0;
                    }
                }
            }
            .product-item {
                padding: 30px 0;
                .product {
                    padding-left: 2px;
                    display: flex;
                    flex-direction: row;
                    &.options {
                        flex-direction: column;
                        margin-top: 2rem;

                        div.content {
                            margin: 0;
                            margin-top: 0.5rem;
                        }
                    }
                    .product-image-container {
                        height: 173px;
                        width: 110px;
                        flex: 2;
                        .product-image-wrapper {
                            overflow: hidden;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100%;
                            filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.25));
                            border-radius: 2px 2px 0 0;

                            img {
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                    .product-item-details {
                        padding-left: 34px;
                        flex: 3;
                        .product-item-inner {
                            display: flex;
                            flex-direction: column;
                            .product-item-name-block {
                                width: 100%;
                                font-size: 1.125rem;
                                .product-item-name {
                                    font-weight: 900;
                                }
                                .details-qty {
                                    margin: 5px 0;
                                    .value {
                                        font-weight: 900;
                                    }
                                }
                                .subtotal {
                                    text-align: left;
                                    .price {
                                        margin: 5px 0;
                                        font-size: 1rem!important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .opc-block-shipping-information {
        padding: 0;
        margin-top: 75px;
        .shipping-information-title {
            span {
                font-family: $roboto;
                font-weight: 700;
            }
        }
        .shipping-information-content {
            font-size: 0.875rem;
            line-height: 268%;
        }
        .shipping-information {
            .ship-to {
                margin-bottom: 50px;
            }
        }
    }
    .opc-block-shipping-information {
        padding: 0;
        margin-top: 75px;
        .shipping-information-title {
            span {
                font-family: $roboto;
                font-weight: 700;
            }
        }
        .shipping-information-content {
            font-size: 0.875rem;
            line-height: 268%;
        }
        .shipping-information {
            .ship-to {
                margin-bottom: 50px;
            }
        }
    }
}

.cart-summary {
    .block {
        .item-options {
            margin: 0 0 16px;

            .field {
                .radio {
                    margin-top: 4px;
                }

                .radio {
                    + .label {
                        display: block;
                        margin: 0;
                        overflow: hidden;
                    }
                }
            }
        }
    }
}
.payment-option {
    order: 3;
    .payment-option-title {
        margin: 20px 0;
    }

    .form-discount {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .payment-option-inner {
            width: calc(100% - 150px);

            .field {
                .label {
                    display: none;
                }
            }
        }

        .actions-toolbar {
            margin: 0 auto !important;

            .primary {
                button.action-apply, button.action-cancel {
                    @include button-to-link;
                }
            }
        }
    }
}
.opc-payment {
    .payment-method {
        .payment-method-title,
        .payment-method-content {
            .items.check.payable {
                    .content {
                    margin: 0 15px 0 0;
                }
            }

            label {
                left: 16px;
                height: auto;

                &::before,
                &::after
                {
                    padding: 0;
                    left: -25px;
                }
            }

            .checkout-agreements-block {
                label.label {
                    &::before,
                    &::after
                    {
                        padding: 0;
                        left: -25px;
                    }
                    &::after {
                        content: '' !important;
                        margin: 0;
                        transform: translate(50%, -50%);
                    }
                    .action.action-show {
                        position: relative;
                        &::after {
                            position: absolute;
                            right: -1ch;
                            top: -5px;
                        }
                    }
                }
            }
        }
    }

    .checkout-billing-address {
        .billing-address-details {
            margin-left: 16px;
            padding: 0 0 0 2rem;
            line-height: 27px;

            br:last-of-type {
                display: none;
            }
        }
    }
}

/** from _blank.scss **/

.checkout-payment-method {
    .step-title {
        border-bottom: 0;
        margin-bottom: 0;
    }
    .payment-method {
        &:first-child {
            .payment-method-title {
                //border-top: 0;
            }
        }
    }
    .payment-method._active {
        .payment-method-content {
            display: block;
        }
    }
    .payment-method-title {
        padding: 20px 0;
        margin: 0;
        .payment-icon {
            display: inline-block;
            margin-right: 5px;
            vertical-align: middle;
        }
        .action-help {
            display: inline-block;
            margin-left: 5px;
        }
    }
    .payment-method-content {
        display: none;
        padding: 0 0 20px 22px;
        .fieldset {
            &:not(:last-child) {
                margin: 0 0 20px;
            }
        }
    }
    .payment-group {
        + {
            .payment-group {
                .step-title {
                    margin: 20px 0 0;
                }
            }
        }
    }
    .field-select-billing {
        max-width: 600px;
    }
    .billing-address-form {
        max-width: 600px;
    }
    .billing-address-same-as-shipping-block {
        margin: 0 0 10px;
    }
    .checkout-billing-address {
        margin: 0 0 20px;
        .primary {
            .action-update {
                margin-right: 0;
            }
        }
    }
    .payment-method-note {
        + {
            .checkout-billing-address {
                margin-top: 20px;
            }
        }
    }
    .payment-method-iframe {
        background-color: transparent;
        display: none;
        width: 100%;
    }
    .no-payments-block {
        margin: 20px 0;
    }
    .payment-option._active {
        .payment-option-title {
            .action-toggle {
                &::after {
                    content: '\e621';
                    font-family: 'icons-blank-theme';
                }
            }
        }
    }
    .payment-option._collapsible {
        .payment-option-title {
            cursor: pointer;
        }
        .payment-option-content {
            display: none;
        }
    }
    .payment-option-title {
        border-top: 1px solid $lighter-grey;
        padding: 20px 0;
        .action-toggle {
            color: #1979c3;
            display: inline-block;
            text-decoration: none;
            &::after {
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 32px;
                line-height: 16px;
                color: $grey;
                content: '\e622';
                font-family: 'icons-blank-theme';
                margin: 0;
                vertical-align: middle;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
            }
            &:hover {
                &::after {
                    color: $black;
                }
            }
            &:active {
                &::after {
                    color: $grey;
                }
            }
        }
    }
    .payment-option-content {
        padding: 0 0 20px 22px;
    }
    .payment-option-inner {
        margin: 0 0 20px;
    }
    .credit-card-types {
        padding: 0;
        .item {
            display: inline-block;
            list-style: none;
            margin: 0 5px 0 0;
            vertical-align: top;
            span {
                display: inline-block;
                padding-top: 6px;
                vertical-align: top;
            }
        }
        .item._active {
            font-weight: 700;
            img {
                -webkit-filter: grayscale(0%);
            }
        }
        .item._inactive {
            opacity: 0.4;
        }
        img {
            -webkit-filter: grayscale(100%);
            -webkit-transition: all 0.6s ease;
        }
    }
    .ccard {
        .fields {
            >.year {
                padding-left: 5px;
            }
            .select {
                padding-left: 5px;
                padding-right: 5px;
            }
        }
        .month {
            .select {
                width: 140px;
            }
        }
        .year {
            .select {
                width: 80px;
            }
        }
        .captcha {
            .input-text {
                width: 225px;
            }
        }
        .number {
            .input-text {
                width: 225px;
            }
        }
        >.field.cvv {
            >.control {
                padding-right: 20px;
                width: auto;
            }
        }
        .cvv {
            .input-text {
                width: 55px;
            }
        }
    }
    .ccard.fieldset {
        >.field {
            .fields.group.group-2 {
                .field {
                    width: auto !important;
                }
            }
        }
    }
}
.checkout-agreements-block {
    .checkout-agreements {
        margin-bottom: 20px;
    }
    .checkout-agreement.field {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
    .checkout-agreement.field.required {
        .action-show {
            &::after {
                content: '*';
                color: $primary;
                font-size: 1.2rem;
            }
        }
    }
    .action-show {
        vertical-align: baseline;
    }
}
.checkout-agreements-items {
    padding-bottom: 30px;
    .checkout-agreements-item {
        margin-bottom: 20px;
    }
    .checkout-agreements-item-title {
        border-bottom: 0;
    }
}
.checkout-agreement-item-content {
    overflow: auto;
}


.checkout-success {
    .order-succes-title {
        font-size: 2rem;
        margin-bottom: 1rem;
    }
    .order-number {
        @include link-underline($primary, 100%, 18px, 1px);
        display: inline-block;
        &:hover,
        &:active {
            color: $black;
            background-image: linear-gradient($black, $black);
        }
        strong {
            font-weight: 400;
            font-family: $roboto!important;
            &::before {
                content: '#';
            }
        }
    }
    .actions-toolbar {
        padding: 1.1rem 0;
    }
}
.action.print {
    @include link-underline($primary, 1ch, 18px, 1px);

    &:hover,
    &:active {
        color: $black;
        background-image: linear-gradient($black, $black);
    }
}
.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content,
.shipping-policy-block.field-tooltip .field-tooltip-content {
    right: -10px;
    top: 40px;
    left: auto;
}
.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:before,
.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:after,
.shipping-policy-block.field-tooltip .field-tooltip-content:before,
.shipping-policy-block.field-tooltip .field-tooltip-content:after {
    border: 10px solid transparent;
    height: 0;
    width: 0;
    margin-top: -21px;
    right: 10px;
    left: auto;
    top: 0;
}
.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:before,
.shipping-policy-block.field-tooltip .field-tooltip-content:before {
    border-bottom-color: #999999;
}
.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:after,
.shipping-policy-block.field-tooltip .field-tooltip-content:after {
    border-bottom-color: #f4f4f4;
    top: 1px;
}
.opc-wrapper .step-title,
.opc-block-shipping-information .shipping-information-title,
.opc-block-summary > .title,
.checkout-agreements-items .checkout-agreements-item-title {
    @include sidebar-title();
}

.opc-block-shipping-information {
    padding: 0;
    .shipping-information-title .action-edit {
        &::before {
            //content : url('data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' fill="none" viewBox=\'0 0 512 512\' width=\'18\' height=\'18\'><path fill=\'%23272727\' d=\'M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z\'></path></svg>');
            content: '\e903';
            font-family: 'icomoon';
            transform: translateY(-25%);
            font-size: 70%;
            color: $black;
            transition: color 0.20s ease;
        }
        &:hover::before {
            color: $grey;
        }
    }
}
.modal-content {
    .fieldset {
        .field {
            .label {
                display: inline !important;
                &::after {
                    display: inherit !important;
                }
                span {
                    &::after {
                        display: inherit !important;
                    }
                }
            }
        }
    }
    .field.choice {
        label.label {
            left: 24px!important;
            &::before,
            &::after {
                left: -24px;
            }
        }
    }
}
.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content,
.shipping-policy-block.field-tooltip .field-tooltip-content {
    right: -10px;
    top: 40px;
    left: auto;
}
.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:before,
.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:after,
.shipping-policy-block.field-tooltip .field-tooltip-content:before,
.shipping-policy-block.field-tooltip .field-tooltip-content:after {
    border: 10px solid transparent;
    height: 0;
    width: 0;
    margin-top: -21px;
    right: 10px;
    left: auto;
    top: 0;
}
.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:before,
.shipping-policy-block.field-tooltip .field-tooltip-content:before {
    border-bottom-color: #999999;
}
.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:after,
.shipping-policy-block.field-tooltip .field-tooltip-content:after {
    border-bottom-color: #f4f4f4;
    top: 1px;
}
.opc-wrapper .step-title,
.opc-block-shipping-information .shipping-information-title,
.opc-block-summary > .title,
.checkout-agreements-items .checkout-agreements-item-title {
    @include sidebar-title();
}

.modal-content {
    .fieldset {
        .field {
            .label {
                display: inline !important;
                &::after {
                    display: inherit !important;
                }
                span {
                    &::after {
                        display: inherit !important;
                    }
                }
            }
        }
    }
    .field.choice {
        label.label {
            left: 24px!important;
            &::before,
            &::after {
                left: -24px;
            }
        }
    }
}

.opc-wrapper .form-login,
.opc-wrapper .form-shipping-address {
    margin-top: 28px;
    margin-bottom: 20px;

    .fieldset .note {
        font-size: 14px;
        margin-top: 10px;
    }
}
.opc-wrapper {
    .shipping-address-items {
        font-size: 0;
        display: flex;
        overflow-x: auto;

        .shipping-address-item {
            flex: 1;
            display: flex;

            .shipping-address-item-container {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
    }
    .shipping-address-item {
        border: 2px solid transparent;
        line-height: 30px;
        margin: 0 0 20px;
        padding: 20px 35px 20px 20px;
        transition: 0.3s border-color;
        width: 50%;
        display: inline-block;
        font-size: 14px;
        position: relative;
        vertical-align: top;
        word-wrap: break-word;
        &.selected-item {
            border-color: $primary;
            &:after {
                background: $primary;
                color: #ffffff;
                content: '\e610';
                font-family: 'icons-blank-theme';
                height: 27px;
                width: 29px;
                font-size: 27px;
                line-height: 21px;
                padding-top: 2px;
                position: absolute;
                right: 0;
                text-align: center;
                top: 0;
            }
        }
    }
    .action-show-popup {
        margin: 0 0 20px;
        & > span {
            &:before {
                content: '+';
                padding-right: 5px;
            }
        }
    }
    .action-select-shipping-item {
        float: right;
        margin: 20px 0 0;
    }
    .edit-address-link {
        display: block;
        float: left;
        margin: 26px 5px 0 0;
    }
}
.checkout-shipping-method {
    position: relative;
    .step-title {
        margin-bottom: 0;
    }
    .no-quotes-block {
        margin: 20px 0;
    }
}
.methods-shipping {
    .actions-toolbar {
        .action {
            &.primary {
                margin: 20px 0 0;
            }
        }
    }
}
.table-checkout-shipping-method {
    thead {
        th {
            display: none;
        }
    }
    tbody {
        td {
            border-top: 1px solid #cccccc;
            padding-bottom: 20px;
            padding-top: 20px;
            &:first-child {
                padding-left: 0;
                padding-right: 0;
                width: 20px;
            }
        }
        tr {
            &:first-child {
                td {
                    border-top: none;
                }
            }
        }
        .row-error {
            td {
                border-top: none;
                padding-bottom: 10px;
                padding-top: 0;
            }
        }
    }
}
.shipping-policy-block {
    &.field-tooltip {
        top: 12px;
        .field-tooltip-action {
            color: #1979c3;
            cursor: pointer;
            &:before {
                display: none;
            }
        }
        .field-tooltip-content {
            width: 420px;
            top: 30px;
        }
    }
}
.opc-block-shipping-information {
    .shipping-information-title {
        border-bottom: 1px solid #cccccc;
        margin: 0 0 20px;
        position: relative;
        .action-edit {
            top: 2px;
            display: inline-block;
            text-decoration: none;
            margin: 0;
            position: absolute;
            right: 0;
            & > span {
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }
            &:before {
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 28px;
                line-height: 28px;
                color: #8f8f8f;
                content: '\e606';
                font-family: 'icons-blank-theme';
                vertical-align: middle;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
            }
            &:hover {
                &:before {
                    color: $black;
                }
            }
            &:active {
                &:before {
                    color: #8f8f8f;
                }
            }
        }
    }
    .shipping-information-content {
        line-height: 27px;
        .actions-toolbar {
            margin-left: 0;
        }
    }
}
.opc-block-shipping-information .ship-to,
.opc-block-shipping-information .ship-via {
    margin: 0 0 20px;
}
.opc-block-summary {
    background: #f5f5f5;
    padding: 22px 30px;
    margin: 0 0 20px;
    & > .title {
        display: block;
    }
    .mark {
        .value {
            color: #999999;
            display: block;
        }
    }
    .discount {
        &.coupon {
            display: none;
        }
    }
    .not-calculated {
        font-style: italic;
    }
    .items-in-cart {
        & > .title {
            border-bottom: 1px solid #d1d1d1;
            padding: 10px 40px 10px 0;
            cursor: pointer;
            display: block;
            text-decoration: none;
            margin-bottom: 0;
            position: relative;
            & > span {
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }
            &:after {
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 30px;
                line-height: 12px;
                color: inherit;
                content: '\e622';
                font-family: 'icons-blank-theme';
                margin: 3px 0 0;
                vertical-align: middle;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
                position: absolute;
                right: 0;
                top: 10px;
            }
            strong {
                font-size: 1.8rem;
                font-weight: 300;
                margin: 0;
            }
        }
        &.active {
            & > .title {
                &:after {
                    content: '\e621';
                    font-family: 'icons-blank-theme';
                }
            }
        }
        .product {
            position: relative;
        }
    }
    .minicart-items-wrapper {
        margin: 0 -15px 0 0;
        max-height: 370px;
        padding: 0 15px 0 0;
        border: 0;
    }
    .product-item {
        .product-item-inner {
            display: table;
            margin: 0 0 10px;
            width: 100%;
        }
        .product-item-name-block {
            display: table-cell;
            padding-right: 5px;
            text-align: left;
            font-weight: 900;
            font-family: $roboto;
        }
        .price {
            font-size: 1.125rem;
            text-align: left;
            font-weight: 900;
            font-family: $roboto;
        }
        .price-including-tax {
            & + .price-excluding-tax {
                margin: 0;
                .price {
                    font-size: 1rem;
                }
            }
        }
    }
    .actions-toolbar {
        position: relative;
        z-index: 1;
        .secondary {
            border-top: 1px solid #d1d1d1;
            display: block;
            float: none;
            margin: -1px 0 0;
            padding: 15px 0 0;
            text-align: left;
            .action {
                margin: 0;
            }
        }
    }
}
.opc-block-summary .grand.incl + .grand.excl .mark,
.opc-block-summary .grand.incl + .grand.excl .amount {
    border-top: 0;
    font-size: 1.4rem;
    padding-top: 0;
}
.column {
    &.main {
        .opc-block-summary {
            .product-item {
                margin: 0;
                padding-left: 0;
            }
        }
    }
}
.opc-wrapper .step-title {
    line-height: 1.5rem;
}
.opc-wrapper .step-title .step-subtitle {
    font-size: 1rem;
}
#checkout-step-payment #co-payment-form .fieldset {
    #checkout-billing-address-label {
        font-weight: 600;
    }
    hr {
        display: none;
    }
}
