.number-input {
    --field-size: 1.5rem;
    --font-size: 0.875rem;
    --cta-font-size: 1.25rem;

    position: relative;
    display: inline-block;
    margin-top: 0.5rem;
    font-size: 0;

    .number-input__input,
    .number-input__minus,
    .number-input__plus {
        padding: 0;
        width: var(--field-size);
        height: var(--field-size);
        color: $black;
        line-height: var(--field-size);
    }

    .number-input__input {
        padding: 0 var(--field-size);
        width: calc(3 * var(--field-size));
        border: 1px solid $lighter-grey;
        text-align: center;
        font-size: var(--font-size);
        font-weight: 700;

        &:focus {
            box-shadow: none;
        }
    }

    .number-input__minus,
    .number-input__plus {
        position: absolute;
        top: 0;
        display: inline-block;
        vertical-align: top;
        background: 0 0;
        outline: 0;
        font-size: var(--cta-font-size);
    }

    .number-input__minus {
        left: 0;
    }

    .number-input__plus {
        left: calc(2 * var(--field-size));
    }
}
