.box-section {
    @include box-shadow;
    padding: 2.25rem;

    .box-section__icon-wrapper {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 1.5rem;
        margin-right: 0.75rem;

        + .box-section__title {
            vertical-align: middle;
        }
    }

    .box-section__icon {
        max-width: 2rem;
    }

    .box-section__title {
        position: relative;
        display: inline-block;
        margin-bottom: 1.5rem;
        color: $primary;
        font-size: 1.375rem;
        font-weight: 700;

        .box-section__title-text {
            padding-right: 1rem;
            background-color: $white;
        }

        &::before {
            margin-right: 0.5rem;
            vertical-align: middle;
            color: $primary;
            font-size: 2rem;
        }
    }

    .box-section__text {
        @include wysiwyg-content-styles;
    }
}
