// Container size
@mixin container-sizing($container-size) {
    body {
        .page-wrapper {
            overflow-x: hidden;
            width:100%;
            header.page-header {
                .panel.wrapper {
                    .panel.header {
                        max-width: $container-size;
                        margin:0 auto;
                    }
                }
                .header.content {
                    max-width: $container-size;
                    margin:0 auto;
                }
            }
            main.page-main {
                width: 100%;
                max-width: $container-size;
                margin:0 auto;
                padding: 0;
            }
            footer.page-footer {
                .footer.content {
                    max-width: 100vw;
                    margin:0 auto;
                }
            }
        }

        //For CMS pages, apply container size to row instead of page-main
        //&[class^=cms-] {
        //    .page-wrapper {
        //        main.page-main {
        //            max-width: none;
        //            [data-content-type="row"] {
        //                [data-appearance="contained"] {
        //                    max-width: $container-size;
        //                }
        //                [data-appearance="full-width"] > .row-full-width-inner {
        //                    max-width: $container-size;
        //                }
        //            }
        //        }
        //    }
        //}
    }
}

// Absolute centring
@mixin absolute-centring {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

@mixin absolute-horizontal-centring {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}

@mixin absolute-vertical-centring {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

// Accessible hidden text
@mixin txt-hidden {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
}

@mixin font-awesome {
    display: none;
    font-family: 'Font Awesome 5 Pro';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 1;
    overflow: visible;
    text-rendering: auto;
}

@mixin background-color {
    background: $very-dark-1 0% 0% no-repeat padding-box;
}

@mixin border-block {
    @include background-color;
    box-shadow: 0px 0px 12px $black;
    border: 3px solid $dark-1;
}

@mixin border-left {
    border-left: 2px solid $dark-1;
    padding-left: 0.8rem;
}

@mixin border-left-gradient {
    border-width: 2px;
    border-style: solid;
    border-image:  linear-gradient(#00FFF1 0%, #00F1FF 20%, #03DCFF 66%, #0283FF 100%) 0 100%;
}

@mixin full-width {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
}

@mixin header-separator {
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 0;
        height: 1rem;
        border-right: 1px solid $black;
    }
}

$underline-distance : 1.1; //CONST Links underline distance factor depending on font-size

/**
 *  $color: Text and underline color
 *  $starting-bg-width: Start width of the underline
 *  $line-height: line-height of the text to underline
 *  $underline-thickness: thickness of the text underline
 *  $hover-color: Text and underline color on hover/focus
 */
@mixin link-underline($color, $starting-bg-width, $line-height, $underline-thickness, $hover-color: $color) {
    display: inline;
    color: $color;
    text-decoration: none;
    background-image: linear-gradient($color, $color);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: $starting-bg-width $underline-thickness;
    transition: background-size .25s ease-in-out;
    height: calc(#{$line-height} * #{$underline-distance});

    &:hover,
    &:focus {
        background-size: 100% $underline-thickness;
    }
}

@mixin input-field {
    height: 60px;
    border-radius: 30px;
    background: $white;
    display: flex;
    flex-direction: row;
    width: 100%;
    white-space: nowrap;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
    padding-left: 30px;
    &::placeholder {
        color: $grey;
    }
}

@mixin prefixer($property, $value, $prefixes) {
    @each $prefix in $prefixes {
        @if $prefix == webkit {
            @if $prefix-for-webkit {
                -webkit-#{$property}: $value;
            }
        } @else if $prefix == moz {
            @if $prefix-for-mozilla {
                -moz-#{$property}: $value;
            }
        } @else if $prefix == ms {
            @if $prefix-for-microsoft {
                -ms-#{$property}: $value;
            }
        } @else if $prefix == o {
            @if $prefix-for-opera {
                -o-#{$property}: $value;
            }
        } @else if $prefix == spec {
            @if $prefix-for-spec {
                #{$property}: $value;
            }
        } @else  {
            @warn "Unrecognized prefix: #{$prefix}";
        }
    }
}

@mixin sidebar-title {
    padding-bottom: 10px;
    border-bottom: 1px solid $lighter-grey;
    color: $black;
    font-weight: 700;
    font-size: 1.5rem;
}

@mixin button-to-link {
    padding: 0;
    background: transparent;
    border: 0;
    color: $primary;
    font-size: 1rem;
    font-weight: 400;
    text-decoration: underline;
}

// Shadow
@mixin box-shadow {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
@mixin box-shadow-hover {
    transition: all ease 0.35s;
    &:hover {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.35);
    }
}
@mixin light-box-shadow {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
@mixin light-top-inset-box-shadow {
    box-shadow: 0 9px 4px -5px rgba(0, 0, 0, 0.25) inset;
}
@mixin light-box-shadow-and-top-inset($opacity) {
    //including the light-box-shadow mixin, outset and inset
    //the inset shadow spreads outward (-5px) and has a 9px vertical length
    //so that the inset shadow is on top only instead of top and left
    box-shadow:
        0 4px 4px rgba(0, 0, 0, $opacity),
        0 9px 4px -5px rgba(0, 0, 0, $opacity) inset
    ;
}

@mixin heavy-box-shadow {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.35);
}

@mixin mobile-navigation-closed {
    position: fixed;
    left: -100%;
    width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    transition: left 0.3s;
}

@mixin wysiwyg-content-styles {
    h1, h2, h3, h4, h5, h6 {
        font-weight: 700;
    }

    h1 {
        font-size: $h1-f-size;
        margin-bottom: $h3-f-size;
        &:not(:first-child) {
            margin-top: $h1-f-size;
        }
    }

    h2 {
        font-size: $h2-f-size;
        margin-bottom: $h4-f-size;
        &:not(:first-child) {
            margin-top: $h2-f-size;
        }
    }

    h3 {
        font-size: $h3-f-size;
        margin-bottom: $h5-f-size;
        &:not(:first-child) {
            margin-top: $h3-f-size;
        }
    }

    h4 {
        font-size: $h4-f-size;
        margin-bottom: $h6-f-size;
        &:not(:first-child) {
            margin-top: $h4-f-size;
        }
    }

    h5 {
        font-size: $h5-f-size;
        margin-bottom: $h6-f-size;
        &:not(:first-child) {
            margin-top: $h5-f-size;
        }
    }

    h6 {
        font-size: $h6-f-size;
        margin-bottom: $h6-f-size;
        &:not(:first-child) {
            margin-top: $h6-f-size;
        }
    }

    p {
        font-size: $p-f-size;
        margin-bottom: $p-f-size;
    }

    ol, ul {
        padding-left: 2.5rem;
        list-style: revert;

        li::marker {
            color: $primary;
        }
    }

    td {
        border-width: 1px;
    }
}

@mixin card-sticker-colors {
    position: absolute;
    text-transform: capitalize;
    font-size: 0.9rem;
    padding: 8px 15px;
    right: 0;
    top: 0;
    color: $black;
    background: #ffffffdd;
    transition: all 0.25s ease-in-out;
    border-radius: 0 2px 0 0;

    &::before {
        content: '';
        display: inline-block;
        margin-right: 1ch;
        width: 11px;
        height: 13px;
        mask: url("data:image/svg+xml;utf8,<svg width=\'11\' height=\'13\' viewBox=\'0 0 11 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M0.205505 6.73513C0.205505 5.25928 0.205505 3.78306 0.205505 2.30648C0.205505 1.35764 0.907187 0.690975 1.90246 0.687744C4.07099 0.687744 6.23951 0.687744 8.40804 0.687744C9.3523 0.687744 10.0755 1.36841 10.0767 2.26825C10.0801 5.27058 10.0801 8.27256 10.0767 11.2742C10.0767 11.6689 9.87431 11.9618 9.49513 12.1239C9.11595 12.286 8.77021 12.2139 8.46528 11.9548C7.46056 11.1062 6.45527 10.258 5.44941 9.41041C5.238 9.23217 5.04643 9.23486 4.83502 9.41365C3.82689 10.2652 2.81801 11.1162 1.80838 11.9667C1.50401 12.223 1.15827 12.286 0.788162 12.1234C0.41805 11.9608 0.210606 11.67 0.21004 11.272C0.207017 9.7599 0.207017 8.24779 0.21004 6.73567L0.205505 6.73513Z\' fill=\'%238f8f8f\'/></svg>");
        background-color: $grey;
    }

    &:hover,
    &:focus,
    &:visited {
        color: $black;
        background: $white;
        box-shadow: 0 0 5px #fff8;
    }

    &.Actualités,
    &.actualité,
    &.actualite {
        &::before {
            background-color: $lavender;
        }
    }
    &.publication,
    &.Publication,
    &.edition,
    &.édition,
    &.Edition {
        &::before {
            background-color: $orange;
        }
    }
    &.Évènement,
    &.évènement,
    &.Event,
    &.événement,
    &.evenement,
    &.Evenement {
        &::before {
            background-color: $light-green;
        }
    }
    &.formations {
        &::before {
            background-color: $red;
        }
    }
    &.projets,
    &.projets_de_recherche {
        &::before {
            background-color: $dark-green;
        }
    }
    &.analyse,
    &.Analyse {
        &::before {
            background-color: $faded-lavender
        }
    }
    &.produit,
    &.Produit,
    &.produits,
    &.Produits {
        &::before {
            background-color: $gold
        }
    }
    &.prestation,
    &.Prestation {
        &::before {
            background-color: $blue;
        }
    }

    &.application,
    &.Application {
        &::before {
            background-color: $green;
        }
    }

    &.press,
    &.Press {
        &::before {
            background-color: $blue-intense;
        }
    }
}
