.amgdpr-block-content {
    div[data-role="amasty-gdpr-consent"] {
        display: none;
    }

    .fieldset {
        margin-bottom: 1.25rem;
    }

    .field.control.checkbox {
        margin: 0.625rem 0;
    }
}

.amgdprcookie-groups-modal {
    .modal-inner-wrap {
        padding-bottom: 2.5rem;
        max-height: 75%;
        overflow-y: auto;
        width: 90%;

        .modal-header {
            padding-bottom: 0;
            .action-close {
                padding: 0;
            }
        }

        .modal-content {
            padding: 0 1.8rem;
        }

        .amgdprcookie-description {
            margin-bottom: 0.938rem;
            padding-right: 2rem;
        }

        .amgdprcookie-cookie-container {
            padding-bottom: 0.938rem;
            margin-bottom: 20px;
            padding-top: 15px;
            border-radius: 6px;
            box-shadow: 0 3px 10px rgba(5, 32, 65, 0.15);

            .amgdprcookie-header {
                display: flex;
                justify-content: space-between;
                padding: 0 15px;

                .amgdprcookie-caption {
                    margin: 0 0 15px;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 30px;
                }

                .amgdprcookie-toggle-cookie {
                    position: relative;
                    .amgdprcookie-input {
                        height: 0;
                        width: 0;
                        visibility: hidden;
                    }
                    .amgdprcookie-label {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 50px;
                        height: 29px;
                        border-radius: 40px;
                        cursor: pointer;
                        background: #a5a5a5;
                        box-shadow: 0 2px 5px rgba(0,0,0,0.05);

                        &::after {
                            content: '';
                            position: absolute;
                            top: 2px;
                            left: 2px;
                            width: 25px;
                            height: 25px;
                            background: #fff;
                            border-radius: 40px;
                            transition: all .4s ease
                        }
                    }

                    .amgdprcookie-input:checked:not([disabled]) + .amgdprcookie-label {
                        background: $dark-green;
                    }

                    .amgdprcookie-input:checked:disabled+.amgdprcookie-label {
                        background:#e8e8e8;
                    }

                    .amgdprcookie-input:not([checked]) + .amgdprcookie-label {
                        &::after {
                            transform: unset;
                        }
                    }

                    .amgdprcookie-input:checked + .amgdprcookie-label {
                        &::after {
                            left: calc(95%);
                            transform: translateX(-100%)
                        }
                    }
                }
            }

            .amgdprcookie-text {
                margin-bottom: 0;
                padding: 0 15px;
                font-size: 12px;
                color: #363636
            }

            .amgdprcookie-options {
                display: inline-block;
                margin: 0.938rem 0.938rem 0;
                width: 120px;
                position: relative;
                margin-top: 15px;
                padding: 7px 15px;
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;
                font-size: 12px;
                color: #fff;
                cursor: pointer;
                background: #767676;
                &:hover {
                    color: $light-green;
                }
                &:after,
                &:before {
                    content: '';
                    position: absolute;
                    top: 16px;
                    height: 2px;
                    width: 10px;
                    background: #fff;
                }
                &:before {
                    right: 20px;
                    transform: rotate(45deg);
                }

                &:after {
                    right: 14px;
                    transform: rotate(-45deg)
                }
            }
        }

        .amgdprcookie-buttons-block {
            padding-bottom: 0;
            display: flex;

            .amgdprcookie-done {
                margin: 0 auto;
            }

        }


    }
}

.amgdprjs-bar-template {
    position: absolute;

    .amgdprcookie-bar-container {
        top: revert;
        pointer-events: auto;
        padding: 0.75rem 0.625rem 0.75rem 0.625rem;

        .action-close {
            display: none;
        }

        .amgdprcookie-buttons-block {
            display: flex;
            flex-direction: row-reverse;

            .amgdprcookie-button {
                text-transform: uppercase;
                letter-spacing: normal;
                font-weight: bold;
                margin: 0.225rem;
                flex-basis: 35%;
            }

            .amgdprcookie-policy {
                margin-bottom: 0.625rem;
            }

        }
    }
}

