body.search-content-index,
body.algolia-list,
body.algolia-list-actu {
    .row {
        margin: 0 !important;
        .algolia-left-container {
            position: relative;
            min-height: 1px;
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
            margin-bottom: 2rem;
        }
    }
    .message.notice,
    .sub-search-for-content {
        display: none;
    }
    .title-block {
        margin-right: 0;
        margin-left: 0;

        h1 {
            margin-left: 0;

            &::before {
                padding-left: 0;
                margin-left: 0;
            }
        }

        h3 {
            padding-left: 0;
        }
    }

        .ais-ClearRefinements {
            .ais-ClearRefinements-button.action.primary {
                background: none;
                border: none;
                color: $black;
                font-size: 0.9em;
                font-weight: 700;
                font-family: $roboto;
                transition: all ease-in-out .3s;
                margin: 0;
                text-align: left;
                padding: 10px 0;
                position: relative;

                &:hover {
                    color: $primary;
                }

                &::after {
                    content: '✕';
                    position: absolute;
                    right: 0;
                    font-size: 1rem;
                }

                &:active {
                    box-shadow: none;
                }
            }
        }


        .ais-CurrentRefinements {
            .ais-CurrentRefinements-list {
                ul {
                    border-radius: 2px;
                    box-shadow: inset 1px 1px 0 $lighter-grey,
                                inset -1px -1px 0px $lighter-grey;
                }

                .ais-CurrentRefinements-item {
                    display: flex;
                    flex-direction: column;
                    font-size: 0.8rem;
                    background: none;
                    border: none;

                    .ais-CurrentRefinements-label {
                        color: $black;
                    }
                }
            }
        }

        .ais-Panel {
            border: 0;
            border-bottom: 1px solid $lighter-grey;
            transition: height ease-in-out .3s;
            margin-bottom: 0;

            .ais-Panel-header {
                position: relative;
                background: none;
                display: flex;
                justify-content: space-between;
                padding: 10px 7px 7px;

                &::after {
                }

                span {
                    .name {
                        font-family: $roboto;
                        font-weight: 900;
                    }
                }

                .ais-Panel-collapseButton {
                    background: none;
                    color: $black;
                    font-size: 1em;
                    font-weight: 700;
                    font-family: $roboto;
                    transition: all ease-in-out .3s;
                    text-align: left;
                    padding: 0;
                    border: none;
                    width: 1em;

                    &:hover {
                        color: $primary;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                    &::after {
                        content: url("data:image/svg+xml;utf8,<svg width=\'10\' height=\'6\' viewBox=\'0 0 10 6\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M4.72562 0.419225L0.108013 5.03683C-0.039624 5.1897 -0.0353851 5.43329 0.117483 5.58093C0.266608 5.72496 0.503012 5.72496 0.652115 5.58093L4.99767 1.23538L9.34323 5.58093C9.49348 5.73116 9.73707 5.73116 9.88733 5.58093C10.0376 5.43066 10.0376 5.18708 9.88733 5.03683L5.26972 0.419225C5.11945 0.268995 4.87587 0.268995 4.72562 0.419225Z\' fill=\'%23272727\'/></svg>");
                        display: block;
                        font-size: 1rem;
                        transform: rotate(180deg) translate(2px, -3px);
                        transition: all .25s ease;
                    }

                    &:active {
                        box-shadow: none;
                    }

                    span {
                        display: none;
                    }

                    &[aria-expanded="true"] {
                        &::after {
                            transform: rotate(0deg) translate(3px, -3px);
                        }
                    }
                }
            }

            .ais-RefinementList-searchBox {
                .ais-SearchBox-form {
                    .ais-SearchBox-input {
                        border: 0 !important;
                        border-bottom: 1px solid $light-grey !important;
                        width: 80%;
                        color: $black;
                        border-bottom: 1px solid $light-grey;
                        margin: 0;
                        padding: 0;
                        padding-bottom: 5px;
                        border-radius: 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        background: none;
                        transition: all 0.3s ease-in-out;

                        &:focus {
                            outline: none !important;
                            box-shadow: none;
                            border-bottom: 1px solid $black !important;
                            fill: $black;

                        }

                        &::placeholder {
                            color: $light-grey;
                        }

                        &::after {
                            content: $magnifier-svg-grey;
                            margin-left: -16px;
                        }

                    }

                    .ais-SearchBox-reset {
                        display: none !important;
                    }

                    .ais-SearchBox-submit {
                        position: absolute;
                        right: 1em;
                        top: 0;
                        display: block;
                        padding: 3px 3px;

                        svg {
                            width: 18px;
                            height: 18px;
                            fill: $light-grey;
                        }

                        &:hover {
                            background: none;
                        }
                    }
                }
            }

            .ais-RefinementList-item {
                margin-bottom: 0;
                position: relative;
                &--selected {
                    isolation: isolate;
                    position: relative;
                    z-index: 1;
                }
                &--disabled {
                    isolation: isolate;
                    position: relative;
                    z-index: 1;
                    opacity: 0.5;
                }
                .specie-disabled {
                    width: 100%;
                    top: 0;
                    left: 0;
                    height: 100%;
                    position: absolute;
                    margin: 0;
                }
                div {
                    margin-left: 25px;
                    display: flex;
                }
                .ais-RefinementList-label {
                    position: relative;
                    margin: 0;
                    cursor: pointer;
                    font-size: 0.825rem;
                    font-weight: 400;
                    font-family: $roboto;
                    margin: 0.15rem 0;
                    &::before {
                        background-color: $white;
                        border: 1px solid $lighter-grey;
                        content: '';
                        display: block;
                        position: absolute;
                        border-radius: 1px;
                        top: 50%;
                        transform: translate3d(0, -50%, 0);
                        height: 16px;
                        width: 16px;
                        padding: 2px;
                        left: -25px;
                    }

                    .ais-RefinementList-checkbox {
                        display: none;

                    }

                    .ais-RefinementList-count {
                    }
                    ul {
                        padding-left: 25px;
                        margin-bottom: 10px;
                        display: none;
                        position: relative;
                        z-index: 200;

                        li {
                            margin: 0;
                        }
                    }
                }
            }
            .ais-RefinementList-item.level1:not(:first-child) {
                margin-top: 1rem;
            }
        }

        .ais-RefinementList-label-title {
            font-family: $roboto;
            font-weight: 400;
        }

        .checked.ais-RefinementList-label {
            font-family: $roboto;
            font-weight: 700;

            &::before {
                content: '';
                background-color: $primary !important;
                box-shadow: inset 2px 2px $white, inset -2px -2px $white !important;
            }
            .ais-RefinementList-label-title {
                font-weight: 700;
            }
        }
        ul.ul-checked {
            display: block !important;
            font-weight: 700;
            .li-checked {
                font-weight: 700;
                .ais-RefinementList-label-title {
                    font-weight: 700;
                }
            }
            .li-unchecked {
                font-weight: 400!important;
                .ais-RefinementList-label-title {
                    font-weight: 400;
                }
            }
        }
        ul.ul-unchecked {
            display: none;
            font-weight: 700;
            .checked {
                font-weight: 700;
            }
        }

        .algolia-right-container {
            position: relative;
            min-height: 1px;
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
            margin-bottom: 2rem;

            .ais-SearchBox {
                display: flex;
                font-family: $roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0;
                text-align: left;
                justify-content: center;

                .ais-SearchBox-form {
                    display: flex;
                    width: 100%;

                    .ais-SearchBox-input {
                        @include input-field;
                        width: 100%;
                        height: 30px;
                        padding-right: 30px;

                        &:focus, &:active {
                            outline: none;
                            box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
                            border: 1px solid $primary-light;
                        }
                    }

                    .ais-SearchBox-reset {
                        position: absolute;
                        right: 0;
                        padding: 0 11px;
                        color: $light-grey;

                        svg {
                            opacity: 0.5;
                            transition: 0.15s ease-in-out all;
                        }

                        &:hover {
                            background: none;

                            svg {
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            .algolia-infos {
                background: none;
                padding: 0 40px;
                line-height: 40px;
                .sort-by-label {
                    text-transform: capitalize;
                    margin-right: 20px;

                    &::after {
                        content: ':';
                    }
                }

                .ais-SortBy {
                    .ais-SortBy-select {
                        border: 0;
                        color: $light-grey;
                        border-bottom: 1px solid $light-grey;
                        margin: 0;
                        padding: 0;
                        border-radius: 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

            }

        }

        .ais-Pagination {
            font-family: $roboto;

            a {
                color: $dark-grey;
                font-weight: 100;
            }

            .ais-Pagination-list {
                .ais-Pagination-item {
                    padding: 0 15px;
                }

                .ais-Pagination-item--selected {
                    display: inline-block;

                    a {
                        display: inline-block;
                        font-family: $roboto;
                        font-weight: 900;
                        color: $ultra-black;
                        text-decoration: none;
                        background-image: linear-gradient($black, $black);
                        background-position: 0 100%;
                        background-repeat: no-repeat;
                        background-size: 100% 1px;
                        height: 20px;
                    }
                }
            }
        }

        .ais-Hits-item {
            width: 100%;
            text-align: left;
            margin: 0;

            a,
            a:active,
            a:visited {
                color: $black;
            }

            a:hover {
                color: $primary !important;
            }

            .result-wrapper.not-first {
                a,
                a:active,
                a:visited,
                a:hover {
                    color: $white !important;
                }
            }

            .result-wrapper {
                padding: 0 0;
                width: 100%;
                position: relative;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                border-radius: 2px;
                border: 0;
                transition: all 0.25s ease-in-out;
                isolation: isolate;
                overflow: hidden;
                height: 100%;
                @include box-shadow;
                @include box-shadow-hover;

                .result {
                    display: flex;
                    flex-direction: column;
                    color: $white;

                    .result-content {
                        position: relative;
                        flex-grow: 1;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;

                        .result-thumbnail {
                            position: relative;
                            top: 0;
                            left: 0;
                            right: 0;
                            width: 100%;
                            overflow: hidden;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                    .result-sub-content-img-container {
                        position: relative;
                        top: 0;
                        left: 0;
                        right: 0;
                        width: 100%;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .result-sub-content-img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .result-sub-content {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        padding: 10px;
                        width: 100%;
                        height: 100%;

                        &-data {
                            &-header {
                                .result-bodytext {
                                    margin-top: 0.94rem;
                                    font-size: 0.94rem;
                                }
                                .result-date {
                                    color: $primary;
                                    font-weight: 400;
                                    font-size: 1rem;
                                    margin-block: 10px;
                                }
                                .result-title {
                                    br {
                                        content: "";
                                        &::before {
                                            content: ' ';
                                        }
                                    }
                                    >  a {
                                        height: 4rem;
                                        display: inline-block;
                                        line-height: 1.25rem;
                                    }
                                }
                                .result-price {
                                    .result-price-default {
                                        color: $primary;
                                        font-size: 1rem;
                                        font-family: $roboto;
                                        font-weight: 700;
                                    }
                                    .result-price-stroke {
                                        text-decoration: line-through;
                                        color: $light-grey;
                                    }
                                }
                            }
                        }
                        .result-sub-content-data {
                            .result-sub-content-data-tags {
                                white-space:nowrap;
                                color: $white;
                                margin-top: 0;
                                height: 4rem;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }

                .card-link {
                    margin: 0 0 20px 20px;
                    bottom: 0;

                    &:hover,
                    &:focus {
                        color: $primary;
                        background-image: linear-gradient($primary, $primary);
                    }

                    &::before {
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                    }
                }

                .actu-card {
                    position: relative;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    border-radius: 2px;
                    transition: all 0.25s ease-in-out;
                    margin: 8px 16px;
                    isolation: isolate;
                    @include box-shadow;
                    @include box-shadow-hover;
                }

                .actu-card-text {
                    display: none;
                }

                .actu-card-textual-infos {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 38px 18px 18px;
                    height: 217px;

                    .actu-card-title {
                        .actu-card-title-link {
                            font-weight: 700;
                            font-size: 1rem;
                            height: 2.2rem;
                            color: $black;
                            transition: all 0.25s ease-in-out;

                            &:hover {
                                color: $primary;
                            }
                        }
                    }
                }

                .actu-card-tags {
                    z-index: 10000;

                    .tag {
                        font-size: 0.9rem;
                        margin-right: 1ch;
                        color: $primary;

                        &::before {
                            content: '#';
                        }
                    }
                }
            }
        }

}

body.search-content-index,
body.algolia-list {
        .algolia-right-container {
            .ais-Hits-list {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            .ais-Hits-item {
                width: 100%;
                text-align: left;
                margin-bottom: 30px;
                a,
                a:active,
                a:visited {
                    color: $black;
                }

                a:hover {
                    color: $primary !important;
                }

                .result-wrapper {
                    padding: 0 0;
                    width: 100%;
                    position: relative;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    border-radius: 2px;
                    border: 0;
                    transition: all 0.25s ease-in-out;
                    isolation: isolate;
                    @include box-shadow;
                    @include box-shadow-hover;

                    .result {
                        height: 125vw;
                        min-height: 320px;

                        .result-sub-content-img {
                            height: 45%;
                            position: relative;
                            overflow: hidden;

                            img {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                height: 100%;
                            }
                        }

                        .subscription-sticker {
                            top: 45%;
                            transform: translateY(-50%);
                        }

                        .result-content {
                            .result-thumbnail {
                                position: relative;
                                top: 0;
                                left: 0;
                                right: 0;
                                width: 100%;
                                overflow: hidden;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                img {
                                    width: 100%;
                                    border-radius: 2px 2.5px 0 0;
                                }
                            }

                            .result-sub-content-img-container {
                                position: relative;
                                top: 0;
                                left: 0;
                                right: 0;
                                width: 100%;
                                overflow: hidden;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .result-sub-content-img {
                                    width: 100%;
                                }
                            }

                            .result-sub-content {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                padding: 20px;
                                width: 100%;

                                .result-title {
                                    a {
                                        display: -webkit-box;
                                        -webkit-box-orient: vertical;
                                        -webkit-line-clamp: 3;
                                        overflow: hidden;
                                        height: 3.8rem;
                                    }

                                    br {
                                        content: "";

                                        &::before {
                                            content: ' ';
                                        }
                                    }

                                    font-family: $roboto;
                                    font-weight: 700;
                                    line-height: 150%;
                                }

                                .result-price {
                                    .result-price-default {
                                        color: $primary;
                                        font-size: 1rem;
                                        font-family: $roboto;
                                        font-weight: 700;
                                    }

                                    .result-price-stroke {
                                        text-decoration: line-through;
                                        color: $light-grey;
                                    }
                                }

                                .result-sub-content-data {
                                    .result-sub-content-data-tags {
                                        //white-space:nowrap;
                                        color: $primary;
                                        margin-top: auto;
                                        height: 4rem;
                                        overflow: hidden;
                                        text-overflow: ellipsis;

                                        a.tag {
                                            display: block;
                                        }
                                    }
                                }

                                .result-button {
                                    margin: 0 auto;
                                    bottom: 0;
                                    background: $primary;
                                    border-radius: 2px;
                                    color: $white;
                                    line-height: 2.5rem;
                                    width: 160px;
                                    text-align: center;
                                    transition: all ease-in-out .3s;

                                    &:hover {
                                        background: $black;
                                        color: $white !important;
                                    }
                                }
                            }
                        }
                    }

                    .card-link {
                        position: absolute;
                        margin: 0 0 20px 20px;
                        bottom: 0;

                        &:hover,
                        &:focus {
                            color: $primary;
                            background-image: linear-gradient($primary, $primary);
                        }

                        &::after {
                            position: absolute;
                            content: '';
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                        }
                    }

                    .actu-card {
                        position: relative;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                        border-radius: 2px;
                        transition: all 0.25s ease-in-out;
                        margin: 8px 16px;
                        isolation: isolate;
                        @include box-shadow;
                        @include box-shadow-hover;
                    }

                    .actu-card-text {
                        display: none;
                    }

                    .actu-card-textual-infos {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding: 38px 18px 18px;
                        height: 217px;

                        .actu-card-title {
                            .actu-card-title-link {
                                font-weight: 700;
                                font-size: 1rem;
                                height: 2.2rem;
                                color: $black;
                                transition: all 0.25s ease-in-out;

                                &:hover {
                                    color: $primary;
                                }
                            }
                        }
                    }

                    .actu-card-tags {
                        z-index: 10000;

                        .tag {
                            font-size: 0.9rem;
                            margin-right: 1ch;
                            color: $primary;

                            &::before {
                                content: '#';
                            }
                        }
                    }
                }
            }
        }
}

body.algolia-list-actu {
    .algolia-right-container {
            .ais-Hits-item {
                margin: 21px 0;
                height: 217px;

                .result-wrapper {
                    .result {
                        display: flex;
                        flex-direction: row;

                        .result-sub-content-img-container {
                            height: 217px;
                            width: 0;
                        }

                        &-content {
                            height: 100%;
                            width: 100%;
                            .result-sub-content {
                                padding: 20px;
                            }
                            .card-link {
                                position: absolute;
                                background-image: linear-gradient(#fff, #fff);
                                color: $white;

                                &.hover {
                                    background-image: linear-gradient(#fff, #fff);
                                }
                            }
                            .result-date {
                                color: $white;
                            }
                        }
                    }
                }

                .first-entry {
                    color: $white;

                    .result {
                        height: 100%;

                        .result-title {
                            font-size: 1rem;
                        }

                        .result-title.page-actus-1 {
                            font-size: 2rem;
                            overflow-y: hidden;
                            &::after {
                                content:'…';
                            }
                        }
                        .result-date {
                            color: $white;
                        }
                        &-sub-content {
                            .result-sub-content-data-tags {
                                width: 100%;
                                color: $white;
                                a.tag:active,
                                a.tag:hover,
                                a.tag:visited,
                                a.tag {
                                    color: $white !important;
                                }
                            }
                            .result-bodytext {
                                margin-bottom: 20px;
                            }
                        }
                        .card-link {
                            color: $white !important;
                            background-image: linear-gradient($white, $white);
                            position: absolute;
                            margin-top: 20px;
                            &::after {
                                content: '';
                                position: absolute;
                                width:  800px;
                                height: 800px;
                                top: -500px;
                                left: -140px;
                            }
                        }
                    }
                }


            }
        }
    .ais-Hits-item {
        .result-wrapper {
            .result {
                .result-sub-content-img-container {
                    .result-sub-content-img {
                        img {
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            height: 100%;
                            max-width: unset;
                        }
                    }
                }
            }
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            .instant-hit-template.page-actus {
                height: 100%;
            }
        }
    }
}


.ais-Panel--collapsed {
    height: 2.7em;
    overflow: hidden;
}


.level-2 {
    margin-left: 25px!important;
}
.level-3 {
    margin-left: 50px!important;
}

.block-search {
    margin-bottom: 0;

    .block-title {
        display: none;
    }
    .block-content {
        margin-bottom: 0;
    }
    .label {
        text-decoration: none;
        display: inline-block;
        float: right;
        & > span {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
        &::before {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 35px;
            line-height: 33px;
            color: #8f8f8f;
            content: '\e615';
            font-family: 'icons-blank-theme';
            vertical-align: middle;
            display: inline-block;
            font-weight: normal;
            overflow: hidden;
            speak: none;
            text-align: center;
        }
        &:hover {
            &::before {
                color: $black;
            }
        }
        &:active {
            &::before {
                color: $black;
            }
        }
        &.active {
            & + .control {
                input {
                    position: static;
                }
            }
        }
    }
    .action {
        &.search {
            display: none;
        }
    }
    .control {
        border-top: 1px solid #d1d1d1;
        clear: both;
        margin: 0 -15px -1px;
        padding: 0 15px;
    }
    input {
        font-size: 1rem;
        left: -300%;
        position: absolute;
    }
    .nested {
        display: none;
    }
}
.search-autocomplete {
    display: none;
    margin-top: -15px;
    overflow: hidden;
    position: absolute;
    z-index: 3;
    ul {
        margin: 0;
        padding: 0;
        list-style: none none;
        li {
            border-top: 1px solid #e5e5e5;
            cursor: pointer;
            margin: 0;
            padding: 5px 40px 5px 10px;
            position: relative;
            text-align: left;
            white-space: normal;
            &:not(:empty) {
                border-top: 0;
                border: 1px solid #c2c2c2;
                background: #ffffff;
            }
            &:first-child {
                border-top: none;
            }
            .amount {
                color: #999999;
                position: absolute;
                right: 7px;
                top: 5px;
            }
        }
    }
}
.search-autocomplete ul li:hover,
.search-autocomplete ul li.selected {
    background: #e8e8e8;
}


