@mixin btn
{
    display: inline-block;
    padding: 0.875rem 1.5rem;
    background-color: $dark-green;
    border: 0 solid transparent;
    border-radius: 2px;
    font-size: 0.875rem;
    line-height: 1rem;
    color: $white;
    text-align: center;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: $black;
    }

    &:focus {
        background-color: $dark-green;
    }
}

.btn, a.btn {
    @include btn;
}
