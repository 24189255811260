body.contentmanager-contenttype-projet {
    .project {
        &__main-name {
            margin: 50px 5rem 80px;
            &__title {
                position: relative;
                font-weight: 700;
                font-size: 1.5rem;
                margin-bottom: 1rem;
                &::before {
                    content: '\00a0';
                    position: absolute;
                    top: 0;
                    left: -1rem;
                    display: block;
                    width: 100vw;
                    height: 1em;
                    transform: translate(-100%, 50%);
                    background-image: linear-gradient(#00746f, #00746f);
                    //background-position: 0 40%;
                    background-repeat: no-repeat;
                    background-size: 100% 4px;
                }
            }
            &__subtitle {
                font-size: 1rem;
            }
        }
        //TODO uniformiser avec banner-heading ?
        &__header {
            &-title {
                font-size: 2.25rem;
                line-height: 150%;
                font-weight: 700;
            }
            &-subtitle {
                font-size: 1.125rem;
                margin-bottom: 0.5rem;
            }
            &-tags {
                color: $light-grey;
            }
        }
        &__image {
            height: 180px;
            overflow: hidden;
            img {
                object-fit: cover;
                object-position: center;
            }
        }
        &__body {
            &__left-column {
                display: none;
            }
            &__right-column {
                width: calc(100% - 20px);
                margin-left: 20px;
                h3 {
                    font-size: 1.25rem;
                    font-weight: 700;
                    margin-bottom: 2rem;
                    &:not(:first-child) {
                        margin-top: 2rem;
                    }
                }
                .ct-field {
                    font-size: 1rem;
                }
                &__file {
                    margin: 2rem 0;
                    border-bottom: 1px solid $lighter-grey;
                }
                &__website-btn {
                    margin-top: 2rem;
                    margin-bottom: calc(2rem + 4px);
                }
            }
        }
        &__related {
            margin-top: 2rem;
        }
    }
}

.theme {
    list-style: none;
}
