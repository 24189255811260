.product-service {
    .sipl__heading-container {
        .service-type {
            display: inline-block;
            margin-bottom: 1.25rem;
        }
    }

    .sipl__side-container {
        .sipl__side-container__info {
            .service-type {
                display: block;
            }

            .product.attribute.overview {
                margin-bottom: 1.5rem;
            }
        }

        .price-box {
            margin-bottom: 1.5rem;
        }
    }

    .sipl__content-column {
        .sipl__content-column__title {
            &::after {
                content: "";
                position: absolute;
                display: block;
                bottom: 0;
                left: 0;
                right: 0;
                height: 0.5em;
                border-top: 3px solid $primary;
                z-index: -1;
            }
        }
    }
}
