.customer-account-edit {
    div.field.confirmation.password {
        display: none !important;
    }
}

.customer-account-create, .customer-account-edit {
    ul.select2-results__options {
        li {
            margin-top: 0.2rem;
            margin-bottom: 0.2rem;

            &:active {
                background: $dark-green;
                color: white;
            }
        }
        .select2-results__option.select2-results__option--selectable.select2-results__option--highlighted {
            background: none;
            color: black;
        }
    }

    #maincontent {
        div.column.main {
            display: flex;
            flex-direction: column;
            align-items: center;

            div.account.create.form.container {
                box-sizing: border-box;
                border: none;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 0;
                padding-top: 2rem;
            }

            h1.account.create.title {
                font-size: 1.6rem;
                color: $dark-green;
                font-weight: bold;
            }

            p.account.create.subtitle {
                font-size: 1rem;

                span {
                    color: $dark-green;
                }
            }

            form.create.account.form-create-account,
            form.form-edit-account {
                box-sizing: border-box;
                width: 100%;
                div.account-create-input-container {
                    margin-top: 1rem;
                    display: flex;

                    div.account-create-input-field {
                        margin-right: 1rem;
                    }
                }

                // Password meter
                input#password {
                    &.mage-error {
                        border-color: $lighter-grey;
                    }
                }
                div#password-error {
                    font-size: 1rem;
                    font-weight: normal;

                    .mage-error {
                        color: $red;
                    }
                }
                div.mage-error {
                    color: $red;
                    font-size: 1rem;
                    font-weight: normal;
                }
                div#password-strength-meter-container {
                    & > div {
                        height: 42px;
                        display: flex;
                        align-items: center;
                        font-weight: normal;
                    }

                    &.password {
                        &-weak > div{
                            color: $red;

                            &::before {
                                background: none;
                                border-bottom: 3px solid $red;
                            }
                        }
                        &-medium > div {
                            color: $orange;

                            &::before {
                                background: none;
                                border-bottom: 3px solid $orange;
                            }
                        }
                        &-strong > div, &-very-strong > div {
                            color: $dark-green;

                            &::before {
                                background: none;
                                border-bottom: 3px solid $dark-green;
                            }
                        }
                    }
                }

                fieldset.fieldset {
                    margin-bottom: 0;
                    margin-top: 40px;

                    legend.legend {
                        margin: 0;

                        span {
                            font-size: 1.5rem;
                            font-weight: bold;
                        }
                    }
                }

                fieldset.fieldset.additional_info,
                fieldset.fieldset.checkboxes {
                    margin: 0;
                }

                .reversed-column {
                    display: flex;
                    flex-direction: column-reverse;
                }

                span.select2.select2-container {
                    width: 100% !important;
                    flex: 1;
                    border: 1px solid $lighter-grey;
                    border-radius: 2px;

                    span.select2-selection {
                        height: 40px;
                        display: flex;
                        align-items: center;
                        border: none;

                        span.select2-selection__arrow {
                            height: auto;
                            top: 50%;
                            bottom: 50%;
                        }
                    }
                }

                div.mage-error ~ span.select2.select2-container {
                    border-color: $red;
                }

                div.multiple_select-default-select-container {
                    display: flex;

                    &::after {
                        content: '✕';
                        opacity: 0;
                        width: 40px;
                    }
                }

                div.multiple_select-container {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0.5rem 0;

                    div.mage-error {
                        width: 100%;
                        order: 1;
                    }
                }

                button.multiple_select-add-button {
                    background: none;
                    border: none;
                    padding: 10px;
                    color: $light-grey;
                    transition: color 0.3s ease-in-out;
                    outline: none;

                    &:hover {
                        color: $dark-green;
                    }
                    &:focus, &:active{
                        outline: none;
                        box-shadow: none;
                    }
                }

                button.action.submit {
                    margin: 4rem 0;
                }

                button.multiple_select-delete-button {
                    background: none;
                    border: none;
                    font-size: 1.4rem;
                    padding: 0;
                    width: 40px;
                    color: $light-grey;
                    transition: color 0.3s ease-in-out;

                    &:hover {
                        color: $red;
                    }
                    &:focus, &:active{
                        outline: none;
                        box-shadow: none;
                    }
                }

                p.account-create-species-description {
                    margin-bottom: 1rem;
                    font-size: 1rem;
                }

                .label_checkbox {
                    span {
                        font-weight: normal;

                        a {
                            color: $dark-green;
                        }
                    }
                }
            }
        }
    }
}
