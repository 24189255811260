#product-preview-banner {
    background: $red;

    .preview-warning {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: white;
        padding: 1.5rem 0;

        h3 {
            font-weight: bold;
            font-size: 1.7rem;
            padding-bottom: 0.6rem;
        }
    }
}
