body {
    margin: 0;
    padding: 0;
}
article,
aside,
details,
figcaption,
figure,
main,
footer,
header,
nav,
section,
summary {
    display: block;
}
audio,
canvas,
video {
    display: inline-block;
}
audio:not([controls]) {
    display: none;
    height: 0;
}
nav ul,
nav ol {
    list-style: none none;
}
img {
    max-width: 100%;
    height: auto;
    border: 0;
}
video,
embed,
object {
    max-width: 100%;
}
svg:not(:root) {
    overflow: hidden;
}
figure {
    margin: 0;
}
html {
    font-size: 62.5%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-size-adjust: 100%;
}
body {
    color: $black;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857143;
    font-size: 1.4rem;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
abbr[title] {
    border-bottom: 1px dotted #d1d1d1;
    cursor: help;
}
b,
strong {
    font-family: $roboto;
    font-weight: 700;
}
em,
i {
    font-style: italic;
}
mark {
    background: #f0f0f0;
    color: #000000;
}
small,
.small {
    font-size: 12px;
}
hr {
    border: 0;
    border-top: 1px solid #d1d1d1;
    margin-bottom: 20px;
    margin-top: 20px;
}
sub,
sup {
    font-size: 71.42857143000001%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}
dfn {
    font-style: italic;
}
h1 {
    font-weight: 300;
    line-height: 1.1;
    font-size: 2.6rem;
    margin-top: 0;
    margin-bottom: 2rem;
}
h2 {
    font-weight: 300;
    line-height: 1.1;
    font-size: 2.6rem;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
}
h3 {
    font-weight: 300;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}
h4 {
    font-weight: 700;
    line-height: 1.1;
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
h5 {
    font-weight: 700;
    line-height: 1.1;
    font-size: 1.2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
h6 {
    font-weight: 700;
    line-height: 1.1;
    font-size: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small {
    color: $black;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
}
a,
.alink {
    color: $primary;
    text-decoration: none;
}
a:visited,
.alink:visited {
    color: $primary;
    text-decoration: none;
}
a:hover,
.alink:hover {
    color: darken($primary, 10%);
    text-decoration: underline;
}
a:active,
.alink:active {
    color: $primary;
    text-decoration: underline;
}
ul,
ol {
    margin-top: 0;
    margin-bottom: 2.5rem;
}
ul > li,
ol > li {
    margin-top: 0;
    margin-bottom: 1rem;
}
ul ul,
ol ul,
ul ol,
ol ol {
    margin-bottom: 0;
}
dl {
    margin-bottom: 20px;
    margin-top: 0;
}
dt {
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 0;
}
dd {
    margin-bottom: 10px;
    margin-top: 0;
    margin-left: 0;
}
code,
kbd,
pre,
samp {
    font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
}
code {
    background: #f0f0f0;
    color: #111111;
    padding: 2px 4px;
    font-size: 1.2rem;
    white-space: nowrap;
}
kbd {
    background: #f0f0f0;
    color: #111111;
    padding: 2px 4px;
    font-size: 1.2rem;
}
pre {
    background: #f0f0f0;
    border: 1px solid #d1d1d1;
    color: #111111;
    line-height: 1.42857143;
    margin: 0 0 10px;
    padding: 10px;
    font-size: 1.2rem;
    display: block;
    word-wrap: break-word;
}
pre code {
    background-color: transparent;
    border-radius: 0;
    color: inherit;
    font-size: inherit;
    padding: 0;
    white-space: pre-wrap;
}
blockquote {
    border-left: 0 solid #d1d1d1;
    margin: 0 0 20px 40px;
    padding: 0;
    color: $black;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: italic;
    font-weight: 400;
    line-height: 1.42857143;
    font-size: 1.4rem;
}
blockquote p:last-child,
blockquote ul:last-child,
blockquote ol:last-child {
    margin-bottom: 0;
}
blockquote footer,
blockquote small,
blockquote .small {
    color: $black;
    line-height: 1.42857143;
    font-size: 1rem;
    display: block;
}
blockquote footer:before,
blockquote small:before,
blockquote .small:before {
    content: '\2014 \00A0';
}
blockquote cite {
    font-style: normal;
}
blockquote:before,
blockquote:after {
    content: '';
}
q {
    quotes: none;
}
q:before,
q:after {
    content: '';
    content: none;
}
cite {
    font-style: normal;
}
table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    max-width: 100%;
}
table th {
    text-align: left;
}
table > tbody > tr > th,
table > tfoot > tr > th,
table > tbody > tr > td,
table > tfoot > tr > td {
    vertical-align: top;
}
table > thead > tr > th,
table > thead > tr > td {
    vertical-align: bottom;
}
table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
    padding: 8px 10px;
}
button,
.cart.table-wrapper .actions-toolbar > .action,
.action-gift {
    background-image: none;
    background: $primary;
    border: 0;
    border-radius: 2px;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 10px 24px;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    transition: background 0.15s ease-in-out;
}
button:focus,
button:active,
.cart.table-wrapper .actions-toolbar > .action:focus,
.cart.table-wrapper .actions-toolbar > .action:active,
.action-gift:focus,
.action-gift:active {
    background: $primary-light;
    //border: 1px solid $primary;
    color: $white;
}
button:hover,
.cart.table-wrapper .actions-toolbar > .action:hover,
.action-gift:hover {
    background: $black;
    //border: 1px solid $primary;
    color: $white;
}
button.disabled,
button[disabled],
fieldset[disabled] button,
.cart.table-wrapper .actions-toolbar > .action.disabled,
.cart.table-wrapper .actions-toolbar > .action[disabled],
fieldset[disabled] .cart.table-wrapper .actions-toolbar > .action,
.action-gift.disabled,
.action-gift[disabled],
fieldset[disabled] .action-gift {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
}
button::-moz-focus-inner,
.cart.table-wrapper .actions-toolbar > .action::-moz-focus-inner,
.action-gift::-moz-focus-inner {
    border: 0;
    padding: 0;
}
/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.bundle-options-container .block-bundle-summary .bundle.items,
.product-items,
.prices-tier,
.cart-container .checkout-methods-items,
.opc-wrapper .opc,
.opc-progress-bar,
.checkout-agreements-items,
.items,
.block-wishlist-management .wishlist-select-items,
.order-details-items .items-qty,
.footer.content ul,
.block-banners .banner-items,
.block-banners-inline .banner-items,
.block-event .slider-panel .slider {
    margin: 0;
    padding: 0;
    list-style: none none;
}
.bundle-options-container .block-bundle-summary .bundle.items > li,
.product-items > li,
.prices-tier > li,
.cart-container .checkout-methods-items > li,
.opc-wrapper .opc > li,
.opc-progress-bar > li,
.checkout-agreements-items > li,
.items > li,
.block-wishlist-management .wishlist-select-items > li,
.order-details-items .items-qty > li,
.footer.content ul > li,
.block-banners .banner-items > li,
.block-banners-inline .banner-items > li,
.block-event .slider-panel .slider > li {
    margin: 0;
}
.abs-action-addto-product,
.block-event .slider-panel .slider .item .action.event,
.bundle-options-container .block-bundle-summary .product-addto-links > .action,
.product-info-main .action.tocompare,
.product-options-bottom .action.tocompare
 {
    background-image: none;
    background: #f2f2f2;
    border: 1px solid #cdcdcd;
    color: $black;
    cursor: pointer;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 700;
    margin: 0;
    padding: 7px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    text-decoration: none;
    border-radius: 3px;
}
.abs-action-link-button:focus,
.abs-action-link-button:active,
.abs-action-addto-product:focus,
.abs-action-addto-product:active,
.block-event .slider-panel .slider .item .action.event:focus,
.block-event .slider-panel .slider .item .action.event:active,
.bundle-options-container .block-bundle-summary .product-addto-links > .action:focus,
.bundle-options-container .block-bundle-summary .product-addto-links > .action:active,
.product-info-main .action.tocompare:focus,
.product-info-main .action.tocompare:active,
.product-options-bottom .action.tocompare:focus,
.product-options-bottom .action.tocompare:active {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: $black;
}
.abs-action-link-button:hover,
.abs-action-addto-product:hover,
.block-event .slider-panel .slider .item .action.event:hover,
.bundle-options-container .block-bundle-summary .product-addto-links > .action:hover,
.product-info-main .action.tocompare:hover,
.product-options-bottom .action.tocompare:hover {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #555555;
}
.abs-action-link-button.disabled,
.abs-action-link-button[disabled],
fieldset[disabled] .abs-action-link-button,
.abs-action-addto-product.disabled,
.abs-action-addto-product[disabled],
fieldset[disabled] .abs-action-addto-product,
.block-event .slider-panel .slider .item .action.event.disabled,
.block-event .slider-panel .slider .item .action.event[disabled],
fieldset[disabled] .block-event .slider-panel .slider .item .action.event,
.bundle-options-container .block-bundle-summary .product-addto-links > .action.disabled,
.bundle-options-container .block-bundle-summary .product-addto-links > .action[disabled],
fieldset[disabled] .bundle-options-container .block-bundle-summary .product-addto-links > .action,
.product-info-main .action.tocompare.disabled,
.product-info-main .action.tocompare[disabled],
fieldset[disabled] .product-info-main .action.tocompare,
.product-options-bottom .action.tocompare.disabled,
.product-options-bottom .action.tocompare[disabled],
fieldset[disabled] .product-options-bottom .action.tocompare,
.product-info-main .action.towishlist.disabled,
.product-info-main .action.towishlist[disabled],
fieldset[disabled] .product-info-main .action.towishlist,
.product-options-bottom .action.towishlist.disabled,
.product-options-bottom .action.towishlist[disabled],
fieldset[disabled] .product-options-bottom .action.towishlist,
.block-bundle-summary .action.towishlist.disabled,
.block-bundle-summary .action.towishlist[disabled],
fieldset[disabled] .block-bundle-summary .action.towishlist {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
}
.abs-action-link-button:hover,
.abs-action-link-button:active,
.abs-action-link-button:focus,
.abs-action-addto-product:hover,
.abs-action-addto-product:active,
.abs-action-addto-product:focus,
.block-event .slider-panel .slider .item .action.event:hover,
.block-event .slider-panel .slider .item .action.event:active,
.block-event .slider-panel .slider .item .action.event:focus,
.bundle-options-container .block-bundle-summary .product-addto-links > .action:hover,
.bundle-options-container .block-bundle-summary .product-addto-links > .action:active,
.bundle-options-container .block-bundle-summary .product-addto-links > .action:focus,
.product-info-main .action.tocompare:hover,
.product-info-main .action.tocompare:active,
.product-info-main .action.tocompare:focus,
.product-options-bottom .action.tocompare:hover,
.product-options-bottom .action.tocompare:active,
.product-options-bottom .action.tocompare:focus,
.product-info-main .action.towishlist:hover,
.product-info-main .action.towishlist:active,
.product-info-main .action.towishlist:focus,
.product-options-bottom .action.towishlist:hover,
.product-options-bottom .action.towishlist:active,
.product-options-bottom .action.towishlist:focus,
.block-bundle-summary .action.towishlist:hover,
.block-bundle-summary .action.towishlist:active,
.block-bundle-summary .action.towishlist:focus {
    text-decoration: none;
}
.cart.table-wrapper .item-options dt,
.opc-block-summary .items-in-cart .product .item-options dt,
.block-giftregistry-shared-items .product .item-options dt {
    clear: left;
    float: left;
    margin: 0 10px 5px 0;
}
.cart.table-wrapper .item-options dt:after,
.opc-block-summary .items-in-cart .product .item-options dt:after,
.block-giftregistry-shared-items .product .item-options dt:after {
    content: ': ';
}
.cart.table-wrapper .item-options dd,
.opc-block-summary .items-in-cart .product .item-options dd,
.block-giftregistry-shared-items .product .item-options dd {
    display: inline-block;
    float: left;
    margin: 0 0 5px;
}
.actions-toolbar > .primary .action,
.actions-toolbar > .secondary .action {
    width: 100%;
}
.products-list .product-image-wrapper,
.cart.table-wrapper .product-image-wrapper,
.minicart-items .product-image-wrapper,
.block-giftregistry-shared-items .product-image-wrapper {
    height: auto;
    padding: 0 !important;
}
.products-list .product-image-wrapper .product-image-photo,
.cart.table-wrapper .product-image-wrapper .product-image-photo,
.minicart-items .product-image-wrapper .product-image-photo,
.block-giftregistry-shared-items .product-image-wrapper .product-image-photo {
    position: static;
}
.product.media .product.photo .photo.image {
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 100%;
}
.abs-account-blocks .block-title,
.block-compare .block-title,
.block-giftregistry-results .block-title,
.block-giftregistry-shared-items .block-title,
.block-wishlist-search-results .block-title,
.block-reorder .block-title,
.widget .block-title,
.block-wishlist .block-title,
.magento-rma-guest-returns .column.main .block:not(.widget) .block-title,
[class^='sales-guest-'] .column.main .block:not(.widget) .block-title,
.sales-guest-view .column.main .block:not(.widget) .block-title {
    margin-bottom: 15px;
}
.abs-account-blocks .block-title > strong,
.block-compare .block-title > strong,
.block-giftregistry-results .block-title > strong,
.block-giftregistry-shared-items .block-title > strong,
.block-wishlist-search-results .block-title > strong,
.block-reorder .block-title > strong,
.widget .block-title > strong,
.block-wishlist .block-title > strong,
.magento-rma-guest-returns .column.main .block:not(.widget) .block-title > strong,
[class^='sales-guest-'] .column.main .block:not(.widget) .block-title > strong,
.sales-guest-view .column.main .block:not(.widget) .block-title > strong {
    font-weight: 300;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}
.account .column.main .block:not(.widget) .block-title > .action,
.magento-rma-guest-returns .column.main .block:not(.widget) .block-title > .action,
[class^='sales-guest-'] .column.main .block:not(.widget) .block-title > .action,
.sales-guest-view .column.main .block:not(.widget) .block-title > .action {
    margin-left: 15px;
}
.account .column.main .block:not(.widget) .box-title,
.magento-rma-guest-returns .column.main .block:not(.widget) .box-title,
[class^='sales-guest-'] .column.main .block:not(.widget) .box-title,
.sales-guest-view .column.main .block:not(.widget) .box-title {
    display: inline-block;
    margin: 0 0 5px;
}
.magento-rma-guest-returns .column.main .block:not(.widget) .box-title > span,
[class^='sales-guest-'] .column.main .block:not(.widget) .box-title > span,
.sales-guest-view .column.main .block:not(.widget) .box-title > span {
    font-weight: 700;
    line-height: 1.1;
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.account .column.main .block:not(.widget) .box-title > .action,
.magento-rma-guest-returns .column.main .block:not(.widget) .box-title > .action,
[class^='sales-guest-'] .column.main .block:not(.widget) .box-title > .action,
.sales-guest-view .column.main .block:not(.widget) .box-title > .action {
    font-weight: 400;
    margin-left: 10px;
}
.magento-rma-guest-returns .column.main .block:not(.widget) .block-content p:last-child,
[class^='sales-guest-'] .column.main .block:not(.widget) .block-content p:last-child,
.sales-guest-view .column.main .block:not(.widget) .block-content p:last-child {
    margin-bottom: 0;
}
.magento-rma-guest-returns .column.main .block:not(.widget) .block-content .box,
[class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box,
.sales-guest-view .column.main .block:not(.widget) .block-content .box {
    margin-bottom: 20px;
}
.giftregisty-dropdown,
.wishlist-dropdown {
    display: inline-block;
    position: relative;
}
.abs-dropdown-simple:before,
.abs-dropdown-simple:after,
.giftregisty-dropdown:before,
.giftregisty-dropdown:after,
.wishlist-dropdown:before,
.wishlist-dropdown:after {
    content: '';
    display: table;
}
.abs-dropdown-simple:after,
.giftregisty-dropdown:after,
.wishlist-dropdown:after {
    clear: both;
}
.abs-dropdown-simple .action.toggle,
.giftregisty-dropdown .action.toggle,
.wishlist-dropdown .action.toggle {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
}
.abs-dropdown-simple .action.toggle:after,
.giftregisty-dropdown .action.toggle:after,
.wishlist-dropdown .action.toggle:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 22px;
    color: inherit;
    content: '\e607';
    font-family: 'icons-blank-theme';
    margin: 0 0 0 5px;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.abs-dropdown-simple .action.toggle:hover:after,
.giftregisty-dropdown .action.toggle:hover:after,
.wishlist-dropdown .action.toggle:hover:after {
    color: inherit;
}
.abs-dropdown-simple .action.toggle:active:after,
.giftregisty-dropdown .action.toggle:active:after,
.wishlist-dropdown .action.toggle:active:after {
    color: inherit;
}
.abs-dropdown-simple .action.toggle.active,
.giftregisty-dropdown .action.toggle.active,
.wishlist-dropdown .action.toggle.active {
    display: inline-block;
    text-decoration: none;
}
.abs-dropdown-simple .action.toggle.active:after,
.giftregisty-dropdown .action.toggle.active:after,
.wishlist-dropdown .action.toggle.active:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 22px;
    color: inherit;
    content: '\e618';
    font-family: 'icons-blank-theme';
    margin: 0 0 0 5px;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.abs-dropdown-simple .action.toggle.active:hover:after,
.giftregisty-dropdown .action.toggle.active:hover:after,
.wishlist-dropdown .action.toggle.active:hover:after {
    color: inherit;
}
.abs-dropdown-simple .action.toggle.active:active:after,
.giftregisty-dropdown .action.toggle.active:active:after,
.wishlist-dropdown .action.toggle.active:active:after {
    color: inherit;
}
.abs-dropdown-simple ul.dropdown,
.giftregisty-dropdown ul.dropdown,
.wishlist-dropdown ul.dropdown {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #ffffff;
    border: 1px solid #bbbbbb;
    margin-top: 4px;
    min-width: 200px;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
}
.abs-dropdown-simple ul.dropdown li,
.giftregisty-dropdown ul.dropdown li,
.wishlist-dropdown ul.dropdown li {
    margin: 0;
    padding: 5px 5px 5px 23px;
}
.abs-dropdown-simple ul.dropdown li:hover,
.giftregisty-dropdown ul.dropdown li:hover,
.wishlist-dropdown ul.dropdown li:hover {
    background: #e8e8e8;
    cursor: pointer;
}
.abs-dropdown-simple ul.dropdown:before,
.abs-dropdown-simple ul.dropdown:after,
.giftregisty-dropdown ul.dropdown:before,
.giftregisty-dropdown ul.dropdown:after,
.wishlist-dropdown ul.dropdown:before,
.wishlist-dropdown ul.dropdown:after {
    border-bottom-style: solid;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0;
}
.abs-dropdown-simple ul.dropdown:before,
.giftregisty-dropdown ul.dropdown:before,
.wishlist-dropdown ul.dropdown:before {
    border: 6px solid;
    border-color: transparent transparent #ffffff transparent;
    z-index: 99;
}
.abs-dropdown-simple ul.dropdown:after,
.giftregisty-dropdown ul.dropdown:after,
.wishlist-dropdown ul.dropdown:after {
    border: 7px solid;
    border-color: transparent transparent #bbbbbb transparent;
    z-index: 98;
}
.abs-dropdown-simple ul.dropdown:before,
.giftregisty-dropdown ul.dropdown:before,
.wishlist-dropdown ul.dropdown:before {
    left: 10px;
    top: -12px;
}
.abs-dropdown-simple ul.dropdown:after,
.giftregisty-dropdown ul.dropdown:after,
.wishlist-dropdown ul.dropdown:after {
    left: 9px;
    top: -14px;
}
.abs-dropdown-simple.active,
.giftregisty-dropdown.active,
.wishlist-dropdown.active {
    overflow: visible;
}
.abs-dropdown-simple.active ul.dropdown,
.giftregisty-dropdown.active ul.dropdown,
.wishlist-dropdown.active ul.dropdown {
    display: block;
}
.column .block-addbysku .qty .qty,
.bundle-options-container .input-text.qty,
.cart.table-wrapper .col.qty .input-text,
.account .table-return-items .qty .input-text,
.table-giftregistry-items .field.qty .qty,
.block-giftregistry-shared-items .qty .qty,
.table.grouped .control .qty,
.block-wishlist-info-items .input-text.qty,
.account .table-wrapper .data.table.wishlist .box-tocart .qty,
.products-grid.wishlist .product-item .box-tocart input.qty,
.sidebar .block-addbysku .fieldset .fields .field.qty .qty {
    text-align: center;
    width: 47px;
}
.block,
.sidebar .widget.block:not(:last-child),
.sidebar .widget:not(:last-child),
.block-banners,
.block-banners-inline {
    margin-bottom: 2.5rem;
}
.sidebar .action.delete,
.table-comparison .cell.remove .action.delete,
.block-compare .action.delete,
.filtered .item .action.remove {
    display: inline-block;
    text-decoration: none;
}
.abs-remove-button-for-blocks > span,
.sidebar .action.delete > span,
.table-comparison .cell.remove .action.delete > span,
.block-compare .action.delete > span,
.filtered .item .action.remove > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.abs-remove-button-for-blocks:before,
.sidebar .action.delete:before,
.table-comparison .cell.remove .action.delete:before,
.block-compare .action.delete:before,
.filtered .item .action.remove:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 26px;
    line-height: 15px;
    color: #303030;
    content: '\e616';
    font-family: 'icons-blank-theme';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.abs-remove-button-for-blocks:hover:before,
.sidebar .action.delete:hover:before,
.table-comparison .cell.remove .action.delete:hover:before,
.block-compare .action.delete:hover:before,
.filtered .item .action.remove:hover:before {
    color: #303030;
}
.abs-remove-button-for-blocks:active:before,
.sidebar .action.delete:active:before,
.table-comparison .cell.remove .action.delete:active:before,
.block-compare .action.delete:active:before,
.filtered .item .action.remove:active:before {
    color: #303030;
}
.product-item-name,
.product.name a {
    font-weight: 400;
}
.product-item-name > a,
.product.name a > a {
    color: $black;
    text-decoration: none;
}
.abs-product-link > a:visited,
.product-item-name > a:visited,
.product.name a > a:visited {
    color: $black;
    text-decoration: underline;
}
.abs-product-link > a:hover,
.product-item-name > a:hover,
.product.name a > a:hover {
    color: $black;
    text-decoration: underline;
}
.abs-product-link > a:active,
.product-item-name > a:active,
.product.name a > a:active {
    color: $black;
    text-decoration: underline;
}
.order-details-items .order-items .order-gift-message:not(.expanded-content) {
    height: 0;
    visibility: hidden;
}
.fieldset > .field.no-label > .label,
.fieldset > .fields > .field.no-label > .label,
.product-item-actions .actions-secondary > .action span,
.table-comparison .cell.label.remove span,
.table-comparison .cell.label.product span,
.cart.table-wrapper .col.qty .label,
.minicart-wrapper .action.showcart .text,
.action.showcart .counter-label,
.checkout-index-index .page-title-wrapper,
.checkout-payment-method .field-select-billing > .label,
.checkout-payment-method .payments .legend,
.checkout-payment-method .ccard .legend,
.fieldset .fullname.field > .label,
.account .column.main .limiter > .label,
.field.street .field .label,
.page-product-downloadable .product-options-wrapper .fieldset .legend.links-title,
.page-product-giftcard .fieldset.giftcard > .legend,
.opc-wrapper .form-giftcard-account .field .label,
.filter-options-content .filter-count-label,
.block-wishlist-search-form .form-wishlist-search .fieldset > .legend,
.page-multiple-wishlist .page-title-wrapper .page-title,
.block.newsletter .label,
.block-reorder .product-item .label,
.opc-wrapper .form-discount .field .label,
.action.skip:not(:focus),
.page-header .panel.wrapper .switcher .label,
.page-footer .switcher .label,
.products-grid.wishlist .product-item .comment-box .label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.shipping-policy-block.field-tooltip .field-tooltip-action span {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}
.abs-add-clearfix:before,
.abs-add-clearfix:after,
.abs-add-fields .fieldset .actions-toolbar:not(:first-child):before,
.abs-add-fields .fieldset .actions-toolbar:not(:first-child):after,
.toolbar:before,
.toolbar:after,
.toolbar-products:before,
.toolbar-products:after,
.sidebar .product-items-images:before,
.sidebar .product-items-images:after,
.cart.table-wrapper .actions-toolbar:before,
.cart.table-wrapper .actions-toolbar:after,
.cart.table-wrapper .item-options:before,
.cart.table-wrapper .item-options:after,
.minicart-items .product-item > .product:before,
.minicart-items .product-item > .product:after,
.checkout-container:before,
.checkout-container:after,
.checkout-onepage-success:before,
.checkout-onepage-success:after,
.opc-estimated-wrapper:before,
.opc-estimated-wrapper:after,
.opc-wrapper .field.addresses:before,
.opc-wrapper .field.addresses:after,
.opc-block-summary .items-in-cart .product .item-options:before,
.opc-block-summary .items-in-cart .product .item-options:after,
.fieldset .fullname .field:before,
.fieldset .fullname .field:after,
.page-product-downloadable .product-add-form:before,
.page-product-downloadable .product-add-form:after,
.gift-options:before,
.gift-options:after,
.gift-item-block .content:before,
.gift-item-block .content:after,
.order-details-items .order-gift-message:before,
.order-details-items .order-gift-message:after,
.order-details-items .block-order-details-gift-message:before,
.order-details-items .block-order-details-gift-message:after,
.block-giftregistry-shared-items .product .item-options:before,
.block-giftregistry-shared-items .product .item-options:after,
.gift-wrapping-list:before,
.gift-wrapping-list:after,
.opc-wrapper .data.table .gift-wrapping:before,
.opc-wrapper .data.table .gift-wrapping:after,
.table-order-items .gift-wrapping:before,
.table-order-items .gift-wrapping:after,
.order-options .product:before,
.order-options .product:after,
.map-popup .map-info-price:before,
.map-popup .map-info-price:after,
.header.content:before,
.header.content:after,
.form-giftregistry-share .fieldset .actions-toolbar:not(:first-child):before,
.form-giftregistry-share .fieldset .actions-toolbar:not(:first-child):after,
.form-giftregistry-edit .fieldset .actions-toolbar:not(:first-child):before,
.form-giftregistry-edit .fieldset .actions-toolbar:not(:first-child):after,
.form-add-invitations .fieldset .actions-toolbar:not(:first-child):before,
.form-add-invitations .fieldset .actions-toolbar:not(:first-child):after,
.form-create-return .fieldset .actions-toolbar:not(:first-child):before,
.form-create-return .fieldset .actions-toolbar:not(:first-child):after,
.form.send.friend .fieldset .actions-toolbar:not(:first-child):before,
.form.send.friend .fieldset .actions-toolbar:not(:first-child):after {
    content: '';
    display: table;
}
.abs-add-clearfix:after,
.abs-add-fields .fieldset .actions-toolbar:not(:first-child):after,
.toolbar:after,
.toolbar-products:after,
.sidebar .product-items-images:after,
.cart.table-wrapper .actions-toolbar:after,
.cart.table-wrapper .item-options:after,
.minicart-items .product-item > .product:after,
.checkout-container:after,
.checkout-onepage-success:after,
.opc-estimated-wrapper:after,
.opc-wrapper .field.addresses:after,
.opc-block-summary .items-in-cart .product .item-options:after,
.fieldset .fullname .field:after,
.page-product-downloadable .product-add-form:after,
.gift-options:after,
.gift-item-block .content:after,
.order-details-items .order-gift-message:after,
.order-details-items .block-order-details-gift-message:after,
.block-giftregistry-shared-items .product .item-options:after,
.gift-wrapping-list:after,
.opc-wrapper .data.table .gift-wrapping:after,
.table-order-items .gift-wrapping:after,
.table-order-review .gift-wrapping:after,
.order-options .product:after,
.map-popup .map-info-price:after,
.customer-review .product-details:after,
.header.content:after,
.form-giftregistry-share .fieldset .actions-toolbar:not(:first-child):after,
.form-giftregistry-edit .fieldset .actions-toolbar:not(:first-child):after,
.form-add-invitations .fieldset .actions-toolbar:not(:first-child):after,
.form-create-return .fieldset .actions-toolbar:not(:first-child):after,
.form.send.friend .fieldset .actions-toolbar:not(:first-child):after {
    clear: both;
}
.abs-field-date .control,
.abs-field-tooltip,
.columns .column.main,
.columns .sidebar-main,
.columns .sidebar-additional,
.column .block-addbysku .sku,
.bundle-options-container .block-bundle-summary,
.product-item,
.sidebar .product-items-images .product-item,
.search-autocomplete,
.cart-summary,
.opc-wrapper .shipping-address-item,
.shipping-policy-block.field-tooltip .field-tooltip-content,
.opc-block-summary,
.sidebar .block-addbysku .fieldset .fields .field.sku,
.block-event .slider-panel .slider .item .action.show,
.field.date .control,
.field .control._with-tooltip {
    box-sizing: border-box;
}
.abs-navigation-icon:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 34px;
    line-height: 1.2;
    color: inherit;
    content: '\e622';
    font-family: 'icons-blank-theme';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.abs-split-button:before,
.abs-split-button:after {
    content: '';
    display: table;
}
.abs-split-button:after {
    clear: both;
}
.abs-split-button .action.split {
    float: left;
    margin: 0;
}
.abs-split-button .action.toggle {
    float: right;
    margin: 0;
}
.abs-split-button button.action.split,
.abs-split-button .cart.table-wrapper .actions-toolbar > .action.action.split,
.abs-split-button .action-gift.action.split {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.abs-split-button button + .action.toggle,
.abs-split-button .cart.table-wrapper .actions-toolbar > .action + .action.toggle,
.abs-split-button .action-gift + .action.toggle {
    border-bottom-left-radius: 0;
    border-left: 0;
    border-top-left-radius: 0;
}
.abs-split-button .action.toggle {
    padding: 4px 5px;
    display: inline-block;
    text-decoration: none;
}
.abs-split-button .action.toggle > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.abs-split-button .action.toggle:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 22px;
    color: inherit;
    content: '\e607';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.abs-split-button .action.toggle:hover:after {
    color: inherit;
}
.abs-split-button .action.toggle:active:after {
    color: inherit;
}
.abs-split-button .action.toggle.active {
    display: inline-block;
    text-decoration: none;
}
.abs-split-button .action.toggle.active > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.abs-split-button .action.toggle.active:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 22px;
    color: inherit;
    content: '\e618';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.abs-split-button .action.toggle.active:hover:after {
    color: inherit;
}
.abs-split-button .action.toggle.active:active:after {
    color: inherit;
}
.abs-split-button .items {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #ffffff;
    border: 1px solid #bbbbbb;
    margin-top: 4px;
    min-width: 100%;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
}
.abs-split-button .items li {
    margin: 0;
    padding: 3px 5px;
}
.abs-split-button .items li:hover {
    background: #e8e8e8;
    cursor: pointer;
}
.abs-split-button .items:before,
.abs-split-button .items:after {
    border-bottom-style: solid;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0;
}
.abs-split-button .items:before {
    border: 6px solid;
    border-color: transparent transparent #ffffff transparent;
    z-index: 99;
}
.abs-split-button .items:after {
    border: 7px solid;
    border-color: transparent transparent #bbbbbb transparent;
    z-index: 98;
}
.abs-split-button .items:before {
    right: 10px;
    top: -12px;
}
.abs-split-button .items:after {
    right: 9px;
    top: -14px;
}
.abs-split-button.active {
    overflow: visible;
}
.abs-split-button.active .items {
    display: block;
}
.bundle-options-container .block-bundle-summary .product-addto-links > .action,
.product-info-main .action.tocompare,
.product-options-bottom .action.tocompare {
    line-height: 1.2rem;
    padding: 5px 8px;
    font-size: 1.1rem;
}
.product-item-actions .actions-secondary > .action,
.table-comparison .wishlist.split.button > .action.split,
.product-items .wishlist.split.button > .action.split,
.product-items .action.towishlist,
.products.list.items .action.towishlist,
.table-comparison .action.towishlist {
    display: inline-block;
    text-decoration: none;
}
.abs-actions-addto-gridlist > span,
.product-item-actions .actions-secondary > .action > span,
.table-comparison .wishlist.split.button > .action.split > span,
.product-items .wishlist.split.button > .action.split > span,
.product-items .action.towishlist > span,
.products.list.items .action.towishlist > span,
.table-comparison .action.towishlist > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.abs-actions-addto-gridlist:before,
.product-item-actions .actions-secondary > .action:before,
.table-comparison .wishlist.split.button > .action.split:before,
.product-items .wishlist.split.button > .action.split:before,
.product-items .action.towishlist:before,
.products.list.items .action.towishlist:before,
.table-comparison .action.towishlist:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 29px;
    line-height: 24px;
    color: #7d7d7d;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.abs-actions-addto-gridlist:hover:before,
.product-item-actions .actions-secondary > .action:hover:before,
.table-comparison .wishlist.split.button > .action.split:hover:before,
.product-items .wishlist.split.button > .action.split:hover:before,
.product-items .action.towishlist:hover:before,
.products.list.items .action.towishlist:hover:before,
.table-comparison .action.towishlist:hover:before {
    color: $black;
}
.bundle-actions .action.primary,
.bundle-options-container .block-bundle-summary .box-tocart .action.primary,
.product-info-main .box-tocart .action.tocart,
.product-options-bottom .box-tocart .action.tocart,
.cart-container .checkout-methods-items .action.primary,
.block-minicart .block-content > .actions > .primary .action.primary,
.methods-shipping .actions-toolbar .action.primary,
.block-authentication .action.action-register,
.block-authentication .action.action-login,
.checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary,
.form-address-edit .actions-toolbar .action.primary {
    line-height: 2.2rem;
    padding: 2.5px 24px;
    font-size: 1rem;
}
.abs-action-remove,
.cart.table-wrapper .action.help.map,
.opc-wrapper .edit-address-link,
.opc-block-shipping-information .shipping-information-title .action-edit,
.action-auth-toggle,
.checkout-payment-method .checkout-billing-address .action-cancel,
.checkout-agreements-block .action-show,
.cart-summary .block.giftcard .action.check,
.gift-options .actions-toolbar .action-cancel,
.table-comparison .wishlist.split.button > .action,
.product-items .wishlist.split.button > .action,
.abs-add-fields .action.remove,
.form-giftregistry-share .action.remove,
.form-giftregistry-edit .action.remove,
.form-add-invitations .action.remove,
.form-create-return .action.remove,
.form.send.friend .action.remove {
    line-height: 1.42857143;
    padding: 0;
    color: #1979c3;
    text-decoration: none;
    background: none;
    border: 0;
    display: inline;
    border-radius: 0;
    font-size: inherit;
    font-weight: 400;
}
.abs-action-button-as-link:visited,
.abs-action-remove:visited,
.cart.table-wrapper .action.help.map:visited,
.opc-wrapper .edit-address-link:visited,
.opc-block-shipping-information .shipping-information-title .action-edit:visited,
.action-auth-toggle:visited,
.checkout-payment-method .checkout-billing-address .action-cancel:visited,
.checkout-agreements-block .action-show:visited,
.cart-summary .block.giftcard .action.check:visited,
.gift-options .actions-toolbar .action-cancel:visited,
.table-comparison .wishlist.split.button > .action:visited,
.product-items .wishlist.split.button > .action:visited,
.abs-add-fields .action.remove:visited,
.form-giftregistry-share .action.remove:visited,
.form-giftregistry-edit .action.remove:visited,
.form-add-invitations .action.remove:visited,
.form-create-return .action.remove:visited,
.form.send.friend .action.remove:visited {
    color: #1979c3;
    text-decoration: none;
}
.abs-action-button-as-link:hover,
.abs-action-remove:hover,
.cart.table-wrapper .action.help.map:hover,
.opc-wrapper .edit-address-link:hover,
.opc-block-shipping-information .shipping-information-title .action-edit:hover,
.action-auth-toggle:hover,
.checkout-payment-method .checkout-billing-address .action-cancel:hover,
.checkout-agreements-block .action-show:hover,
.cart-summary .block.giftcard .action.check:hover,
.gift-options .actions-toolbar .action-cancel:hover,
.table-comparison .wishlist.split.button > .action:hover,
.product-items .wishlist.split.button > .action:hover,
.abs-add-fields .action.remove:hover,
.form-giftregistry-share .action.remove:hover,
.form-giftregistry-edit .action.remove:hover,
.form-add-invitations .action.remove:hover,
.form-create-return .action.remove:hover,
.form.send.friend .action.remove:hover {
    color: #006bb4;
    text-decoration: underline;
}
.abs-action-button-as-link:active,
.abs-action-remove:active,
.cart.table-wrapper .action.help.map:active,
.opc-wrapper .edit-address-link:active,
.opc-block-shipping-information .shipping-information-title .action-edit:active,
.action-auth-toggle:active,
.checkout-payment-method .checkout-billing-address .action-cancel:active,
.checkout-agreements-block .action-show:active,
.cart-summary .block.giftcard .action.check:active,
.gift-options .actions-toolbar .action-cancel:active,
.table-comparison .wishlist.split.button > .action:active,
.product-items .wishlist.split.button > .action:active,
.abs-add-fields .action.remove:active,
.form-giftregistry-share .action.remove:active,
.form-giftregistry-edit .action.remove:active,
.form-add-invitations .action.remove:active,
.form-create-return .action.remove:active,
.form.send.friend .action.remove:active {
    color: $primary;
    text-decoration: underline;
}
.abs-action-button-as-link:hover,
.abs-action-button-as-link:active,
.abs-action-button-as-link:focus,
.abs-action-remove:hover,
.abs-action-remove:active,
.abs-action-remove:focus,
.cart.table-wrapper .action.help.map:hover,
.cart.table-wrapper .action.help.map:active,
.cart.table-wrapper .action.help.map:focus,
.opc-wrapper .edit-address-link:hover,
.opc-wrapper .edit-address-link:active,
.opc-wrapper .edit-address-link:focus,
.opc-block-shipping-information .shipping-information-title .action-edit:hover,
.opc-block-shipping-information .shipping-information-title .action-edit:active,
.opc-block-shipping-information .shipping-information-title .action-edit:focus,
.action-auth-toggle:hover,
.action-auth-toggle:active,
.action-auth-toggle:focus,
.checkout-payment-method .checkout-billing-address .action-cancel:hover,
.checkout-payment-method .checkout-billing-address .action-cancel:active,
.checkout-payment-method .checkout-billing-address .action-cancel:focus,
.checkout-agreements-block .action-show:hover,
.checkout-agreements-block .action-show:active,
.checkout-agreements-block .action-show:focus,
.cart-summary .block.giftcard .action.check:hover,
.cart-summary .block.giftcard .action.check:active,
.cart-summary .block.giftcard .action.check:focus,
.gift-options .actions-toolbar .action-cancel:hover,
.gift-options .actions-toolbar .action-cancel:active,
.gift-options .actions-toolbar .action-cancel:focus,
.table-comparison .wishlist.split.button > .action:hover,
.table-comparison .wishlist.split.button > .action:active,
.table-comparison .wishlist.split.button > .action:focus,
.product-items .wishlist.split.button > .action:hover,
.product-items .wishlist.split.button > .action:active,
.product-items .wishlist.split.button > .action:focus,
.abs-add-fields .action.remove:hover,
.abs-add-fields .action.remove:active,
.abs-add-fields .action.remove:focus,
.form-giftregistry-share .action.remove:hover,
.form-giftregistry-share .action.remove:active,
.form-giftregistry-share .action.remove:focus,
.form-giftregistry-edit .action.remove:hover,
.form-giftregistry-edit .action.remove:active,
.form-giftregistry-edit .action.remove:focus,
.form-add-invitations .action.remove:hover,
.form-add-invitations .action.remove:active,
.form-add-invitations .action.remove:focus,
.form-create-return .action.remove:hover,
.form-create-return .action.remove:active,
.form-create-return .action.remove:focus,
.form.send.friend .action.remove:hover,
.form.send.friend .action.remove:active,
.form.send.friend .action.remove:focus {
    background: none;
    border: 0;
}
.abs-action-button-as-link.disabled,
.abs-action-button-as-link[disabled],
fieldset[disabled] .abs-action-button-as-link,
.abs-action-remove.disabled,
.abs-action-remove[disabled],
fieldset[disabled] .abs-action-remove,
.cart.table-wrapper .action.help.map.disabled,
.cart.table-wrapper .action.help.map[disabled],
fieldset[disabled] .cart.table-wrapper .action.help.map,
.opc-wrapper .edit-address-link.disabled,
.opc-wrapper .edit-address-link[disabled],
fieldset[disabled] .opc-wrapper .edit-address-link,
.opc-block-shipping-information .shipping-information-title .action-edit.disabled,
.opc-block-shipping-information .shipping-information-title .action-edit[disabled],
fieldset[disabled] .opc-block-shipping-information .shipping-information-title .action-edit,
.action-auth-toggle.disabled,
.action-auth-toggle[disabled],
fieldset[disabled] .action-auth-toggle,
.checkout-payment-method .checkout-billing-address .action-cancel.disabled,
.checkout-payment-method .checkout-billing-address .action-cancel[disabled],
fieldset[disabled] .checkout-payment-method .checkout-billing-address .action-cancel,
.checkout-agreements-block .action-show.disabled,
.checkout-agreements-block .action-show[disabled],
fieldset[disabled] .checkout-agreements-block .action-show,
.cart-summary .block.giftcard .action.check.disabled,
.cart-summary .block.giftcard .action.check[disabled],
fieldset[disabled] .cart-summary .block.giftcard .action.check,
.gift-options .actions-toolbar .action-cancel.disabled,
.gift-options .actions-toolbar .action-cancel[disabled],
fieldset[disabled] .gift-options .actions-toolbar .action-cancel,
.table-comparison .wishlist.split.button > .action.disabled,
.table-comparison .wishlist.split.button > .action[disabled],
fieldset[disabled] .table-comparison .wishlist.split.button > .action,
.product-items .wishlist.split.button > .action.disabled,
.product-items .wishlist.split.button > .action[disabled],
fieldset[disabled] .product-items .wishlist.split.button > .action,
.abs-add-fields .action.remove.disabled,
.abs-add-fields .action.remove[disabled],
fieldset[disabled] .abs-add-fields .action.remove,
.form-giftregistry-share .action.remove.disabled,
.form-giftregistry-share .action.remove[disabled],
fieldset[disabled] .form-giftregistry-share .action.remove,
.form-giftregistry-edit .action.remove.disabled,
.form-giftregistry-edit .action.remove[disabled],
fieldset[disabled] .form-giftregistry-edit .action.remove,
.form-add-invitations .action.remove.disabled,
.form-add-invitations .action.remove[disabled],
fieldset[disabled] .form-add-invitations .action.remove,
.form-create-return .action.remove.disabled,
.form-create-return .action.remove[disabled],
fieldset[disabled] .form-create-return .action.remove,
.form.send.friend .action.remove.disabled,
.form.send.friend .action.remove[disabled],
fieldset[disabled] .form.send.friend .action.remove {
    color: #1979c3;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline;
}
.abs-action-remove:active,
.abs-action-remove:not(:focus),
.cart.table-wrapper .action.help.map:active,
.cart.table-wrapper .action.help.map:not(:focus),
.opc-wrapper .edit-address-link:active,
.opc-wrapper .edit-address-link:not(:focus),
.opc-block-shipping-information .shipping-information-title .action-edit:active,
.opc-block-shipping-information .shipping-information-title .action-edit:not(:focus),
.action-auth-toggle:active,
.action-auth-toggle:not(:focus),
.checkout-payment-method .checkout-billing-address .action-cancel:active,
.checkout-payment-method .checkout-billing-address .action-cancel:not(:focus),
.checkout-agreements-block .action-show:active,
.checkout-agreements-block .action-show:not(:focus),
.cart-summary .block.giftcard .action.check:active,
.cart-summary .block.giftcard .action.check:not(:focus),
.gift-options .actions-toolbar .action-cancel:active,
.gift-options .actions-toolbar .action-cancel:not(:focus),
.table-comparison .wishlist.split.button > .action:active,
.table-comparison .wishlist.split.button > .action:not(:focus),
.product-items .wishlist.split.button > .action:active,
.product-items .wishlist.split.button > .action:not(:focus),
.abs-add-fields .action.remove:active,
.abs-add-fields .action.remove:not(:focus),
.form-giftregistry-share .action.remove:active,
.form-giftregistry-share .action.remove:not(:focus),
.form-giftregistry-edit .action.remove:active,
.form-giftregistry-edit .action.remove:not(:focus),
.form-add-invitations .action.remove:active,
.form-add-invitations .action.remove:not(:focus),
.form-create-return .action.remove:active,
.form-create-return .action.remove:not(:focus),
.form.send.friend .action.remove:active,
.form.send.friend .action.remove:not(:focus) {
    box-shadow: none;
}
.block-compare .action.compare,
.cart-summary .actions-toolbar > .primary button,
.cart-summary .form.giftregistry .fieldset .action.primary,
.cart-summary .actions-toolbar > .primary .cart.table-wrapper .actions-toolbar > .action,
.cart-summary .actions-toolbar > .primary .action-gift {
    background: #f2f2f2;
    border: 1px solid #cdcdcd;
    color: $black;
}
.abs-revert-secondary-color:focus,
.abs-revert-secondary-color:active,
.block-compare .action.compare:focus,
.block-compare .action.compare:active,
.cart-summary .actions-toolbar > .primary button:focus,
.cart-summary .actions-toolbar > .primary button:active,
.cart-summary .form.giftregistry .fieldset .action.primary:focus,
.cart-summary .form.giftregistry .fieldset .action.primary:active,
.cart-summary .actions-toolbar > .primary .cart.table-wrapper .actions-toolbar > .action:focus,
.cart-summary .actions-toolbar > .primary .cart.table-wrapper .actions-toolbar > .action:active,
.cart-summary .actions-toolbar > .primary .action-gift:focus,
.cart-summary .actions-toolbar > .primary .action-gift:active {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: $black;
}
.abs-revert-secondary-color:hover,
.block-compare .action.compare:hover,
.cart-summary .actions-toolbar > .primary button:hover,
.cart-summary .actions-toolbar > .primary .cart.table-wrapper .actions-toolbar > .action:hover,
.cart-summary .actions-toolbar > .primary .action-gift:hover {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #555555;
}
.cart-summary .form.giftregistry .fieldset .action.primary {
    font-size: 1.4rem;
    line-height: 1.6rem;
    padding: 7px 15px;
}
.bundle-actions {
    margin: 10px 0;
}
.bundle-options-container .price-notice .price-including-tax,
.bundle-options-container .price-notice .price-excluding-tax,
.bundle-options-container .price-notice .weee,
.product-options-wrapper .field .price-notice .price-including-tax,
.product-options-wrapper .field .price-notice .price-excluding-tax,
.product-options-wrapper .field .price-notice .weee,
.cart-summary .price-including-tax,
.cart-summary .price-excluding-tax,
.cart-summary .weee,
.gift-summary .price-including-tax,
.gift-summary .price-excluding-tax,
.gift-summary .weee,
.gift-options .price-including-tax,
.gift-options .price-excluding-tax,
.gift-options .weee {
    font-size: 1.4rem;
    display: inline-block;
    white-space: nowrap;
}
.bundle-options-container .price-notice .price-including-tax + .price-excluding-tax,
.product-options-wrapper .field .price-notice .price-including-tax + .price-excluding-tax,
.cart-summary .price-including-tax + .price-excluding-tax,
.gift-summary .price-including-tax + .price-excluding-tax,
.gift-options .price-including-tax + .price-excluding-tax {
    display: inline-block;
    font-size: 1.1rem;
}
.bundle-options-container .price-notice .price-including-tax + .price-excluding-tax:before,
.product-options-wrapper .field .price-notice .price-including-tax + .price-excluding-tax:before,
.cart-summary .price-including-tax + .price-excluding-tax:before,
.gift-summary .price-including-tax + .price-excluding-tax:before,
.gift-options .price-including-tax + .price-excluding-tax:before {
    content: '(' attr(data-label) ': ';
}
.bundle-options-container .price-notice .price-including-tax + .price-excluding-tax:after,
.product-options-wrapper .field .price-notice .price-including-tax + .price-excluding-tax:after,
.cart-summary .price-including-tax + .price-excluding-tax:after,
.gift-summary .price-including-tax + .price-excluding-tax:after,
.gift-options .price-including-tax + .price-excluding-tax:after {
    content: ')';
}
.price-including-tax .cart-tax-total,
.price-excluding-tax .cart-tax-total {
    cursor: pointer;
    padding-right: 12px;
    position: relative;
    display: inline-block;
    text-decoration: none;
}
.abs-tax-total:after,
.price-including-tax .cart-tax-total:after,
.price-excluding-tax .cart-tax-total:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 26px;
    line-height: 10px;
    color: inherit;
    content: '\e622';
    font-family: 'icons-blank-theme';
    margin: 3px 0 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.price-including-tax .cart-tax-total:after,
.price-excluding-tax .cart-tax-total:after {
    position: absolute;
    right: -10px;
    top: 3px;
}
.abs-tax-total-expanded:after {
    content: '\e621';
    font-family: 'icons-blank-theme';
}
.abs-tax-total-expanded:after,
.price-including-tax .cart-tax-total-expanded:after,
.price-excluding-tax .cart-tax-total-expanded:after {
    content: '\e621';
    font-family: 'icons-blank-theme';
}
.form-giftregistry-search .legend,
.block-wishlist-search-form .block-title {
    border-bottom: 1px solid #d1d1d1;
    margin-bottom: 25px;
    padding-bottom: 10px;
}
.form-giftregistry-search .legend > strong,
.form-giftregistry-search .legend > span,
.block-wishlist-search-form .block-title > strong,
.block-wishlist-search-form .block-title > span {
    font-size: 2.2rem;
    font-weight: 300;
}
.block-minicart .subtotal .label:after,
.minicart-items .details-qty .label:after,
.minicart-items .price-minicart .label:after,
.order-details-items .order-gift-message .label:after,
.order-details-items .block-order-details-gift-message .label:after,
.block-wishlist-management .wishlist-select .wishlist-name-label:after {
    content: ': ';
}
.abs-dropdown-items-new .action.new,
.wishlist.split.button .action.new,
.wishlist-dropdown .action.new {
    display: inline-block;
    text-decoration: none;
}
.abs-icon-add:before,
.abs-dropdown-items-new .action.new:before,
.wishlist.split.button .action.new:before,
.wishlist-dropdown .action.new:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 10px;
    line-height: 10px;
    color: inherit;
    content: '\e61c';
    font-family: 'icons-blank-theme';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.wishlist.split.button .items .item:last-child:hover,
.wishlist-dropdown .items .item:last-child:hover {
    background: #e8e8e8;
}
.wishlist.split.button .action.new:before,
.wishlist-dropdown .action.new:before {
    margin-left: -17px;
    margin-right: 5px;
}
.abs-sidebar-totals .table-caption,
.sidebar .subtitle,
.cart-summary .block .fieldset.estimate > .legend,
.cart-summary .block .fieldset.estimate > .legend + br,
.minicart-items .product-item-details .weee[data-label] .label,
.page-product-giftcard .fieldset.giftcard > .legend + br,
.order-details-items .order-items .order-gift-message .action.close,
[class^='multishipping-'] .nav-sections,
[class^='multishipping-'] .nav-toggle,
.sidebar-main .block-reorder .subtitle,
.sidebar-additional .block-reorder .subtitle,
.no-display,
.cart-totals .table-caption,
.opc-block-summary .table-totals .table-caption {
    display: none;
}
.return-status,
.order-status {
    display: inline-block;
    margin-bottom: 20px;
}
.block-compare .counter,
.block-wishlist .counter {
    color: #7d7d7d;
    font-size: 1.2rem;
    white-space: nowrap;
}
.block-cart-failed .action.continue,
.cart-container .form-cart .action.continue {
    border-radius: 3px;
    background-image: none;
    background: #f2f2f2;
    border: 1px solid #cdcdcd;
    color: $black;
    cursor: pointer;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 700;
    margin: 0;
    padding: 7px 15px 7px 0;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    text-decoration: none;
}
.abs-shopping-cart-items .action.continue:hover,
.abs-shopping-cart-items .action.continue:active,
.abs-shopping-cart-items .action.continue:focus,
.block-cart-failed .action.continue:hover,
.block-cart-failed .action.continue:active,
.block-cart-failed .action.continue:focus,
.cart-container .form-cart .action.continue:hover,
.cart-container .form-cart .action.continue:active,
.cart-container .form-cart .action.continue:focus {
    text-decoration: none;
}
.abs-shopping-cart-items .action.continue:before,
.block-cart-failed .action.continue:before,
.cart-container .form-cart .action.continue:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 16px;
    color: inherit;
    content: '\e617';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.abs-shopping-cart-items .action.continue:hover:before,
.block-cart-failed .action.continue:hover:before,
.cart-container .form-cart .action.continue:hover:before {
    color: inherit;
}
.abs-shopping-cart-items .action.continue:active:before,
.block-cart-failed .action.continue:active:before,
.cart-container .form-cart .action.continue:active:before {
    color: inherit;
}
.abs-shopping-cart-items .action.continue:focus,
.abs-shopping-cart-items .action.continue:active,
.block-cart-failed .action.continue:focus,
.block-cart-failed .action.continue:active,
.cart-container .form-cart .action.continue:focus,
.cart-container .form-cart .action.continue:active {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: $black;
}
.abs-shopping-cart-items .action.continue:hover,
.block-cart-failed .action.continue:hover,
.cart-container .form-cart .action.continue:hover {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #555555;
}
.abs-shopping-cart-items .action.continue.disabled,
.abs-shopping-cart-items .action.continue[disabled],
fieldset[disabled] .abs-shopping-cart-items .action.continue,
.block-cart-failed .action.continue.disabled,
.block-cart-failed .action.continue[disabled],
fieldset[disabled] .block-cart-failed .action.continue,
.cart-container .form-cart .action.continue.disabled,
.cart-container .form-cart .action.continue[disabled],
fieldset[disabled] .cart-container .form-cart .action.continue {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
}
.block-cart-failed .action.continue:active,
.cart-container .form-cart .action.continue:active {
    box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.12);
}
.block-cart-failed .action.update,
.cart-container .form-cart .action.update {
    display: inline-block;
    text-decoration: none;
    padding-left: 5px;
}
.abs-shopping-cart-items .action.update:before,
.block-cart-failed .action.update:before,
.cart-container .form-cart .action.update:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 16px;
    color: inherit;
    content: '\e603';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.abs-shopping-cart-items .action.update:hover:before,
.block-cart-failed .action.update:hover:before,
.cart-container .form-cart .action.update:hover:before {
    color: inherit;
}
.abs-shopping-cart-items .action.update:active:before,
.block-cart-failed .action.update:active:before,
.cart-container .form-cart .action.update:active:before {
    color: inherit;
}
.field.date .control {
    position: relative;
}
.abs-field-date input,
.form-giftregistry-search .fields-specific-options .datetime-picker,
.field.date input {
    margin-right: 10px;
    width: calc(100% - 50px);
}
.field .control._with-tooltip {
    position: relative;
}
.field .control._with-tooltip input {
    margin-right: 10px;
    width: calc(100% - 39px);
}

/********* Cart & Checkout sidebar totals *********/

.cart-totals .mark,
.opc-block-summary .table-totals .mark {
    font-weight: 400;
    padding-left: 0;
}
.cart-totals .amount,
.opc-block-summary .table-totals .amount {
    padding-right: 0;
    text-align: right;
    white-space: nowrap;
}
.cart-totals .amount strong,
.opc-block-summary .table-totals .amount strong {
    font-weight: 400;
}
.cart-totals .grand .mark,
.cart-totals .grand .amount,
.opc-block-summary .table-totals .grand .mark,
.opc-block-summary .table-totals .grand .amount {
    padding-top: 20px;
}
.cart-totals .grand .amount,
.opc-block-summary .table-totals .grand .amount {
    padding-right: 4px;
    text-align: right;
}
.cart-totals .grand .amount strong,
.opc-block-summary .table-totals .grand .amount strong {
    font-weight: 700;
}
.cart-totals .msrp,
.opc-block-summary .table-totals .msrp {
    margin-bottom: 10px;
}
.cart-totals .totals-tax-summary .mark,
.cart-totals .totals-tax-summary .amount,
.opc-block-summary .table-totals .totals-tax-summary .mark,
.opc-block-summary .table-totals .totals-tax-summary .amount {
    border-top: 1px solid #d1d1d1;
    border-bottom: 1px solid #d1d1d1;
    cursor: pointer;
}
.cart-totals .totals-tax-summary .amount .price,
.opc-block-summary .table-totals .totals-tax-summary .amount .price {
    display: block;
    text-decoration: none;
    padding-right: 25px;
    position: relative;
}
.abs-sidebar-totals .totals-tax-summary .amount .price > span,
.cart-totals .totals-tax-summary .amount .price > span,
.opc-block-summary .table-totals .totals-tax-summary .amount .price > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.abs-sidebar-totals .totals-tax-summary .amount .price:after,
.cart-totals .totals-tax-summary .amount .price:after,
.opc-block-summary .table-totals .totals-tax-summary .amount .price:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 30px;
    line-height: inherit;
    color: inherit;
    content: '\e622';
    font-family: 'icons-blank-theme';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.cart-totals .totals-tax-summary .amount .price:after,
.opc-block-summary .table-totals .totals-tax-summary .amount .price:after {
    position: absolute;
    right: -5px;
    top: -12px;
}
.cart-totals .totals-tax-summary.expanded .mark,
.cart-totals .totals-tax-summary.expanded .amount,
.opc-block-summary .table-totals .totals-tax-summary.expanded .mark,
.opc-block-summary .table-totals .totals-tax-summary.expanded .amount {
    border-bottom: 0;
}
.abs-sidebar-totals .totals-tax-summary.expanded .amount .price:after,
.cart-totals .totals-tax-summary.expanded .amount .price:after,
.opc-block-summary .table-totals .totals-tax-summary.expanded .amount .price:after {
    content: '\e621';
    font-family: 'icons-blank-theme';
}
.cart-totals .totals-tax-details,
.opc-block-summary .table-totals .totals-tax-details {
    border-bottom: 1px solid #d1d1d1;
    display: none;
}
.cart-totals .totals-tax-details.shown,
.opc-block-summary .table-totals .totals-tax-details.shown {
    display: table-row;
}
.abs-discount-block > .title:after,
.cart-summary .block > .title:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 30px;
    line-height: inherit;
    color: inherit;
    content: '\e622';
    font-family: 'icons-blank-theme';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.abs-discount-block.active > .title:after,
.cart-summary .block.active > .title:after {
    content: '\e621';
    font-family: 'icons-blank-theme';
}

/********* END Cart & Checkout sidebar totals *********/

.items {
    margin: 0;
    padding: 0;
    list-style: none none;
}
.columns {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    box-sizing: border-box;
}
.columns:after {
    clear: both;
    content: ' ';
    display: block;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}
.columns .column.main {
    padding-bottom: 40px;
    -webkit-flex-basis: auto;
    flex-basis: auto;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
    width: 100%;
}
.columns .sidebar-main {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
}
.columns .sidebar-additional {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
}
.table-wrapper {
    margin-bottom: 20px;
}
table > caption {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.table:not(.cart):not(.totals) {
    border: none;
}
.table:not(.cart):not(.totals) > thead > tr > th,
.table:not(.cart):not(.totals) > tbody > tr > th,
.table:not(.cart):not(.totals) > tfoot > tr > th,
.table:not(.cart):not(.totals) > thead > tr > td,
.table:not(.cart):not(.totals) > tbody > tr > td,
.table:not(.cart):not(.totals) > tfoot > tr > td {
    border: none;
}
.table:not(.cart):not(.totals) > thead > tr > th,
.table:not(.cart):not(.totals) > thead > tr > td {
    border-bottom: 1px solid #d1d1d1;
}
.table:not(.cart):not(.totals) tfoot > tr:first-child th,
.table:not(.cart):not(.totals) tfoot > tr:first-child td {
    border-top: 1px solid #d1d1d1;
    padding-top: 20px;
}
.table:not(.cart):not(.totals) tfoot .mark {
    font-weight: 400;
    text-align: right;
}
.message.info {
    margin: 0 0 10px;
    padding: 10px 20px;
    display: block;
    line-height: 1.2em;
    font-size: 1.3rem;
    background: #fdf0d5;
    color: #6f4400;
    padding-left: 40px;
    position: relative;
}
.message.info a {
    color: #1979c3;
}
.message.info a:hover {
    color: #006bb4;
}
.message.info a:active {
    color: #006bb4;
}
.message.info > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #c07600;
    content: '\e602';
    font-family: 'icons-blank-theme';
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center;
}
.message.error {
    margin: 0 0 10px;
    padding: 10px 20px;
    display: block;
    line-height: 1.2em;
    font-size: 1.3rem;
    background: #fae5e5;
    color: #e02b27;
    padding-left: 40px;
    position: relative;
}
.message.error a {
    color: #1979c3;
}
.message.error a:hover {
    color: #006bb4;
}
.message.error a:active {
    color: #006bb4;
}
.message.error > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #b30000;
    content: '\e602';
    font-family: 'icons-blank-theme';
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center;
}
.message.warning {
    margin: 0 0 10px;
    padding: 10px 20px;
    display: block;
    line-height: 1.2em;
    font-size: 1.3rem;
    background: #fdf0d5;
    color: #6f4400;
    padding-left: 40px;
    position: relative;
}
.message.warning a {
    color: #1979c3;
}
.message.warning a:hover {
    color: #006bb4;
}
.message.warning a:active {
    color: #006bb4;
}
.message.warning > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #c07600;
    content: '\e602';
    font-family: 'icons-blank-theme';
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center;
}
.message.notice {
    margin: 0 0 10px;
    padding: 10px 20px;
    display: block;
    line-height: 1.2em;
    font-size: 1.3rem;
    background: #fdf0d5;
    color: #6f4400;
    padding-left: 40px;
    position: relative;
}
.minicart-items .message.notice {
    font-size: 1rem;
}
.message.notice a {
    color: #1979c3;
}
.message.notice a:hover {
    color: #006bb4;
}
.message.notice a:active {
    color: #006bb4;
}
.message.notice > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #c07600;
    content: '\e602';
    font-family: 'icons-blank-theme';
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center;
}
.message.success {
    margin: 0 0 10px;
    padding: 10px 20px;
    display: block;
    line-height: 1.2em;
    font-size: 1.3rem;
    background: #e5efe5;
    color: #006400;
    padding-left: 40px;
    position: relative;
}
.message.success a {
    color: #1979c3;
}
.message.success a:hover {
    color: #006bb4;
}
.message.success a:active {
    color: #006bb4;
}
.message.success > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #006400;
    content: '\e610';
    font-family: 'icons-blank-theme';
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center;
}
.panel.header .links,
.panel.header .switcher {
    display: none;
}
.tooltip.wrapper {
    position: relative;
}
.tooltip.wrapper .tooltip.content {
    background: #ffffff;
    max-width: 360px;
    min-width: 210px;
    padding: 12px 16px;
    z-index: 100;
    display: none;
    position: absolute;
    text-align: left;
    color: $black;
    line-height: 1.4;
    border: 1px solid #bbbbbb;
    margin-top: 5px;
    left: 0;
    top: 100%;
}
.tooltip.wrapper .tooltip.content:after,
.tooltip.wrapper .tooltip.content:before {
    border: solid transparent;
    content: '';
    height: 0;
    position: absolute;
    width: 0;
}
.tooltip.wrapper .tooltip.content:after {
    border-width: 5px;
    border-color: transparent;
}
.tooltip.wrapper .tooltip.content:before {
    border-width: 6px;
    border-color: transparent;
}
.tooltip.wrapper .tooltip.content:after,
.tooltip.wrapper .tooltip.content:before {
    bottom: 100%;
}
.tooltip.wrapper .tooltip.content:after {
    border-bottom-color: #ffffff;
    left: 15px;
    margin-left: -5px;
}
.tooltip.wrapper .tooltip.content:before {
    border-bottom-color: #bbbbbb;
    left: 15px;
    margin-left: -6px;
}
.tooltip.wrapper .tooltip.toggle {
    cursor: help;
}
.tooltip.wrapper .tooltip.toggle:hover + .tooltip.content,
.tooltip.wrapper .tooltip.toggle:focus + .tooltip.content,
.tooltip.wrapper:hover .tooltip.content {
    display: block;
}
.tooltip.wrapper .tooltip.content dl {
    margin-bottom: 0;
}
.tooltip.wrapper .tooltip.content dd {
    white-space: normal;
}
.tooltip.wrapper .tooltip.content .subtitle {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
}
.tooltip.wrapper .tooltip.content .label {
    margin-top: 10px;
}
.tooltip.wrapper .tooltip.content .label:first-child {
    margin-top: 0;
}
.tooltip.wrapper .tooltip.content .values {
    margin: 0;
}
.ui-tooltip {
    position: absolute;
    z-index: 9999;
}
.load.indicator {
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 9999;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    position: absolute;
}
.load.indicator:before {
    background: transparent url('../../frontend/Magento/blank/fr_FR/images/loader-2.gif') no-repeat 50% 50%;
    border-radius: 5px;
    height: 160px;
    width: 160px;
    bottom: 0;
    box-sizing: border-box;
    content: '';
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
}
.load.indicator > span {
    display: none;
}
.loading-mask {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
    background: rgba(255, 255, 255, 0.5);
}
.loading-mask .loader > img {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
}
.loading-mask .loader > p {
    display: none;
}
body > .loading-mask {
    z-index: 9999;
}
._block-content-loading {
    position: relative;
}
[data-role='main-css-loader'] {
    display: none;
}

.product-options-wrapper .date.required div[for*='options'].mage-error {
    display: none !important;
}
.field .tooltip {
    position: relative;
}
.field .tooltip .tooltip-content {
    background: #ffffff;
    max-width: 360px;
    min-width: 210px;
    padding: 12px 16px;
    z-index: 100;
    display: none;
    position: absolute;
    text-align: left;
    color: $black;
    line-height: 1.4;
    border: 1px solid #bbbbbb;
    margin-left: 5px;
    left: 100%;
    top: 0;
}
.field .tooltip .tooltip-content:after,
.field .tooltip .tooltip-content:before {
    border: solid transparent;
    content: '';
    height: 0;
    position: absolute;
    width: 0;
}
.field .tooltip .tooltip-content:after {
    border-width: 5px;
    border-color: transparent;
}
.field .tooltip .tooltip-content:before {
    border-width: 6px;
    border-color: transparent;
}
.field .tooltip .tooltip-content:after,
.field .tooltip .tooltip-content:before {
    right: 100%;
}
.field .tooltip .tooltip-content:after {
    border-right-color: #ffffff;
    margin-top: -5px;
    top: 15px;
}
.field .tooltip .tooltip-content:before {
    border-right-color: #bbbbbb;
    margin-top: -6px;
    top: 15px;
}
.field .tooltip .tooltip-toggle {
    cursor: help;
}
.field .tooltip .tooltip-toggle:hover + .tooltip-content,
.field .tooltip .tooltip-toggle:focus + .tooltip-content,
.field .tooltip:hover .tooltip-content {
    display: block;
}
.field .tooltip .tooltip-content {
    min-width: 200px;
    white-space: normal;
}
input:focus ~ .tooltip .tooltip-content,
select:focus ~ .tooltip .tooltip-content {
    display: block;
}
._has-datepicker ~ .ui-datepicker-trigger {
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-shadow: none;
    font-weight: 400;
    display: block;
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
}
._has-datepicker ~ .ui-datepicker-trigger:focus,
._has-datepicker ~ .ui-datepicker-trigger:active {
    background: none;
    border: none;
}
._has-datepicker ~ .ui-datepicker-trigger:hover {
    background: none;
    border: none;
}
._has-datepicker ~ .ui-datepicker-trigger.disabled,
._has-datepicker ~ .ui-datepicker-trigger[disabled],
fieldset[disabled] ._has-datepicker ~ .ui-datepicker-trigger {
    pointer-events: none;
    opacity: 0.5;
}
._has-datepicker ~ .ui-datepicker-trigger > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
._has-datepicker ~ .ui-datepicker-trigger:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 40px;
    line-height: 40px;
    color: #7d7d7d;
    content: '\e612';
    font-family: 'icons-blank-theme';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
._has-datepicker ~ .ui-datepicker-trigger:focus {
    box-shadow: none;
    outline: 0;
}
.sidebar .fieldset {
    margin: 0;
}
.sidebar .fieldset > .field:not(.choice):not(:last-child),
.sidebar .fieldset .fields > .field:not(:last-child) {
    margin: 0 0 20px;
}
.sidebar .fieldset > .field:not(.choice) .label,
.sidebar .fieldset .fields > .field .label {
    margin: 0 0 4px;
    padding: 0 0 5px;
    text-align: left;
    width: 100%;
}
.sidebar .fieldset > .field:not(.choice) .control,
.sidebar .fieldset .fields > .field .control {
    width: 100%;
}
button,
a.action.primary,
.cart.table-wrapper .actions-toolbar > .action,
.action-gift {
    border-radius: 2px;
}
button:active,
.cart.table-wrapper .actions-toolbar > .action:active,
.action-gift:active {
    box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.12);
}
a.action.primary {
    display: inline-block;
    text-decoration: none;
}
a.action.primary:hover,
a.action.primary:active,
a.action.primary:focus {
    text-decoration: none;
}
.action.primary,
.action-primary {
    background-image: none;
    background: $primary;
    border: 1px solid $primary;
    border-radius: 2px;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 700;
    padding: 10px 25px;
    font-size: 1rem;
    box-sizing: border-box;
    vertical-align: middle;
    transition: background 0.15s ease-in-out;
}
.action.primary:focus,
.action-primary:focus,
.action.primary:active,
.action-primary:active,
.action.primary:hover,
.action-primary:hover {
    background: $black;
    border: 1px solid $black;
    color: $white;
}
.action.primary.disabled,
.action-primary.disabled,
.action.primary[disabled],
.action-primary[disabled],
fieldset[disabled] .action.primary,
fieldset[disabled] .action-primary {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
}
.actions-toolbar > .primary,
.actions-toolbar > .secondary {
    text-align: center;
    margin-bottom: 10px;
}
.actions-toolbar > .primary .action,
.actions-toolbar > .secondary .action {
    margin-bottom: 10px;
}
.actions-toolbar > .primary .action:last-child,
.actions-toolbar > .secondary .action:last-child {
    margin-bottom: 0;
}
.actions-toolbar > .primary:last-child,
.actions-toolbar > .secondary:last-child {
    margin-bottom: 0;
}

.ui-dialog.popup .action.close {
    position: absolute;
    height: 40px;
    right: 0;
    top: 0;
    width: 40px;
    z-index: 1001;
    display: inline-block;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
}
.ui-dialog.popup .action.close > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.ui-dialog.popup .action.close:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 22px;
    color: inherit;
    content: '\e616';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.ui-dialog.popup .action.close:hover:before {
    color: inherit;
}
.ui-dialog.popup .action.close:active:before {
    color: inherit;
}
.ui-dialog.popup .action.close:focus,
.ui-dialog.popup .action.close:active {
    background: none;
    border: none;
}
.ui-dialog.popup .action.close:hover {
    background: none;
    border: none;
}
.ui-dialog.popup .action.close.disabled,
.ui-dialog.popup .action.close[disabled],
fieldset[disabled] .ui-dialog.popup .action.close {
    pointer-events: none;
    opacity: 0.5;
}
.popup-pointer {
    margin-left: -14px;
    position: absolute;
    top: -14px;
}
.popup-pointer:before,
.popup-pointer:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-bottom-style: solid;
}
.popup-pointer:before {
    left: 2px;
    top: 2px;
    border: solid 6px;
    border-color: transparent transparent #ffffff transparent;
    z-index: 99;
}
.popup-pointer:after {
    left: 1px;
    top: 0;
    border: solid 7px;
    border-color: transparent transparent #aeaeae transparent;
    z-index: 98;
}
.price-tier_price .price-excluding-tax,
.price-tier_price .price-including-tax {
    display: inline;
}
.price-including-tax,
.price-excluding-tax {
    display: block;
    font-size: 1.8rem;
    line-height: 1;
}
.price-including-tax .price,
.price-excluding-tax .price {
    font-weight: 700;
}
.price-including-tax + .price-excluding-tax,
.weee[data-label] {
    display: block;
    font-size: 1.8rem;
}
.price-including-tax + .price-excluding-tax:before,
.weee[data-label]:before {
    content: attr(data-label) ': ';
    font-size: 1.1rem;
}
.price-including-tax + .price-excluding-tax .price,
.weee[data-label] .price {
    font-size: 1.1rem;
}
body._has-modal {
    height: 100%;
    overflow: hidden;
    width: 100%;
}
.modals-overlay {
    z-index: 899;
}
.modal-slide,
.modal-popup {
    bottom: 0;
    left: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 0s 0.3s, opacity 0.3s ease;
    transition: visibility 0s 0.3s, opacity 0.3s ease;
}
.modal-slide._show,
.modal-popup._show {
    visibility: visible;
    opacity: 1;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
}
.modal-slide._show .modal-inner-wrap,
.modal-popup._show .modal-inner-wrap {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}
.modal-slide .modal-inner-wrap,
.modal-popup .modal-inner-wrap {
    background-color: #ffffff;
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
    opacity: 1;
    pointer-events: auto;
}
.modal-slide {
    left: 44px;
    z-index: 900;
}
.modal-slide._show .modal-inner-wrap {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}
.modal-slide .modal-inner-wrap {
    height: 100%;
    overflow-y: auto;
    position: static;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    width: auto;
}
.modal-slide._inner-scroll .modal-inner-wrap {
    overflow-y: visible;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}
.modal-slide._inner-scroll .modal-header,
.modal-slide._inner-scroll .modal-footer {
    -webkit-flex-grow: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
}
.modal-slide._inner-scroll .modal-content {
    overflow-y: auto;
}
.modal-slide._inner-scroll .modal-footer {
    margin-top: auto;
}
.modal-slide .modal-header,
.modal-slide .modal-content,
.modal-slide .modal-footer {
    padding: 0 3.6rem 3.6rem;
}
.modal-slide .modal-header {
    padding-bottom: 2.1rem;
    padding-top: 2.1rem;
}
.modal-popup {
    z-index: 900;
    left: 0;
    overflow-y: auto;
}
.modal-popup.confirm .modal-inner-wrap {
    max-width: 50rem;
}
.modal-popup._show .modal-inner-wrap {
    -webkit-transform: translateY(0);
    transform: translateY(0);
}
.modal-popup .modal-inner-wrap {
    margin: 5rem auto;
    width: 75%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    box-sizing: border-box;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
    -webkit-transition: -webkit-transform 0.2s ease;
    transition: transform 0.2s ease;
}
.modal-popup._inner-scroll {
    overflow-y: visible;
}
.ie11 .modal-popup._inner-scroll {
    overflow-y: auto;
}
.modal-popup._inner-scroll .modal-inner-wrap {
    max-height: 90%;
}
.ie11 .modal-popup._inner-scroll .modal-inner-wrap {
    max-height: none;
}
.modal-popup._inner-scroll .modal-content {
    overflow-y: auto;
}
.modal-popup .modal-header,
.modal-popup .modal-content,
.modal-popup .modal-footer {
    padding-left: 3.6rem;
    padding-right: 3.6rem;
}
.modal-popup .modal-header,
.modal-popup .modal-footer {
    -webkit-flex-grow: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
}
.modal-popup .modal-header {
    padding-bottom: 2.4rem;
    padding-top: 3rem;
}
.modal-popup .modal-footer {
    margin-top: auto;
    padding-bottom: 3rem;
    padding-top: 3rem;
}
.modal-popup .modal-footer-actions {
    text-align: right;
}
.modal-custom .action-close,
.modal-popup .action-close,
.modal-slide .action-close {
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-shadow: none;
    font-weight: 400;
    display: inline-block;
    text-decoration: none;
    position: absolute;
    right: 0;
    top: 0;
}
.modal-custom .action-close:focus,
.modal-popup .action-close:focus,
.modal-slide .action-close:focus,
.modal-custom .action-close:active,
.modal-popup .action-close:active,
.modal-slide .action-close:active {
    background: none;
    border: none;
}
.modal-custom .action-close:hover,
.modal-popup .action-close:hover,
.modal-slide .action-close:hover {
    background: none;
    border: none;
}
.modal-custom .action-close.disabled,
.modal-popup .action-close.disabled,
.modal-slide .action-close.disabled,
.modal-custom .action-close[disabled],
.modal-popup .action-close[disabled],
.modal-slide .action-close[disabled],
fieldset[disabled] .modal-custom .action-close,
fieldset[disabled] .modal-popup .action-close,
fieldset[disabled] .modal-slide .action-close {
    pointer-events: none;
    opacity: 0.5;
}
.modal-custom .action-close > span,
.modal-popup .action-close > span,
.modal-slide .action-close > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.modal-custom .action-close:before,
.modal-popup .action-close:before,
.modal-slide .action-close:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 58px;
    line-height: 58px;
    color: $lighter-grey;
    content: '\e616';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.modal-custom .action-close:hover:before,
.modal-popup .action-close:hover:before,
.modal-slide .action-close:hover:before {
    color: inherit;
}
.modal-custom .action-close:active:before,
.modal-popup .action-close:active:before,
.modal-slide .action-close:active:before {
    color: inherit;
}
.modal-custom .action-close:hover:before,
.modal-popup .action-close:hover:before,
.modal-slide .action-close:hover:before {
    color: #1a1a1a;
}
.modal-custom .action-close {
    margin: 25px;
}
.modal-popup {
    pointer-events: none;
}
.modal-popup .modal-title {
    border-bottom: 1px solid #c1c1c1;
    font-weight: 300;
    padding-bottom: 10px;
    font-size: 26px;
    margin-bottom: 0;
    min-height: 1em;
    word-wrap: break-word;
}
.modal-popup .action-close {
    padding: 10px;
}
.modal-slide .action-close {
    padding: 1.1rem 2.4rem;
}
.modal-slide .page-main-actions {
    margin-bottom: -12.9rem;
    margin-top: 2.1rem;
}
.modals-overlay {
    background-color: rgba(51, 51, 51, 0.55);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}
body._has-modal-custom .modal-custom-overlay {
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 899;
}
/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.lac-notification-sticky {
    position: relative;
    z-index: 999;
}
.lac-notification-sticky .lac-notification {
    background-color: #373330;
    color: #ffffff;
    font-size: 16px;
}
.lac-notification-sticky .lac-notification .lac-notification-icon {
    float: left;
    margin: 10px 25px 10px 10px;
}
.lac-notification-sticky .lac-notification .lac-notification-icon .logo-img {
    display: block;
}
.lac-notification-sticky .lac-notification .lac-notification-text {
    float: left;
    padding: 15px 0;
}
.lac-notification-sticky .lac-notification .lac-notification-links {
    float: right;
    padding: 15px 0;
}
.lac-notification-sticky .lac-notification .lac-notification-links a {
    color: #ffffff;
    font-size: 14px;
}
.lac-notification-sticky .lac-notification .lac-notification-links .lac-notification-close-link:after {
    background: url('../../frontend/Magento/blank/fr_FR/Magento_LoginAsCustomerFrontendUi/images/close.svg');
    content: ' ';
    display: inline-block;
    height: 12px;
    margin-left: 5px;
    vertical-align: middle;
    width: 12px;
}
/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.login-container .g-recaptcha,
.form-login .g-recaptcha,
.form-edit-account .g-recaptcha {
    margin-bottom: 10px !important;
}
/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.required-captcha.checkbox {
    position: absolute;
    display: block;
    visibility: visible;
    overflow: hidden;
    opacity: 0;
    width: 1px;
    height: 1px;
}
/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.block.newsletter .field-recaptcha .field .control:before {
    content: none;
}
/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.form.send.friend .g-recaptcha {
    margin-top: 40px;
}
.brands-filters {
    display: inline-block;
    margin: 0 0 10px;
    width: 100%;
    min-height: 50px;
}
.brands-filters .letter {
    display: block;
    float: left;
    margin: 0 5px 10px;
    padding: 2px 5px;
    min-width: 17px;
    min-height: 20px;
    text-align: center;
    color: #333;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #e2e2e2;
}
.brands-filters .letter-all {
    min-width: 90px;
    margin-right: 20px;
}
.brands-filters .letters {
    margin-top: 4px;
}
.brands-filters .letter.-disabled {
    opacity: 0.6;
    pointer-events: none;
    background-color: #f1f1f1;
}
.brands-filters .letter.hide {
    display: none;
}
.brands-filters .letter:hover,
.brands-filters .letter.-active {
    background: #fed285;
}
.brand-images .brands-letter {
    width: 100%;
    vertical-align: top;
    box-sizing: border-box;
}
.brand-images .brands-letter .bare-list {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
}
.brand-images .brands-letter .letter {
    border-bottom: 1px solid #b2b2b2;
    font-size: 18px;
    font-weight: 400;
}
.brand-images .brands-letter .letter .title {
    padding: 0 2px 1px;
    text-transform: uppercase;
    background: #e2e2e2;
}
.brands-letter .brand-item {
    position: relative;
    margin: 0 5px 20px;
}
.brands-letter .brand-item .item {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    color: $black;
    -webkit-justify-content: flex-start;
}
.brands-letter .brand-item .item:hover {
    text-decoration: none;
}
.brands-letter .brand-item .no-img {
    color: #e2e2e2;
}
.brands-letter .brand-item .count {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    box-sizing: border-box;
    min-width: 25px;
    height: 25px;
    padding: 0 3px;
    line-height: 25px;
    text-align: center;
    background: #f58c12;
    border-radius: 20px;
    color: #ffffff;
}
.brands-letter .brand-item .label {
    position: relative;
    margin: 5px 0 0;
    padding-top: 5px;
    width: 100%;
    word-wrap: break-word;
}
.brands-letter .brand-item .label:before {
    content: '';
    position: absolute;
    top: 0;
    left: 25%;
    right: 25%;
    display: block;
    height: 1px;
    background: #b2b2b2;
}
.brands-letter .brand-item .item:hover .label:before {
    left: 0;
    right: 0;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    -ms-transition: all ease 0.3s;
    transition: all ease 0.3s;
}
.icon .brand-label {
    float: left;
}
.brand-images li.brand-item {
    list-style-type: none;
    text-align: center;
}
ul.bare-list img {
    display: block;
    margin: 0 auto;
}
.children-category-container.swiper-container {
    width: calc(100% - 2 * 27px);
    margin: auto;
}
.children-category-container .swiper-wrapper {
    -webkit-align-items: baseline;
    -moz-align-items: baseline;
    -ms-align-items: baseline;
    align-items: baseline;
}
#category-carousel .swiper-button-next {
    right: 0;
}
#category-carousel .swiper-button-prev {
    left: 0;
}
#category-carousel .swiper-button-next,
#category-carousel .swiper-button-prev {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0.3;
    height: 100%;
    margin: 0;
}
#category-carousel .swiper-button-next:hover,
#category-carousel .swiper-button-prev:hover {
    opacity: 1;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    -ms-transition: all ease 0.3s;
    transition: all ease 0.3s;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .swiper-brand {
        width: 100%;
    }
    .swiper-brand .no-img {
        width: 100%;
    }
}
.ui-tooltip.ui-widget {
    padding: 5px 10px;
    max-width: 250px;
    border: 1px solid #cccccc;
    background: #fff;
    box-shadow: 1px 0 3px #cccccc;
    word-wrap: break-word;
}
.ui-tooltip .arrow {
    position: absolute;
    bottom: -15px;
    width: 20px;
    height: 15px;
}
.ui-tooltip .arrow:after {
    position: absolute;
    border-top: 10px solid #cccccc;
    content: '';
}
.ui-tooltip .arrow:before {
    position: absolute;
    top: -1px;
    z-index: 9;
    border-top: 9px solid #fff;
    content: '';
}
.ui-tooltip .arrow.top {
    top: -15px;
    transform: scale(1, -1);
}
.ui-tooltip .arrow.left {
    left: 15px;
}
.ui-tooltip .arrow.left:before {
    left: 1px;
    border-right: 19px solid transparent;
}
.ui-tooltip .arrow.left:after {
    border-right: 20px solid transparent;
}
.ui-tooltip .arrow.right {
    right: 15px;
}
.ui-tooltip .arrow.right:before {
    right: 1px;
    border-left: 19px solid transparent;
}
.ui-tooltip .arrow.right:after {
    border-left: 20px solid transparent;
}
.ui-tooltip .ui-tooltip-content h3 {
    margin: 5px 0;
}
.ui-tooltip .arrow {
    left: 8px;
    width: 15px;
}
.ui-tooltip .arrow:after {
    border-right: 15px solid transparent;
}
.ui-tooltip .arrow:before {
    border-right: 14px solid transparent;
}
.filter-options-item .filter-options-title:after {
    font-size: 14px;
}
.catalog-topnav .filter-options {
    flex-wrap: wrap;
    min-height: 40px;
}
.catalog-topnav .filter-options .filter-options-item {
    display: inline-block;
    border: 0;
}
.catalog-topnav .filter-options .filter-options-content {
    position: absolute;
    z-index: 9999;
    margin-top: 12px;
    min-width: 100px;
    border: 1px solid transparent;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(190, 199, 219, 0.21), 0 6px 25px rgba(190, 199, 219, 0.28);
}
.catalog-topnav .block-actions {
    display: none;
}
.catalog-topnav {
    display: none;
}
.filter-options .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
    top: 5px;
    right: 8px;
}
.page-layout-1column .filter-options-item.active .filter-options-content {
    padding: 10px 15px;
}
.page-layout-1column .filter-options-content li.item {
    padding: 0;
}
.filter [data-role='collapsible']:not([data-collapsible='true']) [data-role='content'] {
    display: none;
}
div.swatch-option-tooltip {
    z-index: 9999;
}
.swatch-option-tooltip .image,
.swatch-option-tooltip-layered .image {
    background-size: contain !important;
}
.filter-options .ui-menu {
    display: block;
    margin: 0;
    padding: 0;
    outline: 0;
    list-style: none;
}
.filter-options .ui-menu .ui-menu {
    position: absolute;
}
.filter-options .ui-menu .ui-menu-item {
    margin: 0;
    list-style: none;
    cursor: pointer;
}
.filter-options .ui-menu .ui-menu-item-wrapper {
    position: relative;
    padding: 3px 1em 3px 0.4em;
}
.filter-options .ui-menu .ui-menu-divider {
    margin: 5px 0;
    height: 0;
    border-width: 1px 0 0;
    font-size: 0;
    line-height: 0;
}
.filter-options .items.-am-overflow {
    overflow-x: hidden;
    overflow-y: auto;
}
.filter-options .items .items-children.-folding {
    padding-left: 20px;
    width: 100%;
    list-style: none;
}
.filter-options .items.-am-multiselect .item.-is-expanded .items-children.-folding {
    padding-left: 0;
}
.filter-options .items .items-children.-folding .item {
    margin: 5px 0;
}
.filter-options .item.-empty-value {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
}
.filter-options .ui-widget-overlay {
    background: #aaa;
    opacity: 0.3;
}
.filter-options .ui-widget-shadow {
    box-shadow: 0 0 5px #666;
}
.filter-actions .filter-clear {
    color: #1979c3;
    font-size: 14px;
    line-height: 19px;
}
.filter-actions .filter-clear:hover {
    color: #399ae6;
    text-decoration: none;
}
.filter-actions .filter-clear:focus,
.filter-actions .filter-clear:active {
    color: #135d96;
}
.filter-options-content .item a:hover {
    background-color: transparent;
    color: #1979c3;
}
.filter-options-content .item a:hover .count {
    color: #1979c3;
}
.filter-options-content .item a:active,
.filter-options-content .item a:active .count {
    color: #135d96;
    text-decoration: none;
}
.filter-options-content .item:first-child {
    margin-top: 0;
}
.filter-options-content .item:last-child {
    margin-bottom: 0;
}
.filter-options-content .item > a {
    display: block;
}
.filter-options-content .items.-am-singleselect .item > input[type='radio'] {
    display: none;
}
.column .block-addbysku .fieldset {
    margin: 0;
}
.column .block-addbysku .fieldset .fields {
    position: relative;
}
.column .block-addbysku .fieldset .fields .field {
    display: inline-block;
    margin-bottom: 10px;
    vertical-align: top;
}
.column .block-addbysku .fieldset .fields .actions-toolbar {
    position: absolute;
    right: 0;
    top: 4px;
}
.column .block-addbysku .sku {
    margin-right: -155px;
    padding-right: 160px;
    width: 100%;
}
.column .block-addbysku .skus > .label {
    margin-top: 10px;
}
.column .block-addbysku .qty .qty + .mage-error {
    width: 80px;
}
.column .block-addbysku .block-content .actions-toolbar {
    clear: both;
    margin: 0 0 20px;
    text-align: left;
}
.column .block-addbysku .block-content .actions-toolbar:before,
.column .block-addbysku .block-content .actions-toolbar:after {
    content: '';
    display: table;
}
.column .block-addbysku .block-content .actions-toolbar:after {
    clear: both;
}
.column .block-addbysku .block-content .actions-toolbar .primary {
    float: left;
}
.column .block-addbysku .block-content .actions-toolbar .primary,
.column .block-addbysku .block-content .actions-toolbar .secondary {
    display: inline-block;
}
.column .block-addbysku .block-content .actions-toolbar .primary a.action,
.column .block-addbysku .block-content .actions-toolbar .secondary a.action {
    display: inline-block;
}
.column .block-addbysku .block-content .actions-toolbar .primary .action {
    margin: 0 5px 0 0;
}
.column .block-addbysku .block-content .actions-toolbar .secondary a.action {
    margin-top: 6px;
}
.column .block-addbysku .block-content .actions-toolbar .primary,
.column .block-addbysku .block-content .actions-toolbar .secondary {
    display: block;
    float: none;
}
.column .block-addbysku .block-content .actions-toolbar > .secondary {
    text-align: left;
}
.column .block-addbysku .reset {
    margin: 10px 0;
}
.column .block-addbysku .note {
    display: block;
    margin: 0 0 20px;
}
.block-cart-failed {
    margin-top: 40px;
}
.block-cart-failed .block-title {
    font-size: 2.6rem;
}
.block-cart-failed .block-title strong {
    font-weight: 300;
}
.block-cart-failed .actions {
    text-align: center;
}
.block-cart-failed .actions .action {
    margin-bottom: 10px;
}
.bundle-options-container {
    clear: both;
    margin-bottom: 40px;
}
.bundle-options-container .legend.title {
    font-weight: 300;
    line-height: 1.1;
    font-size: 2.6rem;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
}
.bundle-options-container .product-add-form {
    display: none;
}
.bundle-options-container .product-options-wrapper {
    margin-bottom: 30px;
}
.bundle-options-container .action.back {
    margin-bottom: 30px;
}
.bundle-options-container .price-box .price {
    font-size: 18px;
    font-weight: 700;
}
.bundle-options-container .block-bundle-summary {
    background: #e8e8e8;
    padding: 10px 20px;
}
.bundle-options-container .block-bundle-summary > .title > strong {
    font-weight: 300;
    line-height: 1.1;
    font-size: 2.6rem;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
}
.bundle-options-container .block-bundle-summary .bundle-summary {
    margin-top: 30px;
}
.bundle-options-container .block-bundle-summary .bundle-summary.empty {
    display: none;
}
.bundle-options-container .block-bundle-summary .bundle-summary > .subtitle {
    font-weight: 300;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    display: block;
}
.bundle-options-container .block-bundle-summary .bundle.items > li {
    margin-bottom: 10px;
}
.bundle-options-container .block-bundle-summary .box-tocart .actions {
    display: inline-block;
    margin-bottom: 10px;
}
.bundle-options-container .block-bundle-summary .product-addto-links > .action {
    vertical-align: top;
}
.bundle-options-container .nested .field.qty {
    margin-top: 20px;
}
.bundle-options-container .nested .field.qty .label {
    font-weight: 700;
    margin: 0 0 5px;
    display: inline-block;
}
.bundle-options-container p.required {
    color: #e02b27;
}
.bundle-options-container .product-options-bottom {
    clear: left;
}
.prices-tier {
    background: #f5f5f5;
    margin: 10px 0;
    padding: 10px 15px;
}
.prices-tier .price-container {
    display: inline-block;
}
.prices-tier .price-including-tax,
.prices-tier .price-excluding-tax,
.prices-tier .weee {
    display: inline-block;
}
.prices-tier .price-including-tax .price,
.prices-tier .price-excluding-tax .price,
.prices-tier .weee .price {
    font-size: 1.4rem;
    font-weight: 700;
}
.ui-dialog-titlebar-close {
    line-height: 1.42857143;
    margin: 0;
    padding: 0;
    color: #1979c3;
    text-decoration: none;
    background: none;
    border: 0;
    display: inline;
}
.ui-dialog-titlebar-close:visited {
    color: #1979c3;
    text-decoration: none;
}
.ui-dialog-titlebar-close:hover {
    color: #006bb4;
    text-decoration: underline;
}
.ui-dialog-titlebar-close:active {
    color: $primary;
    text-decoration: underline;
}
.ui-dialog-titlebar-close:hover,
.ui-dialog-titlebar-close:active,
.ui-dialog-titlebar-close:focus {
    background: none;
    border: 0;
}
.ui-dialog-titlebar-close.disabled,
.ui-dialog-titlebar-close[disabled],
fieldset[disabled] .ui-dialog-titlebar-close {
    color: #1979c3;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline;
}
.block.related .action.select {
    margin: 0 5px;
}
.sidebar .product-items .product-item {
    margin-bottom: 20px;
    position: relative;
}
.sidebar .product-items .product-item-info {
    position: relative;
    width: auto;
}
.sidebar .product-items .product-item-info .product-item-photo {
    left: 0;
    position: absolute;
    top: 0;
}
.sidebar .product-items .product-item-name {
    margin-top: 0;
}
.sidebar .product-items .product-item-details {
    margin: 0 0 0 85px;
}
.sidebar .product-items .product-item-actions {
    display: block;
    margin-top: 10px;
}
.sidebar .product-items .price-box {
    display: block;
    margin: 7px 0;
}
.sidebar .product-items .text {
    margin-right: 8px;
}
.sidebar .product-items .counter {
    color: #7d7d7d;
    font-size: 1.2rem;
    white-space: nowrap;
}
.sidebar .product-items .minilist .price {
    display: inline;
    padding: 0;
}
.sidebar .product-items .minilist .weee:before {
    display: inline-block;
}
.sidebar .action.delete {
    position: absolute;
    right: 0;
    top: -1px;
}
.sidebar .block-wishlist .action.delete {
    line-height: unset;
    width: auto;
}
.sidebar .block-compare .action.delete {
    right: initial;
}
.sidebar .product-items-images {
    margin-left: -5px;
}
.sidebar .product-items-images .product-item {
    float: left;
    padding-left: 5px;
}
.sidebar .product-items-names .product-item {
    display: flex;
    margin-bottom: 10px;
}
.sidebar .product-items-names .product-item-name {
    margin: 0;
}
.catalog-category-view.page-layout-1column .column.main {
    min-height: inherit;
}
body.catalog-product-compare-index .action.print {
    float: right;
    margin: 15px 0;
}
.table-wrapper.comparison {
    clear: both;
    max-width: 100%;
    overflow-x: auto;
}
.table-comparison {
    table-layout: fixed;
}
.table-comparison .cell.label,
.table-comparison td:last-child {
    border-right: 1px solid #d1d1d1;
}
.table-comparison .cell {
    padding: 15px;
    width: 140px;
}
.table-comparison .cell .attribute.value {
    overflow: hidden;
    width: 100%;
}
.table-comparison .cell.product.info,
.table-comparison .cell.product.label {
    border-bottom: 1px solid #d1d1d1;
}
.table-comparison .cell.label .attribute.label {
    display: block;
    width: 100%;
    word-wrap: break-word;
}
.table-comparison .cell.attribute {
    font-size: 1.3rem;
}
.table-comparison .cell.attribute img {
    height: auto;
    max-width: 100%;
}
.table-comparison .product-item-photo {
    display: block;
    margin: 0 auto 15px;
}
.table-comparison .product-image-photo {
    margin-left: 0;
}
.table-comparison .product-item-actions,
.table-comparison .price-box,
.table-comparison .product.rating,
.table-comparison .product-item-name {
    display: block;
    margin: 15px 0;
}
.table-comparison .product-addto-links {
    margin-top: 15px;
}
.table-comparison .product-addto-links .action.split,
.table-comparison .product-addto-links .action.toggle {
    line-height: 1.2rem;
    padding: 5px 8px;
    font-size: 1.1rem;
}
.table-comparison .product-addto-links .action.toggle {
    padding: 0;
}
.table-comparison .cell.remove {
    padding-bottom: 0;
    padding-top: 0;
    text-align: right;
}
.table-comparison .product-item-actions > .actions-primary + .actions-secondary {
    margin-top: 10px;
}
.table-comparison .action.tocart {
    white-space: nowrap;
}
.comparison.headings {
    background: #ffffff;
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 2;
}
.block-compare .product-item .product-item-name {
    margin-left: 22px;
}
.block-compare .action.delete {
    left: -6px;
    position: absolute;
    right: 0;
    top: 0;
}
.block-compare .actions-toolbar {
    margin: 17px 0 0;
}
.block-category-event {
    background: #f0f0f0;
    color: $black;
    padding: 10px 0 15px;
    text-align: center;
}
.block-category-event.block:last-child {
    margin-bottom: 10px;
}
.block-category-event .block-title {
    margin: 0 0 10px;
}
.block-category-event .block-title strong {
    font-size: 1.5rem;
    font-weight: 300;
}
.block-category-event .ticker {
    margin: 0;
    padding: 0;
    list-style: none none;
}
.block-category-event .ticker li {
    display: none;
    margin: 0 15px;
}
.block-category-event .ticker .value {
    font-size: 1.8rem;
    font-weight: 300;
}
.block-category-event .ticker .label {
    font-size: 1rem;
    display: block;
    text-transform: lowercase;
}
.block-category-event .dates {
    display: table;
    margin: 0 auto;
}
.block-category-event .dates .start,
.block-category-event .dates .end {
    display: table-cell;
    vertical-align: top;
}
.block-category-event .dates .date {
    font-size: 1.8rem;
}
.block-category-event .dates .time {
    display: block;
}
.block-category-event .dates .start {
    padding-right: 40px;
    position: relative;
}
.block-category-event .dates .start:after {
    font-size: 1.8rem;
    content: '\2013';
    display: block;
    position: absolute;
    right: 15px;
    top: 0;
}
.form.search.advanced .fields.range .field:first-child {
    position: relative;
}
.form.search.advanced .fields.range .field:first-child .control {
    padding-right: 25px;
}
.form.search.advanced .fields.range .field:first-child .control:after {
    content: ' \2013 ';
    display: inline-block;
    position: absolute;
    right: 0;
    text-align: center;
    top: 6px;
    width: 25px;
}
.form.search.advanced .fields.range .field:last-child {
    position: relative;
}
.form.search.advanced .fields.range .field:last-child div.mage-error[generated] {
    left: 0;
    position: absolute;
    top: 32px;
}
.form.search.advanced .fields.range .field.with-addon .control {
    padding-right: 45px;
}
.form.search.advanced .group.price .addon .addafter {
    background: none;
    border: 0;
    padding-top: 6px;
    position: absolute;
    right: 0;
    top: 0;
}
.search.summary {
    margin-bottom: 10px;
}
.cart.table-wrapper .actions-toolbar > .action,
.action-gift {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    margin-right: 10px;
}
.cart.table-wrapper .actions-toolbar > .action:hover,
.cart.table-wrapper .actions-toolbar > .action:active,
.cart.table-wrapper .actions-toolbar > .action:focus,
.action-gift:hover,
.action-gift:active,
.action-gift:focus {
    text-decoration: none;
}
.cart.table-wrapper .col.price,
.order-items.table-wrapper .col.price,
.cart.table-wrapper .col.qty,
.order-items.table-wrapper .col.qty,
.cart.table-wrapper .col.subtotal,
.order-items.table-wrapper .col.subtotal,
.cart.table-wrapper .col.msrp,
.order-items.table-wrapper .col.msrp {
    text-align: right;
}

.field.choice .field-tooltip {
    display: inline-block;
    margin-left: 10px;
    position: relative;
    top: -3px;
}
.field.choice .field-tooltip-action {
    line-height: 24px;
}
.field._error .control input,
.field._error .control select,
.field._error .control textarea {
    border-color: #ed8380;
}
.opc-wrapper .fieldset > .field > .label {
    font-weight: 400;
}
.field-tooltip {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 1px;
}
.field-tooltip._active {
    z-index: 100;
}
.field-tooltip._active .field-tooltip-content {
    display: block;
}
.field-tooltip._active .field-tooltip-action:before {
    color: $black;
}
.field-tooltip .label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.field-tooltip .field-tooltip-action {
    display: inline-block;
    text-decoration: none;
}
.field-tooltip .field-tooltip-action > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.field-tooltip .field-tooltip-action:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 24px;
    line-height: inherit;
    color: #bbbbbb;
    content: '\e623';
    font-family: 'icons-blank-theme';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.field-tooltip .field-tooltip-action:hover:before {
    color: $black;
}
.field-tooltip .field-tooltip-action:before {
    padding-left: 1px;
}
._keyfocus .field-tooltip .field-tooltip-action:focus {
    z-index: 100;
}
._keyfocus .field-tooltip .field-tooltip-action:focus + .field-tooltip-content {
    display: block;
}
._keyfocus .field-tooltip .field-tooltip-action:focus:before {
    color: $black;
}
.field-tooltip .field-tooltip-content {
    background: #f4f4f4;
    border: 1px solid #999999;
    border-radius: 1px;
    font-size: 14px;
    padding: 12px;
    width: 270px;
    display: none;
    left: 38px;
    position: absolute;
    text-transform: none;
    top: -9px;
    word-wrap: break-word;
    z-index: 2;
}
.field-tooltip .field-tooltip-content:before,
.field-tooltip .field-tooltip-content:after {
    border: 10px solid transparent;
    height: 0;
    width: 0;
    border-right-color: #f4f4f4;
    left: -21px;
    top: 12px;
    content: '';
    display: block;
    position: absolute;
    z-index: 3;
}
.field-tooltip .field-tooltip-content:before {
    border-right-color: #666666;
}
.field-tooltip .field-tooltip-content:after {
    border-right-color: #f4f4f4;
    width: 1px;
    z-index: 4;
}
@media only screen and (max-width: 768px) {
    .field-tooltip .field-tooltip-content {
        left: auto;
        right: -10px;
        top: 40px;
    }
    .field-tooltip .field-tooltip-content::before,
    .field-tooltip .field-tooltip-content::after {
        border: 10px solid transparent;
        height: 0;
        left: auto;
        margin-top: -21px;
        right: 10px;
        top: 0;
        width: 0;
    }
    .field-tooltip .field-tooltip-content::before {
        border-bottom-color: #999999;
    }
    .field-tooltip .field-tooltip-content::after {
        border-bottom-color: #f4f4f4;
        top: 1px;
    }
}
.authentication-dropdown {
    box-sizing: border-box;
}
.authentication-dropdown .modal-inner-wrap {
    padding: 25px;
}
.authentication-wrapper {
    float: right;
    margin-top: -60px;
    max-width: 50%;
    position: relative;
    z-index: 1;
}
._has-auth-shown .authentication-wrapper {
    z-index: 900;
}
/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
*/
.contact-index-index .column:not(.sidebar-main) .form.contact {
    float: none;
    width: 50%;
}
.contact-index-index .column:not(.sidebar-additional) .form.contact {
    float: none;
    width: 50%;
}

.block-addresses-list .items.addresses > .item {
    margin-bottom: 20px;
}
.block-addresses-list .items.addresses > .item:last-child {
    margin-bottom: 0;
}
.form-address-edit .region_id {
    display: none;
}
.form-edit-account .fieldset.password {
    display: none;
}
.fieldset .fullname.field > .label + .control {
    width: 100%;
}
.account .column.main h2 {
    margin-top: 0;
}
.account .column.main .toolbar {
    text-align: center;
}
.account .column.main .toolbar .limiter-options {
    width: auto;
}
.account .sidebar-additional {
    margin-top: 40px;
}
.account .table-wrapper:last-child {
    margin-bottom: 0;
}
.account .table-wrapper .action {
    margin-right: 15px;
}
.account .table-wrapper .action:last-child {
    margin-right: 0;
}
.column.main .block:last-child {
    margin-bottom: 0;
}
.block .title {
    margin-bottom: 10px;
}
.block .title strong {
    font-weight: 700;
    line-height: 1.1;
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.column.main .block .title strong {
    font-size: 18px;
}
.block p:last-child {
    margin: 0;
}
.block .box-actions {
    margin-top: 5px;
}
.field.password .control {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}
.field.password .control .mage-error {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
}
.field.password .control .input-text {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
    z-index: 2;
}
.password-strength-meter {
    background-color: #f4f4f4;
    height: 32px;
    line-height: 32px;
    padding: 0 9px;
    position: relative;
    z-index: 1;
}
.password-strength-meter:before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: -1;
}
.password-none .password-strength-meter:before {
    background-color: #f4f4f4;
    width: 100%;
}
.password-weak .password-strength-meter:before {
    background-color: #ffafae;
    width: 25%;
}
.password-medium .password-strength-meter:before {
    background-color: #ffd6b3;
    width: 50%;
}
.password-strong .password-strength-meter:before {
    background-color: #c5eeac;
    width: 75%;
}
.password-very-strong .password-strength-meter:before {
    background-color: #81b562;
    width: 100%;
}
.control.captcha-image {
    margin-top: 10px;
}
.control.captcha-image .captcha-img {
    vertical-align: middle;
}
.downloadable.samples {
    margin-bottom: 20px;
}
.downloadable.samples dd {
    margin: 0 0 10px;
}
.table-downloadable-products .product-name {
    margin-right: 15px;
}
.field.downloads .price-container {
    display: inline;
    white-space: nowrap;
}
.field.downloads .price-container:before {
    content: ' + ';
    display: inline;
}
.field.downloads .price-excluding-tax {
    display: inline;
}
.field.downloads .price-excluding-tax:before {
    content: ' (' attr(data-label) ': ';
}
.field.downloads .price-excluding-tax:after {
    content: ')';
}
.field.downloads .price-including-tax {
    font-size: 1.4rem;
    display: inline;
    font-weight: 600;
}
.field.downloads .sample.link {
    float: right;
}
.cart-summary .block.giftcard .action.check {
    line-height: 1.2rem;
    padding: 5px 8px;
    font-size: 1.1rem;
    margin-top: 20px;
}
.page-product-giftcard .giftcard-amount {
    margin-bottom: 0;
    text-align: left;
}
.page-product-giftcard .giftcard-amount .field:last-child {
    padding-bottom: 20px;
}
.page-product-giftcard .product-options-wrapper .field:first-of-type {
    margin-top: 20px;
}
.giftcard-account .please-wait {
    display: none;
}
.giftcard-account .form-giftcard-redeem .giftcard.info {
    margin-bottom: 30px;
}
.opc-wrapper .form-giftcard-account {
    max-width: 500px;
}
.opc-wrapper .form-giftcard-account .field {
    margin: 0 0 20px;
}
.opc-wrapper .giftcard-account-info {
    margin: 0 0 20px;
}
.opc-wrapper .giftcard-account-info .giftcard-account-number {
    margin: 0 0 10px;
}
.gift-message .field {
    margin-bottom: 20px;
}
.gift-message .field .label {
    color: #666666;
    font-weight: 400;
}
.gift-options .actions-toolbar .action-cancel {
    display: none;
}
.gift-options-title {
    margin: 0 0 20px;
}
.gift-options-content .fieldset {
    margin: 0 0 20px;
}
.gift-summary .actions-toolbar > .secondary {
    float: none;
}
.gift-summary .actions-toolbar > .secondary .action {
    margin: 10px 20px 0 0;
}
.action-gift {
    display: inline-block;
    text-decoration: none;
}
.action-gift:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 16px;
    color: inherit;
    content: '\e622';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.action-gift:hover:after {
    color: inherit;
}
.action-gift:active:after {
    color: inherit;
}
.action-gift.active:after,
.action-gift._active:after {
    content: '\e621';
    font-family: 'icons-blank-theme';
}
.cart.table-wrapper .gift-content {
    box-sizing: border-box;
    clear: left;
    display: none;
    float: left;
    margin: 10px 0 20px;
    text-align: left;
    width: 100%;
}
.cart.table-wrapper .gift-content._active {
    display: block;
}
.gift-item-block {
    margin: 0;
}
.gift-item-block._active .title:after {
    content: '\e621';
    font-family: 'icons-blank-theme';
}
.gift-item-block .title {
    border-radius: 3px;
    background-image: none;
    background: #f2f2f2;
    border: 1px solid #cdcdcd;
    color: $black;
    cursor: pointer;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 700;
    margin: 20px 0 0;
    padding: 7px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    text-decoration: none;
}
.gift-item-block .title:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 16px;
    color: inherit;
    content: '\e622';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.gift-item-block .title:hover:after {
    color: inherit;
}
.gift-item-block .title:active:after {
    color: inherit;
}
.gift-item-block .title:focus,
.gift-item-block .title:active {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: $black;
}
.gift-item-block .title:hover {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #555555;
}
.gift-item-block .title.disabled,
.gift-item-block .title[disabled],
fieldset[disabled] .gift-item-block .title {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
}
.gift-item-block .title:active {
    box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.12);
}
.gift-item-block .content {
    border-bottom: 1px solid #c1c1c1;
    padding: 10px 10px 20px;
    position: relative;
}
.order-details-items .order-gift-message .item-options dt,
.order-details-items .block-order-details-gift-message .item-options dt {
    font-weight: 400;
    margin: 10px 0;
}
.order-details-items .order-gift-message dt:after,
.order-details-items .block-order-details-gift-message dt:after {
    content: '';
}
.order-details-items .order-gift-message .item-message,
.order-details-items .block-order-details-gift-message .item-message {
    clear: left;
}
.order-details-items .order-items .action.show {
    display: inline-block;
    text-decoration: none;
    padding-right: 20px;
    position: relative;
}
.order-details-items .order-items .action.show:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: inherit;
    color: inherit;
    content: '\e622';
    font-family: 'icons-blank-theme';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.order-details-items .order-items .action.show:after {
    position: absolute;
    right: 0;
    top: -4px;
}
.order-details-items .order-items .action.show.expanded:after {
    content: '\e621';
    font-family: 'icons-blank-theme';
}
.block-order-details-gift-message {
    border-top: 1px solid #d1d1d1;
    padding-top: 10px;
}
.form-giftregistry-share .actions-toolbar:not(:last-child),
.form-giftregistry-edit .actions-toolbar:not(:last-child) {
    margin-bottom: 40px;
}
.form-giftregistry-share .fieldset .nested .field:not(.choice) .control,
.form-giftregistry-edit .fieldset .nested .field:not(.choice) .control {
    width: 100%;
}
.table-giftregistry-items .product-image {
    float: left;
    margin-right: 10px;
}
.cart-summary .form.giftregistry .fieldset {
    margin: 25px 0 5px;
}
.cart-summary .form.giftregistry .fieldset .field {
    margin: 0 0 10px;
}
.cart-summary .form.giftregistry .fieldset .field .control {
    float: none;
    width: auto;
}
.cart-summary .form.giftregistry .fieldset .action.primary {
    margin-bottom: 0;
}
.form-giftregistry-search {
    margin-bottom: 60px;
}
.form-giftregistry-search .fields-specific-options .field:nth-last-of-type(1) {
    margin-bottom: 0;
}
.block-giftregistry-results .toolbar-giftregistry-results {
    text-align: center;
}
.block-giftregistry .actions-toolbar {
    margin: 17px 0 0;
}
.block-giftregistry-shared .item-options .item-value {
    margin: 0 0 25px;
}
.block-giftregistry-shared-items .item {
    border-bottom: 1px solid #d1d1d1;
}
.block-giftregistry-shared-items .col {
    padding: 20px 8px 10px;
}
.block-giftregistry-shared-items .col .price-box {
    display: inline-block;
}
.block-giftregistry-shared-items .col .price-wrapper {
    font-size: 1.8rem;
    display: block;
    font-weight: 700;
    white-space: nowrap;
}
.block-giftregistry-shared-items .product .item-options {
    margin-bottom: 0;
}
.block-giftregistry-shared-items .product-item-photo {
    display: block;
    max-width: 65px;
    padding: 10px 0;
}
.block-giftregistry-shared-items .product-item-name {
    display: block;
    margin: 0 0 5px;
}
.gift-wrapping .label {
    margin: 0 0 5px;
    display: inline-block;
}
.gift-wrapping .field {
    margin-bottom: 10px;
}
.gift-wrapping-info {
    font-weight: 300;
    font-size: 18px;
    margin: 20px 0;
}
.gift-wrapping-list {
    list-style: none;
    margin: 20px 0 10px;
    padding: 0;
}
.gift-wrapping-list .no-image {
    background: #f2f2f2;
    display: inline-block;
    text-decoration: none;
}
.gift-wrapping-list .no-image > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.gift-wrapping-list .no-image:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 38px;
    line-height: 4rem;
    color: #494949;
    content: '\e629';
    font-family: 'icons-blank-theme';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.gift-wrapping-list .no-image:after {
    display: block;
}
.gift-wrapping-preview,
.gift-wrapping-item > span {
    cursor: pointer;
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 1;
}
.gift-wrapping-item {
    float: left;
    margin: 0 10px 10px 0;
}
.gift-wrapping-item:last-child {
    margin-right: 0;
}
.gift-wrapping-item._active span:before {
    border: 3px solid $black;
    box-sizing: border-box;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 2;
}
.gift-wrapping-item > span {
    height: 4rem;
    width: 4rem;
}
.gift-wrapping-title {
    margin: 10px 0;
}
.gift-wrapping-title .action-remove {
    margin-left: 5px;
}
.gift-options-content .fieldset > .field {
    margin: 0 0 5px;
}
.gift-options-content .fieldset > .field:before {
    display: none;
}
.gift-wrapping-summary .gift-wrapping-title {
    border: 0;
    display: inline-block;
    padding: 0;
}
.opc-wrapper .data.table .gift-wrapping,
.table-order-items .gift-wrapping {
    margin: 20px 0;
}
.opc-wrapper .data.table .gift-wrapping .title,
.table-order-items .gift-wrapping .title {
    float: none;
}
.opc-wrapper .data.table .gift-wrapping .content,
.table-order-items .gift-wrapping .content {
    margin: 0;
}
.opc-wrapper .data.table .gift-wrapping .content > img,
.table-order-items .gift-wrapping .content > img {
    float: left;
    margin: 0 10px 0 0;
    max-height: 50px;
    max-width: 50px;
}
.gift-summary .price-box,
.gift-options .price-box {
    margin-left: 22px;
}
.gift-summary .regular-price:before,
.gift-options .regular-price:before {
    content: attr(data-label) ': ';
}
.gift-summary .regular-price .price,
.gift-options .regular-price .price {
    font-weight: 700;
}
.order-options .item {
    padding-bottom: 20px;
}
.order-options .product {
    margin-bottom: 10px;
}
.order-options .product-image-container {
    float: left;
    padding-right: 10px;
}
.order-options .gift-wrapping {
    margin-bottom: 10px;
}
.table.grouped > thead > tr > th,
.table.grouped > tbody > tr > th,
.table.grouped > tfoot > tr > th,
.table.grouped > thead > tr > td,
.table.grouped > tbody > tr > td,
.table.grouped > tfoot > tr > td {
    border-top: 1px solid #d1d1d1;
}
.table.grouped > caption + thead > tr:first-child > th,
.table.grouped > colgroup + thead > tr:first-child > th,
.table.grouped > thead:first-child > tr:first-child > th,
.table.grouped > caption + thead > tr:first-child > td,
.table.grouped > colgroup + thead > tr:first-child > td,
.table.grouped > thead:first-child > tr:first-child > td {
    border-top: 0;
}
.table.grouped > tbody + tbody {
    border-top: 1px solid #d1d1d1;
}
.table.grouped > thead > tr > th {
    border-bottom: 0;
}
.table.grouped .product-item-name {
    font-weight: 400;
}
.table.grouped .price-box .price {
    font-size: 14px;
}
.table.grouped .stock.unavailable {
    font-weight: normal;
    margin: 0;
    text-transform: none;
    white-space: nowrap;
}
.table.grouped .row-tier-price td {
    border-top: 0;
}
.table.grouped .row-tier-price .prices-tier {
    margin: 0 -10px 10px;
}
.form-add-invitations .action.add {
    display: inline-block;
    text-decoration: none;
    line-height: normal;
    padding: 2px 0;
    width: auto;
}
.form-add-invitations .action.add > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.form-add-invitations .action.add:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 31px;
    line-height: 22px;
    color: inherit;
    content: '\e61c';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.form-add-invitations .action.add:hover:before {
    color: inherit;
}
.form-add-invitations .action.add:active:before {
    color: inherit;
}
.block.filter {
    margin-bottom: 40px;
}
.block.filter .filter-title {
    margin-bottom: 20px;
}
.block.filter .filter-title strong {
    font-size: 18px;
}
.block-subtitle {
    display: inline-block;
    margin-bottom: 10px;
}
.filter-current .item {
    margin-bottom: 10px;
}
.filter-current .filter-label {
    display: block;
    font-weight: 700;
}
.filter-current .filter-label:after {
    content: ':';
}
.filter-current + .filter-actions {
    margin-bottom: 30px;
}
.filter-options {
    margin: 0;
}
.filter-options .filter-options-title {
    font-weight: 700;
    line-height: 1.1;
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin: 0 0 10px;
    word-break: break-all;
}
.filter-options .filter-options-content {
    margin: 0 0 25px;
}
.filter-options .filter-options-content .item {
    margin-bottom: 3px;
}
.filter-options .count {
    color: #7d7d7d;
}
.filter-options .count:before {
    content: '(';
}
.filter-options .count:after {
    content: ')';
}
.filtered .items {
    margin: 15px 0;
}
.filtered .item {
    margin-bottom: 6px;
    padding-left: 22px;
    position: relative;
}
.filtered .item .label {
    font-weight: 700;
}
.filtered .item .action.remove {
    left: -6px;
    position: absolute;
    top: 0;
}
.filtered + .actions {
    margin-bottom: 35px;
}
.map-popup-wrapper.popup .action.close {
    top: 10px;
}
.map-popup {
    background: #ffffff;
    border: 1px solid #aeaeae;
    padding: 22px;
    width: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
    transition: opacity 0.3s linear;
    left: 0;
    margin-top: 10px;
    position: absolute;
    top: 0;
    z-index: 1001;
    display: none;
    opacity: 0;
    box-sizing: border-box;
}
.map-popup .popup-header {
    margin: 0 0 25px;
    padding-right: 22px;
}
.map-popup .popup-header .title {
    font-weight: 300;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}
.map-popup .popup-content {
    margin: 10px 0 0;
}
.map-popup .popup-actions .action.close {
    position: absolute;
    display: inline-block;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    right: 10px;
    top: 10px;
}
.map-popup .popup-actions .action.close > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.map-popup .popup-actions .action.close:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 22px;
    color: inherit;
    content: '\e616';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.map-popup .popup-actions .action.close:hover:before {
    color: inherit;
}
.map-popup .popup-actions .action.close:active:before {
    color: inherit;
}
.map-popup .popup-actions .action.close:focus,
.map-popup .popup-actions .action.close:active {
    background: none;
    border: none;
}
.map-popup .popup-actions .action.close:hover {
    background: none;
    border: none;
}
.map-popup .popup-actions .action.close.disabled,
.map-popup .popup-actions .action.close[disabled],
fieldset[disabled] .map-popup .popup-actions .action.close {
    pointer-events: none;
    opacity: 0.5;
}
.map-popup.active {
    opacity: 1;
}
.map-popup .map-info-price {
    margin-bottom: 15px;
}
.map-popup .map-info-price .price-box {
    margin: 0 0 20px;
}
.map-popup .map-info-price .price-box .label:after {
    content: ': ';
}
.map-popup .regular-price .price-label {
    display: none;
}
.map-old-price:not(:last-child),
.product-info-price .map-show-info:not(:last-child) {
    margin-right: 10px;
}
.map-fallback-price {
    display: none;
}
.map-old-price {
    text-decoration: none;
}
.map-old-price .price-wrapper {
    text-decoration: line-through;
}
.map-form-addtocart {
    display: inline-block;
    text-align: center;
}
.map-form-addtocart img {
    display: block;
    margin: 0 auto;
}
button.map-show-info,
.cart.table-wrapper .actions-toolbar > .action.map-show-info,
.action-gift.map-show-info {
    background-image: none;
    -moz-box-sizing: content-box;
    box-shadow: none;
    line-height: inherit;
    text-shadow: none;
    font-weight: 400;
    line-height: 1.42857143;
    margin: 0;
    padding: 0;
    color: #1979c3;
    text-decoration: none;
    background: none;
    border: 0;
    display: inline;
}
button.map-show-info:focus,
button.map-show-info:active,
.cart.table-wrapper .actions-toolbar > .action.map-show-info:focus,
.cart.table-wrapper .actions-toolbar > .action.map-show-info:active,
.action-gift.map-show-info:focus,
.action-gift.map-show-info:active {
    background: none;
    border: none;
}
button.map-show-info:hover,
.cart.table-wrapper .actions-toolbar > .action.map-show-info:hover,
.action-gift.map-show-info:hover {
    background: none;
    border: none;
}
button.map-show-info.disabled,
button.map-show-info[disabled],
fieldset[disabled] button.map-show-info,
.cart.table-wrapper .actions-toolbar > .action.map-show-info.disabled,
.cart.table-wrapper .actions-toolbar > .action.map-show-info[disabled],
fieldset[disabled] .cart.table-wrapper .actions-toolbar > .action.map-show-info,
.action-gift.map-show-info.disabled,
.action-gift.map-show-info[disabled],
fieldset[disabled] .action-gift.map-show-info {
    pointer-events: none;
    opacity: 0.5;
}
button.map-show-info:visited,
.cart.table-wrapper .actions-toolbar > .action.map-show-info:visited,
.action-gift.map-show-info:visited {
    color: #1979c3;
    text-decoration: none;
}
button.map-show-info:hover,
.cart.table-wrapper .actions-toolbar > .action.map-show-info:hover,
.action-gift.map-show-info:hover {
    color: #006bb4;
    text-decoration: underline;
}
button.map-show-info:active,
.cart.table-wrapper .actions-toolbar > .action.map-show-info:active,
.action-gift.map-show-info:active {
    color: $primary;
    text-decoration: underline;
}
button.map-show-info:hover,
button.map-show-info:active,
button.map-show-info:focus,
.cart.table-wrapper .actions-toolbar > .action.map-show-info:hover,
.cart.table-wrapper .actions-toolbar > .action.map-show-info:active,
.cart.table-wrapper .actions-toolbar > .action.map-show-info:focus,
.action-gift.map-show-info:hover,
.action-gift.map-show-info:active,
.action-gift.map-show-info:focus {
    background: none;
    border: 0;
}
button.map-show-info.disabled,
button.map-show-info[disabled],
fieldset[disabled] button.map-show-info,
.cart.table-wrapper .actions-toolbar > .action.map-show-info.disabled,
.cart.table-wrapper .actions-toolbar > .action.map-show-info[disabled],
fieldset[disabled] .cart.table-wrapper .actions-toolbar > .action.map-show-info,
.action-gift.map-show-info.disabled,
.action-gift.map-show-info[disabled],
fieldset[disabled] .action-gift.map-show-info {
    color: #1979c3;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline;
}
.table-comparison .wishlist.split.button > .action.split:before,
.product-items .wishlist.split.button > .action.split:before {
    content: '\e601';
    font-family: 'icons-blank-theme';
}
.table-comparison .wishlist.split.button > .action:active,
.product-items .wishlist.split.button > .action:active,
.table-comparison .wishlist.split.button > .action:focus,
.product-items .wishlist.split.button > .action:focus,
.table-comparison .wishlist.split.button > .action:hover,
.product-items .wishlist.split.button > .action:hover {
    color: $black;
}
.table-comparison .wishlist.split.button > .action.toggle:before,
.product-items .wishlist.split.button > .action.toggle:before {
    border-left: 1px solid #c9c9c9;
    content: '';
    float: left;
    height: 15px;
    margin-top: 3px;
}
.product-info-main .wishlist.split.button .action.split,
.block-bundle-summary .wishlist.split.button .action.split,
.product-add-form .wishlist.split.button .action.split,
.product-info-main .wishlist.split.button .action.toggle,
.block-bundle-summary .wishlist.split.button .action.toggle,
.product-add-form .wishlist.split.button .action.toggle {
    line-height: 1.2rem;
    padding: 5px 8px;
    font-size: 1.1rem;
}
.product-info-main .wishlist.split.button .action.toggle,
.block-bundle-summary .wishlist.split.button .action.toggle,
.product-add-form .wishlist.split.button .action.toggle {
    padding: 0;
}
.cart.table-wrapper .wishlist.split.button {
    margin-bottom: 10px;
}
.cart.table-wrapper .wishlist.split.button > .action.toggle {
    padding: 4px 5px;
}
[class^='multishipping-'] .logo {
    margin-left: 0;
}
.multishipping-checkout-success .nav-sections {
    display: block;
}

.fotorama-video-container:after {
    background: url(../../frontend/Magento/blank/fr_FR/Magento_ProductVideo/img/gallery-sprite.png) bottom right;
    bottom: 0;
    content: '';
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 12px;
    width: 100px;
}
.fotorama-video-container .magnify-lens {
    display: none !important;
}
.fotorama-video-container.video-unplayed:hover img {
    opacity: 0.6;
}
.fotorama-video-container.video-unplayed:hover:after {
    transform: scale(1.25);
}
.video-thumb-icon:after {
    background: url(../../frontend/Magento/blank/fr_FR/Magento_ProductVideo/img/gallery-sprite.png) bottom left;
    bottom: 0;
    content: '';
    height: 40px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 10px;
    width: 49px;
}
.product-video {
    bottom: 0;
    height: 75%;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}
.product-video iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9999;
}
.fotorama__stage__shaft:focus .fotorama__stage__frame.fotorama__active:after {
    bottom: 0;
    content: '';
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 12px;
    width: 100px;
}
.fotorama__product-video--loading:after {
    visibility: hidden;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 780px) and (orientation: landscape) {
    .product-video {
        height: 100%;
        width: 81%;
    }
}
.data.switch .counter {
    color: #7d7d7d;
}
.data.switch .counter:before {
    content: '(';
}
.data.switch .counter:after {
    content: ')';
}
.block-reward-info .reward-rates:not(:last-child),
.block-reward-info .reward-limit:not(:last-child),
.block-reward-info .reward-expiring:not(:last-child) {
    margin-bottom: 25px;
}
.block-reward-info .reward-rates .title,
.block-reward-info .reward-limit .title,
.block-reward-info .reward-expiring .title {
    display: inline-block;
    margin-bottom: 10px;
}
.form-create-return .field .control > span,
.form-create-return .field address {
    display: inline-block;
    padding-top: 6px;
}
.form-create-return .additional:first-child {
    margin-top: 0;
}
.form-create-return .additional .field:nth-last-child(2) {
    margin-bottom: 0;
}
.form-create-return .additional .field:last-child {
    margin-top: 20px;
}
.form-create-return .field.comment {
    margin-top: 55px;
}
.block-returns-comments .returns-comments dt,
.block-returns-comments .returns-comments dd {
    margin: 0;
}
.block-returns-comments .returns-comments dt {
    font-weight: 400;
}
.block-returns-comments .returns-comments dd {
    margin-bottom: 15px;
}
.block-returns-comments .returns-comments dd:last-child {
    margin-bottom: 0;
}
.block-returns-comments .fieldset {
    margin-bottom: 20px;
}
.magento-rma-returns-returns .page-title-wrapper .page-title,
.magento-rma-guest-returns .page-title-wrapper .page-title,
.magento-rma-returns-view .page-title-wrapper .page-title {
    margin-right: 25px;
}
.order-links {
    border-bottom: 1px solid #d1d1d1;
    margin-bottom: 10px;
}
.order-links .item {
    display: inline-block;
    margin-right: 20px;
}
.order-actions-toolbar .action {
    margin: 0 20px 0 0;
}
.order-details-items {
    border-bottom: 1px solid #d1d1d1;
    margin-bottom: 20px;
    padding-bottom: 10px;
}
.order-details-items .order-title > strong {
    font-weight: 300;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    display: inline-block;
}
.order-details-items .items-qty .item {
    white-space: nowrap;
}
.order-details-items .items-qty .title:after {
    content: ': ';
}
.order-details-items .table-order-items .product-item-name {
    margin-top: 0;
}
.order-details-items .table-order-items tbody + tbody {
    border-top: 1px solid #d1d1d1;
}
.order-details-items .item-options dt {
    margin: 0;
}
.order-details-items .item-options dd {
    margin: 0 0 15px;
}
.order-details-items .item-options.links dt {
    display: inline-block;
}
.order-details-items .item-options.links dt:after {
    content: ': ';
}
.order-details-items .item-options.links dd {
    margin: 0;
}
.order-pager-wrapper .toolbar-amount {
    padding-top: 3px;
}
.order-pager-wrapper .pages-items .item:first-child,
.order-pager-wrapper .pages-items .item:last-child {
    margin-right: 0;
}
.order-actions-toolbar {
    margin-bottom: 25px;
}
.order-actions-toolbar .action {
    margin-right: 30px;
}
.order-actions-toolbar .action.print {
    margin: 0;
}
.page-title-wrapper .order-date {
    margin: 0 0 20px;
}
.account .page-title-wrapper .page-title,
[class^='sales-guest-'] .page-title-wrapper .page-title,
.sales-guest-view .page-title-wrapper .page-title {
    margin-right: 25px;
}
.form-orders-search .field.email,
.form-orders-search .field.zip {
    margin-bottom: 0;
}
.block-reorder .product-item .checkbox {
    position: relative;
    top: 2px;
    vertical-align: baseline;
}
.block-reorder .product-item .field.item {
    display: inline-block;
}
.block-reorder .product-item-name {
    display: inline-block;
}
.block-reorder .actions-toolbar {
    margin: 17px 0;
    max-width: 178px;
}
.block-order-details-comments {
    margin: 0 0 40px;
}
.block-order-details-comments .comment-date {
    font-weight: 600;
}
.block-order-details-comments .comment-content {
    line-height: 1.6;
    margin: 0 0 20px;
}
.block-order-details-view .box-content .payment-method .title {
    font-weight: 400;
}
.block-order-details-view .box-content .payment-method .content {
    margin: 0;
}
.block-order-details-view .box-content .payment-method .content > strong {
    font-weight: 400;
}
.block-order-details-view .box-content .payment-method .content > strong:after {
    content: ': ';
}
.order-tracking {
    border-bottom: 1px solid #d1d1d1;
    margin: 0;
    padding: 20px 0;
}
.order-tracking .tracking-title {
    display: inline-block;
}
.order-tracking .tracking-content {
    display: inline-block;
    margin: 0 0 0 5px;
}
.opc-wrapper .form-discount {
    max-width: 500px;
}
.action.mailto.friend {
    margin-left: -7px;
    display: inline-block;
    text-decoration: none;
}
.action.mailto.friend:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: inherit;
    content: '\e61d';
    font-family: 'icons-blank-theme';
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.action.mailto.friend span {
    display: inline-block;
    line-height: 28px;
    vertical-align: top;
}
.swatch-attribute-label {
    font-weight: 700;
    position: relative;
}
.swatch-attribute-label.required {
    padding-right: 10px;
}
.swatch-attribute-label[data-required='1']:after {
    color: #e02b27;
    content: '*';
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    right: -11px;
    top: -2px;
}
.swatch-attribute-selected-option {
    color: #646464;
    padding-left: 17px;
}
.swatch-attribute-options {
    margin: 10px 0;
}
.swatch-attribute-options:focus {
    box-shadow: none;
}
.swatch-attribute-options .swatch-option-tooltip-layered .title {
    color: #282828;
    bottom: -5px;
    height: 20px;
    left: 0;
    margin-bottom: 10px;
    position: absolute;
    text-align: center;
    width: 100%;
}
.swatch-attribute.size .swatch-option,
.swatch-attribute.manufacturer .swatch-option {
    background: #f0f0f0;
    color: #949494;
}
.swatch-attribute.size .swatch-option.selected,
.swatch-attribute.manufacturer .swatch-option.selected {
    background: #ffffff;
    border: 1px solid #ffffff;
    color: #000000;
}
.swatch-option {
    border: 1px solid #dadada;
    cursor: pointer;
    float: left;
    height: 20px;
    margin: 0 10px 5px 0;
    max-width: 100%;
    min-width: 30px;
    overflow: hidden;
    padding: 1px 2px;
    position: relative;
    text-align: center;
    text-overflow: ellipsis;
}
.swatch-option:focus {
    box-shadow: 0 0 3px 1px $primary;
}
.swatch-option.text {
    background: #f0f0f0;
    color: #686868;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    margin-right: 7px;
    min-width: 22px;
    padding: 4px 8px;
}
.swatch-option.text.selected {
    background-color: #ffffff;
}
.swatch-option.selected {
    outline: 2px solid $primary;
    border: 1px solid #ffffff;
    color: $black;
}
.swatch-option:not(.disabled):hover {
    border: 1px solid #ffffff;
    color: $black;
    outline: 1px solid #999999;
}
.swatch-option.image:not(.disabled):hover,
.swatch-option.color:not(.disabled):hover {
    border: 1px solid #ffffff;
    outline: 2px solid #c34000;
}
.swatch-option.disabled {
    box-shadow: unset;
    cursor: default;
    pointer-events: none;
}
.swatch-option.disabled:after {
    background: linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, #ffffff 43%, #ffffff 46%, #ff5216 47%, #ff5216 53%, #ffffff 54%, #ffffff 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%);
    background: -moz-linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, #ffffff 43%, #ffffff 46%, #ff5216 47%, #ff5216 53%, #ffffff 54%, #ffffff 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%);
    background: -ms-linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, #ffffff 43%, #ffffff 46%, #ff5216 47%, #ff5216 53%, #ffffff 54%, #ffffff 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%);
    background: -o-linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, #ffffff 43%, #ffffff 46%, #ff5216 47%, #ff5216 53%, #ffffff 54%, #ffffff 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%);
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(42%, rgba(255, 255, 255, 0)), color-stop(43%, #ffffff), color-stop(46%, #ffffff), color-stop(47%, #ff5216), color-stop(53%, #ff5216), color-stop(54%, #ffffff), color-stop(57%, #ffffff), color-stop(58%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 0)));
    background: -webkit-linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, #ffffff 43%, #ffffff 46%, #ff5216 47%, #ff5216 53%, #ffffff 54%, #ffffff 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%);
    bottom: 0;
    content: '';
    filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr=#00ffffff, endColorstr=#00ffffff, GradientType=1)';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
.swatch-option-disabled {
    border: 0;
    cursor: default;
    outline: none !important;
}
.swatch-option-disabled:after {
    -webkit-transform: rotate(-30deg);
    -ms-transform: rotate(-30deg);
    transform: rotate(-30deg);
    background: #e02b27;
    content: '';
    height: 2px;
    left: -4px;
    position: absolute;
    top: 10px;
    width: 42px;
    z-index: 995;
}
.swatch-option-loading {
    content: url('../../frontend/Magento/blank/fr_FR/images/loader-2.gif');
}
.swatch-option-tooltip {
    border: 1px solid #dadada;
    color: #949494;
    background: #ffffff;
    display: none;
    max-height: 100%;
    min-height: 20px;
    min-width: 20px;
    padding: 5px;
    position: absolute;
    text-align: center;
    z-index: 999;
}
.swatch-option-tooltip .corner,
.swatch-option-tooltip-layered .corner {
    bottom: 0;
    height: 8px;
    left: 40%;
    position: absolute;
}
.swatch-option-tooltip .corner:before,
.swatch-option-tooltip-layered .corner:before,
.swatch-option-tooltip .corner:after,
.swatch-option-tooltip-layered .corner:after {
    border-style: solid;
    content: '';
    font-size: 1px;
    height: 0;
    position: relative;
    width: 0;
}
.swatch-option-tooltip .corner:before,
.swatch-option-tooltip-layered .corner:before {
    border-color: #adadad transparent transparent transparent;
    border-width: 8px 8.5px 0 8.5px;
    left: 0;
    top: 2px;
}
.swatch-option-tooltip .corner:after,
.swatch-option-tooltip-layered .corner:after {
    border-color: #ffffff transparent transparent transparent;
    border-width: 7px 7.5px 0 7.5px;
    left: -15px;
    top: 1px;
}
.swatch-option-tooltip .image,
.swatch-option-tooltip-layered .image {
    display: block;
    height: 130px;
    margin: 0 auto;
    width: 130px;
}
.swatch-option-tooltip-layered {
    background: #ffffff;
    border: 1px solid #dadada;
    color: #949494;
    display: none;
    left: -47px;
    position: absolute;
    width: 140px;
    z-index: 999;
}
.swatch-option-tooltip .title {
    color: #282828;
    display: block;
    max-height: 200px;
    min-height: 20px;
    overflow: hidden;
    text-align: center;
}
.swatch-option-link-layered {
    margin: 0 !important;
    padding: 0 !important;
    position: relative;
}
.swatch-option-link-layered:focus > div {
    box-shadow: 0 0 3px 1px $primary;
}
.swatch-option-link-layered:hover > .swatch-option-tooltip-layered {
    display: block;
}
.swatch-opt {
    margin: 20px 0;
}
.swatch-opt-listing {
    margin-bottom: 10px;
}
.swatch-more {
    display: inline-block;
    margin: 2px 0;
    padding: 2px;
    position: static;
}
.swatch-visual-tooltip-layered {
    height: 160px;
    top: -170px;
}
.swatch-textual-tooltip-layered {
    height: 30px;
    top: -40px;
}
.swatch-input {
    left: -1000px;
    position: absolute;
    visibility: hidden;
}
.clearfix:after {
    clear: both;
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
}
.block-collapsible-nav .content {
    background: #f5f5f5;
    padding: 15px 0;
}
.block-collapsible-nav .item {
    margin: 3px 0 0;
}
.block-collapsible-nav .item:first-child {
    margin-top: 0;
}
.block-collapsible-nav .item a,
.block-collapsible-nav .item > strong {
    border-left: 3px solid transparent;
    color: #575757;
    display: block;
    padding: 5px 18px 5px 15px;
}
.block-collapsible-nav .item a {
    text-decoration: none;
}
.block-collapsible-nav .item a:hover {
    background: #e8e8e8;
}
.block-collapsible-nav .item.current a,
.block-collapsible-nav .item.current > strong {
    border-color: $primary;
    color: #000000;
    font-weight: 600;
}
.block-collapsible-nav .item.current a {
    border-color: $primary;
}
.block-collapsible-nav .item .delimiter {
    border-top: 1px solid #d1d1d1;
    display: block;
    margin: 10px 1.8rem;
}
body {
    background-color: #ffffff;
}
.page-wrapper {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
}
.page-main {
    -webkit-flex-grow: 1;
    flex-grow: 1;
}
.page-header {
    border-bottom: 1px solid #d1d1d1;
    margin-bottom: 20px;
}
.logo {
    float: left;
    max-width: 50%;
    position: relative;
    z-index: 5;
}
.logo img {
    display: block;
    height: auto;
}
.page-print .logo {
    float: none;
}
.page-main > .page-title-wrapper .page-title + .action {
    margin-top: 30px;
}
.action.skip:focus {
    background: #f0f0f0;
    padding: 10px;
    box-sizing: border-box;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 15;
}
.action-skip-wrapper {
    height: 0;
    position: relative;
}
.message.global p {
    margin: 0;
}
.message.global.noscript,
.message.global.cookie {
    margin: 0 0 10px;
    padding: 10px 20px;
    display: block;
    line-height: 1.2em;
    font-size: 1.3rem;
    background: #ffee9c;
    border-color: #d6ca8e;
    color: $black;
    margin: 0;
}
.message.global.noscript a,
.message.global.cookie a {
    color: #1979c3;
}
.message.global.noscript a:hover,
.message.global.cookie a:hover {
    color: #006bb4;
}
.message.global.noscript a:active,
.message.global.cookie a:active {
    color: $primary;
}
.message.global.cookie {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 3;
}
.message.global.cookie .actions {
    margin-top: 10px;
}
.message.global.demo {
    margin: 0 0 10px;
    padding: 10px 20px;
    display: block;
    line-height: 1.2em;
    font-size: 1.3rem;
    background: #ff0101;
    border-color: none;
    color: #ffffff;
    margin-bottom: 0;
    text-align: center;
}
.message.global.demo a {
    color: #1979c3;
}
.message.global.demo a:hover {
    color: #006bb4;
}
.message.global.demo a:active {
    color: $primary;
}
.page-footer {
    margin-top: auto;
}
.footer.content {
    margin-top: 25px;
    padding: 0;

    .links > li {
    margin: 0 0 8px;
    }
    .switcher-store {
    margin: 0 0 30px;
    }
}

.footer {
    background: $dark-grey;
    color: $white;
    .copyright {
        width: 100%;
        display: block;
        text-align: center;
        font-size: 0.8rem;
        padding: 1.5rem 0;
        a {
            font-size: unset;
            @include link-underline($white, 0, 1rem, 1px);
        }

    }
    .bugs {
        display: none;
        margin: 20px 0 0;
    }
}
.page-header .panel.wrapper .switcher,
.page-footer .switcher {
    margin-right: 10px;
}
.page-header .panel.wrapper .switcher .options,
.page-footer .switcher .options {
    display: inline-block;
    position: relative;
}
.page-header .panel.wrapper .switcher .options:before,
.page-footer .switcher .options:before,
.page-header .panel.wrapper .switcher .options:after,
.page-footer .switcher .options:after {
    content: '';
    display: table;
}
.page-header .panel.wrapper .switcher .options:after,
.page-footer .switcher .options:after {
    clear: both;
}
.page-header .panel.wrapper .switcher .options .action.toggle,
.page-footer .switcher .options .action.toggle {
    padding: 0;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
}
.page-header .panel.wrapper .switcher .options .action.toggle > span,
.page-footer .switcher .options .action.toggle > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.page-header .panel.wrapper .switcher .options .action.toggle:after,
.page-footer .switcher .options .action.toggle:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 22px;
    color: inherit;
    content: '\e622';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.page-header .panel.wrapper .switcher .options .action.toggle:hover:after,
.page-footer .switcher .options .action.toggle:hover:after {
    color: inherit;
}
.page-header .panel.wrapper .switcher .options .action.toggle:active:after,
.page-footer .switcher .options .action.toggle:active:after {
    color: inherit;
}
.page-header .panel.wrapper .switcher .options .action.toggle.active,
.page-footer .switcher .options .action.toggle.active {
    display: inline-block;
    text-decoration: none;
}
.page-header .panel.wrapper .switcher .options .action.toggle.active > span,
.page-footer .switcher .options .action.toggle.active > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.page-header .panel.wrapper .switcher .options .action.toggle.active:after,
.page-footer .switcher .options .action.toggle.active:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 22px;
    color: inherit;
    content: '\e621';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.page-header .panel.wrapper .switcher .options .action.toggle.active:hover:after,
.page-footer .switcher .options .action.toggle.active:hover:after {
    color: inherit;
}
.page-header .panel.wrapper .switcher .options .action.toggle.active:active:after,
.page-footer .switcher .options .action.toggle.active:active:after {
    color: inherit;
}
.page-header .panel.wrapper .switcher .options ul.dropdown,
.page-footer .switcher .options ul.dropdown {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #ffffff;
    border: 1px solid #bbbbbb;
    margin-top: 4px;
    min-width: 160px;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
}
.page-header .panel.wrapper .switcher .options ul.dropdown li,
.page-footer .switcher .options ul.dropdown li {
    margin: 0;
    padding: 0;
}
.page-header .panel.wrapper .switcher .options ul.dropdown li:hover,
.page-footer .switcher .options ul.dropdown li:hover {
    background: #e8e8e8;
    cursor: pointer;
}
.page-header .panel.wrapper .switcher .options ul.dropdown:before,
.page-footer .switcher .options ul.dropdown:before,
.page-header .panel.wrapper .switcher .options ul.dropdown:after,
.page-footer .switcher .options ul.dropdown:after {
    border-bottom-style: solid;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0;
}
.page-header .panel.wrapper .switcher .options ul.dropdown:before,
.page-footer .switcher .options ul.dropdown:before {
    border: 6px solid;
    border-color: transparent transparent #ffffff transparent;
    z-index: 99;
}
.page-header .panel.wrapper .switcher .options ul.dropdown:after,
.page-footer .switcher .options ul.dropdown:after {
    border: 7px solid;
    border-color: transparent transparent #bbbbbb transparent;
    z-index: 98;
}
.page-header .panel.wrapper .switcher .options ul.dropdown:before,
.page-footer .switcher .options ul.dropdown:before {
    left: 10px;
    top: -12px;
}
.page-header .panel.wrapper .switcher .options ul.dropdown:after,
.page-footer .switcher .options ul.dropdown:after {
    left: 9px;
    top: -14px;
}
.page-header .panel.wrapper .switcher .options.active,
.page-footer .switcher .options.active {
    overflow: visible;
}
.page-header .panel.wrapper .switcher .options.active ul.dropdown,
.page-footer .switcher .options.active ul.dropdown {
    display: block;
}
.page-header .panel.wrapper .switcher .options ul.dropdown a,
.page-footer .switcher .options ul.dropdown a {
    display: block;
    padding: 8px;
}
.page-header .panel.wrapper .switcher .options ul.dropdown a:hover,
.page-footer .switcher .options ul.dropdown a:hover {
    text-decoration: none;
}
.page-header .panel.wrapper .switcher li,
.page-footer .switcher li {
    margin: 0;
}
.page-header .panel.wrapper .switcher strong,
.page-footer .switcher strong {
    font-weight: 400;
}
.widget {
    clear: both;
}
.page-header .widget.block,
.page-footer .widget.block {
    margin: 20px 0;
}
.ui-datepicker td {
    padding: 0;
}
.minilist .weee {
    display: table-row;
    font-size: 1.1rem;
}
.minilist .weee:before {
    display: none;
}
.price-container.weee:before {
    display: none;
}
.toolbar.wishlist-toolbar .limiter {
    float: right;
}
.toolbar.wishlist-toolbar .main .pages {
    display: inline-block;
    position: relative;
    z-index: 0;
}
.toolbar.wishlist-toolbar .toolbar-amount,
.toolbar.wishlist-toolbar .limiter {
    display: inline-block;
    z-index: 1;
}
.product-items .action.towishlist:before,
.products.list.items .action.towishlist:before,
.table-comparison .action.towishlist:before {
    content: '\e601';
    font-family: 'icons-blank-theme';
}
.account .table-wrapper .data.table.wishlist > thead > tr > th,
.account .table-wrapper .data.table.wishlist > tbody > tr > th,
.account .table-wrapper .data.table.wishlist > tfoot > tr > th,
.account .table-wrapper .data.table.wishlist > thead > tr > td,
.account .table-wrapper .data.table.wishlist > tbody > tr > td,
.account .table-wrapper .data.table.wishlist > tfoot > tr > td {
    border-top: 1px solid #d1d1d1;
}
.account .table-wrapper .data.table.wishlist > caption + thead > tr:first-child > th,
.account .table-wrapper .data.table.wishlist > colgroup + thead > tr:first-child > th,
.account .table-wrapper .data.table.wishlist > thead:first-child > tr:first-child > th,
.account .table-wrapper .data.table.wishlist > caption + thead > tr:first-child > td,
.account .table-wrapper .data.table.wishlist > colgroup + thead > tr:first-child > td,
.account .table-wrapper .data.table.wishlist > thead:first-child > tr:first-child > td {
    border-top: 0;
}
.account .table-wrapper .data.table.wishlist > tbody + tbody {
    border-top: 1px solid #d1d1d1;
}
.account .table-wrapper .data.table.wishlist thead > tr > th {
    border-bottom: 0;
}
.account .table-wrapper .data.table.wishlist tbody > tr:last-child > td {
    border-bottom: 1px solid #d1d1d1;
}
.account .table-wrapper .data.table.wishlist .product.name {
    display: inline-block;
    margin-bottom: 10px;
}
.account .table-wrapper .data.table.wishlist .box-tocart {
    margin: 10px 0;
}
.account .table-wrapper .data.table.wishlist .box-tocart .qty {
    vertical-align: middle;
}
.account .table-wrapper .data.table.wishlist .col.item {
    width: 50%;
}
.account .table-wrapper .data.table.wishlist .col.photo {
    max-width: 150px;
}
.account .table-wrapper .data.table.wishlist .col.selector {
    max-width: 15px;
}
.account .table-wrapper .data.table.wishlist textarea {
    margin: 10px 0;
}
.account .table-wrapper .data.table.wishlist .input-text.qty {
    margin-bottom: 10px;
}
.account .table-wrapper .data.table.wishlist .action.primary {
    vertical-align: top;
}
.account .table-wrapper .data.table.wishlist .price {
    font-weight: 700;
}
.block-wishlist .product-item-name {
    margin-right: 25px;
}
.products-grid.wishlist .product-item {
    margin-left: 2%;
    width: calc((100% - 4%) / 3);
}
.products-grid.wishlist .product-item:nth-child(3n + 1) {
    margin-left: 0;
}
.products-grid.wishlist .product-item-photo {
    display: block;
    margin-bottom: 10px;
}
.products-grid.wishlist .product-item-name {
    margin-top: 0;
}
.products-grid.wishlist .product-item .price-box {
    margin: 0;
}
.products-grid.wishlist .product-item-comment {
    display: block;
    height: 42px;
    margin: 10px 0;
}
.products-grid.wishlist .product-item-actions > * {
    margin-right: 15px;
}
.products-grid.wishlist .product-item-actions > *:last-child {
    margin-right: 0;
}
.products-grid.wishlist .product-item .box-tocart input.qty {
    height: 32px;
}
.sidebar .block-addbysku .fieldset {
    margin: 0;
}
.sidebar .block-addbysku .fieldset .fields {
    position: relative;
}
.sidebar .block-addbysku .fieldset .fields .field {
    display: inline-block;
    margin-bottom: 10px;
    vertical-align: top;
}
.sidebar .block-addbysku .fieldset .fields .field.sku {
    margin-right: -85px;
    padding-right: 90px;
    width: 100%;
}
.sidebar .block-addbysku .fieldset .fields .field.qty .qty + .mage-error {
    width: 80px;
}
.sidebar .block-addbysku .fieldset .fields .actions-toolbar {
    position: absolute;
    right: 0;
    top: 6px;
}
.sidebar .block-addbysku .form-addbysku .actions-toolbar {
    margin-bottom: 20px;
}
.sidebar .block-addbysku .form-addbysku .actions-toolbar .primary {
    text-align: left;
}
.sidebar .block-addbysku .form-addbysku .actions-toolbar .primary .action {
    width: auto;
}
.sidebar .block-addbysku .form-addbysku .actions-toolbar .secondary {
    float: none;
    text-align: left;
}
.sidebar .block-addbysku .action.add {
    display: inline-block;
    text-decoration: none;
    line-height: normal;
    padding: 2px 0;
    width: auto;
}
.sidebar .block-addbysku .action.add > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.sidebar .block-addbysku .action.add:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 31px;
    line-height: 22px;
    color: inherit;
    content: '\e61c';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.sidebar .block-addbysku .action.add:hover:before {
    color: inherit;
}
.sidebar .block-addbysku .action.add:active:before {
    color: inherit;
}
.sidebar .block-addbysku .action.links {
    display: inline-block;
    margin: 20px 0 0;
}
.sidebar .block-addbysku .action.reset {
    display: block;
    margin: 10px 0;
}
.sidebar .block-addbysku .action.remove {
    display: inline-block;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
}
.sidebar .block-addbysku .action.remove > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.sidebar .block-addbysku .action.remove:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 22px;
    color: inherit;
    content: '\e616';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.sidebar .block-addbysku .action.remove:hover:before {
    color: inherit;
}
.sidebar .block-addbysku .action.remove:active:before {
    color: inherit;
}
.sidebar .block-addbysku .action.remove:focus,
.sidebar .block-addbysku .action.remove:active {
    background: none;
    border: none;
}
.sidebar .block-addbysku .action.remove:hover {
    background: none;
    border: none;
}
.sidebar .block-addbysku .action.remove.disabled,
.sidebar .block-addbysku .action.remove[disabled],
fieldset[disabled] .sidebar .block-addbysku .action.remove {
    pointer-events: none;
    opacity: 0.5;
}
.block-banners .banner-item,
.block-banners-inline .banner-item {
    display: block;
}
.block-banners .banner-item-content,
.block-banners-inline .banner-item-content {
    margin-bottom: 20px;
}
.block-banners .banner-item-content img,
.block-banners-inline .banner-item-content img {
    display: block;
    margin: 0 auto;
}
.block-product-link.widget,
.block-category-link.widget {
    display: block;
    margin-bottom: 20px;
}
.block-product-link-inline.widget {
    margin: 0;
}
.block.widget .products-grid .product-item {
    margin-left: 2%;
    width: calc((100% - 2%) / 2);
}
.block.widget .products-grid .product-item:nth-child(2n + 1) {
    margin-left: 0;
}
.block.widget .product-item-info {
    width: auto;
}
.block.widget .pager {
    padding: 0;
}
.block.widget .pager .toolbar-amount {
    float: none;
    font-size: 1.2rem;
}
.block.widget .pager .pages-item-previous {
    padding-left: 0;
}
.block.widget .pager .pages-item-next {
    position: relative;
}
.block.widget .pager .items {
    white-space: nowrap;
}
.columns .block-event {
    position: relative;
    z-index: 2;
}
.block-event .block-content {
    position: relative;
}
.block-event .block-content > .action {
    cursor: pointer;
    margin-top: -6px;
    position: absolute;
    top: 50%;
    z-index: 3;
}
.block-event .block-content > .action.backward,
.block-event .block-content > .action.forward {
    opacity: 0.5;
    overflow: hidden;
    width: 20px;
    display: inline-block;
    text-decoration: none;
}
.block-event .block-content > .action.backward > span,
.block-event .block-content > .action.forward > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.block-event .block-content > .action.backward:before,
.block-event .block-content > .action.forward:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 55px;
    line-height: 30px;
    color: inherit;
    content: '\e617';
    font-family: 'icons-blank-theme';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.block-event .block-content > .action.backward:before,
.block-event .block-content > .action.forward:before {
    margin-left: -15px;
}
.block-event .block-content > .action.backward {
    left: 0;
}
.block-event .block-content > .action.forward {
    right: 0;
}
.block-event .block-content > .action.forward:before {
    content: '\e608';
    font-family: 'icons-blank-theme';
}
.block-event .block-content > .action.forward:before {
    margin-left: -20px;
}
.block-event .block-content > .action.inactive {
    cursor: default;
    display: none;
}
.block-event .block-content > .action:hover {
    opacity: 1;
}
.block-event .slider-panel {
    overflow: hidden;
    position: relative;
    z-index: 2;
}
.block-event .slider-panel .slider {
    white-space: nowrap;
}
.block-event .slider-panel .slider .item {
    background: #f0f0f0;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    white-space: normal;
    width: 16.35%;
}
.sidebar-main .block-event .slider-panel .slider .item,
.sidebar-additional .block-event .slider-panel .slider .item {
    width: 100%;
}
.block-event .slider-panel .slider .item img {
    max-width: 100%;
}
.block-event .slider-panel .slider .item .category-name {
    display: inline-block;
    font-size: 1.8rem;
    padding: 15px 5px 0;
    word-break: break-all;
}
.block-event .slider-panel .slider .item .ticker {
    margin: 0;
    padding: 0;
    list-style: none none;
}
.block-event .slider-panel .slider .item .ticker li {
    display: none;
    margin: 0 5px;
}
.block-event .slider-panel .slider .item .ticker .value {
    font-size: 2.6rem;
    font-weight: 300;
}
.block-event .slider-panel .slider .item .ticker .label {
    display: block;
    font-size: 1.3rem;
}
.block-event .slider-panel .slider .item .dates {
    font-weight: 300;
}
.block-event .slider-panel .slider .item .dates .start,
.block-event .slider-panel .slider .item .dates .end,
.block-event .slider-panel .slider .item .dates .date {
    display: block;
}
.block-event .slider-panel .slider .item .dates .start:after {
    font-size: 1.8rem;
    content: '\2013';
    display: block;
}
.block-event .slider-panel .slider .item .dates .date {
    font-size: 1.8rem;
    font-weight: 300;
}
.block-event .slider-panel .slider .item .dates .time {
    font-size: 1.3rem;
}
.block-event .slider-panel .slider .item .box-event .box-content {
    padding: 5px 0 10px;
}
.block-event .slider-panel .slider .item .box-event .box-title {
    display: block;
    font-weight: 300;
    margin: 10px 0 0;
}
.block-event .slider-panel .slider .item .action.show {
    display: block;
    padding: 0;
}
.block-event .slider-panel .slider .item .action.show .category.name {
    display: inline-block;
    padding: 30px 10px 0;
}
.block-event .slider-panel .slider .item .action.show img {
    display: block;
}
.block-event .slider-panel .slider .item .action.event {
    background-image: none;
    background: #1979c3;
    border: 1px solid #1979c3;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 700;
    padding: 7px 15px;
    font-size: 1.4rem;
    box-sizing: border-box;
    vertical-align: middle;
    margin-top: 15px;
}
.block-event .slider-panel .slider .item .action.event:focus,
.block-event .slider-panel .slider .item .action.event:active {
    background: #006bb4;
    border: 1px solid #006bb4;
    color: #ffffff;
}
.block-event .slider-panel .slider .item .action.event:hover {
    background: #006bb4;
    border: 1px solid #006bb4;
    color: #ffffff;
}
.block-event .slider-panel .slider .item .action.event.disabled,
.block-event .slider-panel .slider .item .action.event[disabled],
fieldset[disabled] .block-event .slider-panel .slider .item .action.event {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
}
.block-event .slider-panel .slider .item .action.event:hover {
    text-decoration: none;
}
.block-static-block.widget,
.block-cms-link.widget {
    margin-bottom: 20px;
}
.links .block-static-block.widget,
.links .block-cms-link.widget {
    margin-bottom: 0;
}
.block-cms-link-inline.widget {
    margin: 0;
}
.block-wishlist-search .form-wishlist-search {
    margin: 20px 0 0;
}
.block-cms-hierarchy-link.widget {
    display: block;
    margin-bottom: 20px;
}
.block-cms-hierarchy-link-inline.widget {
    margin: 0;
}
@media only screen and (max-width: 768px) {
    .field-tooltip .field-tooltip-content {
        right: -10px;
        top: 40px;
        left: auto;
    }
}
@media only screen and (max-width: 768px) {
    .abs-button-responsive-smaller,
    .product-info-main .box-tocart .actions .action.tocart {
        width: 100%;
    }
    .abs-visually-hidden-mobile-m {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    .abs-add-clearfix-mobile-m:before,
    .abs-add-clearfix-mobile-m:after,
    .form-create-return .fieldset > .actions-toolbar:before,
    .form-create-return .fieldset > .actions-toolbar:after {
        content: '';
        display: table;
    }
    .abs-add-clearfix-mobile-m:after,
    .form-create-return .fieldset > .actions-toolbar:after {
        clear: both;
    }
    .abs-icon-add-mobile,
    .block-wishlist-management .wishlist-add.item .add {
        display: block;
        text-decoration: none;
    }
    .abs-icon-add-mobile:before,
    .block-wishlist-management .wishlist-add.item .add:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 10px;
        line-height: 10px;
        color: inherit;
        content: '\e61c';
        font-family: 'icons-blank-theme';
        margin: 0 5px 0 0;
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
    }
    .abs-toggling-title-mobile,
    .block-wishlist-management .wishlist-select .wishlist-name,
    .block-collapsible-nav .title {
        border-bottom: 1px solid #d1d1d1;
        border-top: 1px solid #d1d1d1;
        cursor: pointer;
        margin-bottom: 0;
        padding: 10px 40px 10px 15px;
        position: relative;
        display: block;
        text-decoration: none;
    }
    .abs-toggling-title-mobile:after,
    .block-wishlist-management .wishlist-select .wishlist-name:after,
    .block-collapsible-nav .title:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 28px;
        line-height: inherit;
        color: inherit;
        content: '\e622';
        font-family: 'icons-blank-theme';
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
    }
    .block-wishlist-management .wishlist-select .wishlist-name:after,
    .block-collapsible-nav .title:after {
        position: absolute;
        right: 10px;
        top: 0;
    }
    .abs-toggling-title-mobile.active:after,
    .block-wishlist-management .wishlist-select .wishlist-name.active:after,
    .block-collapsible-nav .title.active:after {
        content: '\e621';
        font-family: 'icons-blank-theme';
    }
    .abs-pager-toolbar-mobile .toolbar-amount,
    .abs-pager-toolbar-mobile .limiter,
    .abs-pager-toolbar-mobile .pages,
    .account .toolbar .toolbar-amount,
    .account .toolbar .limiter,
    .account .toolbar .pages,
    .toolbar-wishlist-results .toolbar-amount,
    .toolbar-wishlist-results .limiter,
    .toolbar-wishlist-results .pages {
        float: none;
        margin-bottom: 25px;
    }
    .abs-shopping-cart-items-mobile .actions,
    .cart-container .form-cart .actions {
        text-align: center;
    }
    .cart-container .form-cart .action.update,
    .cart-container .form-cart .action.continue,
    .cart-container .form-cart .action.clear {
        margin: 0 auto 10px;
    }
    .cart-container .form-cart .action.update,
    .cart-container .form-cart .action.clear {
        display: block;
    }
    .abs-checkout-tooltip-content-position-top-mobile,
    .field-tooltip .field-tooltip-content {
        right: -10px;
        top: 40px;
        left: auto;
    }
    .field-tooltip .field-tooltip-content:before,
    .field-tooltip .field-tooltip-content:after {
        border: 10px solid transparent;
        height: 0;
        width: 0;
        margin-top: -21px;
        right: 10px;
        left: auto;
        top: 0;
    }
    .field-tooltip .field-tooltip-content:before {
        border-bottom-color: #999999;
    }
    .field-tooltip .field-tooltip-content:after {
        border-bottom-color: #f4f4f4;
        top: 1px;
    }
    .account .page-main,
    .cms-privacy-policy .page-main {
        padding-top: 41px;
        position: relative;
    }
    .modal-popup.modal-slide {
        left: 44px;
        z-index: 900;
    }
    .modal-popup.modal-slide._show .modal-inner-wrap {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    .modal-popup.modal-slide .modal-inner-wrap {
        height: 100%;
        overflow-y: auto;
        position: static;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-transition: -webkit-transform 0.3s ease-in-out;
        transition: transform 0.3s ease-in-out;
        width: auto;
    }
    .modal-popup.modal-slide .modal-inner-wrap {
        margin: 0;
        max-height: none;
    }
    .custom-slide {
        bottom: 0;
        min-width: 0;
        position: fixed;
        right: 0;
        top: 0;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: visibility 0s 0.3s, opacity 0.3s ease;
        transition: visibility 0s 0.3s, opacity 0.3s ease;
        left: 44px;
        z-index: 900;
    }
    .custom-slide._show {
        visibility: visible;
        opacity: 1;
        -webkit-transition: opacity 0.3s ease;
        transition: opacity 0.3s ease;
    }
    .custom-slide._show .modal-inner-wrap {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    .custom-slide .modal-inner-wrap {
        background-color: #ffffff;
        box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
        opacity: 1;
        pointer-events: auto;
    }
    .custom-slide._show .modal-inner-wrap {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    .custom-slide .modal-inner-wrap {
        height: 100%;
        overflow-y: auto;
        position: static;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-transition: -webkit-transform 0.3s ease-in-out;
        transition: transform 0.3s ease-in-out;
        width: auto;
    }
    .custom-slide._show {
        -webkit-overflow-scrolling: touch;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .custom-slide .modal-inner-wrap {
        background-color: #f4f4f4;
        box-sizing: border-box;
        height: auto;
        min-height: 100%;
    }
    body._has-modal-custom {
        height: 100vh;
        overflow: hidden;
        width: 100vw;
    }
    body._has-modal-custom .modal-custom-overlay {
        background-color: rgba(51, 51, 51, 0.55);
    }
    .modal-popup {
        pointer-events: auto;
    }
    .modal-popup.modal-slide .modal-inner-wrap[class] {
        background-color: #f4f4f4;
    }
    .modal-popup.modal-slide._inner-scroll._show {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
    }
    .modal-popup.modal-slide._inner-scroll .modal-inner-wrap {
        height: auto;
        min-height: 100%;
    }
    .modal-popup .modal-title {
        font-size: 14px;
        font-weight: 700;
    }
    .lac-notification {
        padding: 5px 0;
    }
    .lac-notification .lac-notification-icon {
        display: none;
    }
    .lac-notification .lac-notification-text,
    .lac-notification .lac-notification-links {
        float: none;
        padding: 5px 0;
        text-align: center;
    }
    .catalog-product-view .column.main {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .catalog-product-view .product.media {
        -ms-flex-order: -1;
        -webkit-order: -1;
        order: -1;
    }
    .block.related .action.select {
        display: block;
        margin: 5px 0;
    }
    .compare,
    .product-addto-links .action.tocompare,
    .product-item-actions .actions-secondary > .action.tocompare {
        display: none;
    }
    .block-search .block-content {
        margin-bottom: 0;
    }
    .cart.table-wrapper {
        overflow: inherit;
    }
    .cart.table-wrapper thead .col:not(.item) {
        display: none;
    }
    .cart.table-wrapper .col.qty,
    .cart.table-wrapper .col.price,
    .cart.table-wrapper .col.subtotal,
    .cart.table-wrapper .col.msrp {
        box-sizing: border-box;
        display: block;
        float: left;
        text-align: center;
        white-space: nowrap;
        width: 33%;
    }
    .cart.table-wrapper .col.qty[data-th]:before,
    .cart.table-wrapper .col.price[data-th]:before,
    .cart.table-wrapper .col.subtotal[data-th]:before,
    .cart.table-wrapper .col.msrp[data-th]:before {
        content: attr(data-th) ':';
        display: block;
        font-weight: 700;
        padding-bottom: 10px;
    }
    .cart.table-wrapper .col.msrp {
        white-space: normal;
    }
    .cart.table-wrapper .item .col.item {
        padding-bottom: 0;
    }
    .field-tooltip .field-tooltip-content {
        right: -10px;
        top: 40px;
        left: auto;
    }
    .checkout-payment-method .payment-methods {
        margin: 0 -15px;
    }
    .checkout-payment-method .step-title {
        padding-left: 15px;
        padding-right: 15px;
    }
    .checkout-payment-method .payment-method-title {
        padding: 15px;
    }
    .checkout-payment-method .payment-method-content {
        padding: 0 15px 20px;
    }
    .checkout-payment-method .checkout-billing-address .action-cancel {
        margin-top: 10px;
    }
    .checkout-payment-method .payment-option {
        margin: 0 -15px;
    }
    .checkout-payment-method .payment-option .payment-option-title {
        padding: 15px;
    }
    .checkout-payment-method .payment-option .payment-option-content {
        padding: 0 15px 20px;
    }
    .contact-index-index .column:not(.sidebar-main) .form.contact {
        float: none;
        width: 100%;
    }
    .contact-index-index .column:not(.sidebar-additional) .form.contact {
        float: none;
        width: 100%;
    }
    .login-container .fieldset:after {
        text-align: center;
    }
    .account .messages {
        margin-bottom: 0;
    }
    .control.captcha-image .captcha-img {
        margin-bottom: 10px;
        display: block;
    }
    .customer-account-index .page-title-wrapper {
        position: relative;
    }
    .form.search.advanced .field.price .with-addon .input-text {
        flex-basis: auto;
        width: 100%;
    }
    .gift-options .actions-toolbar .action-update {
        font-size: 2rem;
        padding: 15px;
        width: 100%;
    }
    .gift-item-block {
        border-top: 1px solid #c1c1c1;
        border-bottom: 0;
    }
    .cart.table-wrapper .gift-content {
        margin-right: -10px;
    }
    .gift-wrapping {
        border-bottom: 1px solid #c1c1c1;
        margin-bottom: 20px;
        padding-bottom: 10px;
    }
    .gift-wrapping-list {
        width: 100%;
    }
    .gift-wrapping-preview img {
        width: 100%;
    }
    .item-actions .actions-toolbar .gift-options .gift-wrapping,
    .item-actions .actions-toolbar .gift-options .gift-message {
        display: block;
    }
    .form-add-invitations .additional,
    .form-add-invitations .field.text {
        margin-top: 20px;
    }
    .page-with-filter .columns .sidebar-main {
        -ms-flex-order: 0;
        -webkit-order: 0;
        order: 0;
    }
    .map-popup {
        max-width: 100%;
    }
    .map-old-price,
    .map-show-info {
        display: inline-block;
    }
    .map-old-price:not(:last-child),
    .map-show-info:not(:last-child) {
        margin-bottom: 10px;
    }
    .block-wishlist-management {
        clear: both;
        margin-top: -21px;
    }
    .block-wishlist-management .wishlist-select {
        margin: 0 -15px 20px;
    }
    .block-wishlist-management .wishlist-select-items {
        border-bottom: 1px solid #d1d1d1;
        background: #f5f5f5;
        display: none;
        padding: 15px 0;
    }
    .block-wishlist-management .wishlist-select-items.active {
        display: block;
    }
    .block-wishlist-management .wishlist-select-items .item {
        margin: 3px 0 0;
    }
    .block-wishlist-management .wishlist-select-items .item:first-child {
        margin-top: 0;
    }
    .block-wishlist-management .wishlist-select-items .item.current {
        display: none;
    }
    .block-wishlist-management .wishlist-select-items .item a {
        display: block;
        padding: 5px 18px;
        text-decoration: none;
    }
    .block-wishlist-management .wishlist-select-items .item a:hover {
        background: #e8e8e8;
    }
    .block-wishlist-management .wishlist-title strong {
        font-size: 2.6rem;
    }
    .block-wishlist-management .wishlist-info {
        margin-bottom: 15px;
    }
    .block-wishlist-management .wishlist-toolbar-select,
    .block-wishlist-management .wishlist-toolbar-actions {
        margin-bottom: 15px;
    }
    .products-grid.wishlist .product-item-checkbox {
        left: 0;
        position: absolute;
        top: 20px;
    }
    .page-multiple-wishlist .products-grid.wishlist .product-item-photo {
        margin-left: 25px;
    }
    .page-multiple-wishlist .products-grid.wishlist .product-item-name,
    .page-multiple-wishlist .products-grid.wishlist .product-item-description,
    .page-multiple-wishlist .products-grid.wishlist .product-item .price-box,
    .page-multiple-wishlist .products-grid.wishlist .product-item-tooltip {
        margin-left: 115px;
    }
    .products-grid.wishlist .wishlist-dropdown {
        display: none;
    }
    .multishipping-checkout-success .nav-toggle {
        display: block;
    }
    .multishipping-checkout-success .logo {
        margin-left: 40px;
    }
    .reward-settings + .actions-toolbar {
        margin-top: 10px;
    }
    .account .column.main .returns-details-items .block-returns-tracking .block-title > .action.track {
        display: block;
        float: none;
        margin: 10px 0 0;
    }
    .order-pager-wrapper .toolbar-amount {
        left: inherit;
        position: relative;
        text-align: center;
        top: inherit;
    }
    .order-pager-wrapper .pages {
        text-align: center;
    }
    .order-pager-wrapper .action.previous,
    .order-pager-wrapper .action.next {
        margin: 0;
    }
    .block-collapsible-nav {
        left: 0;
        position: absolute;
        top: -21px;
        width: 100%;
        z-index: 5;
    }
    .block-collapsible-nav .content {
        border-bottom: 1px solid #d1d1d1;
        display: none;
    }
    .block-collapsible-nav .content.active {
        display: block;
    }
    .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status {
        display: none;
    }
    .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status:before {
        display: none;
    }
    .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status._default {
        display: block;
    }
    .my-credit-cards .card-type img {
        vertical-align: middle;
    }
    .products-grid.wishlist {
        margin-bottom: 30px;
        margin-right: 0;
    }
    .products-grid.wishlist .product-item {
        padding: 20px 0 20px 0;
        position: relative;
    }
    .products-grid.wishlist .product-item-photo {
        float: left;
        margin-right: 20px;
    }
    .products-grid.wishlist .product-item-name {
        font-size: 1.6rem;
    }
    .products-grid.wishlist .product-item-actions {
        display: block;
        float: left;
    }
    .products-grid.wishlist .product-item-actions .action {
        margin-right: 15px;
    }
    .products-grid.wishlist .product-item-actions .action:last-child {
        margin-right: 0;
    }
    .products-grid.wishlist .product-item-actions .action.edit {
        float: left;
    }
    .products-grid.wishlist .product-item-actions .action.delete {
        float: right;
    }
    .products-grid.wishlist .product-item-actions .action.edit,
    .products-grid.wishlist .product-item-actions .action.delete {
        margin-top: 7px;
    }
    .products-grid.wishlist .product-item-name,
    .products-grid.wishlist .product-item-description,
    .products-grid.wishlist .product-item .price-box,
    .products-grid.wishlist .product-item-tooltip {
        margin-left: 95px;
    }
    .products-grid.wishlist .product-item .box-tocart {
        float: left;
        margin-right: 20px;
    }
    .products-grid.wishlist .product-item .box-tocart .stock {
        margin-top: 7px;
    }
    .products-grid.wishlist .product-item .giftregisty-dropdown,
    .products-grid.wishlist .product-item .field.qty {
        display: none;
    }
    .products-grid.wishlist .product-image-container {
        max-width: 80px;
    }
    .wishlist-index-index .product-item {
        width: 100%;
    }
    .wishlist-index-index .product-item-info {
        width: auto;
    }
    .sidebar .block-viewed-products-grid .products-grid .product-item {
        margin-left: 2%;
        width: calc((100% - 2%) / 2);
    }
    .sidebar .block-viewed-products-grid .products-grid .product-item:nth-child(2n + 1) {
        margin-left: 0;
    }
    .sidebar .block-viewed-products-grid .products-grid .product-item-photo {
        display: block;
        position: relative;
    }
    .sidebar .block-viewed-products-grid .products-grid .product-item-details {
        margin: 0;
    }
    .sidebar .block-viewed-products-grid .products-grid .product-item-info {
        text-align: center;
    }
}
@media only screen and (max-width: 639px) {
    .abs-visually-hidden-mobile,
    .table-giftregistry-items .col.product:before {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    .abs-add-clearfix-mobile:before,
    .abs-add-clearfix-mobile:after,
    .abs-checkout-order-review tbody tr:before,
    .abs-checkout-order-review tbody tr:after,
    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:before,
    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:after,
    .table-giftregistry-items .col.product:before,
    .table-giftregistry-items .col.product:after,
    .account .toolbar:before,
    .account .toolbar:after {
        content: '';
        display: table;
    }
    .abs-add-clearfix-mobile:after,
    .abs-checkout-order-review tbody tr:after,
    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:after,
    .table-giftregistry-items .col.product:after,
    .account .toolbar:after {
        clear: both;
    }
    .abs-checkout-order-review tbody tr .product-item-name {
        margin: 0;
    }
    .abs-no-display-s,
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison).additional-attributes tbody th {
        display: none;
    }
    .abs-pager-toolbar-mobile-s .toolbar-amount,
    .abs-pager-toolbar-mobile-s .limiter,
    .abs-pager-toolbar-mobile-s .pages,
    .toolbar-giftregistry-results .toolbar-amount,
    .toolbar-giftregistry-results .limiter,
    .toolbar-giftregistry-results .pages {
        margin-bottom: 25px;
    }
    .table-wrapper {
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
        position: relative;
    }
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) {
        border: none;
        display: block;
    }
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > thead > tr > th {
        display: none;
    }
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody {
        display: block;
    }
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr {
        display: block;
    }
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr td,
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr th {
        border-bottom: none;
        display: block;
        padding: 5px 0;
    }
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr td[data-th]:before,
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr th[data-th]:before {
        padding-right: 10px;
        content: attr(data-th) ': ';
        display: inline-block;
        color: #111111;
        font-weight: 700;
    }
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr > td:first-child {
        padding-top: 20px;
    }
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr > td:last-child {
        padding-bottom: 20px;
    }
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison).additional-attributes tbody td:last-child {
        border: none;
        padding: 0 0 5px;
    }
    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot {
        display: block;
    }
    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr {
        display: block;
    }
    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:first-child th,
    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:first-child td {
        padding-top: 20px;
    }
    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot th {
        box-sizing: border-box;
        float: left;
        padding-left: 0;
        padding-right: 0;
        text-align: left;
        width: 70%;
    }
    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot td {
        box-sizing: border-box;
        float: left;
        padding-left: 0;
        padding-right: 0;
        text-align: right;
        width: 30%;
    }
    .data-table-definition-list thead {
        display: none;
    }
    .data-table-definition-list tbody th {
        padding-bottom: 0;
    }
    .data-table-definition-list tbody th,
    .data-table-definition-list tbody td {
        display: block;
        padding-left: 0;
        padding-right: 0;
    }
    .column .block-addbysku .sku {
        margin-right: -85px;
        padding-right: 90px;
    }
    .column .block-addbysku .action.remove {
        display: inline-block;
        background-image: none;
        background: none;
        -moz-box-sizing: content-box;
        border: 0;
        box-shadow: none;
        line-height: inherit;
        margin: 0;
        padding: 0;
        text-decoration: none;
        text-shadow: none;
        font-weight: 400;
    }
    .column .block-addbysku .action.remove > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    .column .block-addbysku .action.remove:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 28px;
        line-height: 22px;
        color: inherit;
        content: '\e616';
        font-family: 'icons-blank-theme';
        margin: 1px 0 0;
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
    }
    .column .block-addbysku .action.remove:hover:before {
        color: inherit;
    }
    .column .block-addbysku .action.remove:active:before {
        color: inherit;
    }
    .column .block-addbysku .action.remove:focus,
    .column .block-addbysku .action.remove:active {
        background: none;
        border: none;
    }
    .column .block-addbysku .action.remove:hover {
        background: none;
        border: none;
    }
    .column .block-addbysku .action.remove.disabled,
    .column .block-addbysku .action.remove[disabled],
    fieldset[disabled] .column .block-addbysku .action.remove {
        pointer-events: none;
        opacity: 0.5;
    }
    .products-list .product-item {
        table-layout: fixed;
    }
    .products-list .product-item-photo {
        padding: 0 10px 10px 0;
        width: 30%;
    }
    .minicart-wrapper:before,
    .minicart-wrapper:after {
        content: '';
        display: table;
    }
    .minicart-wrapper:after {
        clear: both;
    }
    .minicart-wrapper .product .actions {
        float: left;
        margin: 10px 0 0 0;
    }
    .minicart-wrapper .update-cart-item {
        float: right;
        margin-left: 0;
    }
    .opc-wrapper .step-title {
        font-size: 18px;
        border-bottom: 0;
        padding-bottom: 0;
    }
    .opc-wrapper .form-login {
        border-bottom: 1px solid #cccccc;
        margin: 0 0 15px;
        padding: 0 0 15px;
    }
    .opc-wrapper .shipping-address-item {
        border-bottom: 1px solid #cccccc;
        margin: 0 0 15px;
        padding: 0 0 15px;
        width: 100%;
    }
    .opc-wrapper .shipping-address-item.selected-item {
        padding: 15px 35px 15px 18px;
        border-bottom-width: 2px;
    }
    .opc-wrapper .shipping-address-item.selected-item .edit-address-link {
        right: 39px;
    }
    .opc-wrapper .form-login,
    .opc-wrapper .form-shipping-address {
        margin-top: 28px;
    }
    .opc-wrapper .action-select-shipping-item {
        float: none;
        margin-top: 10px;
        width: 100%;
    }
    .opc-wrapper .action-show-popup {
        width: 100%;
    }
    .opc-wrapper .edit-address-link {
        display: inline-block;
        text-decoration: none;
        margin: 0;
        position: absolute;
        right: 0;
        top: 1px;
    }
    .opc-wrapper .edit-address-link > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    .opc-wrapper .edit-address-link:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 28px;
        line-height: 28px;
        color: #303030;
        content: '\e606';
        font-family: 'icons-blank-theme';
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
    }
    .opc-wrapper .edit-address-link:hover:before {
        color: #303030;
    }
    .opc-wrapper .edit-address-link:active:before {
        color: #303030;
    }
    .shipping-policy-block.field-tooltip {
        margin-bottom: 20px;
        position: relative;
        right: auto;
        top: auto;
    }
    .shipping-policy-block.field-tooltip .field-tooltip-content {
        width: 300px;
        right: auto;
    }
    .shipping-policy-block.field-tooltip .field-tooltip-content:before,
    .shipping-policy-block.field-tooltip .field-tooltip-content:after {
        right: auto;
    }
    .opc-block-shipping-information .shipping-information-title {
        font-size: 2.3rem;
    }
    .account .column.main,
    .account .sidebar-additional {
        margin: 0;
    }
    .cart.table-wrapper .gift-content {
        border-bottom: 1px solid #d1d1d1;
        margin-bottom: 20px;
    }
    .table-giftregistry-items .field.choice,
    .table-giftregistry-items .field.qty {
        display: inline-block;
    }
    .form-giftregistry-search .fieldset {
        margin-bottom: 15px;
    }
    .account .order-details-items .table-order-items .product-item-name,
    .account .order-details-items .table-order-items .price-including-tax,
    .account .order-details-items .table-order-items .price-excluding-tax,
    .account .order-details-items .table-order-items .items-qty {
        display: inline-block;
        vertical-align: top;
    }
    .account .toolbar .pages {
        float: right;
    }
    .account .toolbar .limiter {
        clear: both;
    }
    .order-details-items thead {
        display: block;
    }
    .order-details-items thead tr,
    .order-details-items thead td {
        display: block;
    }
    .table-wrapper .table.table-order-items tfoot td.order-pager-wrapper {
        width: 100%;
    }
    .order-items.table-wrapper .col.price,
    .order-items.table-wrapper .col.qty,
    .order-items.table-wrapper .col.subtotal,
    .order-items.table-wrapper .col.msrp {
        text-align: left;
    }
    .products-grid.wishlist .product-item {
        border-bottom: 1px solid #e8e8e8;
        margin: 0;
        width: 100%;
    }
    .products-grid.wishlist .product-item:first-child {
        border-top: 1px solid #e8e8e8;
    }
}
@media only screen and (max-width: 479px) {
    .tooltip.wrapper .tooltip.content {
        min-width: 10rem;
    }
    .minicart-wrapper .block-minicart {
        width: 290px;
    }
    .modal-popup .field-tooltip .field-tooltip-content {
        width: 200px;
    }
}
@media all and (min-width: 640px) {
    .abs-blocks-2columns-s {
        width: 48.8%;
    }
    .abs-reset-left-margin-desktop-s {
        margin-left: 0;
    }
    .abs-margin-for-forms-desktop-s {
        margin-left: 25.8%;
    }
    .abs-visually-hidden-desktop-s {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    .abs-add-clearfix-desktop-s:before,
    .abs-add-clearfix-desktop-s:after {
        content: '';
        display: table;
    }
    .abs-add-clearfix-desktop-s:after {
        clear: both;
    }
    .abs-add-box-sizing-desktop-s {
        box-sizing: border-box;
    }
    .ui-dialog.popup {
        width: 420px;
    }
    .column .block-addbysku .action.remove {
        background-image: none;
        -moz-box-sizing: content-box;
        box-shadow: none;
        line-height: inherit;
        text-shadow: none;
        font-weight: 400;
        line-height: 1.42857143;
        margin: 0;
        padding: 0;
        color: #1979c3;
        text-decoration: none;
        background: none;
        border: 0;
        display: inline;
        margin-top: 2px;
    }
    .column .block-addbysku .action.remove:focus,
    .column .block-addbysku .action.remove:active {
        background: none;
        border: none;
    }
    .column .block-addbysku .action.remove:hover {
        background: none;
        border: none;
    }
    .column .block-addbysku .action.remove.disabled,
    .column .block-addbysku .action.remove[disabled],
    fieldset[disabled] .column .block-addbysku .action.remove {
        pointer-events: none;
        opacity: 0.5;
    }
    .column .block-addbysku .action.remove:visited {
        color: #1979c3;
        text-decoration: none;
    }
    .column .block-addbysku .action.remove:hover {
        color: #006bb4;
        text-decoration: underline;
    }
    .column .block-addbysku .action.remove:active {
        color: $primary;
        text-decoration: underline;
    }
    .column .block-addbysku .action.remove:hover,
    .column .block-addbysku .action.remove:active,
    .column .block-addbysku .action.remove:focus {
        background: none;
        border: 0;
    }
    .column .block-addbysku .action.remove.disabled,
    .column .block-addbysku .action.remove[disabled],
    fieldset[disabled] .column .block-addbysku .action.remove {
        color: #1979c3;
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
        text-decoration: underline;
    }
    .products-grid .product-item {
        margin-bottom: 30px;
    }
    .product-item-actions {
        display: block;
    }
    .products-grid .product-item-actions {
        margin: 10px 0;
    }
    .product-item-actions .actions-primary + .actions-secondary > * {
        white-space: normal;
    }
    .products-grid .product-item {
        width: 33.33333333%;
    }
    .page-products .products-grid .product-item,
    .page-layout-1column .products-grid .product-item,
    .page-layout-3columns .products-grid .product-item,
    .page-products.page-layout-1column .products-grid .product-item,
    .page-products.page-layout-3columns .products-grid .product-item {
        margin-left: 2%;
        padding: 0;
        width: calc((100% - 4%) / 3);
    }
    .page-products .products-grid .product-item:nth-child(3n + 1),
    .page-layout-1column .products-grid .product-item:nth-child(3n + 1),
    .page-layout-3columns .products-grid .product-item:nth-child(3n + 1),
    .page-products.page-layout-1column .products-grid .product-item:nth-child(3n + 1),
    .page-products.page-layout-3columns .products-grid .product-item:nth-child(3n + 1) {
        margin-left: 0;
    }
    .wishlist-index-index .products-grid .product-item {
        margin-bottom: 20px;
    }
    .wishlist-index-index .products-grid .product-item-actions {
        margin: 0;
    }
    .block.widget .products-grid .product-item,
    .page-layout-1column .block.widget .products-grid .product-item,
    .page-layout-3columns .block.widget .products-grid .product-item {
        width: 33.33333333%;
    }
    .page-layout-1column .block.widget .products-grid .product-item {
        margin-left: 2%;
        width: calc((100% - 4%) / 3);
    }
    .page-layout-1column .block.widget .products-grid .product-item:nth-child(3n + 1) {
        margin-left: 0;
    }
}
table.data-table {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    width: 100%;
}

table.data-table th,
table.data-table td {
    padding: 8px 10px;
}

table.data-table th {
    font-weight: bold;
    text-align: left;
}

table.data-table thead th {
    background-color: #e0e0e0;
}

table.data-table tbody th,
table.data-table tbody td {
    font-size: 15px;
    border: 1px solid #e0e0e0;
    border-width: 1px 0;
}
