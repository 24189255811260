$dl-link__side-width: 7.5rem;

.dl-link {
    display: flex;
    align-items: center;

    .dl-link__text {
        @include link-underline($black, 1ch, 7px, 1px, $black);
        margin-right: auto;
        height: 1.2em;
        color: $black;
    }

    .dl-link__weight {
        display: inline-block;
        width: $dl-link__side-width;
        text-align: right;
        position: relative;
        padding: 0.5rem;
        font-weight: 700;
        color: $black;
        font-family: $roboto !important;
        white-space: nowrap;

        &::before {
            content: '|';
            font-size: 1.6em;
            font-weight: 400 !important;
            color: $lighter-grey;
            position: absolute;
            left: 0;
            transform: translateY(-0.3em);
        }

        &::after {
            font-weight: 400;
            @include icon-download;
        }
    }

    .dl-link__icon-link {
        display: inline-block;
        padding-right: 0.5rem;
        color: $black;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
