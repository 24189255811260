$minicart-counter-size: 1rem;
$minicart-font-size: 0.875rem;

$mobile-menu-footer-nav-height: 5rem;

@function item-separator-style() {
    @return 1px solid $lighter-grey;
}

.block-minicart {
    #minicart-content-wrapper {
        margin-top: 1rem;
        color: $black;

        .items-total {
            float: left;
            margin: 0 10px;

            .count {
                font-weight: 700;
            }
        }

        .subtotal {
            margin: 1rem 0 0;

            .amount {
                float: right;
            }

            .price, .label {
                font-size: $minicart-font-size;
                font-weight: 700;
            }
        }

        .subtitle {
            display: none;

            &.empty {
                display: block;
                font-size: $minicart-font-size;
                padding: 30px 0 20px;
                text-align: center;
            }
        }

        .text {
            &.empty {
                text-align: center;
            }
        }

        .block-content {
            padding: 1rem 2rem 2rem;

            & > .actions {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                justify-content: space-between;
                margin-top: 15px;
                font-size: $minicart-font-size;
                text-align: left;

                .primary {
                    margin: 0;

                    .action.checkout {
                        @include btn;
                        display: block;
                        width: 100%;
                    }
                }

                .secondary {
                    margin: 0;
                    text-align: left;

                    .action.viewcart {
                        @include button-to-link;
                    }
                }
            }
        }
    }
}
.block-minicart .block-category-link,
.block-minicart .block-product-link,
.block-minicart .block-cms-link,
.block-minicart .block-banners {
    margin: 15px 0 0;
    text-align: center;
}
.minicart-wrapper {
    display: inline-block;
    position: relative;

    &::after {
        clear: both;
    }

    .action {
        &.showcart {
            transition: 0.3s ease-out transform, 0.3s ease-out opacity;

            &:hover {
                &::before {
                    color: $black;
                }
            }

            &:active {
                &::before {
                    color: $grey;
                }
            }

            &.active {
                &:hover {
                    &::before {
                        color: $black;
                    }
                }

                &:active {
                    &::before {
                        color: $grey;
                    }
                }
            }
        }

        &.close {
            display: inline-block;
            background-image: none;
            background: none;
            -moz-box-sizing: content-box;
            border: 0;
            box-shadow: none;
            line-height: inherit;
            margin: 0;
            padding: 0;
            text-decoration: none;
            text-shadow: none;
            font-weight: 400;
            height: 40px;
            position: absolute;
            right: 0;
            top: 0;
            width: 40px;
            opacity: 0;
            -webkit-transition: opacity 0s;
            transition: opacity 0s;
            pointer-events: none;

            & > span {
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            &::before {
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 32px;
                line-height: 32px;
                color: inherit;
                content: 'X';
                margin: 0;
                vertical-align: top;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
            }

            &:hover {
                background: none;
                border: none;

                &::before {
                    color: inherit;
                }
            }

            &:active {
                &::before {
                    color: inherit;
                }
            }
        }
    }

    .block-minicart {
        margin: 0;
        padding: 0;
        list-style: none none;
        background: $white;
        margin-top: 4px;
        min-width: 100%;
        width: 340px;
        max-width: 90vw;
        z-index: 101;
        box-sizing: border-box;
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        @include box-shadow;

        li {
            margin: 0;

            &:hover {
                cursor: pointer;
            }
        }

        &::before {
            border: 6px solid;
            border-color: transparent transparent #ffffff transparent;
            z-index: 99;
            right: 26px;
            top: -12px;
        }

        &::after {
            border: 7px solid;
            border-color: transparent transparent #bbbbbb transparent;
            z-index: 98;
            right: 25px;
            top: -14px;
        }

        .block-title {
            display: block;
            padding: 0.5rem 0;
            text-align: center;
            color: $black;

            .text {
                font-weight: 700;
                text-transform: uppercase;
            }

            .qty {
                display: none;
            }
        }
    }

    &.active {
        overflow: visible;

        .block-minicart {
            display: block;
        }
    }

    .product {
        .actions {
            float: right;
            text-align: right;

            .primary, .secondary {
                float: right;
                margin-left: 1rem;
                font-size: 1rem;

                .action {
                    &.edit, &.delete {
                        display: inline-block;
                        color: $primary;
                        font-weight: 400;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .minicart-widgets {
        margin-top: 15px;
    }
}
.minicart-wrapper:before,
.minicart-wrapper:after {
    content: '';
    display: table;
}
.minicart-wrapper .block-minicart:before,
.minicart-wrapper .block-minicart:after {
    border-bottom-style: solid;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0;
}
.minicart-wrapper .action.close:focus,
.minicart-wrapper .action.close:active {
    background: none;
    border: none;
}
.minicart-wrapper .action.close.disabled,
.minicart-wrapper .action.close[disabled],
fieldset[disabled] .minicart-wrapper .action.close {
    pointer-events: none;
    opacity: 0.5;
}
.action {
    &.showcart {
        position: relative;
        cursor: pointer;
        display: inline-block;
        text-decoration: none;
        white-space: nowrap;

        &::before {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            margin: 0;
            vertical-align: top;
            display: inline-block;
            font-weight: normal;
            overflow: hidden;
            speak: none;
            text-align: center;
            @include icomoon-base;
            @include icon-minicart;
        }

        &.active {
            &::before {
                font-weight: 700;
            }
        }

        .counter {
            &.qty {
                position: absolute;
                top: 0.5rem;
                right: 50%;
                display: inline-block;
                padding: 0;
                min-width: $minicart-counter-size;
                height: $minicart-counter-size;
                overflow: hidden;
                background: $dark-green;
                border: 1px solid $grey;
                border-radius: $minicart-counter-size;
                color: $white;
                font-size: 0.75rem;
                font-weight: 700;
                line-height: $minicart-counter-size;
                text-align: center;
                white-space: normal;
                transform: translateX(100%);

                &.empty {
                    display: none;
                }

                .loader {
                    & > img {
                        max-width: 24px;
                    }
                }
            }
        }
    }

    &.close {
        display: inline-block;
        background-image: none;
        background: none;
        -moz-box-sizing: content-box;
        border: 0;
        box-shadow: none;
        line-height: inherit;
        margin: 0;
        padding: 0;
        text-decoration: none;
        text-shadow: none;
        font-weight: 400;
        height: 40px;
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        opacity: 0;
        -webkit-transition: opacity 0s;
        transition: opacity 0s;
        pointer-events: none;

        & > span {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        &::before {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 32px;
            line-height: 32px;
            color: inherit;
            content: 'X';
            margin: 0;
            vertical-align: top;
            display: inline-block;
            font-weight: normal;
            overflow: hidden;
            speak: none;
            text-align: center;
        }

        &:hover {
            background: none;
            border: none;

            &::before {
                color: inherit;
            }
        }

        &:active {
            &::before {
                color: inherit;
            }
        }
    }
}
.minicart-items-wrapper {
    border: item-separator-style();
    margin: 0;
    border-left: 0;
    border-right: 0;
    overflow-x: auto;
    padding: 1rem 0;
}
.minicart-items {
    margin: 0;
    padding: 0;
    list-style: none none;
    .product-item {
        padding: 20px 0;
        font-size: $minicart-font-size;

        &:not(:first-child) {
            border-top: item-separator-style();
        }
        &:first-child {
            padding-top: 0;
        }
    }
    .product-item-pricing {
        .label {
            display: inline-block;
        }
    }
    .price-minicart {
        margin-bottom: 5px;
    }
    .message {
        margin-bottom: 0;
        margin-top: 10px;
    }
    .product {
        .toggle {
            display: block;
            text-decoration: none;
            cursor: pointer;
            position: relative;
            white-space: nowrap;
            &::after {
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 28px;
                line-height: 16px;
                color: inherit;
                content: '\e622';
                font-family: 'icons-blank-theme';
                vertical-align: middle;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
                position: static;
                right: 20px;
                top: 0;
            }
        }

        .price-including-tax,
        .price-excluding-tax {
            font-size: 1px;
        }

        &.active {
            & > .toggle {
                &::after {
                    content: '\e621';
                    font-family: 'icons-blank-theme';
                }
            }
        }

        &.options {
            margin-bottom: 0.25rem;

            .tooltip {
                &.toggle {
                    display: inline-block;
                    text-decoration: none;
                    & > span {
                        border: 0;
                        clip: rect(0, 0, 0, 0);
                        height: 1px;
                        margin: -1px;
                        overflow: hidden;
                        padding: 0;
                        position: absolute;
                        width: 1px;
                    }
                    &::after {
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        font-size: 28px;
                        line-height: 28px;
                        color: inherit;
                        content: '\e622';
                        font-family: 'icons-blank-theme';
                        margin: -3px 0 0 7px;
                        vertical-align: middle;
                        display: inline-block;
                        font-weight: normal;
                        overflow: hidden;
                        speak: none;
                        text-align: center;
                    }
                    .details {
                        display: none;
                    }
                }
            }
        }
    }
    .product-item-name {
        margin-top: 0;
        font-weight: 700;

        a {
            color: $black;
        }
    }
    .product-item-details {
        padding-left: 88px;

        .price-container .price {
            color: $light-grey;
            font-size: $minicart-font-size;
            font-weight: 700;
        }
        .weee[data-label] {
            font-size: 1.1rem;
        }
        .info-qty {
            margin-top: 0.25rem;
        }
    }
    .update-cart-item {
        font-size: 1.1rem;
        margin-left: 5px;
        vertical-align: top;
    }
    .subtitle {
        display: none;
    }
}
.minicart-items .product > .product-item-photo,
.minicart-items .product > .product-image-container {
    float: left;
}
.minicart-items .action.edit > span,
.minicart-items .action.delete > span {
    border: 0;
    padding: 0;
}
.minicart-items .action.edit:hover:before,
.minicart-items .action.delete:hover:before {
    color: #303030;
}
.minicart-items .action.edit:active:before,
.minicart-items .action.delete:active:before {
    color: #303030;
}

@media only screen and (max-width: 768px) {
    .minicart-wrapper {
        .block-minicart {
            @include mobile-navigation-closed();
            top: auto;
            left: -110%;
            bottom: $mobile-menu-footer-nav-height;
            height: calc(100% - 5rem - #{$mobile-menu-footer-nav-height});
            z-index: 199;
        }

        .action.showcart + .ui-dialog {
            display: block !important;
        }
    }

    .nav-open {
        .panel.header {
            .action.showcart {
                transform: translateX(10%);
                opacity: 0;

                &.active ~ .ui-dialog .block-minicart {
                    left: 0;
                }
            }
        }
    }
}
