.especes-grid {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    .especes-grid-element {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        position: relative;
        width: 50%;

        img {
            margin: 20px;
            width: 50px;
            height: auto;
        }
        .espece_placeholder {
            mix-blend-mode: multiply;
            transform: scale(2);
        }

        a.espece-link {
            @include link-underline($black, 2ch, 1rem, 1px, $primary);
            height: calc(1rem + 7px);
            display: inline-block;
            font-weight: 400;

            &:hover,
            &:focus {
                color: $primary;
                background-image: linear-gradient($primary, $primary);
            }

            &::before {
                content: '';
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                position: absolute;
            }
        }
    }
}
