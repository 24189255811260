.banner-heading {
    .banner-heading__title {
        font-size: 2.25rem;
        line-height: 150%;
        font-weight: 700;
    }

    .banner-heading__subtitle {
        font-size: 1.125rem;
        margin-bottom: 0.5rem;
    }

    .banner-heading__tags {
        color: $light-grey;
    }

    .breadcrumbs {
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
    }

    .banner-heading__img-wrapper {
        position: relative;
        width: 100%;
        height: 180px;
        margin-bottom: 1.25rem;
        overflow: hidden;

        .banner-heading__img {
            //TODO : remplacer trick du position/translate par object-fit/object-position si possible
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
        }
    }
}
