.mobile-only {
    display: block;
    @media screen and (min-width: $screen-sm) {
        display: none;
    }
}

.desktop-only {
    display: none;
    @media screen and (min-width: $screen-sm) {
        display: block;
    }
}

.uppercase {
    text-transform: uppercase;
}

.hidden {
    display: none;
}

.full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

.field-recaptcha {
    display: none;
}
.grecaptcha-badge {
    display: none !important; //Hide that js-added floating recaptcha badge
}
