$glcl-group-padding: 1rem;

.contentmanager-contentlist-view {
    .title-block {
        padding-left: $glcl-group-padding;
    }
}

.glcl-list {
    .glcl-group-box {
        margin-bottom: 1.25rem;
    }
}

.glcl-group-box {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

    .glcl-group-box__header {
        position: relative;
        padding: $glcl-group-padding $glcl-group-padding 0;
        cursor: pointer;

        &::after {
            content: '\e622';
            position: absolute;
            top: 1rem;
            right: 0.5rem;
            display: inline-block;
            text-align: center;
            color: $black;
            font-size: 4rem;
            font-family: 'icons-blank-theme';
            line-height: 1;
        }
    }

    .glcl-group-box__icon {
        display: block;
        height: 1.5rem;
    }

    .glcl-group-box__title {
        display: inline-block;
        padding-right: 3rem;
        font-size: 1.25rem;
        font-weight: 700;
    }

    .glcl-group-box__app-list {
        padding: 0 $glcl-group-padding $glcl-group-padding;
        max-height: 0;
        overflow-y: hidden;
        transition: max-height 0.3s ease;

        > :first-child {
            margin-top: 1.5rem;
        }
    }

    &.glcl-group-box--green {
        background-color: $primary;
        color: $white;

        .glcl-group-box__header {
            &::after {
                color: $white;
            }
        }

        .glcl-accordion-item {
            .glcl-accordion-item__title {
                &::after {
                    color: $white;
                }
            }

            .glcl-accordion-item__link {
                background-color: $white;
                color: $black;

                &:hover {
                    background-color: $black;
                    color: $white;
                }
            }

            &.glcl-accordion-item--active {
                .glcl-accordion-item__title {
                    color: $white;
                }
            }
        }
    }

    &.glcl-group-box--active {
        .glcl-group-box__app-list {
            max-height: 100vh;
            overflow-y: scroll;
        }

        .glcl-group-box__header {
            &::after {
                content: '\e621';
            }
        }
    }
}

.glcl-accordion-item {
    .glcl-accordion-item__title {
        position: relative;
        display: inline-block;
        padding: 0.5rem 3rem 0.5rem 0;
        width: 100%;
        cursor: pointer;

        &::after {
            content: '\e622';
            position: absolute;
            top: 0.25rem;
            right: 0.5rem;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            color: $black;
            font-size: 2rem;
            font-family: 'icons-blank-theme';
            line-height: 1;
        }
    }

    .glcl-accordion-item__content {
        max-height: 0;
        overflow-y: hidden;
        transition: max-height 0.3s ease;
    }

    .glcl-accordion-item__desc {
        padding-top: 0.5rem;
        margin-bottom: 1rem;
        border-top: 1px solid $lighter-grey;
    }

    .glcl-accordion-item__link {
        margin-bottom: 2rem;
        margin-top: 0.75rem;
        color: $white;
    }

    &.glcl-accordion-item--active {
        .glcl-accordion-item__title {
            font-weight: 700;
            color: $primary;

            &::after {
                content: '\e621';
            }
        }

        .glcl-accordion-item__content {
            max-height: 20rem;
        }
    }
}

.glcl-detail-description {
    margin-bottom: 1rem;
}

.glcl-detail__link-caption {
    margin-bottom: 1rem;
}

.body.contentmanager-contenttype-application, body.contentmanager-contenttype-veille_reglementaire {
    .banner-heading {
        margin-bottom: 3rem;
    }
}
