.product {
    &.data {
        &.items {
            & > .item {
                &.title {
                    box-sizing: border-box;
                    float: none;
                    width: 100%;

                    & > .switch {
                        display: block;
                        padding: 0.5rem 0;
                        border-bottom: 1px solid $lighter-grey;
                        font-weight: 600;
                        font-size: 1.25rem;
                        color: $black;
                        text-decoration: none;
                    }
                }

                &.content {
                    display: block;
                    float: none;
                    margin-bottom: 2rem;
                    padding: 1.25rem 0;
                    box-sizing: border-box;

                    &::after {
                        clear: both;
                    }

                    &.active {
                        display: block;
                    }
                }
            }
        }
    }
    &.media {
        margin-bottom: 1.5rem;

        .placeholder {
            .photo {
                &.container {
                    max-width: 100%;
                }
            }
        }
        .notice {
            color: #7d7d7d;
            font-size: 1.2rem;
            margin: 10px 0;
        }
        .product {
            &.thumbs {
                margin: 20px 0 30px;
            }
        }
        .items {
            &.thumbs {
                margin: 0;
                padding: 0;
                list-style: none none;

                & > li {
                    display: inline-block;
                    vertical-align: top;
                }
                .active {
                    display: block;
                    line-height: 1;
                }
            }
        }
    }
    &.info {
        &.detailed {
            clear: both;

            .additional-attributes {
                width: auto;
            }
        }
    }
}
.product.data.items > .item.content:before,
.product.data.items > .item.content:after {
    content: '';
    display: table;
}
.products {
    margin: 30px 0;

    &.wrapper {
        & ~ .toolbar {
            .toolbar-amount {
                display: none;
            }
            .sorter {
                display: none;
            }
        }
        & ~ .toolbar-products {
            .pages {
                display: block;
            }
        }
    }
}
.product-items {
    font-size: 0;
}
.product-item {
    font-size: 1.4rem;
    vertical-align: top;
    .product-reviews-summary {
        .rating-summary {
            margin: 0 4px 0 0;
        }
        .reviews-actions {
            font-size: 12px;
            margin-top: 5px;
        }
    }
    .price-box {
        margin: 10px 0 25px;
        .price {
            font-size: 1.4rem;
            font-weight: 700;
            white-space: nowrap;
        }
        .price-label {
            font-size: 12px;
            &::after {
                content: ':';
            }
        }
    }
    .special-price {
        display: block;
    }
    .old-price {
        .price {
            font-weight: 400;
        }
    }
    .regular-price {
        .price-label {
            display: none;
        }
    }
    .minimal-price {
        .price-container {
            display: block;
        }
    }
    .minimal-price-link {
        margin-top: 5px;
        .price-label {
            color: #1979c3;
            font-size: 1.4rem;
        }
        .price {
            font-weight: 400;
        }
    }
    .tocompare {
        &::before {
            content: '\e61f';
            font-family: 'icons-blank-theme';
        }
    }
    .tocart {
        white-space: nowrap;
    }
    &:nth-child(2n + 1) {
        margin-left: 0;
    }
}
.products-grid {
    .product-item {
        display: inline-block;
        margin-left: 2%;
        padding: 0;
        width: calc((100% - 2%) / 2);
    }
}
.product-item-name {
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    display: block;
    hyphens: auto;
    margin: 0.25rem 0;
    word-wrap: break-word;
}
.product-item-info {
    max-width: 100%;
    width: 152px;
}
.page-products {
    .product-item-info {
        width: 240px;
    }
    .columns {
        position: relative;
        z-index: 1;
    }
    .toolbar {
        .limiter {
            display: none;
        }
    }
}
.product-item-actions {
    font-size: 0;
    & > * {
        font-size: 1.4rem;
    }
    .actions-secondary {
        display: inline-block;
        font-size: 1.4rem;
        vertical-align: middle;
        white-space: nowrap;
        & > .action {
            line-height: 35px;
            text-align: center;
            width: 35px;
            &::before {
                margin: 0;
            }
        }
    }
    .actions-primary {
        display: inline-block;
        vertical-align: middle;
    }
}
.product-item-actions .actions-secondary > button.action,
.product-item-actions .actions-secondary > .cart.table-wrapper .actions-toolbar > .action.action,
.product-item-actions .actions-secondary > .action-gift.action {
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
}
.product-item-actions .actions-secondary > button.action:focus,
.product-item-actions .actions-secondary > button.action:active,
.product-item-actions .actions-secondary > .cart.table-wrapper .actions-toolbar > .action.action:focus,
.product-item-actions .actions-secondary > .cart.table-wrapper .actions-toolbar > .action.action:active,
.product-item-actions .actions-secondary > .action-gift.action:focus,
.product-item-actions .actions-secondary > .action-gift.action:active {
    background: none;
    border: none;
}
.product-item-actions .actions-secondary > button.action:hover,
.product-item-actions .actions-secondary > .cart.table-wrapper .actions-toolbar > .action.action:hover,
.product-item-actions .actions-secondary > .action-gift.action:hover {
    background: none;
    border: none;
}
.product-item-actions .actions-secondary > button.action.disabled,
.product-item-actions .actions-secondary > button.action[disabled],
fieldset[disabled] .product-item-actions .actions-secondary > button.action,
.product-item-actions .actions-secondary > .cart.table-wrapper .actions-toolbar > .action.action.disabled,
.product-item-actions .actions-secondary > .cart.table-wrapper .actions-toolbar > .action.action[disabled],
fieldset[disabled] .product-item-actions .actions-secondary > .cart.table-wrapper .actions-toolbar > .action.action,
.product-item-actions .actions-secondary > .action-gift.action.disabled,
.product-item-actions .actions-secondary > .action-gift.action[disabled],
fieldset[disabled] .product-item-actions .actions-secondary > .action-gift.action {
    pointer-events: none;
    opacity: 0.5;
}
.product-item-description {
    margin: 25px 0;
}
.product-item .special-price .price,
.product-item .minimal-price .price {
    font-size: 1.4rem;
    font-weight: 700;
}
.product-item .special-price .price-wrapper,
.product-item .minimal-price .price-wrapper {
    display: inline-block;
}
.product-item .special-price .price-including-tax + .price-excluding-tax,
.product-item .minimal-price .price-including-tax + .price-excluding-tax {
    display: block;
}
.product-item .minimal-price-link,
.product-item .price-excluding-tax,
.product-item .price-including-tax {
    display: block;
    white-space: nowrap;
}
.product-item .price-from,
.product-item .price-to {
    margin: 0;
}
.price-container {
    .price {
        font-size: 1.4rem;
    }
    .weee {
        &::before {
            content: '(' attr(data-label) ': ';
        }
        &::after {
            content: ')';
        }
        & + .price-excluding-tax {
            &::before {
                content: attr(data-label) ': ';
            }
        }
    }
}
.price-container .price-including-tax + .price-excluding-tax,
.price-container .weee {
    margin-top: 5px;
}
.price-container .price-including-tax + .price-excluding-tax,
.price-container .weee,
.price-container .price-including-tax + .price-excluding-tax .price,
.price-container .weee .price,
.price-container .weee + .price-excluding-tax:before,
.price-container .weee + .price-excluding-tax .price {
    font-size: 1.1rem;
}
.products-list {
    .product-item {
        display: table;
        width: 100%;
    }
    .product-item-info {
        display: table-row;
    }
    .product-item-photo {
        display: table-cell;
        padding: 0 30px 30px 0;
        vertical-align: top;
        width: 1%;
    }
    .product-item-details {
        display: table-cell;
        vertical-align: top;
    }
}
.toolbar-amount {
    display: block;
    line-height: 26px;
    margin: 0;
    padding: 8px 0 0;
    vertical-align: middle;
}
.toolbar-products {
    margin-bottom: 40px;
    padding: 0 10px;
    text-align: center;
    .pages {
        display: none;
    }
    .limiter {
        .control {
            display: inline-block;
        }
    }
}
.sorter {
    padding: 4px 0 0;
    .sorter-action {
        position: relative;
        top: -2px;
    }
    .sort-desc {
        &::before {
            content: '\e614';
            font-family: 'icons-blank-theme';
        }
    }
}
.sorter-options {
    margin: 0 0 0 7px;
    width: auto;
}
.sorter-action {
    vertical-align: top;
    display: inline-block;
    text-decoration: none;
    & > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    &::before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 28px;
        line-height: 32px;
        color: #8f8f8f;
        content: '\e613';
        font-family: 'icons-blank-theme';
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
    }
    &:hover {
        &::before {
            color: $black;
        }
    }
}
.modes {
    display: none;
}
.limiter-options {
    margin: 0 5px 0 7px;
    width: auto;
}
.limiter-label {
    font-weight: 400;
}
.old-price,
.old.price {
    text-decoration: line-through;
}
.prices-tier {
    .price-container {
        .price-including-tax {
            & + .price-excluding-tax {
                &::before {
                    content: '(' attr(data-label) ': ';
                }
                &:last-child {
                    &::after {
                        content: ')';
                    }
                }
            }
        }
        .weee[data-label] {
            display: inline;
            .price {
                font-size: 1.1rem;
            }
        }
        .weee[data-label]:before {
            content: ' +' attr(data-label) ': ';
        }
    }
}
.actual-price {
    font-weight: 700;
}
.category-image {
    .image {
        display: block;
        height: auto;
        max-width: 100%;
    }
}
.category-image,
.category-description {
    margin-bottom: 20px;
}
.product-image-container {
    display: inline-block;
    max-width: 100%;
}
.product-image-wrapper {
    display: block;
    height: 0;
    overflow: hidden;
    position: relative;
    z-index: 1;
}
.product-image-photo {
    bottom: 0;
    display: block;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}
.product.info.detailed .additional-attributes > thead > tr > td,
.product.info.detailed .additional-attributes > tbody > tr > td,
.product.info.detailed .additional-attributes > tfoot > tr > td {
    padding: 4px 5px 10px 5px;
}
.product.info.detailed .additional-attributes > thead > tr > th,
.product.info.detailed .additional-attributes > tbody > tr > th,
.product.info.detailed .additional-attributes > tfoot > tr > th {
    padding: 4px 30px 10px 0;
}
.page-product-grouped {
    .product-info-main {
        .product-info-stock-sku {
            display: none;
        }
    }
}
.product-info-main {
    .page-title-wrapper {
        .page-title {
            margin-bottom: 0.75rem;
            line-height: 1.42857143;
            font-size: 1.5rem;
            font-weight: 700;
        }
    }
    .product {
        &.attribute {
            &.sku {
                display: inline-block;
                vertical-align: top;

                & > .value {
                    display: inline-block;
                    vertical-align: top;
                    word-break: break-all;
                }

                .type {
                    margin-right: 5px;
                }
            }
            &.overview {
                margin-bottom: 1rem;
            }
        }
        &.alert {
            margin: 10px 0;
        }
    }
    .product-info-price {
        margin-bottom: 0.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid $lighter-grey;
    }
    .price-box {
        display: inline-block;
        margin-top: 0.75rem;
        margin-right: 1rem;

        .old-price {
            .price-wrapper {
                .price {
                    font-size: 1rem;
                }
            }
        }
    }
    .price-label {
        display: none;
    }
    .product-info-stock-sku {
        display: inline-block;
        color: $primary;
        font-weight: 400;
    }
    .product-ctifl-references {
        margin-bottom: 1.5rem;
    }
    .product-reviews-summary {
        .reviews-actions {
            font-size: 1.4rem;
        }
    }
    .product-tags {
        .product-tags__item {
            color: $primary;
        }
    }

    .stock.available, .stock.unavailable {
        display: inline-block;
        font-weight: 700;
        margin-right: 1.25rem;
        text-transform: uppercase;
        vertical-align: top;
    }
}
.product-options-wrapper {
    .fieldset-product-options-inner {
        .legend {
            font-weight: 700;
            margin: 0 0 5px;
            font-size: 1.4rem;
            border: none;
            display: inline-block;
            float: none;
            padding: 0;
        }
        input {
            &.datetime-picker {
                & ~ select {
                    &.datetime-picker {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
    .field {
        .note {
            display: block;
        }
    }
}
.product-options-wrapper .fieldset-product-options-inner {
    .required, ._required {
        .legend:after {
            content: '*';
            color: #e02b27;
            font-size: 1.2rem;
            margin: 0 0 0 5px;
        }
    }
}

.product-info-main, .product-options-bottom {
    .price-box {
        .price-including-tax + .price-excluding-tax,
        .weee + .price-excluding-tax,
        .weee {
            font-size: 1.2rem;
            line-height: 14px;
            margin-bottom: 5px;

            .price {
                font-size: 1.2rem;
                font-weight: 700;
            }
        }

        .price-wrapper .price {
            font-size: 2.25rem;
            font-weight: 700;
        }

        .price {
            white-space: nowrap;
        }
    }

    .special-price {
        display: inline-block;

        .price-container {
            font-size: 1.4rem;
        }

        .price-label + .price-wrapper {
            display: inline-block;
        }
    }

    .old-price, .special-price {
        .price-label:after {
            content: ': ';
        }
    }

    .box-tocart {
        margin: 20px 0;

        .field.qty {
            padding-right: 1rem;

            .label {
                display: block;
            }

            .number-input {
                --field-size: 2.5rem;
                --font-size: 1.5rem;
                --cta-font-size: 2.25rem;
            }
        }

        .input-text.qty {
            text-align: center;
        }
    }

    .product-addto-links {
        margin: 20px 0;
    }

    .action.tocompare {
        vertical-align: top;
    }
}

.product-tags {
    .product-tags__item {
        display: inline-block;
    }
}
.fotorama__stage__frame {
    .fotorama__img {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        transform: translate3d(0,0,0);
    }
}

.gallery-placeholder__image {
    max-height: 750px; //Current max calculated height for a square ratio fotorama-gallery item for this breakpoint and above
}

.page-product-downloadable {
    .product-add-form {
        clear: both;
        margin-bottom: 25px;
    }

    .product-options-bottom .field.qty {
        display: none !important;
    }
}

.table-downloadable-products {
    .col {
        width: unset;
    }

    td.col {
        padding: 8px 15px;
    }
}

//TODO factorise style with .responsables ?
.product-authors {
    margin-top: 1rem;
    margin-bottom: 2rem;

    .product-authors__title {
        display: inline-block;
        margin-bottom: 0.5rem;
        font-weight: 700;
    }

    .author {
        display: inline-block;

        &:not(:first-child) {
            &::before {
                content: "-";
                font-weight: 700;
            }
        }
    }
}

.product-book-infos {
    .price-box {
        display: inline-block;

        .price-label {
            display: none;
        }

        .old-price {
            display: none;
        }
    }

    .price-container .price {
        font-size: 1rem;
    }
}
