$col-padding-top: 20px;

.cart-summary {
    background: #f5f5f5;
    margin-bottom: 25px;
    padding: 2rem;

    > .title {
        @include sidebar-title();
        display: block;
        margin-bottom: 1rem;
        border: 0;
        line-height: 1.1;
    }

    .block {
        margin-bottom: 0;
        .item-options {
            margin-left: 0;
        }
        .fieldset {
            margin: 15px 0 25px 0;
            .field {
                margin: 0 0 10px;
                &.note {
                    font-size: 12px;
                }
            }
            .methods {
                .field {
                    & > .label {
                        display: inline;
                    }
                }
            }
        }
        & > .title {
            border-top: 1px solid #d1d1d1;
            cursor: pointer;
            font-weight: 600;
            display: block;
            text-decoration: none;
            margin-bottom: 0;
            overflow: hidden;
            padding: 7px 30px 7px 0;
            position: relative;
            &::after {
                position: absolute;
                right: -5px;
                top: -5px;
            }
        }
        & > .content {
            display: none;
        }
        &.active {
            & > .content {
                display: block;
            }
        }

        &.discount {
            #block-discount-heading {
                font-weight: 400;
            }

            .fieldset.coupon {
                display: flex;
                align-items: center;

                .field {
                    margin-bottom: 0;
                    max-width: 60%;
                }
            }

            .actions-toolbar {
                margin: 0 auto;
                margin-left: auto !important; //overrides a margin-left: 0 in blank applied on actions-toolbar in blank.scss
                padding-left: 1rem;

                .apply, .cancel {
                    @include button-to-link;
                }
            }
        }
    }
    #block-shipping {
        //Hard disable block shipping in cart
        display:none;
    }
}
.cart-totals {
    border-top: 1px solid #d1d1d1;
    padding-top: 10px;
    .table-wrapper {
        margin-bottom: 0;
        overflow: inherit;
    }
    .discount {
        &.coupon {
            display: none;
        }
    }
}
.cart {
    &.table-wrapper {
        .items {
            thead {
                & + .item {
                    border-top: 1px solid #d1d1d1;
                }
                span {
                    font-weight: 700;
                }

                .col {
                    &.subtotal {
                        white-space: nowrap;
                    }
                }
            }
            & > .item {
                border-bottom: 1px solid #d1d1d1;
                position: relative;
            }
        }
        .col {
            padding-top: $col-padding-top;

            &.qty {
                .input-text {
                    margin-top: -5px;
                }
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
        .item-actions {
            td {
                padding-bottom: 10px;
                text-align: center;
                white-space: normal;
            }

            .actions-toolbar {
                text-align: right;

                .action-delete {
                    @include button-to-link;
                }
            }
        }
        .item {
            .col {
                &.item {
                    display: block;
                    min-height: 75px;
                    padding: $col-padding-top 0 10px 75px;
                    position: relative;

                    .product-item-name {
                        font-size: 1.25rem;
                    }
                }
                &.subtotal {
                    color: $primary;
                }
                &.qty {
                    line-height: 1;
                    font-size: 1.4rem;
                    font-weight: 700;
                }
            }
        }
        .action {
            &.help {
                &.map {
                    font-weight: 400;
                }
            }
            &.configure {
                display: inline-block;
                margin: 0 0 20px;
            }
        }
        .product-item-photo {
            display: block;
            left: 0;
            max-width: 60px;
            padding: 0;
            position: absolute;
            top: 15px;
            width: 100%;
        }
        .product-item-details {
            white-space: normal;
        }
        .product-item-name {
            display: inline-block;
            font-weight: 400;
            margin-top: -6px;
            & + .item-options {
                margin-top: 10px;
            }
        }
        .gift-registry-name-label {
            &::after {
                content: ':';
            }
        }
        .item-options {
            font-size: 12px;
            margin-bottom: 10px;
        }
        .price-including-tax,
        .price-excluding-tax {
            font-size: 1.25rem;
        }
    }
}
.column {
    &.main {
        .cart-summary {
            .block {
                & > .title {
                    strong {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}
.cart.table-wrapper .actions-toolbar > .action:last-child,
.action-gift:last-child {
    margin-right: 0;
}
.cart-container {
    .checkout-methods-items {
        margin-top: 20px;
        text-align: center;
        .action {
            &.primary {
                width: 100%;
            }
        }
        .item {
            & + .item {
                margin-top: 20px;
            }
        }
    }
}
.cart-products-toolbar {
    .toolbar-amount {
        margin: 25px 0 15px;
        padding: 0;
        text-align: center;
    }
    .pages {
        margin: 0 0 25px;
        text-align: center;
        .items {
            & > .item {
                border-bottom: 0;
            }
        }
    }
}
.cart-products-toolbar-top {
    border-bottom: 1px solid #d1d1d1;
}
.block {
    &.crosssell {
        margin-top: 70px;
    }
}
.cart-tax-info + .cart-tax-total,
.cart .cart-tax-info + .cart-tax-total {
    display: block;
}
