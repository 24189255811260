$input-height: 40px;

input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type*="date"],
input[type="email"] {
    background: #ffffff;
    background-clip: padding-box;
    border: 1px solid $lighter-grey;
    border-radius: 2px;
    font-family: $roboto;
    font-size: 14px;
    height: $input-height;
    line-height: 1.42857143;
    padding: 0 9px;
    vertical-align: baseline;
    width: 100%;
    box-sizing: border-box;
}
input[type="text"]:disabled,
input[type="password"]:disabled,
input[type="url"]:disabled,
input[type="tel"]:disabled,
input[type="search"]:disabled,
input[type="number"]:disabled,
input[type*="date"]:disabled,
input[type="email"]:disabled {
    opacity: 0.5;
}
input[type="text"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="url"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
input[type="search"]::-moz-placeholder,
input[type="number"]::-moz-placeholder,
input[type*="date"]::-moz-placeholder,
input[type="email"]::-moz-placeholder {
    color: #c2c2c2;
}
input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="url"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="search"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type*="date"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder {
    color: #c2c2c2;
}
input[type="text"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="url"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type*="date"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder {
    color: #c2c2c2;
}
input[type="number"] {
    -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="search"] {
    -webkit-appearance: none;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
}
select {
    background: #ffffff;
    background-clip: padding-box;
    border: 1px solid #c2c2c2;
    border-radius: 1px;
    font-family: $roboto;
    font-size: 14px;
    height: $input-height;
    line-height: 1.42857143;
    padding: 5px 10px 4px;
    vertical-align: baseline;
    width: 100%;
    box-sizing: border-box;
}
select:disabled {
    opacity: 0.5;
}
select[multiple] {
    height: auto;
    background-image: none;
}
textarea {
    background: #ffffff;
    background-clip: padding-box;
    border: 1px solid #c2c2c2;
    border-radius: 1px;
    font-family: $roboto;
    font-size: 14px;
    height: auto;
    line-height: 1.42857143;
    margin: 0;
    padding: 10px;
    vertical-align: baseline;
    width: 100%;
    box-sizing: border-box;
    resize: vertical;
}
textarea:disabled {
    opacity: 0.5;
}
textarea::-moz-placeholder {
    color: #c2c2c2;
}
textarea::-webkit-input-placeholder {
    color: #c2c2c2;
}
textarea:-ms-input-placeholder {
    color: #c2c2c2;
}
input[type="checkbox"] {
    margin: 2px 5px 0 0;
    position: relative;
    top: 2px;
}
input[type="checkbox"]:disabled {
    opacity: 0.5;
}
input[type="checkbox"],
input[type="radio"] {
    appearance: none;
    display: inline-block;
    vertical-align: middle;
    margin: 2px 0;
    padding: 2px;
    width: 16px;
    height: 16px;
    background-color: $white;
    border: 1px solid $lighter-grey;
    overflow: hidden;
    outline: none;
    position: absolute;

    &::after {
        content: '';
        display: block;
        opacity: 0;
        margin: 1px;
        width: 8px;
        height: 8px;
        background-color: $primary;
    }

    &:checked::after {
        opacity: 1;
    }

    + label {
        position: relative;
        vertical-align: middle;
        margin-right: 2rem;
        padding: 0;
        line-height: 20px;
        cursor: pointer;
        padding-left: 2rem;
    }
}

input[type="radio"] {
    border-radius: 50%;

    &::after {
        border-radius: 50%;
    }
}

input[type="checkbox"].no-label,
input[type="radio"].no-label {
    left: -10000px;
    + label {
        position: relative;
        left: -10000px;
        &::before,
        &::after {
            left: 10000px;
        }
    }
}

input[type="radio"]:disabled {
    opacity: 0.5;
}
input.mage-error,
select.mage-error,
textarea.mage-error {
    border-color: #ed8380;
}
div.mage-error[generated] {
    color: #e02b27;
    font-size: 1.2rem;
}
input[type="button"],
input[type="reset"],
input[type="submit"] {
    cursor: pointer;
    -webkit-appearance: button;
}
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
form {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
address {
    font-style: normal;
}
*:focus {
    box-shadow: none;
    outline: 0;
}
._keyfocus *:focus,
input:not([disabled]):focus,
textarea:not([disabled]):focus,
select:not([disabled]):focus {
    box-shadow: none;
}
.form.giftregistry.items .actions-toolbar,
.form-new-agreement .actions-toolbar,
.fieldset .review-legend.legend,
.form.wishlist.items .actions-toolbar {
    margin-left: 0;
}
.abs-add-fields .action.remove,
.form-giftregistry-share .action.remove,
.form-giftregistry-edit .action.remove,
.form-add-invitations .action.remove,
.form-create-return .action.remove,
.form.send.friend .action.remove {
    left: 10px;
    margin-left: 70%;
    position: absolute;
    top: 31px;
    width: auto;
}
.form-giftregistry-share .fieldset,
.form-giftregistry-edit .fieldset,
.form-add-invitations .fieldset,
.form-create-return .fieldset,
.form.send.friend .fieldset {
    margin-bottom: 50px;
}
.form-giftregistry-share .fieldset .field:not(.choice) .control,
.form-giftregistry-edit .fieldset .field:not(.choice) .control,
.form-add-invitations .fieldset .field:not(.choice) .control,
.form-create-return .fieldset .field:not(.choice) .control,
.form.send.friend .fieldset .field:not(.choice) .control {
    width: 70%;
}
.form-giftregistry-share .fieldset .actions-toolbar:not(:first-child) > .secondary,
.form-giftregistry-edit .fieldset .actions-toolbar:not(:first-child) > .secondary,
.form-add-invitations .fieldset .actions-toolbar:not(:first-child) > .secondary,
.form-create-return .fieldset .actions-toolbar:not(:first-child) > .secondary,
.form.send.friend .fieldset .actions-toolbar:not(:first-child) > .secondary {
    float: left;
}
.form-giftregistry-share .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add,
.form-giftregistry-edit .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add,
.form-add-invitations .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add,
.form-create-return .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add,
.form.send.friend .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add {
    margin-top: 30px;
}
.form-giftregistry-share .fieldset .fields .actions-toolbar,
.form-giftregistry-edit .fieldset .fields .actions-toolbar,
.form-add-invitations .fieldset .fields .actions-toolbar,
.form-create-return .fieldset .fields .actions-toolbar,
.form.send.friend .fieldset .fields .actions-toolbar {
    margin: 0;
}
.form-giftregistry-share .message.notice,
.form-giftregistry-edit .message.notice,
.form-add-invitations .message.notice,
.form-create-return .message.notice,
.form.send.friend .message.notice {
    margin: 30px 0 0;
}
.form-giftregistry-share .additional,
.form-giftregistry-edit .additional,
.form-add-invitations .additional,
.form-create-return .additional,
.form.send.friend .additional {
    margin-top: 55px;
    position: relative;
}

.fieldset {
    border: 0;
    margin: 0 0 40px;
    padding: 0;
    letter-spacing: -.31em;
    >* {
        letter-spacing: normal;
    }
    >.legend {
        margin: 0 0 25px;
        padding: 0;
        box-sizing: border-box;
        float: left;
        line-height: 1.2;
        font-size: 2rem;
        + {
            br {
                clear: both;
                display: block;
                height: 0;
                overflow: hidden;
                visibility: hidden;
            }
        }
    }
    &:last-child {
        margin-bottom: 20px;
    }
    >.field {
        margin: 0 0 20px;
        position: relative;
        >.label {
            margin: 0 0 5px;
            display: inline-block;
            font-weight: 700;
            + {
                br {
                    display: none;
                }
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
        .choice {
            input {
                vertical-align: top;
            }
        }
        .fields.group {
            &::before {
                content: '';
                display: table;
            }
            &::after {
                content: '';
                display: table;
                clear: both;
            }
            .field {
                box-sizing: border-box;
                float: left;
            }
        }
        .fields.group.group-2 {
            .field {
                width: 50% !important;
            }
        }
        .fields.group.group-3 {
            .field {
                width: 33.3% !important;
            }
        }
        .fields.group.group-4 {
            .field {
                width: 25% !important;
            }
        }
        .fields.group.group-5 {
            .field {
                width: 20% !important;
            }
        }
        .addon {
            display: -webkit-inline-flex;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
            padding: 0;
            width: 100%;
            textarea {
                -ms-flex-order: 2;
                -webkit-order: 2;
                order: 2;
                -webkit-flex-basis: 100%;
                flex-basis: 100%;
                display: inline-block;
                margin: 0;
                width: auto;
            }
            select {
                -ms-flex-order: 2;
                -webkit-order: 2;
                order: 2;
                -webkit-flex-basis: 100%;
                flex-basis: 100%;
                display: inline-block;
                margin: 0;
                width: auto;
            }
            input {
                -ms-flex-order: 2;
                -webkit-order: 2;
                order: 2;
                -webkit-flex-basis: 100%;
                flex-basis: 100%;
                display: inline-block;
                margin: 0;
                width: auto;
            }
            .addbefore {
                background: #ffffff;
                background-clip: padding-box;
                border: 1px solid #c2c2c2;
                border-radius: 1px;
                font-family: $roboto;
                font-size: 14px;
                height: $input-height;
                line-height: 1.42857143;
                padding: 0 9px;
                vertical-align: baseline;
                width: 100%;
                box-sizing: border-box;
                -ms-flex-order: 3;
                -webkit-order: 3;
                order: 3;
                display: inline-block;
                vertical-align: middle;
                white-space: nowrap;
                width: auto;
                float: left;
                -ms-flex-order: 1;
                -webkit-order: 1;
                order: 1;
                &:disabled {
                    opacity: 0.5;
                }
                &::-moz-placeholder {
                    color: #c2c2c2;
                }
                &::-webkit-input-placeholder {
                    color: #c2c2c2;
                }
                &:-ms-input-placeholder {
                    color: #c2c2c2;
                }
            }
            .addafter {
                background: #ffffff;
                background-clip: padding-box;
                border: 1px solid #c2c2c2;
                border-radius: 1px;
                font-family: $roboto;
                font-size: 14px;
                height: $input-height;
                line-height: 1.42857143;
                padding: 0 9px;
                vertical-align: baseline;
                width: 100%;
                box-sizing: border-box;
                -ms-flex-order: 3;
                -webkit-order: 3;
                order: 3;
                display: inline-block;
                vertical-align: middle;
                white-space: nowrap;
                width: auto;
                &:disabled {
                    opacity: 0.5;
                }
                &::-moz-placeholder {
                    color: #c2c2c2;
                }
                &::-webkit-input-placeholder {
                    color: #c2c2c2;
                }
                &:-ms-input-placeholder {
                    color: #c2c2c2;
                }
            }
        }
        .additional {
            margin-top: 10px;
        }
        .note {
            font-size: 1.2rem;
            margin: 3px 0 0;
            padding: 0;
            display: inline-block;
            text-decoration: none;
            &::before {
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 24px;
                line-height: 12px;
                content: '\e618';
                font-family: 'icons-blank-theme';
                vertical-align: middle;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
            }
        }
        .field.choice {
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        input[type=file] {
            margin: 5px 0;
        }
    }
    >.fields {
        >.field {
            &:last-child {
                margin: 0 0 20px;
                margin-bottom: 0;
            }
            >.label {
                margin: 0 0 5px;
                display: inline-block;
                font-weight: 700;
                + {
                    br {
                        display: none;
                    }
                }
            }
            .choice {
                input {
                    vertical-align: top;
                }
            }
            .fields.group {
                &::before {
                    content: '';
                    display: table;
                }
                &::after {
                    content: '';
                    display: table;
                    clear: both;
                }
                .field {
                    box-sizing: border-box;
                    float: left;
                }
            }
            .fields.group.group-2 {
                .field {
                    width: 50% !important;
                }
            }
            .fields.group.group-3 {
                .field {
                    width: 33.3% !important;
                }
            }
            .fields.group.group-4 {
                .field {
                    width: 25% !important;
                }
            }
            .fields.group.group-5 {
                .field {
                    width: 20% !important;
                }
            }
            .addon {
                display: -webkit-inline-flex;
                display: -ms-inline-flexbox;
                display: inline-flex;
                -webkit-flex-wrap: nowrap;
                flex-wrap: nowrap;
                padding: 0;
                width: 100%;
                textarea {
                    -ms-flex-order: 2;
                    -webkit-order: 2;
                    order: 2;
                    -webkit-flex-basis: 100%;
                    flex-basis: 100%;
                    display: inline-block;
                    margin: 0;
                    width: auto;
                }
                select {
                    -ms-flex-order: 2;
                    -webkit-order: 2;
                    order: 2;
                    -webkit-flex-basis: 100%;
                    flex-basis: 100%;
                    display: inline-block;
                    margin: 0;
                    width: auto;
                }
                input {
                    -ms-flex-order: 2;
                    -webkit-order: 2;
                    order: 2;
                    -webkit-flex-basis: 100%;
                    flex-basis: 100%;
                    display: inline-block;
                    margin: 0;
                    width: auto;
                }
                .addbefore {
                    background: #ffffff;
                    background-clip: padding-box;
                    border: 1px solid #c2c2c2;
                    border-radius: 1px;
                    font-family: $roboto;
                    font-size: 14px;
                    height: $input-height;
                    line-height: 1.42857143;
                    padding: 0 9px;
                    vertical-align: baseline;
                    width: 100%;
                    box-sizing: border-box;
                    -ms-flex-order: 3;
                    -webkit-order: 3;
                    order: 3;
                    display: inline-block;
                    vertical-align: middle;
                    white-space: nowrap;
                    width: auto;
                    float: left;
                    -ms-flex-order: 1;
                    -webkit-order: 1;
                    order: 1;
                    &:disabled {
                        opacity: 0.5;
                    }
                    &::-moz-placeholder {
                        color: #c2c2c2;
                    }
                    &::-webkit-input-placeholder {
                        color: #c2c2c2;
                    }
                    &:-ms-input-placeholder {
                        color: #c2c2c2;
                    }
                }
                .addafter {
                    background: #ffffff;
                    background-clip: padding-box;
                    border: 1px solid #c2c2c2;
                    border-radius: 1px;
                    font-family: $roboto;
                    font-size: 14px;
                    height: $input-height;
                    line-height: 1.42857143;
                    padding: 0 9px;
                    vertical-align: baseline;
                    width: 100%;
                    box-sizing: border-box;
                    -ms-flex-order: 3;
                    -webkit-order: 3;
                    order: 3;
                    display: inline-block;
                    vertical-align: middle;
                    white-space: nowrap;
                    width: auto;
                    &:disabled {
                        opacity: 0.5;
                    }
                    &::-moz-placeholder {
                        color: #c2c2c2;
                    }
                    &::-webkit-input-placeholder {
                        color: #c2c2c2;
                    }
                    &:-ms-input-placeholder {
                        color: #c2c2c2;
                    }
                }
            }
            .additional {
                margin-top: 10px;
            }
            .note {
                font-size: 1.2rem;
                margin: 3px 0 0;
                padding: 0;
                display: inline-block;
                text-decoration: none;
                &::before {
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    font-size: 24px;
                    line-height: 12px;
                    content: '\e618';
                    font-family: 'icons-blank-theme';
                    vertical-align: middle;
                    display: inline-block;
                    font-weight: normal;
                    overflow: hidden;
                    speak: none;
                    text-align: center;
                }
            }
            .field.choice {
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            input[type=file] {
                margin: 5px 0;
            }
        }
        >.field.required {
            >.label {
                &::after {
                    content: '*';
                    color: $primary;
                    font-size: 1.2rem;
                    margin: 0 0 0 5px;
                    transform: translateY(-.3rem);
                }
            }
        }
        >.field._required {
            >.label {
                &::after {
                    content: '*';
                    color: $primary;
                    font-size: 1.2rem;
                    margin: 0 0 0 5px;
                    transform: translateY(-.3rem);
                }
            }
        }
        >.field.choice {
            .label {
                display: inline;
                font-weight: normal;
            }
        }
    }
    >.field.required {
        >.label,
        .special-required {
            &::after {
                content: '*';
                color: $primary;
                font-size: 1.2rem;
                margin: 0 0 0 5px;
                transform: translateY(-.3rem);
            }
        }
    }
    >.field._required {
        >.label {
            &::after {
                content: '*';
                color: $primary;
                font-size: 1.2rem;
                margin: 0 0 0 5px;
                transform: translateY(-.3rem);
            }
        }
    }
    >.field.choice {
        .label {
            display: inline;
            font-weight: normal;
        }
        label {
            display: flex;
        }
    }
}
.column {
    &:not(.main) {
        .fieldset {
            >.field {
                .label {
                    font-weight: normal;
                }
            }
            >.fields {
                >.field {
                    .label {
                        font-weight: normal;
                    }
                }
            }
        }
    }
}
.legend {
    + {
        .fieldset {
            clear: both;
        }
        div {
            clear: both;
        }
    }
    strong {
        margin-left: 5px;
    }
}
fieldset.field {
    border: 0;
    padding: 0;
}
.field.date {
    .time-picker {
        display: inline-block;
        margin-top: 10px;
        white-space: nowrap;
    }
}
.field {
    .message.warning {
        margin-top: 10px;
    }
}
.field-error {
    margin-top: 7px;
    color: #e02b27;
    font-size: 1.2rem;
}
div.mage-error[generated] {
    margin-top: 7px;
}

.webforms-contact-form {
    textarea {
        height: 10rem;
    }

    .actions-toolbar {
        margin-left: 0;

        .primary {
            float: right;
        }
    }
}
