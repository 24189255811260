.nav-sections {
    background: $white;
}

.nav-sections-item-title {
    display: none;
}

.nav-toggle {
    display: block;
    cursor: pointer;
    font-size: 0;
    text-decoration: none;

    &::before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 1.5rem;
        line-height: 1.5rem;
        color: $grey;
        content: '\e609';
        font-family: 'icons-blank-theme';
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
    }

    > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
}

.navigation {
    a.menu-item-active > span {
        text-decoration: none;
    }
}

@media only screen and (max-width: 768px) {
    .navigation {
        padding: 0;

        .parent {
            .level-top {
                display: block;
                text-decoration: none;
                position: relative;

                &::after {
                    position: absolute;
                    right: 7px;
                    top: -8px;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    font-size: 42px;
                    line-height: inherit;
                    color: inherit;
                    content: '\e622';
                    font-family: 'icons-blank-theme';
                    vertical-align: middle;
                    display: inline-block;
                    font-weight: normal;
                    overflow: hidden;
                    speak: none;
                    text-align: center;
                }

                .ui-state-active:after {
                    content: '\e621';
                    font-family: 'icons-blank-theme';
                }
            }
        }
    }

    .nav-sections {
        @include mobile-navigation-closed();
        bottom: 0;

        .switcher {
            border-top: 1px solid $lighter-grey;
            font-size: 1.6rem;
            font-weight: 700;
            margin: 0;
            padding: 0.8rem 3.5rem 0.8rem 2rem;

            .label {
                display: block;
                margin-bottom: 5px;
            }

            &:last-child {
                border-bottom: 1px solid $lighter-grey;
            }
        }

        .switcher-trigger {
            strong {
                display: block;
                text-decoration: none;
                position: relative;

                &::after {
                    position: absolute;
                    right: -40px;
                    top: -16px;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    font-size: 42px;
                    line-height: inherit;
                    color: inherit;
                    content: '\e622';
                    font-family: 'icons-blank-theme';
                    vertical-align: middle;
                    display: inline-block;
                    font-weight: normal;
                    overflow: hidden;
                    speak: none;
                    text-align: center;
                }
            }

            &.active {
                strong:after {
                    content: '\e621';
                    font-family: 'icons-blank-theme';
                }
            }
        }

        .switcher-dropdown {
            margin: 0;
            padding: 0;
            list-style: none none;
            display: none;
            padding: 10px 0;
        }

        .switcher-options.active .switcher-dropdown {
            display: block;
        }

        .header.links {
            margin: 0;
            padding: 0;
            list-style: none none;
            border-bottom: 1px solid $lighter-grey;

            li {
                font-size: 1.6rem;
                margin: 0;

                > a {
                    border-top: 1px solid $lighter-grey;
                }

                &.greet.welcome {
                    border-top: 1px solid $lighter-grey;
                    font-weight: 700;
                    padding: 0.8rem 15px;
                }
            }

            .header.links {
                border: 0;
            }
        }
    }
    .nav-sections .header.links a,
    .nav-sections .header.links a:hover {
        color: #575757;
        text-decoration: none;
        display: block;
        font-weight: 700;
        padding: 0.8rem 15px;
    }

    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;

        .page-wrapper {
            -webkit-transition: left 0.3s;
            -moz-transition: left 0.3s;
            -ms-transition: left 0.3s;
            transition: left 0.3s;
            height: 100%;
            left: 0;
            overflow: hidden;
            position: relative;
        }

        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }
    }

    .nav-open {
        .nav-sections {
            left: 0;

            @include light-box-shadow-and-top-inset(0.15);
        }

        .nav-toggle {
            padding-right: 1.25rem;
            padding-left: 0.5rem;

            &::before {
                font-size: 3rem;
                content: '\e616';
            }
        }
    }

    .nav-sections-items {
        z-index: 1;

        &::before, &::after {
            content: '';
            display: table;
        }
    }
    .nav-sections-items:after {
        clear: both;
    }
    .nav-sections-item-content {
        box-sizing: border-box;
        float: right;
        width: 100%;
    }
    .nav-sections-item-content:before,
    .nav-sections-item-content:after {
        content: '';
        display: table;
    }
    .nav-sections-item-content:after {
        clear: both;
    }
    .nav-sections-item-content.active {
        display: block;
    }


    .navigation {
        background: $lightest-grey;
        box-sizing: border-box;
        ul {
            margin: 0;
            padding: 0;
        }
        li {
            margin: 0;
            &.level0 {
                border-top: 1px solid $lighter-grey;

                &:last-child {
                    border-bottom: 1px solid $lighter-grey;
                }
            }
        }
        a {
            display: block;
            padding-top: 10px;
            padding-right: 0;
            padding-bottom: 10px;
            padding-left: 15px;
            line-height: 1.2;
        }
        .level0 {
            font-size: 1.25rem;

            & > .level-top {
                padding: 8px 40px 8px 15px;
                word-wrap: break-word;
            }
            &.active {
                .all-category {
                    .ui-state-focus {
                        border-color: $primary;
                        border-style: solid;
                        border-width: 0 0 0 8px;
                        padding-left: 7px;
                        display: inline-block;
                    }
                }
            }
            & > .level1 {
                font-weight: 600;
            }
        }
        li.level0 {
            position: relative;

            > .level0.submenu {
                display: none;
                border-top: 1px solid $lighter-grey;
            }

            &.level0-active-mobile {
                > .level0.submenu {
                    display: block;
                }

                > a.level-top span, > span {
                    color: $primary;
                    font-weight: 700;
                }
            }
        }
        .submenu {
            & > li {
                word-wrap: break-word;
                & > a {
                    &:hover {
                        color: $black;
                    }
                }
            }
            &:not(:first-child) {
                font-weight: 400;
                line-height: 1.3;
                left: auto !important;
                overflow-x: hidden;
                padding: 0;
                position: relative;
                top: auto !important;
                transition: left 0.3s ease-out;
                & > li {
                    & > a {
                        padding-left: 15px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                ul {
                    & > li {
                        margin: 0;
                        a {
                            display: block;
                            line-height: normal;
                            &:hover {
                                color: $black;
                            }
                        }
                    }
                }
                .active {
                    & > a {
                        border-color: $primary;
                        border-style: solid;
                        border-width: 0 0 0 8px;
                        padding-left: 7px;
                    }
                }
                .level1 {
                    &.active {
                        & > a {
                            padding-left: 7px;
                        }
                    }
                }
            }
            &:not(:first-child).expanded {
                display: block !important;
                padding-right: 0;
                top: 0 !important;
            }
        }
    }
    .navigation a,
    .navigation a:hover {
        text-decoration: none;
    }
    .navigation .level0.active > a:not(.ui-state-active),
    .navigation .level0.has-active > a:not(.ui-state-active) {
        border-color: $primary;
        border-style: solid;
        border-width: 0 0 0 8px;
    }
    .navigation .level0.active > a:not(.ui-state-active) span:not(.ui-menu-icon),
    .navigation .level0.has-active > a:not(.ui-state-active) span:not(.ui-menu-icon) {
        margin-left: -8px;
    }
}

/*****************************/
/***** MOBILE NAV FOOTER *****/
/*****************************/

[id="header.mobile.header.footer"] {
    display: block !important;
    position: fixed;
    bottom: 0;
}

.mobile-nav-footer {
    display: flex;
    background-color: $primary;
    text-align: center;

    .mobile-nav-footer__element {
        width: 34%;
    }

    .mobile-nav-footer__action {
        display: inline-block;
        padding: 1rem 0;
        width: 100%;
        color: $white;
        font-size: 1rem;

        &::before {
            color: $white;
            font-size: 1.5rem;
        }
    }

    .mobile-nav-footer__text {
        display: block;
        margin-top: 0.5rem;
    }

    .action.showcart .counter.qty {
        background-color: $white;
        border-color: $white;
        color: $primary;
    }
}
