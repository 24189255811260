.aw-event-tickets {
	&.dynamic-rows {
		&.storefront_description {
			.select_field {
				width: 20%;
				&._disabled {
					opacity: 1;
				}
				.admin__field {
					&.select_field {
						width: 100%;
					}
				}
			}
			.action_delete_button {
				&._disabled {
					opacity: 1;
				}
			}
		}
		&.sectors {
			.status_field {
				width: 20%;
			}
		}
	}
	&.sectors {
		.price-field {
			width: 20rem;
		}
		thead {
			.admin__field-tooltip {
				vertical-align: bottom;
				.admin__field-tooltip-action {
					margin-left: 0.5rem;
				}
				.admin__field-tooltip-content {
					right: -43rem;
					width: 46rem;
				}
			}
		}
		.admin__control-table-wrapper {
			overflow: inherit;
		}
	}
}
.aw-event-tickets.dynamic-rows.storefront_description .action_delete_button._disabled button,
.aw-event-tickets.dynamic-rows.storefront_description .action_delete_button._disabled .block-footer .action-add {
	visibility: hidden;
}
.aw-event-tickets.dynamic-rows.storefront_description td:nth-child(2),
.aw-event-tickets.dynamic-rows.storefront_description th:nth-child(2) {
	padding-left: 1rem;
}
.aw-event-tickets.sectors thead .admin__field-tooltip .admin__field-tooltip-content:after,
.aw-event-tickets.sectors thead .admin__field-tooltip .admin__field-tooltip-content:before {
	right: 39.5rem;
}
.page-main-actions {
	&._hidden {
		.aw-page-menu {
			display: none;
		}
	}
}
.aw-page-menu {
	color: #41362f;
	float: left;
	font-size: 1.6rem;
	margin-top: .7rem;
	.dropdown {
		display: inline-block;
		position: relative;
		.dropdown-menu {
			margin: 0;
			padding: 6px 0 3px 0;
			list-style: none none;
			box-sizing: border-box;
			background: #ffffff;
			border: 1px #adadad solid;
			position: absolute;
			z-index: 500;
			top: 100%;
			line-height: 1.36;
			min-width: 25.5rem;
			margin-top: -1px;
			display: none;
			transition: all 0.15s ease;
			visibility: hidden;
			opacity: 0;
		}
		&.active {
			.admin__action-dropdown-text {
				&::after {
					background-color: #ffffff;
					content: '';
					height: 6px;
					position: absolute;
					top: 100%;
					right: -6px;
					left: 0;
				}
			}
			.dropdown-menu {
				display: block;
				opacity: 1;
				visibility: visible;
				li {
					margin: 0;
					border: 0;
					cursor: default;
					font-size: 1.4rem;
					a {
						text-decoration: none;
						color: #41362f;
						display: block;
						padding: .6rem 1.8rem;
						&.separator {
							border-top: 1px #adadad solid;
							padding-top: .7rem;
						}
					}
				}
			}
		}
	}
	.admin__action-dropdown {
		font-size: 1.6rem;
		letter-spacing: .025em;
		background-color: #f8f8f8;
		z-index: 520;
		transition: border-color 0.15s ease;
	}
}
.aw-page-menu .dropdown:before,
.aw-page-menu .dropdown:after {
	content: '';
	display: table;
}
.aw-page-menu .dropdown.active .admin__action-dropdown,
.aw-page-menu .dropdown.active .dropdown-menu {
	border-color: #adadad;
	background-color: #fff;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}
.aw-page-menu .dropdown.active .dropdown-menu li a:hover,
.aw-page-menu .dropdown.active .dropdown-menu li a.current {
	background: #e9e9e9;
	cursor: pointer;
}
.aw-et__data-grid-radio-cell-inner {
	.admin__control-radio {
		&:checked {
			& + label {
				&::after {
					top: 3px;
				}
			}
		}
	}
}
.aw-et-summary-block {
	.venue-info {
		margin-bottom: 2rem;
	}
}
.catalog-product-edit .modal-slide .admin__action-multiselect-crumb .action-close,
.catalog-product-edit .modal-slide .modal-slide.pagebuilder_stage_template_pagebuilder_stage_template_modal .admin__action-multiselect-crumb .action-close {
	padding: 0;
}
.catalog-product-edit .modal-slide .admin__action-multiselect-crumb .action-close:before,
.catalog-product-edit .modal-slide .modal-slide.pagebuilder_stage_template_pagebuilder_stage_template_modal .admin__action-multiselect-crumb .action-close:before {
	font-size: 0.5rem;
}
.modal-slide {
	#product_composite_configure {
		.attendee-element {
			.admin__field-label {
				text-align: left;
			}
			._required {
				& > .admin__field-label {
					& > label {
						&::after {
							content: '';
						}
					}
				}
			}
		}
	}
}
.aw-et__select-products-modal .admin__data-grid-filters-wrap .admin__action-multiselect-crumb .action-close,
.aw-et__select-products-modal .admin__data-grid-filters-wrap .modal-slide.pagebuilder_stage_template_pagebuilder_stage_template_modal .admin__action-multiselect-crumb .action-close {
	padding: 0;
}
.aw-et__select-products-modal .admin__data-grid-filters-wrap .admin__action-multiselect-crumb .action-close:before,
.aw-et__select-products-modal .admin__data-grid-filters-wrap .modal-slide.pagebuilder_stage_template_pagebuilder_stage_template_modal .admin__action-multiselect-crumb .action-close:before {
	font-size: 1em;
}
.aw-et__weekly-row-container {
	margin-left: calc( ((100%) * 0.25) + 8px );
	margin-bottom: 25px;
	.admin__field-label {
		display: none;
	}
	.float-left {
		float: left !important;
		&.admin__field {
			margin: 0 7px 0 7px;
		}
	}
	.admin__field {
		&.weeks-count {
			input {
				width: 20px;
			}
			.admin__field-error {
				position: absolute;
			}
		}
	}
	.clearer {
		clear: both;
	}
}
.aw-et__time-slots {
	padding-top: 15px !important;
	.admin__control-table {
		width: 43%;
	}
}
.aw-et__view-options-container {
	clear: both;
	.options {
		.time-slot {
			.slot-description {
				padding: 15px 20px;
				background-color: #efefef;
				box-shadow: 0 3px 5px -5px #000000;
				position: relative;
				.date {
					font-size: 15px;
					font-weight: 600;
					line-height: 1.2;
					letter-spacing: -0.2px;
					text-transform: capitalize;
				}
			}
		}
	}
	.sectors {
		.sectors {
			padding-bottom: 0;
		}
		.required-message {
			color: #e02b27;
			margin-top: 1rem;
			padding: 0 15px;
			display: none;
			font-size: 1.2rem 1;
		}
		.attendee-element {
			& + .required-message {
				display: block;
			}
		}
		.sector-tickets {
			&.active {
				.header-sector-tickets {
					.arrow {
						transform: rotate(180deg);
					}
				}
			}
		}
		.header-sector-tickets {
			padding: 2rem 0 1rem;
			position: relative;
			cursor: pointer;
			.sector-storefront-description {
				margin-bottom: 1rem;
				.name {
					color: #1979c3;
					text-transform: uppercase;
					font-size: 1.6rem;
					line-height: 1.2;
					font-weight: 600;
				}
			}
			.price-range-ticket {
				font-size: 1.4rem;
				line-height: 0.9;
				font-weight: 600;
				color: #333333;
				margin-bottom: 1rem;
			}
			.block__status-qty {
				font-size: 1.4rem;
				line-height: 0.9;
				color: #999999;
			}
			.arrow {
				position: absolute;
				right: 0;
				top: 25px;
				display: inline-block;
				text-decoration: none;
				top: 30%;
				display: inline-block;
				text-decoration: none;
				&::before {
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					font-size: 1.6rem;
					line-height: 1;
					color: #333333;
					content: '\e622';
					font-family: 'icons-blank-theme';
					margin: 0;
					vertical-align: top;
					display: inline-block;
					font-weight: normal;
					overflow: hidden;
					speak: none;
					text-align: center;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					font-size: 46px;
					line-height: 1;
					color: #333333;
					content: '\e622';
					font-family: 'icons-blank-theme';
					margin: 0;
					vertical-align: top;
					display: inline-block;
					font-weight: normal;
					overflow: hidden;
					speak: none;
					text-align: center;
				}
				&:hover {
					&::before {
						color: #333333;
					}
				}
				&:active {
					&::before {
						color: inherit;
					}
				}
			}
		}
		.tickets-type {
			.tickets-type-item {
				background-color: #f9f9f9;
				border-bottom: 1px solid #c4c4c4;
				padding: 2rem;

				.tickets-type-item-content {
					display: -webkit-flex;
					display: -ms-flexbox;
					display: flex;
					justify-content: space-between;
					.item-content-ticket {
						min-width: 120px;
						color: #333333;
						& + .item-content-ticket {
							text-align: right;
							min-width: 130px;
						}
						.ticket-type-storefront-description {
							margin-bottom: 1rem;
							.name {
								font-size: 15px;
								font-weight: 600;
								line-height: 1.2;
								letter-spacing: -0.2px;
								text-transform: uppercase;
							}
						}
						.ticket-price {
							font-weight: bold;
							font-size: 1.4rem;
							line-height: 1.1;
							margin-bottom: 0.5rem;
						}
						.unit-price-ticket {
							font-size: 1.2rem;
							line-height: 1;
							clear: both;
							.sum-price-tiket {
								color: #999999;
							}
							.unit-price {
								font-weight: 600;
							}
						}
						.qty-ticket-inp-block {
							.input-text {
								width: 43px;
								text-indent: 9px;
							}
						}
					}
				}

                &:last-child {
                    border-bottom: none;
                }
			}
			.qty-inp-block {
				font-size: 0;
				overflow: hidden;
				border-radius: 3px;
				width: 89px;
				height: 30px;
				border: 1px solid #c2c2c2;
				clear: both;
				text-align: left;

				.input-text {
					font-size: 1.4rem;
					display: inline-block;
					width: 45px;
					border: none;
					border-left: 1px solid #c2c2c2;
					border-right: 1px solid #c2c2c2;
					outline: none;
					box-shadow: none;
					line-height: 30px;
					height: 30px;
					text-align: center;
					width: 43px;
				}
				.button-qty {
					font-size: 1.4rem;
					display: inline-block;
					width: 22px;
					padding: 0;
					box-shadow: none;
					border: none;
					vertical-align: top;
					line-height: 30px;
					background-color: #f4f4f4;
					position: relative;
					height: 30px;
					&:hover {
						background-color: #fbfbfb;
					}
					&::after {
						content: '';
						width: 9px;
						height: 9px;
						position: absolute;
						display: block;
						left: 30%;
						top: 39%;
					}
					&.decrease {
						&::after {
							width: 8px;
							background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjkiIHZpZXdCb3g9IjAgMCA4IDkiPiAgPGRlZnM+ICAgIDxzdHlsZT4gICAgICAuY2xzLTEgeyAgICAgICAgZmlsbDogIzdlN2U3ZTsgICAgICB9ICAgIDwvc3R5bGU+ICA8L2RlZnM+ICA8cmVjdCBpZD0iUmVjdGFuZ2xlXzJfY29weV83IiBkYXRhLW5hbWU9IlJlY3RhbmdsZSAyIGNvcHkgNyIgY2xhc3M9ImNscy0xIiB5PSI0IiB3aWR0aD0iOCIgaGVpZ2h0PSIxIi8+PC9zdmc+");
						}
					}
					&.increase {
						&::after {
							background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5IiBoZWlnaHQ9IjkiIHZpZXdCb3g9IjAgMCA5IDkiPiAgPGRlZnM+ICAgIDxzdHlsZT4gICAgICAuY2xzLTEgeyAgICAgICAgZmlsbDogIzdlN2U3ZTsgICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsgICAgICB9ICAgIDwvc3R5bGU+ICA8L2RlZnM+ICA8cGF0aCBpZD0iUmVjdGFuZ2xlXzJfY29weV83IiBkYXRhLW5hbWU9IlJlY3RhbmdsZSAyIGNvcHkgNyIgY2xhc3M9ImNscy0xIiBkPSJNOSw1SDVWOUg0VjVIMFY0SDRWMEg1VjRIOVY1WiIvPjwvc3ZnPg==");
						}
					}
				}
			}
			.price-box {
				.hide-price {
					display: none;
				}
				.show-price {
					display: inline;
				}
				.regular-price {
					&.old-price {
						text-decoration: line-through;
						.price-wrapper {
							& + .price-wrapper {
								display: none;
							}
						}
					}
				}
				.price-excluding-tax {
					&::before {
						font-weight: normal;
					}
				}
			}
			.products-item {
				.qty-inp-block {
					float: none;
				}
			}
		}
	}
	.aw-et-remove-slot-btn {
		padding: 0;
		margin: 0;
		border: none;
		position: absolute;
		right: 15px;
		top: 25px;
		background: transparent;
		display: inline-block;
		text-decoration: none;
		& > span {
			border: 0;
			clip: rect(0, 0, 0, 0);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px;
		}
		&::before {
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			font-size: 20px;
			line-height: 1;
			color: #333333;
			content: '\e604';
			font-family: 'icons-blank-theme';
			margin: 0;
			vertical-align: top;
			display: inline-block;
			font-weight: normal;
			overflow: hidden;
			speak: none;
			text-align: center;
		}
		&:hover {
			&::before {
				color: #333333;
			}
		}
		&:active {
			&::before {
				color: inherit;
			}
		}
	}
	.aw-et-remove-event {
		padding: 0;
		margin: 0;
		border: none;
		position: absolute;
		z-index: 100;
		right: 0;
		top: 0;
		background: transparent;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		-ms-box-shadow: none;
		box-shadow: none;
		display: inline-block;
		text-decoration: none;
		& > span {
			border: 0;
			clip: rect(0, 0, 0, 0);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px;
		}
		&::before {
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			font-size: 19px;
			line-height: 1;
			color: #ffffff;
			content: '\e616';
			font-family: 'icons-blank-theme';
			margin: 0;
			vertical-align: top;
			display: inline-block;
			font-weight: normal;
			overflow: hidden;
			speak: none;
			text-align: center;
		}
		&:hover {
			&::before {
				color: #ffffff;
			}
		}
		&:active {
			&::before {
				color: inherit;
			}
		}
	}
    .summary-container {
        .summary {
            &.active {
                .summary-header {
                    .arrow-icon {
                        transform: rotate(180deg);
                    }
                }
            }
        }
        .summary-header {
            border-bottom: 1px solid #e4e4e4;
            padding-bottom: 11px;
            margin-bottom: 25px;
            color: #333;
            overflow: hidden;
            position: relative;
            cursor: pointer;
            .title-sum {
                font-size: 18px;
                font-weight: 300;
                line-height: 1;
                letter-spacing: -.2px;
                float: left;
            }
            .ticket-sum {
                font-size: 14px;
                font-weight: 700;
                line-height: 1;
                letter-spacing: -.1px;
                float: right;
                margin-right: 3rem;
                position: relative;
            }
            .ticket-sum-product {
                &::after {
                    content: '+';
                    display: block;
                    position: absolute;
                    right: -17px;
                    top: 0;
                }
            }
            .arrow-icon {
                position: absolute;
                right: 0;
                top: 0;
                line-height: 1;
                display: inline-block;
                text-decoration: none;
                &::before {
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    font-size: 2rem;
                    line-height: 14px;
                    color: #333;
                    content: '\e622';
                    font-family: 'icons-blank-theme';
                    margin: 0;
                    vertical-align: top;
                    display: inline-block;
                    font-weight: 400;
                    overflow: hidden;
                    speak: none;
                    text-align: center;
                }
                &:hover {
                    &::before {
                        color: #333;
                    }
                }
                &:active {
                    &::before {
                        color: inherit;
                    }
                }
            }
        }
        .summary-detail-block {
            border-bottom: 1px solid #e4e4e4;
            margin-bottom: 25px;
            color: #333;
            .summary-default-msg {
                margin-bottom: 25px;
                font-size: 14px;
                line-height: 1.2;
            }
            .sector-element {
                margin-bottom: 20px;
                font-size: 14px;
                line-height: 1.2;
                padding-left: 15px;
                .sector-name-wrapper {
                    text-transform: uppercase;
                    font-weight: 700;
                    margin-bottom: 10px;
                }
            }
            .summary-item-block {
                &:last-child {
                    margin-top: 35px;
                }
            }
            .summary-item-block-title {
                margin-bottom: 13px;
                font-size: 16px;
                font-weight: 600;
                line-height: 1;
                text-transform: uppercase;
                text-align: left;
                color: #333;
            }
        }
        .price-wrapper {
            color: #575757;
            font-size: 36px;
            font-weight: 600;
            line-height: 1;
            margin-bottom: 40px;
        }
        .price-excluding-tax {
            font-size: 12px;
            line-height: 16px;
            margin-top: .5rem;
            &::before {
                content: attr(data-label) ': ';
                font-size: 1.1rem;
                font-weight: 400;
            }
        }
        .slot-description {
            margin-bottom: 10px;
            font-weight: 700;
            .date {
                display: inline;
            }
            .time {
                display: inline;
                margin-left: 10px;
            }
        }
    }
}
.aw-et-people-qty-filter {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
	-ms-align-items: center;
	align-items: center;
	-webkit-justify-content: flex-start;
	-moz-justify-content: flex-start;
	-ms-justify-content: flex-start;
	justify-content: flex-start;
	max-width: 200px;
	position: relative;
	bottom: -32px;
	.label-qty {
		margin-right: 15px;
	}
}
.aw-et-filter-qty-block {
	font-size: 0;
	overflow: hidden;
	border-radius: 3px;
	width: 89px;
	height: 30px;
	border: 1px solid #c2c2c2;
	float: right;
	clear: both;
	text-align: left;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	.input-text {
		font-size: 1.4rem;
		display: inline-block;
		width: 45px;
		border: none;
		border-left: 1px solid #c2c2c2;
		border-right: 1px solid #c2c2c2;
		outline: none;
		box-shadow: none;
		line-height: 30px;
		height: 30px;
		text-align: center;
	}
	.button-qty {
		font-size: 1.4rem;
		display: inline-block;
		width: 22px;
		padding: 0;
		box-shadow: none;
		border: none;
		vertical-align: top;
		line-height: 30px;
		background-color: #f4f4f4;
		position: relative;
		height: 30px;
		&:hover {
			background-color: #fbfbfb;
		}
		&::after {
			content: '';
			width: 9px;
			height: 9px;
			position: absolute;
			display: block;
			left: 30%;
			top: 39%;
		}
		&.decrease {
			&::after {
				width: 8px;
				background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjkiIHZpZXdCb3g9IjAgMCA4IDkiPiAgPGRlZnM+ICAgIDxzdHlsZT4gICAgICAuY2xzLTEgeyAgICAgICAgZmlsbDogIzdlN2U3ZTsgICAgICB9ICAgIDwvc3R5bGU+ICA8L2RlZnM+ICA8cmVjdCBpZD0iUmVjdGFuZ2xlXzJfY29weV83IiBkYXRhLW5hbWU9IlJlY3RhbmdsZSAyIGNvcHkgNyIgY2xhc3M9ImNscy0xIiB5PSI0IiB3aWR0aD0iOCIgaGVpZ2h0PSIxIi8+PC9zdmc+");
			}
		}
		&.increase {
			&::after {
				background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5IiBoZWlnaHQ9IjkiIHZpZXdCb3g9IjAgMCA5IDkiPiAgPGRlZnM+ICAgIDxzdHlsZT4gICAgICAuY2xzLTEgeyAgICAgICAgZmlsbDogIzdlN2U3ZTsgICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsgICAgICB9ICAgIDwvc3R5bGU+ICA8L2RlZnM+ICA8cGF0aCBpZD0iUmVjdGFuZ2xlXzJfY29weV83IiBkYXRhLW5hbWU9IlJlY3RhbmdsZSAyIGNvcHkgNyIgY2xhc3M9ImNscy0xIiBkPSJNOSw1SDVWOUg0VjVIMFY0SDRWMEg1VjRIOVY1WiIvPjwvc3ZnPg==");
			}
		}
	}
}
.aw-et__view-options-container input[type="number"]::-webkit-inner-spin-button,
.aw-et__view-options-container input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
}
.aw_event_tickets-ticket-index .admin__data-grid-wrap .action-select-wrap .action-menu,
.aw_event_tickets-recurring_ticket-index .admin__data-grid-wrap .action-select-wrap .action-menu,
.aw_event_tickets-ticket-index .admin__data-grid-wrap .action-select-wrap .abs-action-menu .action-submenu,
.aw_event_tickets-recurring_ticket-index .admin__data-grid-wrap .action-select-wrap .abs-action-menu .action-submenu,
.aw_event_tickets-ticket-index .admin__data-grid-wrap .action-select-wrap .action-menu .action-submenu,
.aw_event_tickets-recurring_ticket-index .admin__data-grid-wrap .action-select-wrap .action-menu .action-submenu,
.aw_event_tickets-ticket-index .admin__data-grid-wrap .action-select-wrap .actions-split .dropdown-menu .action-submenu,
.aw_event_tickets-recurring_ticket-index .admin__data-grid-wrap .action-select-wrap .actions-split .dropdown-menu .action-submenu,
.aw_event_tickets-ticket-index .admin__data-grid-wrap .action-select-wrap .actions-split .action-menu .action-submenu,
.aw_event_tickets-recurring_ticket-index .admin__data-grid-wrap .action-select-wrap .actions-split .action-menu .action-submenu {
	right: auto !important;
	min-width: auto !important;
}
.fc-event {
	&._disabled {
		pointer-events: none;
		cursor: default;
	}
}
.time-slot {
	.aw-et-remove-slot-btn {
		top: 20px;
		&::before {
			font-size: 30px;
		}
	}
}
.aw-et__space-configuration-panel-content {
	.admin__fieldset {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
}

@media all and (max-width: 767px) {
    .aw-et__view-options-container {
        clear: both;
    }
    .aw-et__view-options-container .sectors {
        /* margin: 0 -15px;*/
    }
    .aw-et__view-options-container .sectors .sector {
        padding: 0 15px;
    }
    .aw-et__view-options-container .sectors .tickets-type {
        padding-left: 0;
    }
}
