$card-item__img-height: 12rem;

.card-list {
    display: flex;
    flex-direction: column;

    .card-list__item {
        margin-bottom: 2rem;
    }
}

.card-item {
    position: relative;
    transition: all .35s ease;
    @include box-shadow;

    .card-item__img {
        display: inline-block;
        width: 100%;
        height: $card-item__img-height;
        background-size: cover;
        background-position: 50%;
    }

    .card-item__title {
        margin-bottom: 0.5rem;
        font-size: 1rem;
        font-weight: 700;
    }

    .card-item__body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem;
        min-height: calc(100% - #{$card-item__img-height})
    }

    .card-item__sticker {
        z-index: 1;

        @include card-sticker-colors;
    }

    .card-item__footer {
        margin-top: auto;
    }

    .card-item__link {
        display: inline-block;
        margin-top: auto;
        font-size: 1rem;

        @include link-underline($black, 1ch, 1.3rem, 1px);

        &:visited {
            color: $black;
        }

        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }

    &:hover {
        @include box-shadow-hover;
        cursor: pointer;

        .card-item__link {
            &:hover,
            &:focus {
                color: $primary;
                background-image: linear-gradient($primary, $primary);
            }
        }
    }
}
