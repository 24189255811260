@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.woff2') format("woff2");
    src:  url('../fonts/icomoon.eot?wfa6xw') format('embedded-opentype'),
    url('../fonts/icomoon.eot?wfa6xw#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?wfa6xw') format('truetype'),
    url('../fonts/icomoon.woff?wfa6xw') format('woff'),
    url('../fonts/icomoon.svg?wfa6xw#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@mixin icomoon-base {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: $grey;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"], [class*=" icon-"] {
    &::before {
        @include icomoon-base();
    }
}

@mixin icon-account {
    content: "\e900";
}
.icon-account:before {
    @include icon-account;
}

@mixin icon-minicart {
    content: "\e901";
}
.icon-minicart:before {
    @include icon-minicart;
}

@mixin icon-search {
    content: "\e902";
}
.icon-search:before {
    @include icon-search;
}

@mixin icon-pencil {
    content: "\e903";
}
.icon-pencil:before {
    @include icon-pencil;
}

@mixin icon-check-mark {
    content: "\e904";
}
.icon-check-mark:before {
    @include icon-check-mark;
}

@mixin icon-actualites {
    content: "\e905";
}
.icon-actualites:before {
    @include icon-actualites;
}

@mixin icon-publications {
    content: "\e906";
}
.icon-publications:before {
    @include icon-publications;
}

@mixin icon-proj_resultats {
    content: "\e907";
}
.icon-proj_resultats:before {
    @include icon-proj_resultats;
}

@mixin icon-ctifl {
    content: "\e908";
}
.icon-ctifl:before {
    @include icon-ctifl;
}

@mixin icon-especes {
    content: "\e909";
}
.icon-especes:before {
    @include icon-especes;
}

@mixin icon-formations {
    content: "\e91d";
}
.icon-formations:before {
    @include icon-formations;
}

@mixin icon-produits_services {
    content: "\e910";
}
.icon-produits_services:before {
    @include icon-produits_services;
}

@mixin icon-evenements {
    content: "\e90b";
}
.icon-evenements:before {
    @include icon-evenements;
}

@mixin icon-download {
    font-family: 'icomoon' !important;
    content: "\e90c";
}
.icon-download::before {
    @include icon-download;
}

@mixin icon-printer {
    font-family: 'icomoon' !important;
    content: "\e90d";
}
.icon-printer::before {
    @include icon-printer;
}

@mixin icon-phone {
    content: "\e90e";
}

@mixin icon-envelope {
    content: "\e90f";
}
@mixin icon-qna {
    content: "\e90a";
}
.icon-qna:before {
    @include icon-qna;
}

@mixin icon-website {
    content: "\e911";
}
.icon-website::before {
    @include icon-website;
    color: $primary;
}

@mixin formation-material_practices {
    content: "\e911";
}
.icon-formation-material_practices::before {
    @include formation-material_practices;
}

@mixin formation-outlet_merchandising {
    content: "\e914";
}
.icon-formation-outlet_merchandising::before {
    @include formation-outlet_merchandising;
}

@mixin formation-business_skills {
    content: "\e915";
}
.icon-formation-business_skills::before {
    @include formation-business_skills;
}

@mixin formation-quality_workflow {
    content: "\e916";
}
.icon-formation-quality_workflow::before {
    @include formation-quality_workflow;
}

@mixin formation-balance_animate {
    content: "\e917";
}
.icon-formation-balance_animate::before {
    @include formation-balance_animate;
}

@mixin formation-product_knowledge {
    content: "\e918";
}
.icon-formation-product_knowledge::before {
    @include formation-product_knowledge;
}

@mixin formation-moyen-pedago {
    content: "\e912";
}
.icon-formation-moyen-pedago::before {
    @include formation-moyen-pedago;
}

@mixin formation-evaluation {
    content: "\e919";
}
.icon-formation-evaluation::before {
    @include formation-evaluation;
}

@mixin formation-public {
    content: "\e91a";
}
.icon-formation-public::before {
    @include formation-public;
}

@mixin formation-contenu {
    content: "\e91b";
}
.icon-formation-contenu:before {
    @include formation-contenu;
}

@mixin formation-objectif {
    content: "\e91c";
}
.icon-formation-objectif::before {
    @include formation-objectif;
}
