/* SIPL = Side Image Product Layout */
.sipl {
    .page-main {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;

        .column.main {
            padding-left: 0;
        }
    }

    .sipl__content-wrapper {
        margin: 0 auto;
        padding-left: 1rem;
        padding-right: 1rem;
        width: 100%;
    }

    .breadcrumbs {
        padding-left: 0;
        padding-right: 0;
    }

    .sipl__heading-container {
        padding: 5rem 0;
        background-color: $primary;
        color: $white;

        .page-title {
            display: inline-block;
            margin-bottom: 0.5rem;
            font-size: 2rem;
            font-weight: 700;
        }

        .product-tags {
            margin-bottom: 0.5rem;
        }
    }

    .sipl__maincontent,
    .request-form {
        width: 100%;
        padding-top: 3rem;
    }

    .sipl__maincontent {
        &::after {
            content: '';
            display: block;
            clear: both;
        }
    }

    .sipl__side-container {
        margin-bottom: 3rem;
        background-color: $lightest-grey;

        .sipl__product-image {
            width: 100%;
        }

        .sipl__side-container__info {
            padding: 1.5rem;

            .sipl__side-container__header {
                margin-bottom: 1.5rem;
                text-align: center;
            }

            .sipl__side-container__footer {
                padding-top: 1.5rem;
                border-top: 1px solid $lighter-grey;
            }

            .sipl__side-container__name {
                display: block;
                font-weight: 700;
            }

            .sipl__file-list {
                margin-bottom: 2rem;
            }

            .dl-link {
                margin-bottom: 1rem;
                border-bottom: 1px solid $lighter-grey;
            }
        }

        .sipl__side-container__cta-container {
            display: flex;
            align-items: center;
            margin-bottom: 1.25rem;

            .product-info-main {
                width: auto;
            }

            .product-info-price {
                color: $primary;
            }

            .sipl__side-container__cta {
                margin-left: auto;
            }
        }

        .product-info-main .product-info-price {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }
    }

    .sipl__content-column {
        .sipl__content-column__section{
            margin-bottom: 3rem;
        }

        .sipl__content-column__title {
            position: relative;
            display: inline-block;
            margin-bottom: 1.5rem;
            width: 100%;
            color: $primary;
            font-size: 1.375rem;
            font-weight: 700;

            .sipl__content-column__title-text {
                padding-right: 1rem;
                background-color: $white;
            }
        }

        .sipl__content-column__text {
            font-size: 1.125rem;

            @include wysiwyg-content-styles;
        }
    }
}
