.wishlist.split.button {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}
.wishlist.split.button:before,
.wishlist.split.button:after {
    content: '';
    display: table;
}
.wishlist.split.button:after {
    clear: both;
}
.wishlist.split.button .action.split {
    float: left;
    margin: 0;
}
.wishlist.split.button .action.toggle {
    float: right;
    margin: 0;
}
.wishlist.split.button button.action.split,
.wishlist.split.button .cart.table-wrapper .actions-toolbar > .action.action.split,
.wishlist.split.button .action-gift.action.split {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.wishlist.split.button button + .action.toggle,
.wishlist.split.button .cart.table-wrapper .actions-toolbar > .action + .action.toggle,
.wishlist.split.button .action-gift + .action.toggle {
    border-bottom-left-radius: 0;
    border-left: 0;
    border-top-left-radius: 0;
}
.wishlist.split.button .action.toggle {
    display: inline-block;
    text-decoration: none;
}
.wishlist.split.button .action.toggle > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.wishlist.split.button .action.toggle:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 22px;
    color: #7d7d7d;
    content: '\e622';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.wishlist.split.button .action.toggle:hover:after {
    color: $black;
}
.wishlist.split.button .action.toggle:active:after {
    color: inherit;
}
.wishlist.split.button .action.toggle.active {
    display: inline-block;
    text-decoration: none;
}
.wishlist.split.button .action.toggle.active > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.wishlist.split.button .action.toggle.active:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 22px;
    color: #7d7d7d;
    content: '\e621';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.wishlist.split.button .action.toggle.active:hover:after {
    color: $black;
}
.wishlist.split.button .action.toggle.active:active:after {
    color: inherit;
}
.wishlist.split.button .items {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #ffffff;
    border: 1px solid #bbbbbb;
    margin-top: 4px;
    min-width: 200px;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
}
.wishlist.split.button .items li {
    margin: 0;
    padding: 5px 5px 5px 23px;
}
.wishlist.split.button .items li:hover {
    background: #e8e8e8;
    cursor: pointer;
}
.wishlist.split.button .items:before,
.wishlist.split.button .items:after {
    border-bottom-style: solid;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0;
}
.wishlist.split.button .items:before {
    border: 6px solid;
    border-color: transparent transparent #ffffff transparent;
    z-index: 99;
}
.wishlist.split.button .items:after {
    border: 7px solid;
    border-color: transparent transparent #bbbbbb transparent;
    z-index: 98;
}
.wishlist.split.button .items:before {
    left: 10px;
    top: -12px;
}
.wishlist.split.button .items:after {
    left: 9px;
    top: -14px;
}
.wishlist.split.button.active {
    overflow: visible;
}
.wishlist.split.button.active .items {
    display: block;
}
.wishlist.split.button .items {
    text-align: left;
}
.wishlist.split.button .items .item > span {
    display: block;
    padding: 5px 5px 5px 23px;
}
.wishlist.split.button .items .item:last-child:hover {
    background: #e8e8e8;
}
.wishlist.split.button .items li {
    padding: 0;
}
.wishlist.window.popup {
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #aeaeae;
    padding: 22px;
    width: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
    transition: opacity 0.3s linear;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1001;
    display: none;
    opacity: 0;
}
.wishlist.window.popup .popup-header {
    margin: 0 0 25px;
    padding-right: 22px;
}
.wishlist.window.popup .popup-header .title {
    font-weight: 300;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}
.wishlist.window.popup .popup-actions .action.close {
    position: absolute;
    display: inline-block;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    right: 10px;
    top: 10px;
}
.wishlist.window.popup .popup-actions .action.close > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.wishlist.window.popup .popup-actions .action.close:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 22px;
    color: inherit;
    content: '\e616';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.wishlist.window.popup .popup-actions .action.close:hover:before {
    color: inherit;
}
.wishlist.window.popup .popup-actions .action.close:active:before {
    color: inherit;
}
.wishlist.window.popup .popup-actions .action.close:focus,
.wishlist.window.popup .popup-actions .action.close:active {
    background: none;
    border: none;
}
.wishlist.window.popup .popup-actions .action.close:hover {
    background: none;
    border: none;
}
.wishlist.window.popup .popup-actions .action.close.disabled,
.wishlist.window.popup .popup-actions .action.close[disabled],
fieldset[disabled] .wishlist.window.popup .popup-actions .action.close {
    pointer-events: none;
    opacity: 0.5;
}
.wishlist.window.popup.active {
    opacity: 1;
}
.wishlist.window.popup.active {
    display: block;
}
.wishlist.overlay {
    transition: opacity 0.15s linear;
    background: #000000;
    z-index: 899;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
}
.wishlist.overlay.active {
    opacity: 0.5;
    filter: alpha(opacity=50);
}
.wishlist.overlay.active {
    display: block;
}
.block-wishlist-management {
    position: relative;
}
.block-wishlist-management .wishlist-select .wishlist-name-label {
    font-weight: 600;
}
.block-wishlist-management .wishlist-title {
    margin-bottom: 10px;
}
.block-wishlist-management .wishlist-title strong {
    font-weight: 300;
    margin-right: 10px;
}
.block-wishlist-management .wishlist-notice {
    color: #7d7d7d;
    display: inline-block;
    margin-right: 20px;
}
.block-wishlist-management .wishlist-toolbar-select,
.block-wishlist-management .wishlist-toolbar-actions {
    display: inline-block;
}
.block-wishlist-management .wishlist-toolbar-select {
    margin-right: 20px;
}
.block-wishlist-management .wishlist-dropdown.move {
    margin-right: 20px;
}
.block-wishlist-search-form .form-wishlist-search {
    margin-bottom: 60px;
    max-width: 500px;
}
.block-wishlist-search-results .toolbar-wishlist-results {
    text-align: center;
}
.block-wishlist-info-items .block-title {
    font-size: 2.2rem;
    margin-bottom: 20px;
}
.block-wishlist-info-items .block-title > strong {
    font-weight: 300;
}
.block-wishlist-info-items .product-item {
    width: 100%;
}
.block-wishlist-info-items .product-item-photo {
    margin-left: 20px;
}
.block-wishlist-info-items .product-item-info {
    width: auto;
}
.block-wishlist-info-items .product-item-info:hover .split {
    visibility: visible;
}
.block-wishlist-info-items .price-wrapper {
    display: block;
    margin: 0 0 10px;
}
.block-wishlist-info-items .split {
    clear: both;
    padding: 10px 0;
}
.block-wishlist-info-items .action.towishlist {
    background: none;
    border: none;
    padding: 10px 0;
}
.block-wishlist-info-items .input-text.qty {
    margin-right: 10px;
}

.product-info-main .action.towishlist,
.product-options-bottom .action.towishlist,
.aw-et__view-options-container .action.towishlist,
.block-bundle-summary .action.towishlist {
    .wishlist-heart {
        height: 30px;
        transform: translateY(33%);
        margin-left: 1rem;
        .wishlist-heart-outline {
            fill: $primary;
        }
        .wishlist-heart-background {
            fill: $primary;
            fill-opacity: 0;
            transition: fill-opacity ease-in-out .15s;
            &:focus,
            &:active,
            &:hover {
                fill-opacity: 1;
            }
        }
    }
}
.wishlist-index-index .products-grid {
    .product-items {
        margin: 0;
    }
    .product-item {
        margin-left: calc((100% - 3 * 30%) / 3);
        width: 30%;
        height: 100%;
        padding: 10px;

        @include box-shadow;
        @include box-shadow-hover;
        &:nth-child(3n + 1) {
            margin-left: 0;
        }
        .product-item-name {
            height: 3.7rem;
            line-height: 1.2rem;
            overflow: hidden;
            margin: 1rem 0;
            .product-item-link {
                font-size: 1.2rem;
            }
        }

        .price-as-configured {
            text-align: center;
        }
        .actions-primary {
            width: 100%;
            .tocart {
                width: 100%;
            }
        }
        .product-item-actions {
            text-align: center;
            > * {
                font-size: 1rem;
            }
            .action.tocart.primary {
                font-family: $roboto;
                width: 100%;
            }
            .btn-remove.action.delete {
                @include link-underline($primary, 2ch, 22px, 1px, $primary);
                display: inline-block;
            }
        }
    }
}

.wishlist-index-index {
    .fieldset {
        &::before,
        & ::after {
            content: unset;
        }
        > .field:not(.choice) > .control {
            width: unset ;
            &::before,
            &::after {
                content: unset;
            }
        }
    }
}
