$vertcial-thread-left-margin: 18px;

.press-space {

    &-navigator {
        width: 280px;
        display: none;
        background-color: $lightest-grey;
        float: left;
        &-body {
            padding: 10px 20px;
            &-title {
                display: flex;
                justify-content: space-between;
                font-weight: 700;
                font-size: 1.1rem;
            }
            &-summary {
                max-height: calc(100vh - 550px);
                overflow-y: scroll;
                overflow-x: visible;
                margin-top: 1rem;
                position: relative;
                ul {
                    width: calc(100% - 17px);
                    margin-left: 10px;
                    li {
                        margin-left: $vertcial-thread-left-margin;
                        margin-bottom: 0;
                        padding-top: 0.5rem;
                        padding-bottom: 0.5rem;
                        position: relative;
                        &::before {
                            content: '';
                            width:  0.5rem;
                            height: 0.5rem;
                            border-radius: 50%;
                            background-color: $lighter-grey;
                            position: absolute;
                            left: -$vertcial-thread-left-margin;
                            transform: translate(-50%, 0.25rem);
                            line-height: 1rem;
                            transition: all 0.25s ease-in;
                            transition-delay: 250ms;
                            z-index: 3;
                        }
                        &::after {
                            content: '';
                            width: 1px;
                            height: 100%;
                            position: absolute;
                            transform: translateX(-100%);
                            top: 1rem;
                            background-color: $lighter-grey;
                            left: -$vertcial-thread-left-margin;
                            transition: all 0.25s ease;
                            z-index: 1;
                        }

                        &:last-child::after,
                        &.passed:last-child::after,
                        &.active:last-child::after {
                            content: unset;
                        }
                        &.passed::after,
                        &.passed::before,
                        &.active::before {
                            background-color: $primary;
                        }
                        &.active::before {
                            width:  0.75rem;
                            height: 0.75rem;
                            z-index: 100;
                        }
                        a {
                            color: $black;
                            &:hover {
                                color: $primary;
                            }
                        }
                    }
                }
            }
            &-share {
                display: flex;
                flex-direction: row;
                margin: 25px $vertcial-thread-left-margin;

                .socials {
                    &__link {
                        margin-right: 20px;
                        line-height: 20px;

                        &:hover {
                            .socials__icon {
                                fill: $black;
                            }
                        }
                    }

                    &__item {
                        float: left;
                        margin: 0 0.75rem;
                    }

                    &__icon {
                        display: block;
                        fill: $primary;
                        transition: fill 0.15s ease-in-out;
                    }
                }
            }
        }

        &-footer {
            background-color: $lightest-grey-nearly;
            font-family: $roboto;
            font-size: 0.8rem;
            color: $black;
            display: flex;
            justify-content: space-around;
            flex-direction: row;
            align-items: center;
            li {
                padding: 10px;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                margin-bottom: 0;
                position: relative;

                a {
                    font-family: $roboto !important;
                    color: $black;
                    &:hover,
                    &:active {
                        font-weight: 700;
                        color: $black;
                    }
                    &::before {
                        color: $black;
                        margin-right: 1rem;
                        font-size: 120%;
                        transform: translateY(-30%);
                    }
                    &::after {
                        position: absolute;
                        content: '';
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            li:not(:first-child)::before {
                content:'';
                position: absolute;
                width: 1px;
                height: 50%;
                left: 0;
                background-color: $black;
            }

        }
    }

    &-body {
        width: 100%;
        margin-left: 0;
        h4 {
            font-size: 2rem;
            margin-bottom: 2rem;
        }
        &-releases,
        &-presentation,
        &-ressources,
        &-logo,
        &-reports,
        &-contacts {
            margin-bottom: 80px;
        }
        &-presentation,
        &-ressources,
        &-logo,
        &-contacts {
            a {
                @include link-underline($black, 1ch, 7px, 1px, $black)
            }
        }
        &-releases {
            &-release {
                &-header {
                    &-date {
                        color: $light-grey;
                        margin-right: 25px;
                    }
                    &-tags {
                        display: inline;
                        color: $primary;
                        a.tag {
                            margin-right: 18px;
                            &::before {
                                content: '#';
                            }
                        }
                    }
                }
                &-link {
                    margin-top: 0.7rem;
                    a {
                        @include link-underline($black, 1ch, 7px, 1px, $black);
                        font-weight: 700;
                    }
                }
            }
        }

        &-presentation {
            &-body {
                margin-left: 30px;
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    left: -30px;
                    background-color: $primary;
                    height: 100%;
                    width: 10px;
                }
                &-text {
                    margin-bottom: 3rem;
                }
                &-link {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    height: 3rem;
                    width: 100%;
                    position: relative;
                    a {
                        position: relative;
                        height: 25px;
                    }
                }
            }
        }

        &-ressources {
            &-images {
                max-height: 200px;
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                img {
                    width: 22%;
                    object-fit: cover;
                }
            }
            &-text {
                margin: 1.5rem 0;
            }
        }

        &-logo {
            &-body {
                display: flex;
                flex-direction: row;
                &__left-column {
                    width: 0;
                    img {
                        display: none;
                    }
                }
                &__right-column {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-left: 0;
                    &__text {

                    }
                    &__files {
                        display: flex;
                        flex-direction: column;
                    }
                    &__file {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        height: 3rem;
                        width: 100%;
                        border-bottom: 1px solid $lighter-grey;
                        padding: 10px 0;
                        position: relative;
                        a {
                            position: relative;
                            height: 25px;
                        }
                    }
                }
            }
        }

        &-reports {
            &-body {
                margin-bottom: 40px;
            }
        }

        &-contacts {
            background-color: $primary;
            color: $white;
            padding : 20px 40px;
            &-text {
                margin: 1rem 0 1.75rem;
            }
            a {
                color: $white;
                background-image: linear-gradient($white, $white);
            }
            .email,
            .phone {
                margin: 0.8rem 0;
                &::before {
                    font-family: $icomoon;
                    margin-right: 15px;
                }
            }
            .email::before {
                @include icon-envelope;
            }
            .phone::before {
                @include icon-phone;
            }
        }

        //TODO factorize with dl-link
        // and project
        .icon-download-after {
            width: 150px;
            text-align: right;
            position: relative;
            padding: 10px 20px 10px 10px;
            font-weight: 700;
            color: $black;
            font-family: $roboto!important;
            white-space: nowrap;
            &::before {
                content: '|';
                font-size: 1.6em;
                font-weight: 400 !important;
                color: $lighter-grey;
                position: absolute;
                left: 0;
                transform: translateY(-0.3em);
            }
            &::after {
                font-weight: 400;
                margin-left: 20px;
                @include icon-download;
            }
        }
        .download-only {
            background: unset;
            position: unset;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.press-release {
    &-header__published-at {
        color: $light-grey;
    }
    .tags {
        color: $primary;
    }
    &-body-main {

    }
}
