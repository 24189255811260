$pager-color: $dark-grey;

.pages {
    > .label {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .items {
        display: inline-block;
        margin: 0;
        margin-bottom: 1rem;
        padding: 0;
        font-size: 0;
        white-space: nowrap;
        list-style: none none;
    }

    .item {
        display: inline-block;
        padding: 0 1rem;
        color: $pager-color;
        font-size: 1rem;
        line-height: 1rem;
        letter-spacing: normal;
        margin: 0 2px 0 0;
        font-weight: 100;

        .label {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        .page {
            color: $pager-color;
        }

        &.current .page {
            display: inline-block;
            font-weight: 900;
            color: $ultra-black;
            text-decoration: none;
            background-image: linear-gradient($black, $black);
            background-position: 0 100%;
            background-repeat: no-repeat;
            background-size: 100% 1px;
            height: 20px;
        }
    }

    .action {
        display: inline-block;
        color: $pager-color;
        text-decoration: none;

        &:visited {
            color: $pager-color;
        }

        &:hover {
            color: $pager-color;
            text-decoration: none;
        }

        &:active {
            color: $pager-color;
        }

        &.next,
        &.previous {
            display: inline-block;
            text-decoration: none;

            &:before {
                color: $pager-color;
                margin: 0 0 0 -6px;
                display: inline-block;
                overflow: hidden;
                speak: none;
                text-align: center;
            }

            &:visited,
            &:active {
                &:before {
                    color: $pager-color
                }
            }

            > span {
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }
        }

        &.next:before {
            content: "→";
        }

        &.previous:before {
            content: "←";
        }
    }
}
